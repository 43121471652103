import { useCallback } from "react";
import { API, graphqlOperation, Logger } from "aws-amplify";
import * as mutations from "graphql/mutations";
import * as queries from "graphql/queries";
import { useSnackbar } from "notistack";
import useAppState from "store/appState";
import { useDemoConfig } from "store/serverState";
import useNoticeAction from "./noticeAction";
const logger = new Logger("Scenario Functions", "INFO");

export default function useScenarios() {
  const { enqueueSnackbar } = useSnackbar();
  const { currentDemoConfigId } = useAppState();
  const { demoConfig, bpOrgInfo } = useDemoConfig(currentDemoConfigId);

  const noticeAction = useNoticeAction();

  const postAlerts = useCallback(
    async function postAlerts(alerts) {
      logger.info(`Posting alerts:`, alerts);
      let uniqueAppKeys = [...new Set(alerts.map((a) => a.app_key))];
      let integrations = demoConfig?.integrations.filter((i) =>
        uniqueAppKeys.includes(i.bp_integration?.stream_id)
      );
      return API.graphql({
        query: mutations.postAlerts,
        variables: {
          access_token: bpOrgInfo?.organization?.api_token,
          region: demoConfig.region,
          alerts: JSON.stringify(alerts),
          integrations: JSON.stringify(integrations),
        },
      }).catch((error) => {
        console.error("postAlerts error:", error);
        if (typeof error == "object" && error.errors) {
          error.errors.forEach((error) =>
            enqueueSnackbar(`postAlerts error: ${error.message}`, {
              variant: "error",
              persist: true,
              action: noticeAction,
            })
          );
        } else
          enqueueSnackbar(`postAlerts error: ${error.message}`, {
            variant: "error",
            persist: true,
            action: noticeAction,
          });
      });
    },
    [
      bpOrgInfo?.organization?.api_token,
      demoConfig.region,
      demoConfig?.integrations,
      enqueueSnackbar,
      noticeAction,
    ]
  );

  const postITAGS = useCallback(
    async function postITAGS(itags, incident_identifiers) {
      return API.graphql({
        query: mutations.postITAGS,
        variables: {
          access_token: demoConfig.api_key,
          region: demoConfig.region,
          itags: JSON.stringify(itags),
          incident_identifiers: JSON.stringify(incident_identifiers),
        },
      }).catch((error) => {
        console.error("postITAGS error:", error);
        if (typeof error == "object" && error.errors) {
          error.errors.forEach((error) =>
            enqueueSnackbar(`postITAGS error: ${error.message}`, {
              variant: "error",
              persist: true,
              action: noticeAction,
            })
          );
        } else
          enqueueSnackbar(`postITAGS error: ${error.message}`, {
            variant: "error",
            persist: true,
            action: noticeAction,
          });
      });
    },
    [demoConfig.api_key, demoConfig.region, enqueueSnackbar, noticeAction]
  );

  const postChanges = useCallback(
    async function postChanges(changes) {
      return API.graphql({
        query: mutations.postChanges,
        variables: {
          access_token: demoConfig.api_key,
          region: demoConfig.region,
          changes: JSON.stringify(changes),
        },
      }).catch((error) => {
        console.error("postChanges error:", error);
        if (typeof error == "object" && error.errors) {
          error.errors.forEach((error) =>
            enqueueSnackbar(`postChanges error: ${error.message}`, {
              variant: "error",
              persist: true,
              action: noticeAction,
            })
          );
        } else
          enqueueSnackbar(`postChanges error: ${error.message}`, {
            variant: "error",
            persist: true,
            action: noticeAction,
          });
      });
    },
    [demoConfig.api_key, demoConfig.region, enqueueSnackbar, noticeAction]
  );

  const queueFromS3 = useCallback(async function queueFromS3({
    endpoint,
    folder,
    file,
    fast,
    app_key,
    access_token,
  }) {
    const query = { endpoint, fast };
    if (app_key) query.app_key = app_key;
    if (access_token) query.access_token = access_token;
    return API.graphql(
      graphqlOperation(queries.queueAlertsFromS3, {
        params: { folder, file },
        query,
      })
    )
      .then((res) => res.data.queueAlertsFromS3)
      .catch((error) => {
        logger.error(`queueFromS3 error:`, error);
        return error.message;
      });
  }, []);

  return {
    postAlerts,
    postChanges,
    postITAGS,
    queueFromS3,
  };
}
