import React, { useState, useCallback } from "react";
import {
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";
import { MRT_Column } from "material-react-table"; // assuming you're using this
import { removeUndefined } from "../../lib/transform_funcs"; // importing the removeUndefined function
import { Logger } from "aws-amplify";

const logger = new Logger("TableColumnMenuClone", "INFO");

// Define component props
interface TableColumnMenuCloneProps<T> {
  data: T[];
  setData: React.Dispatch<React.SetStateAction<T[]>>;
  updateColumns: (data: T[]) => void;
  column: MRT_Column<T, unknown>;
  columnDescription?: string;
  closeMenu: () => void;
  setIsDataChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  setValidationErrors?: (
    callback: (prev: Record<string, any>) => Record<string, any>
  ) => void;
  validateFn?: (data: T) => Record<string, any>; // Validation function for each row
}

const TableColumnMenuClone = <T extends { id: string }>({
  data,
  setData,
  updateColumns,
  column,
  columnDescription = "column",
  closeMenu,
  setIsDataChanged = () => {},
  setValidationErrors = () => {},
  validateFn = () => ({}),
}: TableColumnMenuCloneProps<T>) => {
  const [open, setOpen] = useState(false); // For controlling the dialog
  const [newColumnName, setNewColumnName] = useState(""); // New column name input

  // Open the dialog
  const handleOpenDialog = () => {
    logger.info("Opening dialog to clone column", column.id);
    setOpen(true);
  };

  // Close the dialog
  const handleCloseDialog = useCallback(() => {
    setOpen(false);
    closeMenu();
  }, [closeMenu]);

  // Handle cloning the column and applying validation
  const handleCloneColumn = useCallback(() => {
    if (!newColumnName) return;

    let newValidationErrors: Record<string, any> = {};

    setData((prevRows) => {
      const newRows = prevRows.map((row) => {
        const updatedRow = { ...row };

        // Find the object level where the new column will be added
        const keys = column.id.split("."); // Break the source column path into its levels
        let targetObject = updatedRow;
        if (targetObject["event_type"] === "PAUSE") return updatedRow;

        // Traverse the object until reaching the appropriate level
        for (let i = 0; i < keys.length - 1; i++) {
          targetObject = targetObject[keys[i]];
        }

        // Clone the value from the source column to the new column
        const sourceColumnName = keys[keys.length - 1]; // The name of the source column
        targetObject[newColumnName] = targetObject[sourceColumnName];

        return updatedRow;
      });

      logger.info(
        `Cloned column ${column.id} to ${newColumnName}. Updated data:`,
        newRows
      );
      updateColumns(newRows);
      setValidationErrors(() =>
        removeUndefined(
          newRows.reduce((acc, row) => ({ ...acc, ...validateFn(row) }), {})
        )
      );
      return newRows;
    });

    // Update the validation errors
    setValidationErrors((prev) =>
      removeUndefined({
        ...prev,
        ...newValidationErrors,
      })
    );

    // Mark the scenario as changed
    setIsDataChanged(true);

    handleCloseDialog();
  }, [
    column.id,
    handleCloseDialog,
    newColumnName,
    setData,
    updateColumns,
    setIsDataChanged,
    setValidationErrors,
    validateFn,
  ]);

  return (
    <>
      <MenuItem key="clone-column" onClick={handleOpenDialog}>
        Clone {columnDescription}
      </MenuItem>

      {/* Dialog for cloning a column */}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>Clone {columnDescription}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={`New ${columnDescription} Name`}
            fullWidth
            value={newColumnName}
            onChange={(e) => setNewColumnName(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCloneColumn} color="primary">
            Clone {columnDescription}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TableColumnMenuClone;
