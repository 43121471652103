import {  useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import  {
  useResources,
  useSchedules,
} from "../../store/serverState";
import ResourceManager from "./ResourceManager";
import ResourceReservationCard from "./ResourceReservationCard";
import EditScheduleDialog from "./EditScheduleDialog";
import CreateScheduleDialog from "./CreateScheduleDialog";
import { Logger } from "aws-amplify";
import { parseISO } from "date-fns";
import Paper from "@mui/material/Paper";

const logger = new Logger("ResourceScheduler", "INFO");

interface Schedule {
  event_id: string;
  title: string;
  start: string;
  end: string;
  scheduleResourceId: string;
  project?: string;
  history?: string;
  comment?: string;
  owner: string;
}

interface Resource {
  id: string;
  name: string;
  type: string;
  owner: string;
  description?: string;
  instructions?: string;
  secret?: string;
  url?: string;
  admin_id?: string;
}
export default function ResourceScheduler() {
  const { data: resources } = useResources();

  const { data: schedules, isLoading: schedulesLoading } = useSchedules();

  const [createScheduleDialogIsOpen, setCreateScheduleDialogOpen] =
    useState(false);

  const [editScheduleDialogIsOpen, setEditScheduleDialogOpen] = useState(false);
  const [editSchedule, setEditSchedule] = useState<Schedule | null>(null);

  return (
    <Paper className="RouteContainer">
      <Stack direction="column" spacing={2}>
        <Typography variant="h2">SE Resource Scheduler</Typography>
        <Stack direction="row" spacing={2}>
          <ResourceManager />
          <Button onClick={() => setCreateScheduleDialogOpen(true)}>
            Schedule A Resource Reservation
          </Button>
        </Stack>
        <Stack direction="row" spacing={2}>
          {!!!schedulesLoading &&
            schedules
              .sort(
                (a, b) =>
                  parseISO(a.start).getTime() - parseISO(b.start).getTime()
              )
              .map((schedule: Schedule) => (
                <ResourceReservationCard
                  key={schedule.event_id}
                  scheduleId={schedule.event_id}
                  handleEditScheduleButtonClick={async () => {
                    logger.info("handleEditScheduleButtonClick", schedule);
                    setEditSchedule(schedule);
                    setEditScheduleDialogOpen(true);
                  }}
                  // resource={resources.find(
                  //   (resource: Resource) =>
                  //     resource.id === schedule.scheduleResourceId
                  // )}
                />
              ))}
        </Stack>
      </Stack>
      <EditScheduleDialog
        open={editScheduleDialogIsOpen}
        setOpen={setEditScheduleDialogOpen}
        schedule={editSchedule}
        resource={resources.find(
          (resource: Resource) =>
            resource.id === editSchedule?.scheduleResourceId
        )}
      />
      <CreateScheduleDialog
        open={createScheduleDialogIsOpen}
        setOpen={setCreateScheduleDialogOpen}
      />
    </Paper>
  );
}
