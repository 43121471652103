const {
  addBusinessDays,
  setHours,
  setMinutes,
  setSeconds,
  setMilliseconds,
} = require("date-fns");

// Function to check if the given date is a weekend (Saturday or Sunday)
// const isWeekend = (date) => {
//   const day = date.getDay();
//   return day === 0 || day === 6 || day === 5; // Sunday is 0, Saturday is 6
// };

// Function to get the next available weekday at 8:00 AM
export const getNextAvailableWeekdayAt8AM = () => {
  let targetDate = addBusinessDays(new Date(), 1);

  // Set the time to 8:00 AM
  targetDate = setHours(targetDate, 8);
  targetDate = setMinutes(targetDate, 0);
  targetDate = setSeconds(targetDate, 0);
  targetDate = setMilliseconds(targetDate, 0);

  return targetDate;
};

// Function to get the end of a 4 week period
export const getFourWeeksFromDate = (date) => {
  let targetDate = addBusinessDays(date, 19);
  targetDate = setHours(targetDate, 17);
  targetDate = setMinutes(targetDate, 0);
  targetDate = setSeconds(targetDate, 0);
  targetDate = setMilliseconds(targetDate, 0);
  return targetDate;
};
