/* 
  This page enables the user to load a CSV or JSON file of data, 
  edit the data and schema in a Material React Table (MRT) DataGrid component,
  and then post the data to a specified webhook or a BigPanda org integration.
  It also enables the user to download a CLI tool that can be used to post data into a running BigPanda org.
  It also enables the user to download a CLI tool that can be used to post data to a specified webhook.

Downloads these files:
bpinjector-linux-x64
bpinjector-macos-arm64
bpinjector-macos-x64
bpinjector-win-x64.exe
bpWebhookPoster-win-x64.exe
bpWebhookPoster-linux-x64
bpWebhookPoster-macos-arm64
bpWebhookPoster-macos-x64

Structure of the page is as follows:
  It uses a WizardStepper component to display the steps.
  It also uses a MRT DataGrid to display the data and enable editing.
  Thirdly, if there are CLI versions of the tool available, it displays a CliDownloads component.

  Steps are defined in the steps array. Each step has the following properties:
  label: the text to display in the step header
  optional: whether the step is optional
  description: the text to display in the step body
  component: the component to display in the step body
  gate: a boolean that determines whether the step is enabled
 */

import { useState, useEffect, useCallback, useMemo, ChangeEvent } from "react";
import { parse } from "csv-parse/browser/esm/sync";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_RowSelectionState,
} from "material-react-table";
import { JsonEditor } from "json-edit-react";
import { useTheme } from "@mui/material/styles";

// MUI components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";
import ListItem from "@mui/material/ListItem";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Assignment from "@mui/icons-material/Assignment";

// Custom components
import OrgSelector from "../../../components/OrgSelector/OrgSelector";
import InputFileOpen from "../../../components/Inputs/InputFileOpen";
import HorizontalNonLinearStepper, {
  WizardStepProps,
} from "../../../components/WizardStepper/HorizontalNonLinearStepper";
import ControlledRadioButtonsGroup from "../../../components/OptionSelectors/ControlledRadioButtonsGroup";
import ThrottleRateControl from "../../../components/Inputs/ThrottleRateControl";

import TableColumnMenuDelete from "../../../components/Tables/MRT_ColumnMenuDelete";
import TableColumnMenuInsert from "../../../components/Tables/MRT_ColumnMenuInsert";
import TableColumnMenuClone from "../../../components/Tables/MRT_ColumnMenuClone";
import MrtToolbarButtonExport from "../../../components/Tables/MRT_ToolbarButton_Export";
import MrtToolbarButtonCreate from "../../../components/Tables/MRT_ToolbarButton_Create";
import MrtToolbarButtonDelete from "../../../components/Tables/MRT_ToolbarButton_Delete";
import MrtToolbarButtonClone from "../../../components/Tables/MRT_ToolbarButton_Clone";
import MrtToolbarButtonSearchReplace from "../../../components/Tables/MRT_ToolbarButton_SearchReplace";
import HeaderForm from "../../../components/Inputs/HeaderForm";

// Custom hooks and functions
import useAppState from "../../../store/appState.js";
import { useDemoConfig } from "../../../store/serverState.js";
import {
  isValidArrayOfObjects,
  isValidJSON,
  gatherPropertyPaths,
  enforceSchema,
} from "../../../lib/transform_funcs";
import Bottleneck from "bottleneck";
import { postUtil } from "../../../store/graphql-functions.js";
import {
  getDefaultMRTOptions,
  createHandleCreatingRowSave,
  createHandleEditingRowSave,
  createHandleDeleteSelected,
  createHandleCloneSelected,
  createHandleCreatingRowChange,
  createHandleMuiEditTextFieldOnBlur,
} from "../../../components/Tables/MRT_Factories";

// utilities
import { v4 as uuidv4 } from "uuid";
import { Logger } from "aws-amplify";
import { useSnackbar } from "notistack";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";

// Types and interfaces
type RecordWithId = Record<string, unknown> & {
  id: string;
};
const RecordWithIdTypeDefinition: RecordWithId = {
  id: "string",
};

// Transform functions
const objectsToMrtData = (
  objects: Record<string, unknown>[]
): RecordWithId[] => {
  //@ts-ignore
  return objects.map((object: Record<string, unknown>) => {
    return enforceSchema<RecordWithId>(RecordWithIdTypeDefinition, {
      ...object,
      id: uuidv4(),
    });
  });
};

const mrtDataToObjects = (rows: RecordWithId[]): Record<string, unknown>[] => {
  return rows.map((row) => {
    const { id, ...rest } = row;
    return rest;
  });
};

const logger = new Logger("SETools/DataPoster", "INFO");

export default function DataPoster() {
  const theme = useTheme();
  // Global state
  const { currentDemoConfigId } = useAppState();
  const { demoConfig, bpOrgIntegrations, bpOrgInfo } =
    useDemoConfig(currentDemoConfigId);
  const { enqueueSnackbar } = useSnackbar();
  const defaultEventMRTOptions = getDefaultMRTOptions<RecordWithId>(theme);

  // local state
  const [isLoading, setIsLoading] = useState(false);
  const [dataTitle, setDataTitle] = useState<string>("Data");
  const [postLog, setPostLog] = useState<string[]>([]);
  const storedEndpointUrl = localStorage.getItem("endpointUrl");
  const [throttle_rate, setThrottleRate] = useState<number>(3);
  const [dataObjects, setDataObjects] = useState<RecordWithId[]>([]);
  const [dataColumns, setDataColumns] = useState<MRT_ColumnDef<RecordWithId>[]>(
    []
  );
  const [endpointType, setEndpointType] = useState<string>("bigpanda");
  const [endpointUrl, setEndpointUrl] = useState<string>(
    storedEndpointUrl || ""
  );
  const [headers, setHeaders] = useState<{ [key: string]: string }>({
    "Content-Type": "application/json",
  });
  const [integrationDest, setIntegrationDest] = useState(null);
  const [integrationDestInput, setIntegrationDestInput] = useState("");
  const [posting, setPosting] = useState<boolean>(false);
  // state for choosing between using the table editor or the json editor. choices are "table" or "json". define the choices in the type
  const [editorChoice, setEditorChoice] = useState<"table" | "json">("table");
  const [mrtRowsSelection, setMrtRowsSelection] =
    useState<MRT_RowSelectionState>({});

  // Function to create column definitions for the data array
  const defineDataColumns = useCallback(
    (data: RecordWithId[]): MRT_ColumnDef<RecordWithId>[] => {
      setIsLoading(true);
      logger.info("defineDataColumns Updating columns for data:", data);

      const allPropertyPaths = new Set<string>();

      // Gather all unique property paths from all objects in the data array
      data.forEach((item) => {
        gatherPropertyPaths(item, "", allPropertyPaths);
      });
      // Generate column definitions from the collected property paths
      const columnDefs = Array.from(allPropertyPaths).map((path) => ({
        accessorFn: (row) =>
          path.split(".").reduce((acc, key) => acc?.[key], row),
        id: path,
        header: path.split(".").pop() || path, // Use the last part of the path as the header
        renderColumnActionsMenuItems: ({
          closeMenu,
          internalColumnMenuItems,
          column,
        }) => [
          ...internalColumnMenuItems,
          <Divider key="column-divider" />,
          <TableColumnMenuDelete
            key="delete-column"
            setData={setDataObjects}
            updateColumns={(thisdata) =>
              setDataColumns(defineDataColumns(thisdata))
            }
            column={column}
            closeMenu={closeMenu}
          />,
          <TableColumnMenuInsert
            key="insert-column"
            data={dataObjects}
            setData={setDataObjects}
            updateColumns={(data) => setDataColumns(defineDataColumns(data))}
            column={column}
            closeMenu={closeMenu}
          />,
          <TableColumnMenuClone
            key="clone-column"
            data={dataObjects}
            setData={setDataObjects}
            updateColumns={(data) => setDataColumns(defineDataColumns(data))}
            column={column}
            closeMenu={closeMenu}
          />,
        ],
      }));
      setIsLoading(false);
      return columnDefs;
    },
    [dataObjects]
  );

  const handleCreatingRowSave = createHandleCreatingRowSave<RecordWithId>({
    setData: setDataObjects,
  });
  const handleCreatingRowChange = createHandleCreatingRowChange<RecordWithId>(
    {}
  );
  const handleEditingRecordSave = createHandleEditingRowSave<RecordWithId>({
    setData: setDataObjects,
  });
  const handleDeleteSelectedRows = createHandleDeleteSelected<RecordWithId>({
    selection: mrtRowsSelection,
    setData: setDataObjects,
    setSelection: setMrtRowsSelection,
  });
  const handleCloneSelectedRows = createHandleCloneSelected<RecordWithId>({
    data: dataObjects,
    setData: setDataObjects,
    selection: mrtRowsSelection,
    setSelection: setMrtRowsSelection,
  });
  const handleDataEditTextFieldOnBlur = createHandleMuiEditTextFieldOnBlur({
    setData: setDataObjects,
  });

  const table = useMaterialReactTable({
    ...defaultEventMRTOptions,
    columns: dataColumns,
    data: dataObjects,
    getRowId: (row) => `${row.id}`,
    onCreatingRowSave: handleCreatingRowSave,
    onCreatingRowChange: handleCreatingRowChange,
    onEditingRowSave: handleEditingRecordSave,
    onRowSelectionChange: setMrtRowsSelection,
    initialState: {
      ...defaultEventMRTOptions.initialState,
      columnVisibility: { id: false },
      columnPinning: {
        left: ["mrt-row-actions"],
      },
    },
    state: {
      isLoading: isLoading,
      rowSelection: mrtRowsSelection,
    },
    defaultColumn: {
      muiEditTextFieldProps: ({ cell, row, table, column }) => ({
        onKeyDown: (event) => {
          // Don't change this until bug is fixed
          if (
            column.columnDef.editVariant === "select" &&
            event.key === "Enter" &&
            !event.shiftKey
          ) {
            event.shiftKey = true;
            table.setEditingCell(null);
          }
        },
        onBlur: (event) => handleDataEditTextFieldOnBlur(event, row, cell),
      }),
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        // header: "Change Account Settings", //change header text
        size: 100, //make actions column wider
      },
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Stack direction="row" spacing={2}>
        <MrtToolbarButtonCreate
          table={table}
          dataRowTemplate={table.getAllColumns().reduce(
            (acc, col) => {
              acc[col.id] = "";
              return acc;
            },
            { id: uuidv4() }
          )}
          recordDescription="Record"
          dataRowsSelection={mrtRowsSelection}
        />
        <MrtToolbarButtonDelete
          recordDescription="Record"
          dataRowsSelection={mrtRowsSelection}
          onDelete={handleDeleteSelectedRows}
        />
        <MrtToolbarButtonClone
          recordDescription="Record"
          dataRowsSelection={mrtRowsSelection}
          onClone={handleCloneSelectedRows}
        />
        <MrtToolbarButtonSearchReplace
          data={dataObjects}
          setData={setDataObjects}
        />
        <MrtToolbarButtonExport table={table} />
      </Stack>
    ),
  });

  const handleCsvFileUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setDataObjects([]);
      setDataColumns([]);
      setIsLoading(true);
      setDataTitle(e.target.files[0].name);

      if (e.target.files && e.target.files[0]) {
        const reader = new FileReader();
        reader.readAsText(e.target.files[0], "UTF-8");
        reader.onloadend = (readerEvent: ProgressEvent<FileReader>) => {
          logger.info("readerEvent", readerEvent);
          if (readerEvent?.target?.result) {
            const csvAsObjects = parse(readerEvent?.target?.result.toString(), {
              columns: true,
              skip_empty_lines: true,
              trim: true,
              skip_records_with_empty_values: true,
              relax_column_count: true,
              // cast: (value, context) => value.replace(/[\r\n]/g, " "), //TODO - add button to remove newlines from data
            });
            logger.info(`csvAsObjects retrieved:`, csvAsObjects);

            setDataObjects(objectsToMrtData(csvAsObjects));
            setDataColumns(defineDataColumns(objectsToMrtData(csvAsObjects)));
            setEditorChoice("table");
          }
          setIsLoading(false);
        };
      }
      e.target.value = null;
    },
    [defineDataColumns]
  );

  const handleJsonFileUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setDataObjects([]);
      setDataColumns([]);
      setIsLoading(true);
      setDataTitle(e.target.files[0].name);

      if (e.target.files && e.target.files[0]) {
        const reader = new FileReader();
        reader.readAsText(e.target.files[0], "UTF-8");
        reader.onloadend = (readerEvent: ProgressEvent<FileReader>) => {
          if (
            readerEvent?.target?.result &&
            isValidJSON(readerEvent?.target?.result.toString())
          ) {
            logger.info("result", readerEvent?.target?.result.toString());
            const jsonResults = JSON.parse(
              readerEvent?.target?.result.toString()
            );
            if (isValidArrayOfObjects(jsonResults)) {
              setDataObjects(objectsToMrtData(jsonResults));
              setDataColumns(defineDataColumns(objectsToMrtData(jsonResults)));
              setEditorChoice("table");
            } else if (isValidArrayOfObjects([jsonResults])) {
              setDataObjects(objectsToMrtData([jsonResults]));
              setDataColumns(
                defineDataColumns(objectsToMrtData([jsonResults]))
              );
              setEditorChoice("table");
            } else if (typeof jsonResults === "object") {
              setDataObjects(objectsToMrtData([jsonResults]));
              setEditorChoice("json");
            } else
              enqueueSnackbar(
                "Data doesn't appear to be either an array or object.",
                { variant: "error" }
              );
          } else {
            enqueueSnackbar("Invalid JSON file", { variant: "error" });
          }
        };
        setIsLoading(false);
      }
      e.target.value = null;
    },
    [defineDataColumns, enqueueSnackbar]
  );

  const [group, setGroup] = useState(null);

  // Initialize the Bottleneck.Group on mount
  useEffect(() => {
    const newGroup = new Bottleneck.Group({
      minTime: Math.round(1000 / throttle_rate),
      maxConcurrent: throttle_rate,
    });
    newGroup.on("created", (limiter, key) => {
      console.log("A new limiter was created for key: " + key);

      // Prepare the limiter, for example we'll want to listen to its "error" events!
      limiter.on("empty", function () {
        // This will be called when `limiter.empty()` becomes true.
        setPosting(false);
      });
      limiter.on("failed", async (error, jobInfo: any) => {
        logger.error(`Failed to post record ${jobInfo.options.id}`, error);
        if (error.code === "ECONNABORTED") {
          logger.warn(`Throttle error posting record ${jobInfo.options.id}`);
          setPostLog((loghistory) => [
            `${new Date().toLocaleTimeString()}\t\tRecord ${
              jobInfo.options.id
            }: ${error.message} (${error.code}), Retrying after 1 second\n`,
            ...loghistory,
          ]);

          return 1000; // wait 200ms before retrying
        } else {
          setPostLog((loghistory) => [
            `${new Date().toLocaleTimeString()}\tFailed to post record ${
              jobInfo.options.id
            }. Error: ${error.message} (${error.code})\n`,
            ...loghistory,
          ]);
        }
      });
      limiter.on("done", () => {
        logger.info("Done posting");
        // setPosting(false);
      });
    });

    setGroup(newGroup);

    // Cleanup on unmount
    return () => {
      newGroup
        .limiters()
        .forEach(({ key, limiter }) =>
          limiter.stop().then(() => newGroup.deleteKey(key))
        );
    };
  }, [throttle_rate]);

  // Schedule posts using group.key() for each item
  const schedulePosts = useCallback(() => {
    setPosting(true);
    if (group) {
      logger.info("Posting");
      // create random string to instantiate the limiter
      const limiter = group.key(uuidv4());
      mrtDataToObjects(dataObjects)
        // add "app_key": integrationDest["stream_id"] to each object if endpointType is "bigpanda" and integrationDest["parent_source_system"] is "api"
        .map((obj) => {
          if (
            endpointType === "bigpanda" &&
            integrationDest["parent_source_system"] === "api"
          ) {
            obj["app_key"] = integrationDest["stream_id"];
          }
          return obj;
        })
        .forEach((payload, idx) => {
          let identifier = `${idx + 1}`;
          limiter
            .schedule({ id: identifier }, postUtil, {
              access_token: demoConfig.api_key,
              region: demoConfig.region,
              params: {
                action: "postGeneric",
                headers: headers,
                rawUrl:
                  endpointType === "bigpanda"
                    ? integrationDest.url
                    : endpointUrl,
              },
              payload: payload,
            })
            .then((result: any) => {
              logger.info(
                `Post Result: ${JSON.stringify(result)} for payload id ${identifier}`
              );
              setPostLog((loghistory) => [
                `${new Date().toLocaleTimeString()}\tPayload ${identifier} posted. Result: ${JSON.stringify(result)}\n`,
                ...loghistory,
              ]);
            })
            .catch((error: Error) => {
              if (error instanceof Bottleneck.BottleneckError) {
                if (error.message === "This limiter has been stopped.") {
                  logger.error(`Scheduling cancelled`, error);
                  setPostLog((loghistory) => [
                    `${new Date().toLocaleTimeString()}\t\tpayload ${identifier} removed from posting queue\n`,
                    ...loghistory,
                  ]);
                } else {
                  logger.error(`Scheduling error`, error);
                  setPostLog((loghistory) => [
                    `${new Date().toLocaleTimeString()}\t\tPayload ${identifier} scheduling error: ${JSON.stringify(
                      error
                    )}\n`,
                    ...loghistory,
                  ]);
                }
              } else {
                logger.error(error);
                setPostLog((loghistory) => [
                  `${new Date().toLocaleTimeString()}\t\tPayload ${identifier} error: ${JSON.stringify(
                    error
                  )}\n`,
                  ...loghistory,
                ]);
              }
            });
        });
    }
  }, [
    group,
    dataObjects,
    demoConfig,
    endpointType,
    integrationDest,
    endpointUrl,
    headers,
  ]);

  // Handle cancellation by deleting each limiter key
  const handleCancel = useCallback(() => {
    if (group) {
      group
        .limiters()
        .forEach(({ key, limiter }) =>
          limiter.stop().then(() => group.deleteKey(key))
        );
      setPosting(false);
    }
  }, [group]);

  const steps: WizardStepProps[] = useMemo(() => {
    return [
      {
        label: `${
          demoConfig.bporgname
            ? `Using BigPanda Org:  ${demoConfig.bporgname}`
            : "Select/Create a Demo Config"
        }`,
        optional: false,
        description: `Data Poster enables you to:
      - load data from a CSV or JSON file
      - edit the data and schema
      - post the data to a BigPanda integration or a custom webhook.

      To perform the following steps you need a Demo Config record with a BigPanda Org User API Key.

      If you've already created a Demo Config record, select it.
      
      If you need to create a Demo Config, type a new record name into the Demo Config Selector.
      This will open a dialog; enter your BigPanda org's User API Key and your BigPanda email address.
      
      If you've entered the correct User API key, you'll see your BigPanda Org name in the box on the right
      and the step label will display the name of your BigPanda Org.`,
        component: <OrgSelector />,
        gate: Boolean(demoConfig.bporgname),
      },
      {
        label: "Set Endpoint",
        optional: false,
        description: `Set the destination endpoint.`,
        component: (
          <Stack direction="column" spacing={2} sx={{ m: 2 }}>
            <ControlledRadioButtonsGroup
              name="Endpoint Type"
              options={[
                { value: "bigpanda", label: "BigPanda" },
                { value: "webhook", label: "Webhook" },
              ]}
              value={endpointType}
              setValue={(value) => {
                if (value === "webhook") {
                  setIntegrationDest(null);
                  setHeaders({ "Content-Type": "application/json" });
                }
                setEndpointType(value);
              }}
            />
            {endpointType === "bigpanda" && (
              <Autocomplete
                sx={{ width: "50%" }}
                about="Select the BigPanda integration target"
                disableClearable
                openOnFocus
                blurOnSelect
                size="small"
                options={bpOrgIntegrations.filter(
                  (i) =>
                    i.parent_source_system &&
                    (i.parent_source_system === "api" ||
                      i.parent_source_system === "oim")
                )}
                getOptionLabel={(option) =>
                  `${option.source_system} (***${option?.stream_id?.slice(-6)})`
                }
                renderOption={(props, option) => (
                  <ListItem {...props} key={option.source_system}>
                    <Grid container sx={{ width: "100%" }}>
                      <Grid item xs={4}>
                        <Typography variant="body1" noWrap>
                          Type: {option.parent_source_system}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body1" noWrap>
                          Name: {option.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sx={{ textAlign: "right" }}>
                        <Typography variant="body2" noWrap>
                          API Key: ***{option?.stream_id?.slice(-6)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="medium"
                    variant="outlined"
                    placeholder="Select Integration Target"
                    label="BigPanda Integration Target"
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.stream_id === value.stream_id
                }
                value={integrationDest}
                onChange={(event: any, integration: string | null) => {
                  logger.info("Integration Dest", integration);
                  const urlPrefix = demoConfig.region === "EU" ? "eu-" : "";
                  switch (integration["parent_source_system"]) {
                    case "api":
                      integration["url"] =
                        `https://${urlPrefix}api.bigpanda.io/data/v2/alerts`;
                      // ensure that x-app-key removed from headers
                      setHeaders((prevHeaders) => {
                        const newHeaders = {
                          ...prevHeaders,
                          Authorization: `Bearer ${bpOrgInfo.organization.api_token}`,
                        };
                        delete newHeaders["x-app-key"];
                        return newHeaders;
                      });
                      break;
                    case "oim":
                      integration["url"] =
                        demoConfig.region === "EU"
                          ? `https://eu.integrations.bigpanda.io/oim/api/alerts`
                          : `https://integrations.bigpanda.io/oim/api/alerts`;
                      setHeaders((prevHeaders) => ({
                        ...prevHeaders,
                        Authorization: `Bearer ${demoConfig.api_key}`,
                        "x-app-key": integration["stream_id"],
                      }));
                      break;

                    default:
                      integration["url"] =
                        `https://${urlPrefix}api.bigpanda.io/data/v2/alerts`;
                      // ensure that x-app-key removed from headers
                      setHeaders((prevHeaders) => {
                        const newHeaders = {
                          ...prevHeaders,
                          Authorization: `Bearer ${bpOrgInfo.organization.api_token}`,
                        };
                        delete newHeaders["x-app-key"];
                        return newHeaders;
                      });
                      break;
                  }
                  setIntegrationDest(integration);
                }}
                inputValue={integrationDestInput}
                onInputChange={(event, newInputValue) => {
                  setIntegrationDestInput(newInputValue);
                }}
              />
            )}
            {/* if the integration parent_source_system is api, show an informational alert that says that the app_key will be added to each record if the integration is an api integration */}
            {integrationDest?.parent_source_system === "api" && (
              <Alert severity="info">
                Each record will have an "app_key" field added with the value of
                the integration's stream_id.
              </Alert>
            )}
            {endpointType === "webhook" && (
              <TextField
                sx={{ width: "50%" }}
                size="medium"
                variant="outlined"
                label="Webhook URL"
                type="url"
                error={
                  Boolean(endpointUrl) && !/https?:\/\/.+\..+/.test(endpointUrl)
                }
                helperText="Must be a valid URL starting with http:// or https://"
                placeholder="https://webhook.site/[my-unique-webhook-id]"
                value={endpointUrl}
                onChange={(e) => {
                  localStorage.setItem("endpointUrl", e.target.value);
                  setEndpointUrl(e.target.value);
                }}
              />
            )}
            <HeaderForm headers={headers} setHeaders={setHeaders} />
          </Stack>
        ),
        gate: integrationDest || endpointUrl,
      },
      {
        label: "Load Data",
        optional: false,
        description: `Load data from a file.

        Note: CSV files will be displayed in a table, whereas JSON files will be displayed in an expandable JSON viewer.`,
        component: (
          <Stack direction="column" spacing={2} margin={2}>
            <InputFileOpen
              type="file"
              text="Load CSV file"
              accept=".csv"
              onChange={handleCsvFileUpload}
            />
            <InputFileOpen
              type="file"
              text="Load JSON file"
              accept=".json"
              onChange={handleJsonFileUpload}
            />
          </Stack>
        ),
        gate: Boolean(dataObjects.length > 0),
      },
      {
        label: "Post",
        optional: false,
        description: `Click the POST button to post the data.`,
        component: (
          <Stack direction="row" spacing={2} margin={2}>
            <Stack direction="row" spacing={2}>
              <ThrottleRateControl
                direction="horizontal"
                throttle_rate={throttle_rate}
                setThrottleRate={setThrottleRate}
                // setThrottleRate={(value) => {
                //   setThrottleRate(Number(value));
                //   group.updateSettings({
                //     minTime: Math.round(1000 / Number(value)),
                //     maxConcurrent: value,
                //   });
                // }}
                defaultMinimumRate={1}
                defaultMaximumRate={100}
              />
              <Button
                variant="outlined"
                sx={{
                  margin: 2,
                  width: "fit-content",
                  height: "fit-content",
                }}
                onClick={schedulePosts}
                disabled={
                  (endpointType === "bigpanda"
                    ? !integrationDest
                    : !endpointUrl) || posting
                }
              >
                Start Posting
              </Button>
              <Button
                variant="outlined"
                sx={{
                  margin: 2,
                  width: "fit-content",
                  height: "fit-content",
                }}
                onClick={handleCancel}
                disabled={
                  (endpointType === "bigpanda"
                    ? !integrationDest
                    : !endpointUrl) || !posting
                }
              >
                Stop Posting
              </Button>
            </Stack>
            {posting && (
              <Stack direction="column" spacing={2} margin={2}>
                <Alert severity="info">Posting</Alert>
                <LinearProgress />
              </Stack>
            )}
          </Stack>
        ),
        gate: true,
      },
    ];
  }, [
    bpOrgInfo.organization.api_token,
    demoConfig.api_key,
    demoConfig.region,
    handleCancel,
    handleCsvFileUpload,
    headers,
    schedulePosts,
    throttle_rate,
    dataObjects,
    demoConfig.bporgname,
    bpOrgIntegrations,
    integrationDest,
    integrationDestInput,
    posting,
    endpointType,
    endpointUrl,
    setEndpointUrl,
    handleJsonFileUpload,
  ]);

  const handleReset = () => {
    setDataObjects([]);
    setPostLog([]);
    setPosting(false);
    setDataColumns([]);
    //TODO - ensure that all state is reset
  };

  useEffect(() => {
    if (dataObjects.length > 0) {
      logger.info("dataObjects", dataObjects);
      logger.info("dataColumns", dataColumns);
    }
  }, [dataObjects, dataColumns]);

  return (
    <Stack
      direction="column"
      spacing={2}
      display="flex"
      position="relative" // Make the parent relative for absolute positioning
      sx={{
        overflow: "visible",
        flexGrow: 1,
        alignItems: "center",
        maxWidth: "100%",
        width: "100%",
      }}
    >
      {/* Center the HorizontalNonLinearStepper */}
      <Box
        sx={{
          width: "100%",
          maxWidth: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <HorizontalNonLinearStepper steps={steps} resetFunction={handleReset} />
      </Box>

      {/* TextField for Post Log */}
      <TextField
        sx={{ mt: 2 }}
        id="post-log"
        label="Post Log"
        multiline
        maxRows={10}
        value={postLog.join("")}
        disabled
      />

      {/* Divider */}
      <Divider sx={{ margin: 2 }} />

      {/* Render dataObjects content */}
      {dataObjects.length > 0 && (
        <Card sx={{ boxShadow: 2, flex: 1, width: "100%", mb: 2 }}>
          <CardHeader
            avatar={<Assignment color="primary" />}
            title={dataTitle}
            titleTypographyProps={{ color: "primary", variant: "h4" }}
          />
          <CardContent>
            <Stack direction="column" spacing={2} margin={2}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Editor
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={editorChoice}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    //@ts-ignore
                    setEditorChoice((event.target as HTMLInputElement).value);
                  }}
                >
                  <FormControlLabel
                    value="table"
                    control={<Radio />}
                    label="Table Editor"
                    disabled={!isValidArrayOfObjects(dataObjects)}
                  />
                  <FormControlLabel
                    value="json"
                    control={<Radio />}
                    label="JSON Editor"
                  />
                </RadioGroup>
              </FormControl>
              {editorChoice === "table" && <MaterialReactTable table={table} />}
              {editorChoice === "json" && (
                <JsonEditor
                  data={dataObjects}
                  setData={setDataObjects}
                  collapse={true}
                />
              )}
            </Stack>
          </CardContent>
        </Card>
      )}
    </Stack>
  );
}
