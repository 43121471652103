import React from 'react';
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import AlertTitle from '@mui/material/AlertTitle';

const SchemaAuditBox = ({ validationSchema }) => {
  return (
    <Box margin={2} border={2} paddingTop={1} paddingX={1} paddingBottom={0.5} borderColor="gray" sx={{ width: '100%' }}>
      {validationSchema.map((schemaElement, idx) => {
        return schemaElement.status === "success" ?
          <Box key={idx} marginBottom={0.5}><Alert severity={schemaElement.status}>{schemaElement.message}</Alert></Box> :
          <Box key={idx} marginBottom={0.5}><Alert severity={schemaElement.status}><AlertTitle>{cap(schemaElement.status)}</AlertTitle>{schemaElement.message}</Alert></Box>
      })}
    </Box>
  );
}

export default SchemaAuditBox;

function cap(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}