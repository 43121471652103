import React, { useEffect } from "react";
import { Logger } from "aws-amplify";

// mui components
// Paper,  List,  ListItem,  ListItemIcon,  Checkbox,  Link,  Stack,  Box,  Typography,  LinearProgress,
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

// Custom components

const logger = new Logger("CheckList", "INFO");

type Props = {
  DisplayComponent: React.ComponentType<any>;
  itemList: any[];
  selectedItems: any[];
  setSelectedItems: (value: any[]) => void;
  matchProp: string;
  isError?: boolean;
  error?: any;
  isLoading?: boolean;
};

export default function CheckList({
  DisplayComponent,
  itemList,
  selectedItems,
  setSelectedItems,
  matchProp,
  isError = false,
  error = { errors: [] },
  isLoading = false,
}: Props) {
  const handleToggle = (element) => () => {
    const currentIndex = selectedItems.findIndex(
      (selectedItem) => selectedItem[matchProp] === element[matchProp]
    );
    const newChecked = [...selectedItems];

    if (currentIndex === -1) {
      newChecked.push(element);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedItems(newChecked);
  };

  useEffect(() => {
    logger.debug("itemList", itemList);
    if (selectedItems.length > 0) {
      logger.debug("selectedItems", selectedItems);
      let validatedSelectedItems = [...selectedItems];
      selectedItems.forEach((selectedItem) => {
        if (
          !itemList.find(
            (sample) => sample[matchProp] === selectedItem[matchProp]
          )
        )
          validatedSelectedItems.splice(
            validatedSelectedItems.findIndex(
              (validItem) => validItem[matchProp] === selectedItem[matchProp]
            ),
            1
          );
      });
      if (selectedItems.length !== validatedSelectedItems.length)
        setSelectedItems(validatedSelectedItems);
    }
  }, [itemList, matchProp, selectedItems, setSelectedItems]);

  if (isLoading) {
    return (
      <>
        <Paper sx={{ p: 1 }}>
          <Typography variant="h6" sx={{ fontWeight: 700, mb: 1, ml: 1 }}>
            Loading...
          </Typography>
          <LinearProgress />
        </Paper>
      </>
    );
  }

  if (isError) {
    logger.error("error", error);
    return (
      <>
        <Typography variant="h6" sx={{ fontWeight: 700, mb: 1, ml: 1 }}>
          Error(s)
        </Typography>
        {error.errors ? (
          <Paper sx={{ p: 1 }}>
            {error?.errors.map((error, idx) => (
              <Stack key={idx} direction="row">
                <Typography variant="h5" sx={{ ml: 1 }}>
                  {error.message} -{" "}
                </Typography>
                <Typography variant="h6" sx={{ color: "red", ml: 1 }}>
                  {error.errorType}
                </Typography>
              </Stack>
            ))}
          </Paper>
        ) : (
          <Paper sx={{ p: 1 }}>
            <Stack direction="row">
              <Typography variant="h5" sx={{ ml: 1 }}>
                {error.message} -{" "}
              </Typography>
              <Typography variant="h6" sx={{ color: "red", ml: 1 }}>
                {error.errorType}
              </Typography>
            </Stack>
          </Paper>
        )}
      </>
    );
  }

  if (!itemList || itemList.length === 0) {
    return (
      <>
        <Paper sx={{ p: 1 }}>
          <Typography variant="h6" sx={{ fontWeight: 700, mb: 1, ml: 1 }}>
            None Found
          </Typography>
        </Paper>
      </>
    );
  }

  const numberOfChecked = (samples) => {
    let checked = intersection(
      selectedItems.map((i) => i[matchProp]),
      samples.map((i) => i[matchProp])
    ).length;
    return checked;
  };

  const handleToggleAll = (samples) => () => {
    if (numberOfChecked(samples) === samples.length) {
      logger.debug("unselecting all items");
      setSelectedItems([]);
    } else {
      logger.debug("selecting all items");
      setSelectedItems(samples);
    }
  };

  return (
    <Paper id="Checklist" sx={{ p: 2, maxHeight: "480px", overflow: "auto" }}>
      <Stack direction="row" justifyContent="start">
        <Checkbox
          onClick={handleToggleAll(itemList)}
          checked={
            numberOfChecked(itemList) === itemList.length &&
            itemList.length !== 0
          }
          indeterminate={
            numberOfChecked(itemList) !== itemList.length &&
            numberOfChecked(itemList) !== 0
          }
          disabled={itemList.length === 0}
          inputProps={{
            "aria-label": "all items selected",
          }}
        />
        <Typography variant="subtitle1" sx={{ fontWeight: 700, mb: 1, ml: 1 }}>
          {`${numberOfChecked(itemList)}/${itemList.length} selected`}
        </Typography>
      </Stack>
      <List dense component="div" role="list">
        {itemList?.map((item) => {
          const labelId = `check-list-item-${item[matchProp]}-label`;
          return (
            <Stack direction="row" key={item[matchProp]}>
              <ListItemButton
                key={item[matchProp]}
                role="listitem"
                onClick={handleToggle(item)}
                //   sx={{ width: "50%" }}
                alignItems="flex-start"
              >
                <ListItemIcon>
                  <Checkbox
                    checked={
                      selectedItems
                        .map((i) => i[matchProp])
                        .indexOf(item[matchProp]) !== -1
                    }
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
                <DisplayComponent item={item} />
                {item.incidentUrl && (
                  <Box
                    // width="50%"
                    marginLeft={2}
                  >
                    <Link
                      href={item.incidentUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`(ID: ${item[matchProp]})`}
                    </Link>
                  </Box>
                )}
              </ListItemButton>
            </Stack>
          );
        })}
      </List>
    </Paper>
  );
}

// function not(a, b) {
//   return a.filter((value) => b.indexOf(value) === -1);
// }

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1) || [];
}

// function union(a, b) {
//   return [...a, ...not(b, a)];
// }
