import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Masonry from "@mui/lab/Masonry";
import { Box, Stack } from "@mui/material";
import useAppState from "store/appState";
import { useDemoConfig, useOrgScenarios } from "store/serverState";
import ScenarioScheduler from "components/ScenarioScheduler/SchenarioScheduler";

export default function OrgScheduleTab() {
  const { currentDemoConfigId } = useAppState();
  const { bpOrgInfo } = useDemoConfig(currentDemoConfigId);
  const { orgScenerios } = useOrgScenarios(currentDemoConfigId);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2,
        boxSizing: "border-box",
        width: "100%",
        height: "100%",
        overflowY: "auto",
      }}
    >
      {/* Header Section */}
      <Stack
        direction="column"
        spacing={2}
        sx={{ width: "100%", maxWidth: "800px" }}
      >
        <Typography variant="h5" align="center">
          {`Scenario Schedules for ${bpOrgInfo.organization.name}`}
        </Typography>
        <Typography variant="caption" align="center">
          You can schedule DemoSim scenarios here.
        </Typography>
        {orgScenerios.length === 0 && (
          <Alert severity="info" sx={{ mt: 2 }}>
            No scenarios have been added to this organization.
          </Alert>
        )}
      </Stack>

      {/* Instructions Section */}
      <Box sx={{ width: "100%", maxWidth: "800px", mt: 2 }}>
        <Accordion
          defaultExpanded={false}
          slotProps={{ transition: { unmountOnExit: true } }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              variant="h5"
              sx={{ textDecoration: "underline" }}
              display="inline"
            >
              Scheduling Instructions
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={1}>
              <Typography variant="body1">
                Scenario Scheduler runs every 5 minutes. If a scenario is
                scheduled to run at a specific time, it will run within the
                nearest available 5-minute interval.
                <br />
                For example, if a scenario is scheduled to run at 12:01 PM, it
                will run at 12:00 PM.
              </Typography>
              <Typography variant="body1">
                To schedule a scenario, click the 'ADD NEW SCHEDULE' button (+)
                in the scenario you would like to schedule. This will create a
                new 5-minute interval schedule.
                <br />
                From there you can edit the schedule. We recommend using the
                helper tool to generate a cron expression, but you can also
                enter a cron expression manually.
              </Typography>
              <Typography
                variant="h5"
                sx={{ textDecoration: "underline" }}
                display="inline"
              >
                CRON Expression Format
              </Typography>
              <Typography variant="body1">
                A cron expression is a string representing a set of times, using
                a special syntax.
                <br />
                For more information on cron syntax, please refer to the
                following link:{" "}
                <a
                  href="https://en.wikipedia.org/wiki/Cron"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cron Syntax Reference
                </a>
              </Typography>
              <pre>
                <Typography
                  fontFamily="monospace"
                  sx={{ whiteSpace: "break-spaces" }}
                >
                  ┌───────────── second (0 - 59, optional)
                  <br />
                  │ ┌───────────── minute (0 - 59)
                  <br />
                  │ │ ┌───────────── hour (0 - 23)
                  <br />
                  │ │ │ ┌───────────── day of month (1 - 31)
                  <br />
                  │ │ │ │ ┌───────────── month (1 - 12)
                  <br />
                  │ │ │ │ │ ┌───────────── weekday (0 - 7)
                  <br />* * * * * *
                </Typography>
              </pre>
              <Typography variant="body1">
                All Linux cron features are supported, including:
              </Typography>
              <ul>
                <li>lists</li>
                <li>ranges</li>
                <li>ranges in lists</li>
                <li>step values</li>
                <li>month names (jan, feb, ... - case insensitive)</li>
                <li>weekday names (mon, tue, ... - case insensitive)</li>
                <li>
                  time nicknames (@yearly, @annually, @monthly, @weekly, @daily,
                  @hourly - case insensitive)
                </li>
              </ul>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* Scenario Scheduler Section */}
      <Box
        sx={{
          width: "100%",
          mt: 3,
        }}
      >
        <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={2}>
          {orgScenerios.length > 0 &&
            orgScenerios
              .sort((a, b) =>
                a?.scenario?.name.localeCompare(b?.scenario?.name)
              )
              .map((orgScenario) => (
                <ScenarioScheduler
                  key={`orgScenario-${orgScenario.id}`}
                  orgScenarioID={orgScenario.id}
                  scenarioName={orgScenario.scenario.name}
                />
              ))}
        </Masonry>
      </Box>
    </Box>
  );
}
