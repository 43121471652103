/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://yf2bxnvejzbnlegjk66lhgihhy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-7p5s7t7atfa5nf5mxb5wdaqaeq",
    "aws_cognito_identity_pool_id": "us-east-1:42fd4c88-bd98-4eea-9552-e253f593a4f2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_foFUJIePD",
    "aws_user_pools_web_client_id": "6er4fdcap8adu2nhlehatpp753",
    "oauth": {
        "domain": "bpsalesengineering-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.bpsalesengineering.com/admin/dashboard/",
        "redirectSignOut": "https://www.bpsalesengineering.com/auth/login-page/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "bpsalesengineering111209-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
