import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// utilities
import { Logger } from "aws-amplify";
import { useSnackbar } from "notistack";
import useNoticeAction from "../../hooks/noticeAction";
import useServerStateMutations, { useScenario } from "../../store/serverState";
import AuthContext from "../../store/AuthContext";
import useAppState from "../../store/appState";
import { fetchScenarioOrgs } from "../../store/graphql-functions";

const logger = new Logger("DeleteScenarioDialog", "INFO");

type Props = {
  scenarioID: string;
  setDeletingScenario: (value: boolean) => void;
};

export default function DeleteScenarioDialog({
  scenarioID,
  setDeletingScenario,
}: Props) {
  // Global state
  const { currentDemoConfigId } = useAppState();
  const { user } = useContext(AuthContext);

  // Local state
  const [openScenarioDeleteVerifyDialog, setOpenScenarioDeleteVerifyDialog] =
    useState(false);

  const {
    data: scenarioData,
    // isLoading: scenerioLoading,
    // error: scenarioError,
    // isFetching: scenarioFetching,
  } = useScenario(scenarioID);

  // Functions
  const { enqueueSnackbar } = useSnackbar();
  const noticeAction = useNoticeAction();
  const { removeScenariosFromOrg, deleteScenario } = useServerStateMutations();
  async function handleDeleteScenarioClick(e) {
    let scenarioOrgs = await fetchScenarioOrgs(scenarioID);
    let otherOrgsUsingThisScenario = scenarioOrgs.orgs.items
      .map((item) => item.org)
      .filter((org) => org.id !== currentDemoConfigId)
      .map((org) => org.name);

    if (otherOrgsUsingThisScenario.length) {
      enqueueSnackbar(
        `Cannot delete; first remove from these other demo configs: ${otherOrgsUsingThisScenario.join(
          ", "
        )}`,
        { variant: "error", persist: true, action: noticeAction }
      );
    } else setOpenScenarioDeleteVerifyDialog(true);
  }

  async function handleVerifiedDeleteScenario() {
    logger.info(`Deleting scenario ${scenarioData.name} (${scenarioID})`);
    setDeletingScenario(true);
    removeScenariosFromOrg
      //@ts-ignore
      .mutateAsync([scenarioID])
      //@ts-ignore
      .then(() => deleteScenario.mutateAsync(scenarioID))
      .catch((error) => {
        setDeletingScenario(false);
        logger.error(
          `Error deleting scenario: ${scenarioData.name} (${scenarioID})`,
          error
        );
        if (typeof error === "object" && error.errors) {
          error.errors.forEach((error) =>
            enqueueSnackbar(
              `Error deleting scenario ${scenarioData.name} (${scenarioID})`,
              { variant: "error", persist: true, action: noticeAction }
            )
          );
        } else
          enqueueSnackbar(
            `Error deleting scenario ${scenarioData.name} (${scenarioID})`,
            { variant: "error", persist: true, action: noticeAction }
          );
      });
  }

  return (
    <>
      <Button
        data-test="scenario-editor-delete-button"
        color="error"
        variant="contained"
        size="medium"
        sx={{ height: "max-content" }}
        onClick={handleDeleteScenarioClick}
        disabled={
          scenarioData.owners && !scenarioData.owners.includes(user.username)
        }
      >
        DELETE SCENARIO
      </Button>
      <Dialog
        open={openScenarioDeleteVerifyDialog}
        onClose={() => setOpenScenarioDeleteVerifyDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEnforceFocus
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete this Scenario?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will PERMANENTLY delete this scenario from the Scenario
            Store!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenScenarioDeleteVerifyDialog(false)}
            autoFocus
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            data-test="dlg-scenario-delete-confirm-button"
            onClick={handleVerifiedDeleteScenario}
            color="error"
            variant="outlined"
          >
            Yes, Delete this scenario
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
