import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Logger } from "aws-amplify";

const logger = new Logger("CheckboxesTags", "INFO");

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags({
  label,
  options,
  optionNameField,
  optionValueField,
  integrationDest,
  setIntegrationDest,
  integrationDestInput,
  setIntegrationDestInput,
}) {
  return (
    <Autocomplete
      //   sx={{ width: "25%", ml: 3 }}
      multiple
      id="checkboxes-tags-demo"
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option[optionNameField]}
      renderOption={(props, option, { selected }) => {
        //@ts-ignore
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option[optionNameField]}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          //   placeholder="Filter by Integration Source(s)"
        />
      )}
      isOptionEqualToValue={(option, value) =>
        option[optionValueField] === value[optionValueField]
      }
      value={integrationDest}
      //@ts-ignore
      onChange={(event, newValue) => {
        logger.info("newValue", newValue);
        setIntegrationDest(newValue);
      }}
      inputValue={integrationDestInput}
      onInputChange={(event, newInputValue) => {
        logger.info("newInputValue", newInputValue);
        setIntegrationDestInput(newInputValue);
      }}
    />
  );
}

/* 
<Autocomplete
                sx={{ width: "50%" }}
                // fullWidth
                openOnFocus
                blurOnSelect
                size="small"
                options={integrationOptions}
                getOptionLabel={(option) =>
                  `${option.name} (***${option?.stream_id?.slice(-6)})`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Filter by Integration Source(s)"
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.stream_id === value.stream_id
                }
                value={integrationDest}
                onChange={(event: any, newValue: string | null) => {
                  setIntegrationDest(newValue);
                }}
                inputValue={integrationDestInput}
                onInputChange={(event, newInputValue) => {
                  setIntegrationDestInput(newInputValue);
                }}
              />
*/
