/* eslint-disable no-template-curly-in-string */
import React, { useState, useContext } from "react";
import { useSnackbar } from "notistack";
// material-ui icons
import UploadFileIcon from "@mui/icons-material/UploadFile";

// mui components
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { menuCategories } from "templates/initialStateTemplates";

// custom components
import useAppState from "store/appState";
import {
  newScenarioTemplate,
  baseScenarioTemplate,
} from "templates/scenarioTemplates";
import useServerStateMutations, { useDemoConfig } from "store/serverState";
import AuthContext from "store/AuthContext";

import { Logger } from "aws-amplify";
const logger = new Logger("ScenarioCreator", "INFO");

const ScenarioCreator = ({ newEvents }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);
  const { addOrgScenarios, createScenario } = useServerStateMutations();
  const { user } = useContext(AuthContext);

  // Local state
  const [open, setOpen] = React.useState(false);
  const [newScenario, setNewScenario] = useState(newScenarioTemplate);
  const [publishing, setPublishing] = useState(false);

  const handleClickOpen = () => {
    logger.info("newEvents", newEvents);
    if (newEvents) {
      setNewScenario({
        ...baseScenarioTemplate,
        events: newEvents.map((event) => {
          delete event.id;
          return {
            ...event,
            tags: JSON.stringify(event.tags),
          };
        }),
      });
    }
    setOpen(true);
  };

  const resetDefaults = () => {
    setNewScenario(newScenarioTemplate);
    setPublishing(false);
  };

  const handleClose = () => {
    resetDefaults();
    // setActiveStep(0);
    setOpen(false);
  };

  function handlePublishScenario() {
    setPublishing(true);
    let finalScenario = {
      ...newScenarioTemplate,
      ...newScenario,
      owners: [user.username],
    };
    logger.info("finalScenario", finalScenario);
    createScenario
      .mutateAsync(finalScenario)
      .then((newScenario) =>
        addOrgScenarios.mutateAsync({
          orgID: demoConfig.id,
          scenarioIDs: [newScenario.id],
          owners: demoConfig.owners,
        })
      )
      .then(() => {
        enqueueSnackbar(
          `Scenario ${newScenario.name} created and added to Demo Config ${demoConfig.name}`,
          {
            variant: "success",
          }
        );
      })
      .then(handleClose);
  }

  return (
    <Box>
      <Button
        id="btn-scenario-create"
        data-test="btn-scenario-create"
        sx={{ minWidth: "max-content" }}
        variant="outlined"
        color="info"
        size="small"
        onClick={handleClickOpen}
        startIcon={<UploadFileIcon />}
      >
        Create New Scenario
      </Button>
      <Dialog
        id="dlg-scenario-creator"
        data-test="dlg-scenario-creator"
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
        disableEnforceFocus
      >
        <DialogTitle>Create New DemoSim Scenario</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2, mx: 2 }}>
            Provide a title, category, and description to help you locate and
            use your scenario.
          </DialogContentText>
          <Stack direction="column">
            <Stack direction="column" spacing={2}>
              <TextField
                id="scenario-creator-name-input"
                inputProps={{ "data-test": "scenario-creator-name-input" }}
                label="Scenario Name"
                helperText="The name that will show on DemoSim Chrome Extension"
                variant="standard"
                value={newScenario.name}
                onChange={(e) =>
                  setNewScenario({ ...newScenario, name: e.target.value })
                }
              />
              <TextField
                id="scenario-creator-category-input"
                data-test="scenario-creator-category-input"
                // inputProps={{ 'data-test': "scenario-creator-category-input" }}
                select
                label="Category"
                value={newScenario.category}
                onChange={(e) => {
                  setNewScenario({ ...newScenario, category: e.target.value });
                }}
                helperText="Select the category for display in the DemoSim Chrome Extension"
              >
                {menuCategories.map((category) => (
                  <MenuItem
                    key={category}
                    value={category}
                    data-test={`category-option-${category}`}
                  >
                    {category}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="scenario-creator-description-input"
                inputProps={{
                  "data-test": "scenario-creator-description-input",
                }}
                label="Scenario Description"
                helperText="Summarize the scenario script"
                variant="standard"
                value={newScenario.description}
                onChange={(e) => {
                  logger.info(
                    "setting decription to e.target.value",
                    e.target.value
                  );
                  setNewScenario({
                    ...newScenario,
                    description: e.target.value,
                  });
                }}
              />
            </Stack>
            <Button
              data-test="scenario-creator-publish-button"
              variant="outlined"
              color="success"
              disabled={
                !Boolean(newScenario.name) ||
                !Boolean(newScenario.category) ||
                !Boolean(newScenario.description) ||
                publishing
              }
              onClick={handlePublishScenario}
            >
              PUBLISH
            </Button>
            {publishing && <Alert severity="info">Publishing...</Alert>}
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ScenarioCreator;
