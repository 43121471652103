import React from "react";
import { Box, TextField, Button, Grid, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

interface HeaderFormProps {
  headers: { [key: string]: string };
  setHeaders: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
}

const HeaderForm: React.FC<HeaderFormProps> = ({ headers, setHeaders }) => {
  const handleHeaderChange = (
    oldKey: string,
    newKey: string,
    value: string
  ) => {
    const updatedHeaders = { ...headers };
    delete updatedHeaders[oldKey]; // Remove the old key
    updatedHeaders[newKey] = value; // Add the new key-value pair
    setHeaders(updatedHeaders);
  };

  const addHeader = () => {
    setHeaders({ ...headers, "": "" }); // Add an empty key-value pair
  };

  const removeHeader = (key: string) => {
    const updatedHeaders = { ...headers };
    delete updatedHeaders[key];
    setHeaders(updatedHeaders);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        {Object.entries(headers).map(([key, value], index) => (
          <Grid
            item
            xs={12}
            key={index}
            container
            spacing={2}
            alignItems="center"
          >
            <Grid item xs={5}>
              <TextField
                label="Header Key"
                variant="outlined"
                fullWidth
                value={key}
                onChange={(e) => handleHeaderChange(key, e.target.value, value)}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label="Header Value"
                variant="outlined"
                fullWidth
                value={value}
                onChange={(e) => handleHeaderChange(key, key, e.target.value)} // Only value changes here
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={() => removeHeader(key)}
                disabled={Object.keys(headers).length === 1}
              >
                <RemoveIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button startIcon={<AddIcon />} onClick={addHeader}>
            Add Header
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeaderForm;
