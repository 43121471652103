// Present a dialog to te user that performs the following:
// Presents a list of all the values in the Status field
// Allows the user to map each value to one of the following (case sensitive):
// "Planned", "In Progress", "Done" ,or "Canceled".
// Returns an array of the mapped values

import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

const bigPandaStatuses = ["Planned", "In Progress", "Done", "Canceled"];

export default function ChangeStatusMapDialog(props) {
  const { statusColumn, changeDataRows, setChangeDataRows, statusValuesValidated } = props;
  const [open, setOpen] = React.useState(false);

  const [statusMap, setStatusMap] = useState({});

  const handleClickOpen = () => {
    const statusSet = new Set(changeDataRows.map((row) => row[statusColumn]));
    const statusValues = Array.from(statusSet);
    setStatusMap(
      statusValues.reduce((acc, status: string) => {
        if (bigPandaStatuses.includes(status)) {
          acc[status] = status;
          return acc;
        }
        acc[status] = "Planned";
        return acc;
      }, {})
    );
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    const newChangeDataRows = changeDataRows.map((row) => {
      row[statusColumn] = statusMap[row[statusColumn]];
      return row;
    });
    setChangeDataRows(newChangeDataRows);
    setOpen(false);
  };

  // component that takes an orignial status and the list of bigPandaStatuses
  // then returns a TextField with the original status and a dropdown with the
  // bigPandaStatuses. The dropdown

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen} color={statusValuesValidated ? "primary" : "error"}>
        Status Mapper
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Status Mapper</DialogTitle>
        <DialogContent>
          <DialogContentText marginBottom={2}>
            Map each original status to a BigPanda status
          </DialogContentText>
          <Stack direction="column" spacing={2}>
            {Object.keys(statusMap).map((status) => {
              return (
                <Stack direction="row" spacing={2} alignItems="center">
                  <TextField
                    margin="dense"
                    id="fldOrigStatus"
                    label="Original Status"
                    type="text"
                    disabled
                    value={status}
                  />
                  <InputLabel id="demo-simple-select-label">map to:</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={statusMap[status]}
                    label="Mapped Status"
                    onChange={(event) => {
                      setStatusMap({
                        ...statusMap,
                        [status]: event.target.value,
                      });
                    }}
                  >
                    {bigPandaStatuses.map((status) => {
                      return <MenuItem value={status}>{status}</MenuItem>;
                    })}
                  </Select>
                </Stack>
              );
            })}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
