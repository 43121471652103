import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  createMRTColumnHelper,
  type MRT_ColumnDef,
} from "material-react-table";
import { Logger } from "aws-amplify";
import { useTheme } from "@mui/material/styles";

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
// core components
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
// custom components
import { useSnackbar } from "notistack";
import isJSON from "is-json";
import useScenarios from "../../hooks/scenarioFunctions";
import useParamCache from "../../hooks/paramCache";
import useAppState from "../../store/appState";
import { useDemoConfig } from "../../store/serverState";
import useNoticeAction from "../../hooks/noticeAction";
import { getDefaultMRTOptions } from "../Tables/MRT_Factories";
import { removeUndefined } from "../../lib/transform_funcs";
import {
  useInstantiatedEventValidation,
  useInstantiatedChangeValidation,
} from "../../validators/scenarioValidators";

// Types
import type { Event, Change } from "../../types/API";

// Define a type that extends Event and adds an id property
type EventWithId = Event & {
  id: string;
  tags: Record<string, any>;
};

type InstantiatedEvent = {
  id: string;
  event_type: string;
  timestamp: number;
  app_key: string;
  primary_property: string;
  secondary_property: string;
  tags: Record<string, any>;
};

type ChangeWithId = Change & {
  id: string;
  tags: Record<string, any>;
};

type InstantiatedChange = {
  id: string;
  app_key: string;
  identifier: string;
  summary: string;
  status: string;
  ticket_url: string;
  start: number;
  end: number;
  tags: Record<string, any>;
};

type ValidationState = {
  [uuid: string]: {
    [property: string]: string; // Each uuid has string properties
  };
};

type Props = {
  scenarioName: string;
  eventData: EventWithId[];
  changeData: ChangeWithId[];
};

// Global functions, constants and variables
const logger = new Logger("ScenarioTestModal", "INFO");
// const sortByOffset = (a, b) => a._offset - b._offset;

const ScenarioTest = ({ scenarioName, eventData, changeData }: Props) => {
  const theme = useTheme();
  useEffect(() => {
    logger.info("ScenarioTest mounted");
    logger.info("scenarioName", scenarioName);
    logger.info("eventData", eventData);
    logger.info("changeData", changeData);
    return () => {
      logger.info("ScenarioTest unmounted");
    };
  }, [scenarioName, eventData, changeData]);

  const { enqueueSnackbar } = useSnackbar();
  const noticeAction = useNoticeAction();
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);
  const { paramCacheClear, hydrateScenarioVariables } = useParamCache();
  const integrations = useMemo(
    () => demoConfig.integrations,
    [demoConfig.integrations]
  );
  const integrationTypes = useMemo(
    () => integrations.map((i) => i.integration_type),
    [integrations]
  );

  const eventColumnHelper = createMRTColumnHelper<InstantiatedEvent>();
  const defaultEventMRTOptions = getDefaultMRTOptions<InstantiatedEvent>(theme);
  const changeColumnHelper = createMRTColumnHelper<InstantiatedChange>();
  const defaultChangeMRTOptions =
    getDefaultMRTOptions<InstantiatedChange>(theme);

  const { postAlerts, postITAGS, postChanges } = useScenarios();
  // const [refreshTagValues, setRefreshTagValues] = useState(true);
  // const [hasInvalidAppkey, setHasInvalidAppkey] = useState(false); //TODO handle invalid app keys
  const validateEvent = useInstantiatedEventValidation(
    integrationTypes.filter((t) => t !== "change-man")
  );
  const validateChange = useInstantiatedChangeValidation(
    integrationTypes.filter((t) => t === "change-man")
  );

  // Initialize local state
  const defineEventColumns = useCallback(
    (data: InstantiatedEvent[]): MRT_ColumnDef<InstantiatedEvent>[] => {
      // reducer to extract all keys from tags object in eventData
      logger.info("Defining eventColumns for instantiated eventData", data);
      return data.reduce(
        (headers, event) => {
          let newHeaders = [];
          if (event.event_type === "ALERT" || event.event_type === "ITAG") {
            let accessorIDs = headers.map((h) => h?.id);
            let newTags = Object.keys(event.tags).filter(
              (tag) => !accessorIDs.includes(tag)
            );
            newTags.forEach((tag) => {
              let tagRenderer = eventColumnHelper.accessor(
                (row) => row.tags && row.tags[tag],
                {
                  header: tag,
                  id: tag,
                }
              );
              //@ts-ignore
              newHeaders.push(tagRenderer);
            });
          }
          return [...headers, ...newHeaders];
        },
        [
          eventColumnHelper.accessor((row) => row["id"], {
            header: "ID",
            id: "id",
            size: 200,
            visibleInShowHideMenu: false,
          }),
          eventColumnHelper.accessor((row) => row["event_type"], {
            header: "Event Type",
            id: "event_type",
            size: 160,
          }),
          eventColumnHelper.accessor((row) => row["app_key"], {
            header: "Integration",
            id: "app_key",
            size: 190,
            Cell({ cell }) {
              return (
                integrations.find(
                  (i) =>
                    i?.bp_integration?.stream_id === cell.row.original.app_key
                )?.bp_integration?.name || cell.row.original.app_key
              );
            },
          }),
          eventColumnHelper.accessor((row) => Number(row["timestamp"]), {
            header: "Timestamp",
            id: "timestamp",
            size: 130,
            Cell({ cell }) {
              return new Date(
                cell.row.original.timestamp * 1000
              ).toLocaleTimeString();
            },
          }),
          eventColumnHelper.accessor((row) => row["primary_property"], {
            header: "Primary Property",
            id: "primary_property",
            size: 200,
          }),
          eventColumnHelper.accessor((row) => row["secondary_property"], {
            header: "Secondary Property",
            id: "secondary_property",
            size: 210,
          }),
          eventColumnHelper.accessor((row) => row.tags && row.tags["status"], {
            header: "Status",
            id: "status",
            size: 130,
          }),
        ]
      );
    },
    [integrations, eventColumnHelper]
  );

  const [workingEventColumns, setWorkingEventColumns] = useState<
    MRT_ColumnDef<InstantiatedEvent>[]
  >([]);
  const [workingChangeColumns, setWorkingChangeColumns] = useState<
    MRT_ColumnDef<InstantiatedChange>[]
  >([]);

  const defineChangeColumns = useCallback(
    (data: ChangeWithId[]): MRT_ColumnDef<InstantiatedChange>[] => {
      logger.info("Defining changeColumns for changeData", data);
      return data.reduce(
        (headers, change) => {
          let newHeaders = [];
          let accessorIDs = headers.map((h) => h?.id);
          let newTags = Object.keys(change.tags).filter(
            (tag) => !accessorIDs.includes(tag)
          );
          newTags.forEach((tag) => {
            let tagRenderer = changeColumnHelper.accessor(
              (row) => row.tags[tag],
              {
                header: tag,
                id: tag,
              }
            );
            newHeaders.push(tagRenderer);
          });
          return [...headers, ...newHeaders];
        },
        [
          changeColumnHelper.accessor((row) => row["id"], {
            header: "ID",
            id: "id",
            size: 200,
            visibleInShowHideMenu: false,
          }),
          changeColumnHelper.accessor((row) => row["app_key"], {
            header: "Integration",
            id: "app_key",
            size: 190,
            Cell({ cell }) {
              return integrations.find(
                (i) => i.bp_integration.stream_id === cell.row.original.app_key
              )?.bp_integration?.name;
            },
          }),
          changeColumnHelper.accessor((row) => Number(row["start"]), {
            header: "Start",
            id: "start",
            size: 130,
            Cell({ cell }) {
              return new Date(
                cell.row.original.start * 1000
              ).toLocaleTimeString();
            },
          }),
          changeColumnHelper.accessor((row) => Number(row["end"]), {
            header: "End",
            id: "end",
            size: 130,
            Cell({ cell }) {
              return new Date(
                cell.row.original.end * 1000
              ).toLocaleTimeString();
            },
          }),
          changeColumnHelper.accessor((row) => row["identifier"], {
            header: "Identifier",
            id: "identifier",
            size: 190,
          }),
          changeColumnHelper.accessor((row) => row["status"], {
            header: "Status",
            id: "status",
            size: 210,
          }),
          changeColumnHelper.accessor((row) => row["summary"], {
            header: "Summary",
            id: "summary",
            size: 210,
          }),
          changeColumnHelper.accessor((row) => row["ticket_url"], {
            header: "Ticket URL",
            id: "ticket_url",
            size: 210,
          }),
        ]
      );
    },
    [integrations, changeColumnHelper]
  );

  const [eventValidationErrors, setEventValidationErrors] =
    useState<ValidationState>({} as ValidationState);
  const [changeValidationErrors, setChangeValidationErrors] =
    useState<ValidationState>({} as ValidationState);

  // replace the variables with param values
  const [workingEventData, setWorkingEventData] = useState<InstantiatedEvent[]>(
    []
  );
  const [workingChangeData, setWorkingChangeData] = useState<
    InstantiatedChange[]
  >([]);

  const updateTestData = useCallback(() => {
    paramCacheClear();
    logger.info(
      "Refreshing variables for scenario test",
      eventData,
      changeData,
      demoConfig.integrations
    );
    let { newEventData, newChangeData } = hydrateScenarioVariables({
      eventData: eventData,
      changeData: changeData,
      orgIntegrations: demoConfig.integrations,
    });
    logger.info("Hydrated event data:", newEventData);
    logger.info("Hydrated change data:", newChangeData);
    setWorkingEventData(newEventData);
    setWorkingEventColumns(defineEventColumns(newEventData));
    setEventValidationErrors(
      removeUndefined(
        newEventData.reduce((acc, row) => {
          return {
            ...acc,
            ...validateEvent(row),
          };
        }, {})
      )
    );
    setWorkingChangeData(newChangeData);
    setWorkingChangeColumns(defineChangeColumns(newChangeData));
    setChangeValidationErrors(
      removeUndefined(
        newChangeData.reduce((acc, row) => {
          return {
            ...acc,
            ...validateChange(row),
          };
        }, {})
      )
    );
    // setRefreshTagValues(false);
  }, [
    defineChangeColumns,
    defineEventColumns,
    validateChange,
    validateEvent,
    eventData,
    changeData,
    demoConfig.integrations,
    paramCacheClear,
    setWorkingEventData,
    setWorkingChangeData,
    hydrateScenarioVariables,
  ]);

  // Post instantiated data to BigPanda
  const handlePostScenarioClick = useCallback(
    async function handlePostScenarioClick() {
      if (Array.isArray(workingEventData) && workingEventData.length > 0) {
        let eventsToPost = workingEventData
          .filter((event) => event.event_type === "ALERT")
          .map((event) => {
            let { tags, id, ...rest } = event;
            return {
              ...rest,
              //@ts-ignore
              ...tags,
            };
          });
        logger.info("handlePostScenarioClick eventsToPost", eventsToPost);

        let itagsToPost = workingEventData
          .filter((event) => event.event_type === "ITAG")
          .map((event) => {
            let { tags, id, ...rest } = event;
            return {
              ...rest,
              //@ts-ignore
              ...tags,
            };
          });
        logger.info("handlePostScenarioClick itagsToPost", itagsToPost);

        postAlerts(eventsToPost)
          .then((res) => {
            //@ts-ignore
            logger.info("postAlerts response:", res.data.postAlerts);
            let allIncidentIdentifiers = [];
            //@ts-ignore
            let responses = isJSON(res.data.postAlerts)
              ? //@ts-ignore
                JSON.parse(res.data.postAlerts)
              : //@ts-ignore
                res.data.postAlerts;
            if (Array.isArray(responses)) {
              responses.forEach((result) => {
                if (result.status === "fulfilled") {
                  if (result.value?.status === "rejected") {
                    logger.error(`postAlerts: Failed payload:`, eventsToPost);
                    enqueueSnackbar(
                      `${result.value.response.status} (hint: locate ${result.value.incident_identifiers[0]} in console log.)`,
                      {
                        variant: "error",
                        persist: true,
                        action: noticeAction,
                      }
                    );
                  }
                  if (
                    result.value.status === "fulfilled" &&
                    result.value.incident_identifiers
                  ) {
                    result.value.incident_identifiers.forEach((i) =>
                      allIncidentIdentifiers.push(i)
                    );
                    let integration = demoConfig.integrations.find(
                      (integration) =>
                        integration.bp_integration.stream_id ===
                        result.value.app_key
                    );
                    enqueueSnackbar(
                      `Posted ${result.value.incident_identifiers.length} ${integration.integration_type} alerts`,
                      {
                        variant: "success",
                      }
                    );
                  }
                }
                if (result.status === "rejected") {
                  logger.error(
                    "postAlerts: Alert post failure reason:",
                    result.reason
                  );
                  logger.error(`postAlerts: Failed payload:`, eventsToPost);
                  enqueueSnackbar(
                    `${JSON.stringify(
                      result.reason
                    )} See Failed Payload in console log.`,
                    {
                      variant: "error",
                      persist: true,
                      action: noticeAction,
                    }
                  );
                }
              });
            } else if (responses?.statusCode && responses?.body) {
              logger.error(`handling converted response:`, responses);
              // postAlert function defaults to returning an object with statusCode and body
              enqueueSnackbar(
                `PostAlerts Error ${responses.statusCode}: ${responses.body}`,
                {
                  variant: "error",
                  persist: true,
                  action: noticeAction,
                }
              );
            } else {
              logger.error(`Error processing postAlerts response:`, responses);
              enqueueSnackbar(
                `Error processing postAlerts response: ${responses}`,
                {
                  variant: "error",
                  persist: true,
                  action: noticeAction,
                }
              );
            }

            return allIncidentIdentifiers;
          })
          .then((allIncidentIdentifiers) => {
            if (itagsToPost.length > 0) {
              logger.info(
                "posting itags:",
                itagsToPost,
                allIncidentIdentifiers
              );
              postITAGS(itagsToPost, allIncidentIdentifiers)
                .then((res) => {
                  //@ts-ignore
                  logger.info("postITAGS response:", res.data.postITAGS);
                  //@ts-ignore
                  let responses = isJSON(res.data.postITAGS)
                    ? //@ts-ignore
                      JSON.parse(res.data.postITAGS)
                    : //@ts-ignore
                      res.data.postITAGS;
                  if (Array.isArray(responses)) {
                    responses.forEach((result) => {
                      if (result.status === "fulfilled") {
                        if (result.value.incidentId) {
                          enqueueSnackbar(
                            `Tagged incident ${result.value.incidentId}`,
                            {
                              variant: "success",
                            }
                          );
                        }
                      }
                      if (result.status === "rejected") {
                        logger.error(
                          "postITAGS: Incident Tag failure reason:",
                          result.reason
                        );
                        logger.error(`postITAGS: Failed payload:`, itagsToPost);
                        enqueueSnackbar(
                          `${JSON.stringify(
                            result.reason
                          )} See "postITAGS: Failed payload" in console log.`,
                          {
                            variant: "error",
                            persist: true,
                            action: noticeAction,
                          }
                        );
                      }
                    });
                  } else if (responses?.statusCode && responses?.body) {
                    logger.error(`handling converted response:`, responses);
                    // postAlert function defaults to returning an object with statusCode and body
                    enqueueSnackbar(
                      `postITAGS Error ${responses.statusCode}: ${responses.body}`,
                      {
                        variant: "error",
                        persist: true,
                        action: noticeAction,
                      }
                    );
                  } else {
                    logger.error(
                      `Error processing postITAGS response:`,
                      responses
                    );
                    enqueueSnackbar(
                      `Error processing postITAGS response: ${responses}`,
                      {
                        variant: "error",
                        persist: true,
                        action: noticeAction,
                      }
                    );
                  }
                })
                .catch((err) => {
                  logger.error("postITAGS", err);
                  if (typeof err == "object" && err.errors)
                    err.errors.forEach((error) =>
                      enqueueSnackbar(error.message, {
                        variant: "error",
                        persist: true,
                        action: noticeAction,
                      })
                    );
                });
            }
          })
          .catch((err) => {
            logger.error("postAlerts", err);
            if (typeof err == "object" && err.errors)
              err.errors.forEach((error) =>
                enqueueSnackbar(error.message, {
                  variant: "error",
                  persist: true,
                  action: noticeAction,
                })
              );
          });
        //TODO ITAG: filter for ITAG events then post incident tags
      }

      if (Array.isArray(workingChangeData) && workingChangeData.length > 0) {
        let changesToPost = workingChangeData.map((change) => {
          let { tags, id, ...rest } = change;
          return {
            ...rest,
            //@ts-ignore
            ...tags,
          };
        });
        logger.info("handlePostScenarioClick changesToPost", changesToPost);

        postChanges(changesToPost)
          .then((res) => {
            logger.info(
              "postChanges response:",
              //@ts-ignore
              JSON.parse(res.data.postChanges)
            );
            //@ts-ignore
            let responses = JSON.parse(res.data.postChanges);
            responses.forEach((result) => {
              if (result.status === "fulfilled")
                enqueueSnackbar(
                  `Change posted: ${result.value.change_identifier}`,
                  {
                    variant: "success",
                    preventDuplicate: true,
                  }
                );
              if (result.status === "rejected")
                enqueueSnackbar(
                  `Failed to post change: ${JSON.stringify(result.reason)}`,
                  {
                    variant: "error",
                    persist: true,
                    action: noticeAction,
                  }
                );
            });
          })
          .catch((err) => {
            logger.error("postChanges", err);
            if (typeof err == "object" && err.errors)
              err.errors.forEach((error) =>
                enqueueSnackbar(error.message, {
                  variant: "error",
                  persist: true,
                  action: noticeAction,
                })
              );
          });
      }
    },
    [
      demoConfig,
      workingEventData,
      workingChangeData,
      postAlerts,
      postChanges,
      postITAGS,
      enqueueSnackbar,
      noticeAction,
    ]
  );

  // Modal state
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
    updateTestData();
  };
  const handleClose = () => {
    paramCacheClear();
    setOpen(false);
  };

  const eventsTable = useMaterialReactTable({
    ...defaultEventMRTOptions,
    columns: workingEventColumns,
    data: workingEventData,
    enableEditing: false,
    enableRowSelection: false,

    getRowId: (row) => `${row.id}`,
    initialState: {
      ...defaultEventMRTOptions.initialState,
      sorting: [{ id: "timestamp", desc: false }],
      columnVisibility: { id: false },
      columnPinning: {
        left: ["mrt-row-actions"],
      },
    },
    defaultColumn: {
      muiTableBodyCellProps: ({ row, cell }) => {
        return {
          sx: {
            borderStyle:
              eventValidationErrors[row.original.id] &&
              eventValidationErrors[row.original.id][cell.column.id]
                ? "solid"
                : undefined,
            borderColor:
              eventValidationErrors[row.original.id] &&
              eventValidationErrors[row.original.id][cell.column.id]
                ? "red"
                : undefined,
          },
        };
      },
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Stack direction="row" spacing={2}>
        <Button variant="contained" color="primary" onClick={updateTestData}>
          Refresh Variables
        </Button>
      </Stack>
    ),
  });

  const changesTable = useMaterialReactTable({
    ...defaultChangeMRTOptions,
    columns: workingChangeColumns,
    data: workingChangeData,
    getRowId: (row) => `${row.id}`,
    enableEditing: false,
    enableRowSelection: false,

    initialState: {
      ...defaultChangeMRTOptions.initialState,
      sorting: [{ id: "timestamp", desc: false }],
      columnVisibility: { id: false },
      columnPinning: {
        left: ["mrt-row-actions"],
      },
    },
    defaultColumn: {
      muiTableBodyCellProps: ({ row, cell }) => ({
        sx: {
          color: "red",
          borderStyle:
            changeValidationErrors[row.original.id] &&
            changeValidationErrors[row.original.id][cell.column.id]
              ? "solid"
              : undefined,
          borderColor:
            changeValidationErrors[row.original.id] &&
            changeValidationErrors[row.original.id][cell.column.id]
              ? "red"
              : undefined,
        },
      }),
    },

    renderTopToolbarCustomActions: ({ table }) => (
      <Stack direction="row" spacing={2}>
        <Button variant="contained" color="primary" onClick={updateTestData}>
          Refresh Variables
        </Button>
      </Stack>
    ),
  });

  return (
    <React.Fragment>
      <Button
        variant="contained"
        size="medium"
        sx={{ height: "max-content" }}
        onClick={handleClickOpen}
      >
        Test Scenario
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        scroll="paper"
        disableEnforceFocus
        keepMounted={false}
      >
        <DialogTitle>Scenario Tester</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This dialog will post your events and changes to BigPanda. Refresh
            to re-evaluate Org Variables.
          </DialogContentText>
          <Stack direction="column" spacing={2}>
            <Alert severity="info">
              PAUSE events will be ignored (future functionality for this
              tester...)
            </Alert>
            {/* {hasInvalidAppkey && (
              <Alert severity="error">
                Scenario has invalid app keys and will fail to post
                events/changes to BigPanda correctly.
              </Alert>
            )} */}
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Button
                id="btn-org-change-submit"
                variant="contained"
                color="secondary"
                onClick={handlePostScenarioClick}
              >
                Post {scenarioName} events{" "}
                {workingChangeData.length > 0 ? "and changes " : null}to{" "}
                {demoConfig?.bporgname}
              </Button>
            </Stack>

            <Card>
              <CardHeader
                avatar={<Assignment color="primary" />}
                title="Events"
                titleTypographyProps={{ color: "primary", variant: "h5" }}
              />
              <CardContent>
                {Object.keys(eventValidationErrors).length > 0 &&
                  Object.keys(eventValidationErrors).map((rowId) => {
                    return Object.keys(eventValidationErrors[rowId]).map(
                      (property) => {
                        let rowNumber =
                          eventData.findIndex((row) => row.id === rowId) + 1;
                        return (
                          <Alert
                            severity="error"
                            variant="outlined"
                            sx={{ mb: 1 }}
                            key={rowId + property}
                          >
                            {rowNumber === 0 ? `New Row` : `Row ${rowNumber}`}
                            {` - ${property}:  ${eventValidationErrors[rowId][property]}`}
                          </Alert>
                        );
                      }
                    );
                  })}
                <MaterialReactTable table={eventsTable} />
              </CardContent>
            </Card>
            <Card>
              <CardHeader
                avatar={<Assignment color="primary" />}
                title="Changes"
                titleTypographyProps={{ color: "primary", variant: "h5" }}
              />
              <CardContent>
                {Object.keys(changeValidationErrors).length > 0 &&
                  Object.keys(changeValidationErrors).map((rowId) => {
                    return Object.keys(changeValidationErrors[rowId]).map(
                      (property) => {
                        let rowNumber =
                          changeData.findIndex((row) => row.id === rowId) + 1;
                        return (
                          <Alert
                            severity="error"
                            variant="outlined"
                            sx={{ mb: 1 }}
                            key={rowId + property}
                          >
                            {rowNumber === 0 ? `New Row` : `Row ${rowNumber}`}
                            {` - ${property}:  ${changeValidationErrors[rowId][property]}`}
                          </Alert>
                        );
                      }
                    );
                  })}
                <MaterialReactTable table={changesTable} />
              </CardContent>
            </Card>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ScenarioTest;
