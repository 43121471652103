import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// define option type as value and label
export interface Option {
  value: string;
  label: string;
}

interface ControlledRadioButtonsGroupProps {
  name: string;
  options: Option[];
  value: string;
  setValue: (value: string) => void;
}

export default function ControlledRadioButtonsGroup({
  name,
  options,
  value,
  setValue,
}: ControlledRadioButtonsGroupProps): JSX.Element {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <FormControl>
      <FormLabel id="generic-controlled-radio-buttons-group">{name}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="generic-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
