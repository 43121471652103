import React, { useState, useMemo } from "react";
import useAppState from "store/appState";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Autocomplete from "@mui/material/Autocomplete";
import useServerStateMutations, { useDemoConfig } from "store/serverState";

const BpIntegrationChooser = ({ integration_type }) => {
  const { updateDemoConfig } = useServerStateMutations();
  const { currentDemoConfigId } = useAppState();
  const { demoConfig, bpOrgIntegrations } = useDemoConfig(currentDemoConfigId);
  const integration = React.useMemo(() => demoConfig.integrations.find(i => i.integration_type === integration_type), [integration_type, demoConfig.integrations])
  const integrationOptions = useMemo(
    () =>
      bpOrgIntegrations.filter((i) =>
        (integration_type === "change-man"
          ? i.parent_source_system === "changes"
          : i.parent_source_system !== "changes") &&
          typeof i.parent_source_system === "string"
      ),
    [bpOrgIntegrations, integration_type]
  );

  // in case the integration has been deleted, need to nullify the choice
  const [integrationChoice, setIntegrationChoice] = useState(() => {
    if ( integrationOptions.some(
        (opt) => opt.stream_id === integration?.bp_integration?.stream_id
      )
    ) {
      return integration.bp_integration;
    } else return null;
  });

  function handleChangeIntegrationChoice(value) {
    if (value !== integrationChoice) {
      setIntegrationChoice(value);
      updateDemoConfig.mutate({
        ...demoConfig,
        integrations: demoConfig.integrations.map((i) => {
          if (i.integration_type === integration_type) {
            return {
              ...i,
              bp_integration: value,
            };
          } else return i;
        }),
      });
    }
  }

  return (
    <div>
      <InputLabel>Integration Target</InputLabel>
      <Autocomplete
        id={`integration-select-${integration.integration_type}`}
        data-test={`integration-select-${integration.integration_type}`}
        size="small"
        disableClearable
        selectOnFocus
        handleHomeEndKeys
        blurOnSelect
        autoSelect
        autoHighlight
        options={integrationOptions.sort(sortByName)}
        value={integrationChoice}
        onChange={(e, value) => handleChangeIntegrationChoice(value)}
        getOptionLabel={(option) =>
          `${option.name} (***${option?.stream_id?.slice(-6)})`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            placeholder="Select Integration Target"
          />
        )}
        isOptionEqualToValue={(option, value) =>
          option.stream_id === value.stream_id
        }
      />
    </div>
  );
};

export default BpIntegrationChooser;

function sortByName(a, b) {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}
