import * as React from 'react';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function RegionSelector({ region, setRegion }) {
  const handleRegionSelection = event => {
    setRegion(event.target.value)
}
  return (
    <FormControl id="region-selector-form">
      <FormLabel >Region</FormLabel>
      <RadioGroup
        id='region-selector-radio-group'
        aria-labelledby="region-selector-form"
        name="region-selector-radio-buttons-group"
        value={region ? region : "USA"}
        onChange={handleRegionSelection}
        row
      >
        <FormControlLabel id='region-choice-usa' data-test='region-choice-usa' value="USA" control={<Radio inputProps={{ 'data-test': 'radio-region-choice-usa' }} />} label="USA" />
        <FormControlLabel id='region-choice-eu' data-test='region-choice-eu' value="EU"    control={<Radio inputProps={{ 'data-test': 'radio-region-choice-eu' }} />} label="EU" />
      </RadioGroup>
    </FormControl>
  )
}

