import React, { useState, } from "react";
import { Logger } from "aws-amplify";
// @mui/icons-material
// mui components
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
// custom components
import useServerStateMutations from "store/serverState";
import cronstrue from "cronstrue";
import cronValidator from "cron-validate";

const logger = new Logger("CronTabEditor", "INFO");

export default function CronTabEditor({ crontabData }) {
  const { updateCrontabMutation } = useServerStateMutations();
  const [cronTab, setCronTab] = useState(crontabData);
  const [isEditing, setIsEditing] = useState(false);
  const [cronExpression, setCronExpression] = useState(cronTab.schedule);
  const [cronExpressionError, setCronExpressionError] = useState(false);
  const [cronExpressionErrorText, setCronExpressionErrorText] = useState("");

  const handleUpdateCrontab = async () => {
    try {
      updateCrontabMutation.mutateAsync(cronTab);
      setIsEditing(false);
    } catch (error) {
      logger.error("Error updating crontab", error);
    }
  };

  const handleChangeCronExpression = (event) => {
    logger.info("event.target.value", event.target.value);
    setCronExpression(event.target.value);
    let validation = cronValidator(event.target.value);
    logger.info("validation", validation);
    if (validation.isValid() === false) {
      setCronExpressionError(true);
      setCronExpressionErrorText(validation.getError().join("; "));
      setIsEditing(false);
    } else {
      setIsEditing(true);
      setCronExpressionError(false);
      setCronExpressionErrorText(cronstrue.toString(event.target.value));
      setCronTab((cronTabData) => {
        return {
          ...cronTabData,
          schedule: event.target.value,
        };
      });
    }
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="start"
      alignItems="end"
      sx={{ marginRight: 2}}
    >
      <TextField
        id="cron-expression-raw"
        label="CRON Expression Editor"
        value={cronExpression}
        onChange={handleChangeCronExpression}
        error={cronExpressionError}
        helperText={cronExpressionErrorText || "Enter a valid CRON expression"}
      />
      <Button
        disabled={!isEditing}
        variant="contained"
        color="primary"
        onClick={handleUpdateCrontab}
      >
        Save
      </Button>
    </Stack>
  );
}
