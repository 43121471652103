import { useState, useEffect } from "react";
import { Storage } from "aws-amplify";

// MUI components
import { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

type Props = {
  fileNames: string[];
};

Storage.configure({ level: "public" });

export default function CliDownloads({ fileNames }: Props) {
  const [files, setFiles] = useState(() =>
    fileNames.map((filename) => ({ filename, href: null }))
  );
  const [selectedFile, setSelectedFile] = useState<string | null>(null);

  // Find the longest filename to calculate dynamic width
  const longestFileNameLength = Math.max(
    ...fileNames.map((name) => name.length)
  );
  const calculatedWidth = `${longestFileNameLength + 2}ch`; // Adding some buffer for padding

  useEffect(() => {
    const getLinks = fileNames.map((filename) =>
      Storage.get(`setools/${filename}`, { expires: 3600 })
    );
    Promise.all(getLinks).then((links) => {
      setFiles(
        links.map((link, index) => ({ filename: fileNames[index], href: link }))
      );
    });
  }, [fileNames, setFiles]);

  const handleFileSelect = (event: SelectChangeEvent<string>) => {
    const selectedFilename = event.target.value;
    setSelectedFile(selectedFilename);

    const file = files.find((file) => file.filename === selectedFilename);
    if (file && file.href) {
      window.location.href = file.href; // Trigger file download
    }
  };

  return (
    <TextField
      select
      label="CLI EXECUTABLES"
      value={selectedFile || ""}
      onChange={(event) => handleFileSelect(event as SelectChangeEvent<string>)}
      fullWidth
      size="small"
      sx={{ width: calculatedWidth }}
    >
      {files.map((file) => (
        <MenuItem key={file.filename} value={file.filename}>
          {file.filename}
        </MenuItem>
      ))}
    </TextField>
  );
  // return (
  //   <Tooltip
  //     title="Use CLI executable to perform the same function from command shell, scripts, etc. Use --help to see options."
  //     placement="left"
  //   >
  //     <FormControl fullWidth>
  //       <InputLabel>CLI EXECUTABLES</InputLabel>
  //       <Select
  //         value={selectedFile || ""}
  //         onChange={handleFileSelect}
  //         label="CLI EXECUTABLES"
  //         sx={{ width: calculatedWidth }}
  //         size="small"
  //       >
  //         {files.map((file) => (
  //           <MenuItem key={file.filename} value={file.filename}>
  //             {file.filename}
  //           </MenuItem>
  //         ))}
  //       </Select>
  //     </FormControl>
  //   </Tooltip>
  // );
}
