import React, { useState, useContext } from "react";
// import { Logger } from "aws-amplify";
// Icons
import CircularProgress from "@mui/material/CircularProgress";
// MUI components
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
// Custom components
import { initialOrgFormState } from "templates/initialStateTemplates";
import AuthContext from "store/AuthContext";
import useAppState from "store/appState";
import { useDemoConfigs, useDemoConfig } from "store/serverState";
import NewOrgDialog from "components/NewOrgDialog/NewOrgDialog";
import ErrorAlert from "components/ErrorAlert/ErrorAlert";

// const logger = new Logger("OrgSelector", "INFO");

const filter = createFilterOptions({ stringify: (option) => option.name });

const OrgSelector = () => {
  const { user } = useContext(AuthContext);
  const { currentDemoConfigId, setCurrentDemoConfigId } = useAppState();
  const {
    // isLoading: demoConfigsLoading,
    error: demoConfigsError,
    data: demoConfigs,
    isFetching: demoConfigsFetching,
  } = useDemoConfigs(user.username);
  const { demoConfig, bpOrgInfo, bpOrgInfosError } =
    useDemoConfig(currentDemoConfigId);
  const [open, setOpen] = useState(false);
  const [orgFormState, setOrgFormState] = useState(initialOrgFormState);
  const [newOrgFormDialogOpen, setNewOrgFormDialogOpen] = React.useState(false);

  const handleOrgSelectFilterOptions = function handleOrgSelectFilterOptions(
    options,
    params
  ) {
    const filtered = filter(options, params);

    if (
      !options.some((o) => o.name === params.inputValue) &&
      params.inputValue !== ""
    ) {
      filtered.push({
        id: params.inputValue,
        inputValue: params.inputValue,
        name: `Add "${params.inputValue}"`,
      });
    }
    return filtered;
  };

  function startNewOrgForm(newValue) {
    if (newValue && newValue.inputValue) {
      setOrgFormState({
        ...initialOrgFormState,
        name: newValue.inputValue,
        api_key: "",
        spoc_email: "",
        region: "USA",
        owners: [user.username],
      });
      setNewOrgFormDialogOpen(true);
    } else
      setTimeout(() => {
        // timeout to avoid instant validation of the dialog's form.
        console.log("startNewOrgForm setTimeout", newValue); // DEBUG
        setOrgFormState({
          ...initialOrgFormState,
          name: newValue,
          api_key: "",
          spoc_email: "",
          region: "USA",
          owners: [user.username],
        });
        setNewOrgFormDialogOpen(true);
      });
  }

  if (demoConfigsError)
    return "An error has occurred: " + demoConfigsError.message;

  return (
    <>
      <ErrorAlert
        errorState={
          currentDemoConfigId &&
          (bpOrgInfosError || bpOrgInfo?.organization?.name.includes("ERROR"))
        }
        errorMessage="Please provide a valid API key."
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          // flexGrow: 1,
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "fill-available",
          flexWrap: "wrap",
          m: 2,
        }}
      >
        <Autocomplete
          id="org-selector-input"
          data-test={`org-selector-input`}
          sx={{
            flexGrow: 0,
            minWidth: "350px",
            my: 2,
            // flexBasis: "20%",
            // width: { xs: "100%", md: "80%", lg: "60%", xl: "50%" },
          }}
          freeSolo
          disableClearable
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          blurOnSelect
          // autoSelect
          autoComplete
          autoHighlight
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          options={demoConfigs}
          loading={demoConfigsFetching}
          // value and onChange: state represents the value selected by the user, for instance when pressing Enter.
          value={demoConfig}
          onChange={(event, newValue) => {
            if (typeof newValue === "string" || newValue?.inputValue) {
              startNewOrgForm(newValue);
            } else {
              // user selected an org
              setCurrentDemoConfigId(newValue.id);
            }
          }}
          // inputValue and onInputChange: state represents the value displayed in the textbox.
          filterOptions={handleOrgSelectFilterOptions}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.name;
          }}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Demo Config Selector"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                type: "search",
                endAdornment: (
                  <React.Fragment>
                    {demoConfigsFetching ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />

        {currentDemoConfigId ? (
          <TextField
            id="bp-org-name"
            inputProps={{
              "data-test": "bp-org-name",
            }}
            sx={{
              flexGrow: 0,
              minWidth: "350px",
              my: 2,
            }}
            label="BigPanda Org Name"
            value={bpOrgInfo?.organization?.name}
            error={
              bpOrgInfosError || bpOrgInfo?.organization?.name.includes("ERROR")
            }
            disabled={true}
            helperText={
              bpOrgInfosError || bpOrgInfo?.organization?.name.includes("ERROR")
                ? `Error retrieving data (invalid API key?)`
                : `The BigPanda Org linked to this Demo Config.`
            }
            variant="standard"
          />
        ) : (
          <Alert
            data-test="alert-org-select"
            elevation={6}
            variant="filled"
            severity="warning"
            sx={{ width: "325px" }}
          >
            Use the Demo Config Selector to select (or create) a Demo Config
            record.
          </Alert>
        )}
        <NewOrgDialog
          open={newOrgFormDialogOpen}
          setOpen={setNewOrgFormDialogOpen}
          orgFormState={orgFormState}
          setOrgFormState={setOrgFormState}
        />
      </Box>
    </>
  );
};

export default OrgSelector;
