import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { initialOrgState } from "templates/initialStateTemplates";
import useAppState from "store/appState";
import useServerStateMutations, { useDemoConfig } from "store/serverState";

const DeleteIntegrationTypeDialog = ({ integrationType }) => {
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);
  const { updateDemoConfig } = useServerStateMutations();
  const [open, setOpen] = React.useState(false);

  const handleDeleteIntegrationButtonClick = () => {
    let newIntegrations = [...demoConfig.integrations];
    let idx = newIntegrations.findIndex(
      (i) => i.integration_type === integrationType
    );
    if (idx >= 0) {
      newIntegrations.splice(idx, 1);
      updateDemoConfig
        .mutateAsync({
          ...demoConfig,
          integrations: newIntegrations,
        })
        .then(() => setOpen(false));
    } else setOpen(false);
  };

  return initialOrgState.integrations
    .map((i) => i.integration_type)
    .includes(integrationType) ? (
    <div />
  ) : (
    <div>
      <IconButton
        id={`btn-delete-integration-${integrationType}`}
        data-test={`btn-delete-integration-${integrationType}`}
        aria-label="delete"
        size="small"
        onClick={() => setOpen(true)}
      >
        <DeleteIcon fontSize="inherit" />
      </IconButton>
      <Dialog
        id={`dlg-delete-integration-${integrationType}`}
        data-test={`dlg-delete-integration-${integrationType}`}
        open={open}
        onClose={() => setOpen(false)}
        disableEnforceFocus
      >
        <DialogTitle>Delete Integration Type</DialogTitle>
        <DialogContent>
          <DialogContentText color="red">{`This will delete the ${integrationType} integration type!`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-test={`dlg-delete-integration-${integrationType}-cancel-button`}
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            data-test={`dlg-delete-integration-${integrationType}-delete-button`}
            onClick={handleDeleteIntegrationButtonClick}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteIntegrationTypeDialog;
