import React from "react";
import { useTheme } from "@mui/material/styles";
import { Typography, Box, Stack } from "@mui/material";

export default function IncidentSummary({ item: incident }) {
  const theme = useTheme();
  const severityColors = {
    Critical: theme.palette.error.main,
    Warning: theme.palette.warning.main,
  };

  let latestEligibleMatchersIndex =
    incident.correlation_matchers_log.length - 1;
  let currentMatcher =
    incident.correlation_matchers_log.length > 0 &&
    incident.correlation_matchers_log[latestEligibleMatchersIndex].length > 0
      ? incident.correlation_matchers_log[latestEligibleMatchersIndex].sort(
          (a, b) => b.time_window - a.time_window
        )[0]
      : { tags: [{ name: "No correlation matchers found", value: "" }] };

  return (
    <Stack direction="row" spacing={1} key={incident.id}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: severityColors[incident.severity],
          color: "white",
          width: "22px",
        }}
      >
        {incident.alerts.length}
      </Box>
      {currentMatcher?.tags.map((tag) => {
        return (
          <Stack direction="row" key={tag.name}>
            <Typography sx={{ mr: 0.5, fontWeight: 300 }}>
              {tag.name}:
            </Typography>
            <Typography sx={{ fontWeight: 700 }}>{tag.value}</Typography>
          </Stack>
        );
      })}
      {/* <Typography sx={{ fontWeight: 100 }}>{`(ID: ${incident.id})`}</Typography> */}
    </Stack>
  );
}
