import { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import useAppState from "store/appState";
import AddVariableDialog from "components/AddVariableDialog/AddVariableDialog";
import DeleteVariableDialog from "components/DeleteVariableDialog/DeleteVariableDialog";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import useServerStateMutations, { useDemoConfig } from "store/serverState";

const OrgVariablesTab = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Detect small screens
  const { currentDemoConfigId } = useAppState();
  const { demoConfig, bpOrgInfo } = useDemoConfig(currentDemoConfigId);
  const { updateDemoConfig } = useServerStateMutations();
  const [params, setParams] = useState(demoConfig.params);

  useEffect(() => {
    setParams(demoConfig.params);
  }, [demoConfig.params]);

  const handleMutateTags = (name, tags) => {
    setParams((prevParams) => {
      const newParams = [...prevParams];
      const idx = newParams.findIndex((p) => p.name === name);
      newParams[idx] = { name, values: tags };
      updateDemoConfig.mutate({
        ...demoConfig,
        params: newParams,
      });
      return newParams;
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(2),
        boxSizing: "border-box",
      }}
    >
      <LoadingSpinner />

      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row", // Stack vertically on small screens
          alignItems: isSmallScreen ? "center" : "flex-start", // Center-align on small screens
          justifyContent: "center",
          gap: theme.spacing(2), // Spacing between Typography and button
          position: "relative",
          width: "100%", // Ensure the header spans the full width
        }}
      >
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Typography variant="h5">Scenario Variable Data</Typography>
          <Typography
            variant="caption"
            sx={{ display: "block", marginTop: theme.spacing(1) }}
          >
            {`Customize these variables in order to make your scenarios unique to ${bpOrgInfo.organization.name}.`}
          </Typography>
        </Box>
        <Box
          sx={{
            position: { xs: "static", lg: "absolute" },
            top: 0,
            right: theme.spacing(2),
            marginTop: { xs: theme.spacing(2), lg: 0 },
          }}
        >
          <AddVariableDialog />
        </Box>
      </Box>

      {/* Variable Cards Section */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap", // Wrap cards into rows
          justifyContent: "center", // Center cards horizontally
          alignItems: "flex-start", // Align cards at the top
          gap: theme.spacing(2), // Space between cards
          width: "100%", // Ensure full width
          marginTop: theme.spacing(4), // Space below the header
        }}
      >
        {/* Static Card */}
        <Box
          sx={{
            flex: "1 1 calc(50% - 16px)", // Take half the width with some spacing
            maxWidth: "400px", // Optional max width for consistent sizing
            minWidth: "300px", // Ensure reasonable minimum width
            boxSizing: "border-box",
          }}
        >
          <Card>
            <CardHeader title="Variable Classes" />
            <CardContent>
              ip_octet, aws_id, a (alpa), d (digit), h (hex)
            </CardContent>
          </Card>
        </Box>

        {/* Dynamic Cards */}
        {params &&
          [...params]
            .sort((a, b) => -b.name.localeCompare(a.name))
            .map((param) => (
              <Box
                key={param.name}
                sx={{
                  flex: "1 1 calc(50% - 16px)", // Ensure consistent width
                  maxWidth: "400px",
                  minWidth: "300px",
                  boxSizing: "border-box",
                }}
              >
                <Card>
                  <CardHeader
                    title={`Variable: ${param.name}`}
                    action={<DeleteVariableDialog variableCode={param.name} />}
                  />
                  <CardContent>
                    <Autocomplete
                      clearIcon={false}
                      freeSolo
                      multiple
                      size="small"
                      id={`org-params-${param.name}`}
                      data-test={`org-params-${param.name}`}
                      options={params
                        .filter((p) => p.name === param.name)
                        .flatMap((p) => p.values)}
                      value={params
                        .filter((p) => p.name === param.name)
                        .flatMap((p) => p.values)}
                      onChange={(event, tags) =>
                        handleMutateTags(param.name, tags)
                      }
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            key={option}
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={`${param.name} values`}
                        />
                      )}
                    />
                  </CardContent>
                </Card>
              </Box>
            ))}
      </Box>
    </Box>
  );
};

export default OrgVariablesTab;
