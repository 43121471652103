import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import logo from "assets/img/bpse-logo.png";
import { Auth } from "aws-amplify";

export default function LoginPage() {
  const [callLogin, setCallLogin] = useState(false);
  useEffect(() => {
    if (callLogin) {
      setCallLogin(false);
      // isLocalhost() ? Auth.federatedSignIn() : Auth.federatedSignIn({provider: 'Okta'});
      Auth.federatedSignIn()
    }
  }, [callLogin]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            BigPanda Sales Engineering
          </Typography>
          <Button id="login" onClick={() => setCallLogin(true)} color="inherit">
            Login
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ width: 1 }}>
        <img width={"100%"} src={logo} alt="Logo" />
      </Box>
    </Box>
  );
}
