import React, { useContext } from "react";
import { Typography } from "@mui/material";
import AuthContext from "store/AuthContext";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";

const Dashboard = () => {
  const { user } = useContext(AuthContext);
  console.log(user);

  return (
    <Paper className="RouteContainer">
      <Typography variant="h3">Welcome {user.username}</Typography>
      <Divider
        orientation="horizontal"
        variant="middle"
        flexItem
        sx={{ my: 6 }}
      />
      <Typography paragraph>
        BigPanda SE Tools site is maintained by Sales Engineering to provide
        utilities in support of product demonstration and experimentation.
      </Typography>
      <Typography>
        Got an idea for a utility? Contact SWAT via email at{" "}
        {
          <a href="mailto:swat@bigpanda.io" rel="noreferrer" target="_blank">
            swat@bigpanda.io
          </a>
        }
      </Typography>
    </Paper>
  );
};

export default Dashboard;
