import React from "react";

// utilities
import { Logger } from "aws-amplify";
// @mui/icons-material
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// mui components
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
// custom components
import cronstrue from "cronstrue";
import { parseCronExpression } from "cron-schedule";

import useServerStateMutations, {
  useScenarioCronTabs,
} from "store/serverState";
import CronTabEditor from "./CronTabEditor";

const logger = new Logger("ScenarioScheduler", "INFO");

const ScenarioScheduler = ({ orgScenarioID, scenarioName }) => {
  const {
    createCrontabMutation,
    deleteCrontabMutation,
  } = useServerStateMutations();
  const {
    data: crontabs,
    error: crontabsError,
  } = useScenarioCronTabs(orgScenarioID);

  logger.info("crontabs", crontabs);

  const handleCreateCrontab = async (schedule) => {
    try {
      createCrontabMutation.mutateAsync({
        orgScenarioID,
        schedule,
      });
    } catch (error) {
      logger.error("Error creating crontab", error);
    }
  };

  const handleDeleteCrontab = async (crontabID) => {
    try {
      deleteCrontabMutation.mutateAsync(crontabID);
    } catch (error) {
      logger.error("Error deleting crontab", error);
    }
  };

  return (
      <Card elevation={3}>
        <CardHeader
          id={`${orgScenarioID}-scenario-schedule-header`}
          data-test={`scenario-schedule-header-${orgScenarioID}`}
          title={scenarioName}
          subheader={crontabs.length > 0 ? `${crontabs.length} Schedule${crontabs.length > 1 ? "s":""}` : "No Schedules Set"}
          action={
            <Tooltip title="Add New Schedule" placement="top" disableTouchListener>
          <IconButton aria-label="settings" onClick={() => handleCreateCrontab("*/5 * * * *")} color="success">
            <AddCircleOutlineIcon />
          </IconButton>
          </Tooltip>
        }
        />
        <CardContent>
          {crontabsError && (
            <Typography>Error: {crontabsError.message}</Typography>
          )}
          {crontabs && crontabs.length > 0 ? (
            crontabs.map((crontab, idx) => (
              <Card
                key={`crontab-${crontab.id}`}
                sx={{ width: "100%", marginBottom: 2 }}
              >
                <CardHeader
                  title={`Schedule ${idx + 1}: ${cronstrue.toString(crontab.schedule)}`}
                />
                <CardContent>
                  
                  {isOlder(crontab.updatedAt) && (
                    <Typography variant="subtitle1">
                      Last Run:{" "}
                      {parseCronExpression(crontab.schedule)
                        .getPrevDates(1)[0]
                        .toLocaleString()}
                    </Typography>
                  )}
                  <Typography variant="subtitle1">Next 5 Runs:</Typography>
                  {parseCronExpression(crontab.schedule)
                    .getNextDates(5)
                    .map((date) => (
                      <Typography variant="caption">
                        {date.toLocaleString()}
                        <br />
                      </Typography>
                    ))}
                  
                </CardContent>
                <CardActions disableSpacing sx={{justifyContent: "flex-start", alignItems: "flex-end"}}>
                  <CronTabEditor crontabData={crontab} />
                  <Button onClick={() => handleDeleteCrontab(crontab.id)} variant="contained" color="warning" sx={{ marginLeft: "auto" }}>
                    Delete
                  </Button>
                  </CardActions>
              </Card>
            ))
          ) : (
            <Stack direction="column" spacing={2}>
              <Typography>No schedules set for this scenario</Typography>
            </Stack>
          )}
        </CardContent>
      </Card>
  );
};

export default ScenarioScheduler;

const isOlder = (updatedAt) => {
  const currentTime = new Date();
  const updatedTime = new Date(updatedAt);
  return currentTime - updatedTime > 300000;
};
