/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBpIncidents = /* GraphQL */ `
  query GetBpIncidents(
    $access_token: String!
    $region: String!
    $envId: String!
    $filter: AWSJSON
  ) {
    getBpIncidents(
      access_token: $access_token
      region: $region
      envId: $envId
      filter: $filter
    )
  }
`;
export const getBpIncidentEvents = /* GraphQL */ `
  query GetBpIncidentEvents(
    $access_token: String!
    $region: String!
    $incidentId: String
  ) {
    getBpIncidentEvents(
      access_token: $access_token
      region: $region
      incidentId: $incidentId
    )
  }
`;
export const listBpIntegrations = /* GraphQL */ `
  query ListBpIntegrations($query: QueryListBpIntegrationsQueryInput!) {
    listBpIntegrations(query: $query) {
      response {
        status
        __typename
      }
      item {
        _id
        updated_at
        name
        stream_id
        type
        source_system
        transport
        parent_source_system
        dataset_options
        agent_ids
        created_at
        config_secrets
        last_event
        activated_at
        last_day_error_count
        status
        __typename
      }
      __typename
    }
  }
`;
export const listBpIntegrationsEU = /* GraphQL */ `
  query ListBpIntegrationsEU($query: QueryListBpIntegrationsEUQueryInput!) {
    listBpIntegrationsEU(query: $query) {
      response {
        status
        __typename
      }
      item {
        _id
        updated_at
        name
        stream_id
        type
        source_system
        transport
        parent_source_system
        dataset_options
        agent_ids
        created_at
        config_secrets
        last_event
        activated_at
        last_day_error_count
        status
        __typename
      }
      __typename
    }
  }
`;
export const getBpOrgInfo = /* GraphQL */ `
  query GetBpOrgInfo($query: QueryGetBpOrgInfoQueryInput!) {
    getBpOrgInfo(query: $query) {
      _id
      username
      name
      organization {
        _id
        name
        display_name
        created_at
        api_token
        config
        feature_toggles
        __typename
      }
      createdAt
      measure
      roles
      state
      firstLoginAt
      lastLoginAt
      ip
      intercom_user_hash
      permissions
      granular_permissions
      __typename
    }
  }
`;
export const getBpOrgInfoEU = /* GraphQL */ `
  query GetBpOrgInfoEU($query: QueryGetBpOrgInfoEUQueryInput!) {
    getBpOrgInfoEU(query: $query) {
      _id
      username
      name
      organization {
        _id
        name
        display_name
        created_at
        api_token
        config
        feature_toggles
        __typename
      }
      createdAt
      measure
      roles
      state
      firstLoginAt
      lastLoginAt
      ip
      intercom_user_hash
      permissions
      granular_permissions
      __typename
    }
  }
`;
export const getBpEnvironments = /* GraphQL */ `
  query GetBpEnvironments($query: QueryGetBpEnvironmentsQueryInput!) {
    getBpEnvironments(query: $query)
  }
`;
export const getBpEnvironmentsEU = /* GraphQL */ `
  query GetBpEnvironmentsEU($query: QueryGetBpEnvironmentsEUQueryInput!) {
    getBpEnvironmentsEU(query: $query)
  }
`;
export const getBpCorrPattn = /* GraphQL */ `
  query GetBpCorrPattn($query: QueryGetBpCorrPattnQueryInput!) {
    getBpCorrPattn(query: $query)
  }
`;
export const getBpCorrPattnEU = /* GraphQL */ `
  query GetBpCorrPattnEU($query: QueryGetBpCorrPattnEUQueryInput!) {
    getBpCorrPattnEU(query: $query)
  }
`;
export const queueAlertsFromS3 = /* GraphQL */ `
  query QueueAlertsFromS3(
    $params: QueryQueueAlertsFromS3ParamsInput!
    $query: QueryQueueAlertsFromS3QueryInput!
  ) {
    queueAlertsFromS3(params: $params, query: $query)
  }
`;
export const getEventPage = /* GraphQL */ `
  query GetEventPage($id: ID!) {
    getEventPage(id: $id) {
      page
      events {
        event_type
        _offset
        seconds
        integration_type
        primary_property
        secondary_property
        tags
        __typename
      }
      groupsCanEdit
      groupsCanRead
      owners
      scenario {
        id
        name
        description
        category
        events {
          event_type
          _offset
          seconds
          integration_type
          primary_property
          secondary_property
          tags
          __typename
        }
        changes {
          integration_type
          _offset
          identifier
          status
          summary
          ticket_url
          tags
          __typename
        }
        orgs {
          nextToken
          __typename
        }
        groupsCanEdit
        groupsCanRead
        owners
        eventPages {
          nextToken
          __typename
        }
        changePages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      scenarioEventPagesId
      __typename
    }
  }
`;
export const listEventPages = /* GraphQL */ `
  query ListEventPages(
    $filter: ModelEventPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        page
        events {
          event_type
          _offset
          seconds
          integration_type
          primary_property
          secondary_property
          tags
          __typename
        }
        groupsCanEdit
        groupsCanRead
        owners
        scenario {
          id
          name
          description
          category
          groupsCanEdit
          groupsCanRead
          owners
          createdAt
          updatedAt
          __typename
        }
        id
        createdAt
        updatedAt
        scenarioEventPagesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChangePage = /* GraphQL */ `
  query GetChangePage($id: ID!) {
    getChangePage(id: $id) {
      page
      changes {
        integration_type
        _offset
        identifier
        status
        summary
        ticket_url
        tags
        __typename
      }
      groupsCanEdit
      groupsCanRead
      owners
      scenario {
        id
        name
        description
        category
        events {
          event_type
          _offset
          seconds
          integration_type
          primary_property
          secondary_property
          tags
          __typename
        }
        changes {
          integration_type
          _offset
          identifier
          status
          summary
          ticket_url
          tags
          __typename
        }
        orgs {
          nextToken
          __typename
        }
        groupsCanEdit
        groupsCanRead
        owners
        eventPages {
          nextToken
          __typename
        }
        changePages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      scenarioChangePagesId
      __typename
    }
  }
`;
export const listChangePages = /* GraphQL */ `
  query ListChangePages(
    $filter: ModelChangePageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChangePages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        page
        changes {
          integration_type
          _offset
          identifier
          status
          summary
          ticket_url
          tags
          __typename
        }
        groupsCanEdit
        groupsCanRead
        owners
        scenario {
          id
          name
          description
          category
          groupsCanEdit
          groupsCanRead
          owners
          createdAt
          updatedAt
          __typename
        }
        id
        createdAt
        updatedAt
        scenarioChangePagesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getScenario = /* GraphQL */ `
  query GetScenario($id: ID!) {
    getScenario(id: $id) {
      id
      name
      description
      category
      events {
        event_type
        _offset
        seconds
        integration_type
        primary_property
        secondary_property
        tags
        __typename
      }
      changes {
        integration_type
        _offset
        identifier
        status
        summary
        ticket_url
        tags
        __typename
      }
      orgs {
        items {
          id
          orgID
          scenarioID
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      groupsCanEdit
      groupsCanRead
      owners
      eventPages {
        items {
          page
          groupsCanEdit
          groupsCanRead
          owners
          id
          createdAt
          updatedAt
          scenarioEventPagesId
          __typename
        }
        nextToken
        __typename
      }
      changePages {
        items {
          page
          groupsCanEdit
          groupsCanRead
          owners
          id
          createdAt
          updatedAt
          scenarioChangePagesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listScenarios = /* GraphQL */ `
  query ListScenarios(
    $filter: ModelScenarioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScenarios(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        category
        events {
          event_type
          _offset
          seconds
          integration_type
          primary_property
          secondary_property
          tags
          __typename
        }
        changes {
          integration_type
          _offset
          identifier
          status
          summary
          ticket_url
          tags
          __typename
        }
        orgs {
          nextToken
          __typename
        }
        groupsCanEdit
        groupsCanRead
        owners
        eventPages {
          nextToken
          __typename
        }
        changePages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrg = /* GraphQL */ `
  query GetOrg($id: ID!) {
    getOrg(id: $id) {
      id
      name
      spoc_email
      api_key
      region
      params {
        name
        values
        __typename
      }
      integrations {
        integration_type
        primary_property
        secondary_property
        bp_integration {
          _id
          updated_at
          name
          stream_id
          type
          source_system
          transport
          parent_source_system
          dataset_options
          agent_ids
          created_at
          config_secrets
          last_event
          activated_at
          last_day_error_count
          status
          __typename
        }
        __typename
      }
      scenarios {
        items {
          id
          orgID
          scenarioID
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owners
      bporgname
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrgs = /* GraphQL */ `
  query ListOrgs(
    $filter: ModelOrgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        spoc_email
        api_key
        region
        params {
          name
          values
          __typename
        }
        integrations {
          integration_type
          primary_property
          secondary_property
          __typename
        }
        scenarios {
          nextToken
          __typename
        }
        owners
        bporgname
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrgScenario = /* GraphQL */ `
  query GetOrgScenario($id: ID!) {
    getOrgScenario(id: $id) {
      id
      orgID
      scenarioID
      org {
        id
        name
        spoc_email
        api_key
        region
        params {
          name
          values
          __typename
        }
        integrations {
          integration_type
          primary_property
          secondary_property
          __typename
        }
        scenarios {
          nextToken
          __typename
        }
        owners
        bporgname
        createdAt
        updatedAt
        __typename
      }
      scenario {
        id
        name
        description
        category
        events {
          event_type
          _offset
          seconds
          integration_type
          primary_property
          secondary_property
          tags
          __typename
        }
        changes {
          integration_type
          _offset
          identifier
          status
          summary
          ticket_url
          tags
          __typename
        }
        orgs {
          nextToken
          __typename
        }
        groupsCanEdit
        groupsCanRead
        owners
        eventPages {
          nextToken
          __typename
        }
        changePages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      owners
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrgScenarios = /* GraphQL */ `
  query ListOrgScenarios(
    $filter: ModelOrgScenarioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgScenarios(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        scenarioID
        org {
          id
          name
          spoc_email
          api_key
          region
          owners
          bporgname
          createdAt
          updatedAt
          __typename
        }
        scenario {
          id
          name
          description
          category
          groupsCanEdit
          groupsCanRead
          owners
          createdAt
          updatedAt
          __typename
        }
        owners
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCronTab = /* GraphQL */ `
  query GetCronTab($id: ID!) {
    getCronTab(id: $id) {
      id
      schedule
      orgScenarioID
      owners
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCronTabs = /* GraphQL */ `
  query ListCronTabs(
    $filter: ModelCronTabFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCronTabs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        schedule
        orgScenarioID
        owners
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getResource = /* GraphQL */ `
  query GetResource($id: ID!) {
    getResource(id: $id) {
      name
      description
      instructions
      type
      url
      admin_id
      owner
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listResources = /* GraphQL */ `
  query ListResources(
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResources(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        description
        instructions
        type
        url
        admin_id
        owner
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const resourceByTypeAndName = /* GraphQL */ `
  query ResourceByTypeAndName(
    $type: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourceByTypeAndName(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        description
        instructions
        type
        url
        admin_id
        owner
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSchedule = /* GraphQL */ `
  query GetSchedule($event_id: ID!) {
    getSchedule(event_id: $event_id) {
      event_id
      title
      start
      end
      disabled
      color
      textColor
      editable
      deletable
      draggable
      allDay
      resource {
        name
        description
        instructions
        type
        url
        admin_id
        owner
        id
        createdAt
        updatedAt
        __typename
      }
      project
      history
      comment
      owner
      createdAt
      updatedAt
      scheduleResourceId
      __typename
    }
  }
`;
export const listSchedules = /* GraphQL */ `
  query ListSchedules(
    $event_id: ID
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSchedules(
      event_id: $event_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        event_id
        title
        start
        end
        disabled
        color
        textColor
        editable
        deletable
        draggable
        allDay
        resource {
          name
          description
          instructions
          type
          url
          admin_id
          owner
          id
          createdAt
          updatedAt
          __typename
        }
        project
        history
        comment
        owner
        createdAt
        updatedAt
        scheduleResourceId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const scheduleByOwnerAndTime = /* GraphQL */ `
  query ScheduleByOwnerAndTime(
    $owner: String!
    $start: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scheduleByOwnerAndTime(
      owner: $owner
      start: $start
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        event_id
        title
        start
        end
        disabled
        color
        textColor
        editable
        deletable
        draggable
        allDay
        resource {
          name
          description
          instructions
          type
          url
          admin_id
          owner
          id
          createdAt
          updatedAt
          __typename
        }
        project
        history
        comment
        owner
        createdAt
        updatedAt
        scheduleResourceId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
