import { Fragment, useCallback } from "react";
import { useSnackbar } from "notistack";
import Button from "@mui/material/Button";

export default function useNoticeAction() {
  const { closeSnackbar } = useSnackbar();

  return useCallback(
    (key) => (
      <Fragment>
        <Button
          color="primary"
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          'Dismiss'
        </Button>
      </Fragment>
    ),
    [closeSnackbar]
  );
}
