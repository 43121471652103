import React, { useState, useContext, useEffect } from "react";

// MUI components
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DateRange } from "@mui/x-date-pickers-pro";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { parseISO } from "date-fns";

// custom components
import AuthContext from "../../store/AuthContext";
import useServerStateMutations from "../../store/serverState";

import { Logger } from "aws-amplify";
const logger = new Logger("EditScheduleDialog", "INFO");

interface Schedule {
  event_id: string;
  title: string;
  start: string;
  end: string;
  scheduleResourceId: string;
  project?: string;
  history?: string;
  comment?: string;
  owner: string;
}

interface Resource {
  id: string;
  name: string;
  type: string;
  owner: string;
  description?: string;
  instructions?: string;
  secret?: string;
  url?: string;
  admin_id?: string;
}

export default function EditScheduleDialog({
  open,
  setOpen,
  schedule,
  resource,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  schedule: Schedule;
  resource: Resource;
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { user } = useContext(AuthContext);
  const {
    updateScheduleMutation,
  } = useServerStateMutations();

  // create local state for form fields
  const [title, setTitle] = useState(schedule?.title);
  const [range, setRange] = React.useState<DateRange<Date>>([
    parseISO(schedule?.start),
    parseISO(schedule?.end),
  ]);
  const [project, setProject] = useState(schedule?.project);
  const [history, setHistory] = useState(schedule?.history);
  const [comment, setComment] = useState(schedule?.comment);
  const [owner, setOwner] = useState(schedule?.owner || user.username);

  useEffect(() => {
    setTitle(schedule?.title);
    setRange([parseISO(schedule?.start), parseISO(schedule?.end)]);
    setProject(schedule?.project);
    setHistory(schedule?.history);
    setComment(schedule?.comment);
    setOwner(schedule?.owner || user.username);
  }, [schedule, user.username]);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  async function handleEditScheduleDialogSubmit(event) {
    event.preventDefault();
    const newSchedule = {
      event_id: schedule.event_id,
      title,
      start: range[0].toISOString(),
      end: range[1].toISOString(),
      project,
      history,
      comment,
      owner,
    };
    logger.info("newSchedule", newSchedule);
    // @ts-ignore
    await updateScheduleMutation.mutateAsync(newSchedule);
    handleCloseDialog();
  }

  logger.info("EditScheduleDialog open", open);
  logger.info("EditScheduleDialog schedule", schedule);
  return (
    <Dialog
      id="edit-schedule-dialog"
      data-test="edit-schedule-dialog"
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="edit-schedule-dialog"
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
      // disableEnforceFocus
      // keepMounted
    >
      <form onSubmit={handleEditScheduleDialogSubmit}>
        <DialogTitle>Editing Reservation for {resource?.name}</DialogTitle>
        <DialogContent>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <TextField
              id="input-schedule-title"
              inputProps={{ "data-test": "input-schedule-title" }}
              autoFocus
              required
              margin="dense"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              label="Schedule Title"
              type="text"
              variant="standard"
            />
            <DemoItem label="Controlled picker" component="DateRangePicker">
              <DateRangePicker
                value={range}
                onChange={(newValue) => setRange(newValue)}
              />
            </DemoItem>
            <TextField
              id="input-schedule-project"
              inputProps={{ "data-test": "input-schedule-project" }}
              margin="dense"
              value={project}
              onChange={(event) => setProject(event.target.value)}
              label="Project"
              type="text"
              variant="standard"
              required
            />
            <TextField
              id="input-schedule-history"
              inputProps={{ "data-test": "input-schedule-history" }}
              margin="dense"
              value={history}
              onChange={(event) => setHistory(event.target.value)}
              label="History"
              type="text"
              variant="standard"
            />
            <TextField
              id="input-schedule-comment"
              inputProps={{ "data-test": "input-schedule-comment" }}
              margin="dense"
              value={comment}
              onChange={(event) => setComment(event.target.value)}
              label="Comment"
              type="text"
              variant="standard"
            />
            <TextField
              id="input-schedule-owner"
              inputProps={{ "data-test": "input-schedule-owner" }}
              margin="dense"
              value={owner}
              onChange={(event) => setOwner(event.target.value)}
              label="Owner"
              type="text"
              variant="standard"
              required
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            id="btn-clone-form-submit"
            data-test="btn-clone-form-submit"
            type={"submit"}
            // disabled={name.length === 0}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
