import { Logger } from "aws-amplify";
import { useSnackbar } from "notistack";
import {
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery,
  useSuspenseQuery,
  keepPreviousData,
} from "@tanstack/react-query";
// import AuthContext from "store/AuthContext";
import {
  mutateOrgRecord,
  getOrgRecord,
  createOrgRecord,
  deleteOrgRecord,
  deleteOrgScenario,
  addOrgScenario,
  getBpIntegrations,
  getBpOrgInfo,
  listDemoConfigs,
  fetchScenarioRecord,
  mutateScenarioRecord,
  deleteScenarioRecord,
  createScenarioRecord,
  listOrgScenarios,
  getBpEnvironments,
  getBpCorrPattn,
  getBpIncidentEvents,
  getBpIncidents,
  postUtil,
  listResources,
  getResource,
  createResource,
  updateResource,
  deleteResource,
  listSchedules,
  getSchedule,
  createSchedule,
  updateSchedule,
  deleteSchedule,
  listScenarioCronTabs,
  createCronTab,
  updateCronTab,
  deleteCronTab,
  listScenarioStore,
} from "./graphql-functions";
import {
  initialOrgState,
  initialBpOrgInfo,
  initialScenarioState,
} from "templates/initialStateTemplates";
import useNoticeAction from "hooks/noticeAction";
import useAppState from "store/appState";
// import { getOrgScenario } from "../graphql/queries-custom";
const logger = new Logger("Server State (ReactQuery)", "INFO");

// Queries

export function useScenarioCronTabs(orgScenarioID) {
  return useQuery({
    queryKey: ["scenarioCronTabs", orgScenarioID],
    queryFn: async () => listScenarioCronTabs(orgScenarioID),
    placeholderData: [],
    refetchOnWindowFocus: true,
  });
}

export function useResources() {
  return useQuery({
    queryKey: ["resources"],
    queryFn: listResources,
    placeholderData: [],
    refetchOnWindowFocus: true,
  });
}

export function useResource(resourceID) {
  return useQuery({
    queryKey: ["resource", resourceID],
    queryFn: async () => getResource(resourceID),
    // placeholderData: {},
    //   refetchOnWindowFocus: false,
  });
}

export function useSchedules() {
  return useQuery({
    queryKey: ["schedules"],
    queryFn: listSchedules,
    placeholderData: [],
    refetchOnWindowFocus: true,
  });
}

export function useSchedule(scheduleID) {
  return useQuery({
    queryKey: ["schedule", scheduleID],
    queryFn: async () => getSchedule(scheduleID),
    // placeholderData: {},
    //   refetchOnWindowFocus: false,
  });
}

export function useScenarioStoreInfinite(params = {}) {
  return useInfiniteQuery({
    queryKey: ["scenariosInfinite", params],
    queryFn: async ({ pageParam }) => {
      logger.info("useScenarioStoreInfinite pageParam:", pageParam);
      logger.info("useScenarioStoreInfinite params:", params);
      let limit = params.limit || 100;
      let filter = params.filter || undefined;
      return listScenarioStore(limit, filter, pageParam);
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage) => lastPage.nextToken || undefined,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
  });
};


export function useDemoConfigs(username) {
  return useQuery({
    queryKey: ["democonfigs", username],
    queryFn: async () => listDemoConfigs(username),
    placeholderData: [],
    select: (data) => data.sort((a, b) => -b.name.localeCompare(a.name)),
    refetchOnWindowFocus: false,
  });
}

export function useScenario(scenarioID) {
  return useSuspenseQuery({
    queryKey: ["scenario", scenarioID],
    queryFn: async () => fetchScenarioRecord(scenarioID),
    // onSuccess: (data) => {logger.info('useScenario received data:', data)},
    placeholderData: initialScenarioState,
    refetchOnWindowFocus: false,
  });
}

export function useBpEnvironments({ region, api_key }) {
  return useQuery({
    queryKey: ["bpEnvironments", region, api_key],
    queryFn: async () => getBpEnvironments({ region, access_token: api_key }),
    enabled: !!region && !!api_key,
    placeholderData: [],
  });
}

export function useBpCorrPattns({ region, api_key }) {
  return useQuery({
    queryKey: ["bpCorrPattns", region, api_key],
    queryFn: async () => getBpCorrPattn({ region, access_token: api_key }),
    enabled: !!region && !!api_key,
    placeholderData: [],
  });
}

export function useBpUsers({ region, api_key }) {
  if (!region) region = "USA";
  return useQuery({
    queryKey: ["bpUsers", region, api_key],
    queryFn: async () =>
      postUtil({
        region,
        access_token: api_key,
        params: {
          action: "getUsers",
        },
      }),
    enabled: !!region && !!api_key,
    // placeholderData: [],  // enabling this prevents the no-rows overlay from displaying in datagrid
  });
}

export function useBpIncidentEvents({ region, api_key, incidentIDs }) {
  return useQuery({
    queryKey: ["bpIncidentEvents", region, api_key, incidentIDs],
    queryFn: async () => {
      if (incidentIDs.length > 0) {
        return await getBpIncidentEvents({
          region,
          access_token: api_key,
          incidentIDs,
        });
      } else return [];
    },
    enabled: !!region && !!api_key && incidentIDs.length > 0,
    placeholderData: [],
    // refetchOnWindowFocus: false,
  });
}

export function useBpIncidentsSearch({ access_token, region, envId, filter }) {
  const {
    data: incidentsData,
    error: incidentsError,
    fetchNextPage: fetchNextIncidentsPage,
    hasNextPage: hasNextIncidentsPage,
    isFetching: isFetchingIncidents,
    isFetchingNextPage: isFetchingNextIncidentsPage,
    status: incidentsStatus,
  } = useInfiniteQuery({
    queryKey: ["bpIncidentsQuery", access_token, region, envId, filter],
    queryFn: async ({ pageParam }) => {
      return await getBpIncidents({
        access_token,
        region,
        envId,
        filter: { ...filter, page: pageParam },
      });
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      // logger.info("useBpIncidentsSearch lastPage:", lastPage);
      // logger.info("useBpIncidentsSearch pages:", pages);
      if (pages.flatMap((page) => page.items).length === lastPage.total)
        return undefined;
      if (lastPage?.items?.length === 0) {
        return undefined;
      }
      return lastPage.page + 1;
    },
    enabled: !!access_token && !!region && !!envId,
    placeholderData: { pages: [], pageParams: [] },
    refetchOnWindowFocus: false,
    onError: async (error) => {
      logger.info("useBpIncidentsSearch error:", error);
    },
    onSuccess: (data) => {
      logger.info("useBpIncidentsSearch received data:", data);
    },
  });
  return {
    incidentsData,
    incidentsError,
    fetchNextIncidentsPage,
    hasNextIncidentsPage,
    isFetchingIncidents,
    isFetchingNextIncidentsPage,
    incidentsStatus,
  };
}

export function useOrgScenarios(demoConfigId) {
  const {
    isLoading: orgSceneriosLoading,
    error: orgSceneriosError,
    data: orgScenerios,
    isFetching: orgSceneriosFetching,
  } = useQuery({
    queryKey: ["orgScenarios", demoConfigId],
    queryFn: async () => {
      if (Boolean(demoConfigId)) {
        return listOrgScenarios(demoConfigId);
      } else return [];
    },
    onSuccess: (data) => {
      logger.info("useOrgScenarios received data:", data);
    },
    placeholderData: [],
    onError: async (error) => {
      logger.info("useOrgScenarios error:", error);
    },
    //   refetchOnWindowFocus: false,
  });
  return {
    orgScenerios,
    orgSceneriosLoading,
    orgSceneriosError,
    orgSceneriosFetching,
  };
}

export function useDemoConfig(demoConfigId) {
  const {
    isLoading: demoConfigLoading,
    error: demoConfigError,
    data: demoConfig,
    isFetching: demoConfigFetching,
  } = useQuery({
    queryKey: ["demoConfig", demoConfigId],
    queryFn: async () => {
      let orgRecord = await getOrgRecord(demoConfigId);
      if (!Boolean(orgRecord.bporgname)) {
        const bpOrgInfo = await getBpOrgInfo({
          region: orgRecord.region,
          access_token: orgRecord.api_key,
        });
        await mutateOrgRecord({
          ...orgRecord,
          bporgname: bpOrgInfo?.organization?.name,
        });
        orgRecord = await getOrgRecord(demoConfigId);
      }
      return orgRecord;
    },
    enabled: !!demoConfigId,
    placeholderData: initialOrgState,
  });

  const region = demoConfig?.region;
  const api_key = demoConfig?.api_key;

  const {
    isLoading: bpOrgIntegrationsLoading,
    error: bpOrgIntegrationsError,
    data: bpOrgIntegrations,
    isFetching: bpOrgIntegrationsFetching,
  } = useQuery({
    queryKey: ["bpOrgIntegrations", region, api_key],
    queryFn: async () => getBpIntegrations({ region, access_token: api_key }),
    enabled: !!region && !!api_key,
    placeholderData: [],
  });

  const {
    isLoading: bpOrgInfoLoading,
    error: bpOrgInfosError,
    data: bpOrgInfo,
    isFetching: bpOrgInfoFetching,
  } = useQuery({
    queryKey: ["bpOrgInfo", region, api_key],
    queryFn: async () => getBpOrgInfo({ region, access_token: api_key }),
    enabled: !!region && !!api_key,
    placeholderData: initialBpOrgInfo,
  });

  return {
    demoConfig,
    demoConfigLoading,
    demoConfigError,
    demoConfigFetching,
    bpOrgIntegrations,
    bpOrgIntegrationsError,
    bpOrgIntegrationsFetching,
    bpOrgIntegrationsLoading,
    bpOrgInfo,
    bpOrgInfoFetching,
    bpOrgInfoLoading,
    bpOrgInfosError,
  };
}

export const useUpdateScenario = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const noticeAction = useNoticeAction();

  return useMutation({
    mutationFn: async (mutatedScenarioData) => {
      logger.info("updateScenario:", mutatedScenarioData);
      delete mutatedScenarioData.createdAt;
      delete mutatedScenarioData.updatedAt;
      return mutateScenarioRecord(mutatedScenarioData);
    },
    //client side optimistic update
    onMutate: async (newScenarioData) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: ["scenario", newScenarioData.id],
      });

      // Snapshot the previous value
      const previousScenarioData = queryClient.getQueryData([
        "scenario",
        newScenarioData.id,
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData(
        ["demoConfig", newScenarioData.id],
        newScenarioData
      );

      // Return a context with the previous and new todo
      return { previousScenarioData, newScenarioData };
    },
    // If the mutation fails, use the context we returned above
    onError: (error, newScenarioData, context) => {
      queryClient.setQueryData(
        ["scenario", context.newScenarioData.id],
        context.previousScenarioData
      );
      enqueueSnackbar(`updateScenario error: ${error}`, {
        variant: "error",
        persist: true,
        action: noticeAction,
      });
    },

    onSuccess: (data, variables) => {
      logger.info("updateScenario newScenarioData:", data);
      queryClient.invalidateQueries({
        queryKey: ["scenario", variables.id],
      });
    },
  });
};

// Mutations
export default function useServerStateMutations() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { currentDemoConfigId, setCurrentDemoConfigId } = useAppState();

  const noticeAction = useNoticeAction();

  const addOrgScenarios = useMutation({
    mutationFn: async ({ orgID, scenarioIDs, owners }) => {
      logger.info(
        "addOrgScenarios adding scenarios to current org:",
        orgID,
        scenarioIDs,
        owners
      );
      const createOrgScenarios = scenarioIDs.map((scenarioID) =>
        addOrgScenario(orgID, scenarioID, owners)
      );
      return await Promise.all(createOrgScenarios);
    },
    onSuccess: (data) => {
      logger.info("addOrgScenarios successfully added:", data);
      if (data.length > 0) {
        queryClient.invalidateQueries({
          queryKey: ["demoConfig", data[0].orgID],
        });
        queryClient.invalidateQueries({
          queryKey: ["orgScenarios", data[0].orgID],
        });
      }
    },
    onError: (error) => {
      queryClient.invalidateQueries({
        queryKey: ["demoConfig", currentDemoConfigId], //TODO: this is wrong, I think...
      });
      enqueueSnackbar(`addOrgScenarios error: ${error}`, {
        variant: "error",
        persist: true,
        action: noticeAction,
      });
    },
  });

  const removeOrgScenarios = useMutation({
    mutationFn: async (orgScenarios) => {
      logger.info("removeOrgScenarios deleting OrgScenarios:", orgScenarios);
      const deleteOrgScenarios = orgScenarios.map((orgScenario) =>
        deleteOrgScenario(orgScenario.id)
      );
      return await Promise.all(deleteOrgScenarios);
    },
    onSuccess: (data) => {
      logger.info("removeOrgScenarios successfully deleted:", data);
      queryClient.invalidateQueries({
        queryKey: ["demoConfig", currentDemoConfigId],
      });
      queryClient.invalidateQueries({
        queryKey: ["orgScenarios", currentDemoConfigId],
      });
    },
    onError: (error) => {
      queryClient.invalidateQueries({
        queryKey: ["demoConfig", currentDemoConfigId],
      });
      enqueueSnackbar(`removeOrgScenarios error: ${error}`, {
        variant: "error",
        persist: true,
        action: noticeAction,
      });
    },
  });

  const removeScenariosFromOrg = useMutation({
    mutationFn: async (scenarioIDs) => {
      // Same as removeOrgScenarios but instead using scenario IDs to find OrgScenarios
      logger.info("removeScenariosFromOrg removing scenarioIDs:", scenarioIDs);

      let allOrgScenarios = await queryClient.fetchQuery({
        queryKey: ["orgScenarios", currentDemoConfigId],
      });

      let orgScenarios = allOrgScenarios.filter((orgScenario) =>
        scenarioIDs.some((scenarioID) => orgScenario.scenarioID === scenarioID)
      );
      logger.info(
        `removeScenariosFromOrg found matching OrgScenarios:`,
        orgScenarios
      );

      logger.info(
        "removeScenariosFromOrg deleting OrgScenarios:",
        orgScenarios
      );
      return await removeOrgScenarios.mutate(orgScenarios);
      // const deleteOrgScenarios = orgScenarios.map(async (orgScenario) => {
      //   return deleteOrgScenario(orgScenario.id);
      // });
      // return await Promise.all(deleteOrgScenarios);
    },
    onSuccess: (data) => {
      logger.info("removeScenariosFromOrg successfully removed:", data);
      queryClient.invalidateQueries({
        queryKey: ["demoConfig", currentDemoConfigId],
      });
      queryClient.invalidateQueries({
        queryKey: ["orgScenarios", currentDemoConfigId],
      });
    },
    onError: (error) => {
      queryClient.invalidateQueries({
        queryKey: ["demoConfig", currentDemoConfigId],
      });
      enqueueSnackbar(`removeScenariosFromOrg error: ${error}`, {
        variant: "error",
        persist: true,
        action: noticeAction,
      });
    },
  });

  const deleteDemoConfig = useMutation({
    mutationFn: async (demoConfigId) => {
      logger.info("deleteDemoConfig deleting Org ID:", demoConfigId);
      // remove OrgScenarios before deleting the demo config!
      let demoConfigOrgScenarios = queryClient.getQueryData([
        "orgScenarios",
        demoConfigId,
      ]);
      logger.info(
        "First removing demoConfig Scenarios",
        demoConfigOrgScenarios
      );
      const deleteOrgScenarios = demoConfigOrgScenarios.map((orgScenario) =>
        deleteOrgScenario(orgScenario.id)
      );
      await Promise.all(deleteOrgScenarios);
      return await deleteOrgRecord(demoConfigId);
    },
    onSuccess: (data) => {
      logger.info("deleteDemoConfig successfully deleted:", data);
      queryClient.removeQueries({
        queryKey: ["demoConfig", currentDemoConfigId],
      });
      setCurrentDemoConfigId(undefined);
      queryClient.invalidateQueries({ queryKey: ["democonfigs"] });
    },
    onError: (error) => {
      enqueueSnackbar(`deleteDemoConfig error: ${error}`, {
        variant: "error",
        persist: true,
        action: noticeAction,
      });
    },
  });

  const cloneDemoConfig = useMutation({
    mutationFn: async (orgFormState) => {
      //TODO carry over scenario mapping
      logger.info("cloneDemoConfig new Demo Config data:", orgFormState);
      return await createOrgRecord(orgFormState);
    },
    onError: (error) => {
      enqueueSnackbar(`cloneDemoConfig error: ${error}`, {
        variant: "error",
        persist: true,
        action: noticeAction,
      });
    },
  });

  const createDemoConfig = useMutation({
    mutationFn: async (orgFormState) => {
      logger.info("createDemoConfig new Demo Config data:", orgFormState);
      return await createOrgRecord(orgFormState);
    },
    onError: (error) => {
      enqueueSnackbar(`updateDemoConfig error: ${error}`, {
        variant: "error",
        persist: true,
        action: noticeAction,
      });
    },
  });

  const updateDemoConfig = useMutation({
    mutationFn: async (mutatedOrgData) => {
      delete mutatedOrgData.createdAt;
      delete mutatedOrgData.scenarios;
      delete mutatedOrgData.updatedAt;
      logger.info("mutatingOrgData:", mutatedOrgData);
      return mutateOrgRecord(mutatedOrgData);
    },
    onMutate: async (newOrgData) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: ["demoConfig", newOrgData.id],
      });

      // Snapshot the previous value
      const previousOrgData = queryClient.getQueryData([
        "demoConfig",
        newOrgData.id,
      ]);
      logger.info("previousOrgData:", previousOrgData);

      // Optimistically update to the new value
      // queryClient.setQueryData(["demoConfig", newOrgData.id], newOrgData);

      // Return a context with the previous and new todo
      return { previousOrgData, newOrgData };
    },
    // If the mutation fails, use the context we returned above
    onError: (error, newOrgData, context) => {
      queryClient.setQueryData(
        ["demoConfig", context.newOrgData.id],
        context.previousOrgData
      );
      enqueueSnackbar(`updateDemoConfig error: ${error}`, {
        variant: "error",
        persist: true,
        action: noticeAction,
      });
    },
    // Always refetch after error or success:
    onSettled: (newOrgData) => {
      queryClient.invalidateQueries({
        queryKey: ["demoConfig", newOrgData.id],
      });
    },
  });

  const deleteScenario = useMutation({
    mutationFn: async (scenarioID) => {
      logger.info("deleteScenario deleting scenario ID:", scenarioID);
      return await deleteScenarioRecord(scenarioID);
    },
    onSuccess: (data) => {
      logger.info("deleteScenario successfully deleted:", data);
      queryClient.removeQueries({ queryKey: ["scenario", data.id] });
      queryClient.invalidateQueries({ queryKey: ["scenarios"] });
      queryClient.invalidateQueries({
        queryKey: ["demoConfig", currentDemoConfigId],
      });
      queryClient.invalidateQueries({
        queryKey: ["orgScenarios", currentDemoConfigId],
      });
    },
    onError: (error) => {
      enqueueSnackbar(`deleteScenario error: ${error}`, {
        variant: "error",
        persist: true,
        action: noticeAction,
      });
    },
  });

  const createScenario = useMutation({
    mutationFn: async (scenarioData) => {
      logger.info("createScenario new Scenario data:", scenarioData);
      return await createScenarioRecord(scenarioData);
    },
    onError: (error) => {
      logger.info("createScenario error:", error);
      enqueueSnackbar(`createScenario error: ${error}`, {
        variant: "error",
        persist: true,
        action: noticeAction,
      });
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ["scenarios"] }),
    onSuccess: (data) => {
      logger.info("createScenario successfully added:", data);
    },
  });

  const createResourceMutation = useMutation({
    mutationFn: async (resourceData) => {
      logger.info("createResourceMutation new Resource data:", resourceData);
      return await createResource(resourceData);
    },
    onSuccess: (data) => {
      logger.info("createResourceMutation successfully added:", data);
    },
    onError: (error) => {
      enqueueSnackbar(`createResourceMutation error: ${error}`, {
        variant: "error",
        persist: true,
        action: noticeAction,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["resources"] }),
  });

  const updateResourceMutation = useMutation({
    mutationFn: async (resourceData) => {
      logger.info("updateResourceMutation new Resource data:", resourceData);
      return await updateResource(resourceData);
    },
    onSuccess: (data) => {
      logger.info("updateResourceMutation successfully changed:", data);
    },
    onError: (error) => {
      enqueueSnackbar(`updateResourceMutation error: ${error}`, {
        variant: "error",
        persist: true,
        action: noticeAction,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["resources"] }),
  });

  const deleteResourceMutation = useMutation({
    mutationFn: async (resourceData) => {
      logger.info("deleteResourceMutation new Resource data:", resourceData);
      return await deleteResource(resourceData);
    },
    onSuccess: (data) => {
      logger.info("deleteResourceMutation successfully deleted:", data);
    },
    onError: (error) => {
      enqueueSnackbar(`deleteResourceMutation error: ${error}`, {
        variant: "error",
        persist: true,
        action: noticeAction,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["resources"] }),
  });

  const createScheduleMutation = useMutation({
    mutationFn: async (scheduleData) => {
      logger.info("createScheduleMutation new Schedule data:", scheduleData);
      return await createSchedule(scheduleData);
    },
    onError: (error) => {
      enqueueSnackbar(`createScheduleMutation error: ${error}`, {
        variant: "error",
        persist: true,
        action: noticeAction,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["schedules"] }),
  });

  const updateScheduleMutation = useMutation({
    mutationFn: async (scheduleData) => {
      logger.info("updateScheduleMutation new Schedule data:", scheduleData);
      return await updateSchedule(scheduleData);
    },
    onError: (error) => {
      enqueueSnackbar(`updateScheduleMutation error: ${error}`, {
        variant: "error",
        persist: true,
        action: noticeAction,
      });
    },
    onSettled: (scheduleData) => {
      queryClient.invalidateQueries({ queryKey: ["schedules"] });
      queryClient.invalidateQueries({
        queryKey: ["schedule", scheduleData.event_id],
      });
    },
  });

  const deleteScheduleMutation = useMutation({
    mutationFn: async (scheduleData) => {
      logger.info("deleteScheduleMutation new Schedule data:", scheduleData);
      return await deleteSchedule(scheduleData);
    },
    onError: (error) => {
      enqueueSnackbar(`deleteScheduleMutation error: ${error}`, {
        variant: "error",
        persist: true,
        action: noticeAction,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["schedules"] }),
  });

  // crontab mutations
  const createCrontabMutation = useMutation({
    mutationFn: async (crontabData) => {
      logger.info("createCrontabMutation new Crontab data:", crontabData);
      return await createCronTab(crontabData);
    },
    onError: (error) => {
      enqueueSnackbar(`createCrontabMutation error: ${error}`, {
        variant: "error",
        persist: true,
        action: noticeAction,
      });
    },
    onSettled: (crontabData) =>
      queryClient.invalidateQueries({
        queryKey: ["scenarioCronTabs", crontabData.orgScenarioID],
      }),
  });

  const updateCrontabMutation = useMutation({
    mutationFn: async (crontabData) => {
      logger.info("updateCrontabMutation new CronTab data:", crontabData);
      return await updateCronTab(crontabData);
    },
    onError: (error) => {
      enqueueSnackbar(`updateCrontabMutation error: ${error}`, {
        variant: "error",
        persist: true,
        action: noticeAction,
      });
    },
    onSettled: (crontabData) => {
      queryClient.invalidateQueries({
        queryKey: ["scenarioCronTabs", crontabData.orgScenarioID],
      });
    },
  });

  const deleteCrontabMutation = useMutation({
    mutationFn: async (crontabID) => {
      logger.info("deleteCrontabMutation CronTab ID:", crontabID);
      return await deleteCronTab(crontabID);
    },
    onError: (error) => {
      enqueueSnackbar(`deleteCrontabMutation error: ${error}`, {
        variant: "error",
        persist: true,
        action: noticeAction,
      });
    },
    onSettled: (crontabData) =>
      queryClient.invalidateQueries({
        queryKey: ["scenarioCronTabs", crontabData.orgScenarioID],
      }),
  });

  // export mutation functions
  return {
    updateDemoConfig,
    createDemoConfig,
    cloneDemoConfig,
    deleteDemoConfig,
    removeOrgScenarios,
    addOrgScenarios,
    removeScenariosFromOrg,
    deleteScenario,
    createScenario,
    createResourceMutation,
    updateResourceMutation,
    deleteResourceMutation,
    createScheduleMutation,
    updateScheduleMutation,
    deleteScheduleMutation,
    createCrontabMutation,
    updateCrontabMutation,
    deleteCrontabMutation,
  };
}
