import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Chip,
  Autocomplete,
  Alert,
} from "@mui/material";

interface DemosimScenarioMetadata {
  name: string;
  description: string;
  symptoms: string[];
  rootCause: string;
  resolution: string;
  impactedServices: string[];
  tags: string[];
}

interface ScenarioFormProps {
  setDemosimScenarioMetadata: (metadata: DemosimScenarioMetadata) => void;
  demosimScenarioMetadata?: DemosimScenarioMetadata;
}

const ScenarioMetaDataForm: React.FC<ScenarioFormProps> = ({
  demosimScenarioMetadata,
  setDemosimScenarioMetadata,
}) => {
  const [name, setName] = useState(demosimScenarioMetadata?.name || "");
  const [description, setDescription] = useState(
    demosimScenarioMetadata?.description || ""
  );
  const [symptoms, setSymptoms] = useState<string[]>(
    demosimScenarioMetadata?.symptoms || []
  );
  const [rootCause, setRootCause] = useState(
    demosimScenarioMetadata?.rootCause || ""
  );
  const [resolution, setResolution] = useState(
    demosimScenarioMetadata?.resolution || ""
  );
  const [impactedServices, setImpactedServices] = useState<string[]>(
    demosimScenarioMetadata?.impactedServices || []
  );
  const [tags, setTags] = useState<string[]>(
    demosimScenarioMetadata?.tags || []
  );
  const [submitted, setSubmitted] = useState(true); // New state for submission tracking

  const handleSubmit = () => {
    const metadata: DemosimScenarioMetadata = {
      name,
      description,
      symptoms,
      rootCause,
      resolution,
      impactedServices,
      tags,
    };
    setDemosimScenarioMetadata(metadata);
    setSubmitted(true); // Set submitted state to true
  };

  const handleChange = (setter: React.Dispatch<React.SetStateAction<any>>) => {
    return (value: any) => {
      setter(value);
      setSubmitted(false); // Reset submitted state on change
    };
  };

  const isSubmitDisabled = !name || !description || submitted; // Disable logic

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
        maxWidth: 800,
        margin: "0 auto",
        mt: 4,
      }}
    >
      <Typography variant="h5">Scenario Metadata</Typography>
      {/* Instruct the user. Name, Description, and Tags are pre-populated from the scenario template. User is strongly encouraged to edit these to produce appropriate content for Biggy */}
      <Typography variant="body1">
        The following fields are pre-populated from the scenario template.
      </Typography>
      <Typography variant="body2">
        Please edit these fields to produce appropriate content for Biggy.
      </Typography>
      <Alert severity="info">
        Remember to press "Enter" after typing each symptom, service, or tag.
      </Alert>
      <TextField
        label="Name"
        value={name}
        onChange={(e) => handleChange(setName)(e.target.value)}
        fullWidth
        required
        helperText="A name that Biggy will use to refer to this type of incident (NOT the DemoSim scenario name)."
      />
      <TextField
        label="Description"
        value={description}
        onChange={(e) => handleChange(setDescription)(e.target.value)}
        multiline
        rows={3}
        fullWidth
        required
        helperText="A high-level summary of the incident."
      />
      <Autocomplete
        multiple
        freeSolo
        options={[]}
        value={symptoms}
        onChange={(event, newValue) => handleChange(setSymptoms)(newValue)}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Symptoms"
            placeholder="Add symptoms"
            helperText="The observable effects of the incident (e.g., “Pod Crash Looping Due to PVC Access Failure,” “High Latency on Storage Volume”)."
          />
        )}
      />
      <TextField
        label="Root Cause"
        value={rootCause}
        onChange={(e) => handleChange(setRootCause)(e.target.value)}
        multiline
        rows={3}
        fullWidth
        helperText="The underlying reason for the incident (e.g., “A security policy prevented access to the PersistentVolumeClaim”)."
      />
      <TextField
        label="Resolution"
        value={resolution}
        onChange={(e) => handleChange(setResolution)(e.target.value)}
        multiline
        rows={3}
        fullWidth
        helperText="The steps taken to resolve the incident (e.g., “Rolling back the security policy restored access to the PersistentVolumeClaim and resolved the incident.”)."
      />
      <Autocomplete
        multiple
        freeSolo
        options={[]}
        value={impactedServices}
        onChange={(event, newValue) =>
          handleChange(setImpactedServices)(newValue)
        }
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Applications/Services Impacted"
            placeholder="Add applications/services"
            helperText="The key systems affected (e.g., e-commerce platform, customer authentication service)."
          />
        )}
      />
      <Autocomplete
        multiple
        freeSolo
        options={[]}
        value={tags}
        onChange={(event, newValue) => handleChange(setTags)(newValue)}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Significant Tags"
            placeholder="Add tags"
            helperText="Any alert or change tags that might be key to resolving this incident (e.g., datacenter, app, cluster)."
          />
        )}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isSubmitDisabled} // Use the combined logic
      >
        SAVE
      </Button>
    </Box>
  );
};

export default ScenarioMetaDataForm;
