import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Tooltip from "@mui/material/Tooltip";
import {
  createRow,
  type MRT_RowSelectionState,
  type MRT_TableInstance,
} from "material-react-table";

interface MrtToolbarButtonCreateProps<TData> {
  table: MRT_TableInstance<TData>;
  dataRowTemplate: TData;
  recordDescription: string;
  dataRowsSelection: MRT_RowSelectionState;
}

export default function MrtToolbarButtonCreate<TData>({
  table,
  dataRowTemplate,
  recordDescription,
  dataRowsSelection,
}: MrtToolbarButtonCreateProps<TData>) {
  return (
    <Tooltip title={`Create a new ${recordDescription}`}>
      <Button
        variant="contained"
        size="small"
        startIcon={<AddCircleOutlineIcon />}
        disabled={Object.keys(dataRowsSelection).length > 0}
        onClick={() => {
          table.setCreatingRow(
            createRow(table, {
              ...dataRowTemplate,
            })
          );
        }}
      >
        {`Create ${recordDescription}`}
      </Button>
    </Tooltip>
  );
}
