//TODO - refactor this component to use the new hooks and context, especially validation
import React, { useState, useEffect, useCallback, useContext } from "react";

import { parse } from "csv-parse/browser/esm/sync";
import { styled } from "@mui/material/styles";

// material-ui icons
import UploadFileIcon from "@mui/icons-material/UploadFile";

// mui components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import CardContent from "@mui/material/CardContent";

// custom components
import { menuCategories } from "templates/initialStateTemplates";
import SchemaAuditBox from "./SchemaAuditBox";
import { baseScenarioTemplate } from "templates/scenarioTemplates";

import useAppState from "store/appState";
import useServerStateMutations, { useDemoConfig } from "store/serverState";
import AuthContext from "store/AuthContext";

import { Logger } from "aws-amplify";

const logger = new Logger("ScenarioImport", "INFO");

const Input = styled("input")({
  display: "none",
});

const tableToHeadersColumns = (table) => {
  let cnt = 0;
  const headers = table[0].map((h) => (Boolean(h) ? h : `_${cnt++}`));
  const rows = table.slice(1).map((row) => {
    const eachObject = headers.reduce((obj, header, i) => {
      obj[header] = Boolean(row[i]) ? row[i] : " ";
      return obj;
    }, {});
    return eachObject;
  });
  return { headers, rows };
};

const ScenarioImport = () => {
  // Global state
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);
  const { addOrgScenarios, createScenario } = useServerStateMutations();
  const { user } = useContext(AuthContext);

  // Local state
  const [eventSchemaValidationMessages, setEventSchemaValidationMessages] =
    useState([]);
  const [changeSchemaValidationMessages, setChangeSchemaValidationMessages] =
    useState([]);
  const [newScenario, setNewScenario] = useState(baseScenarioTemplate);
  const [publishing, setPublishing] = useState(false);
  const [isSchemaValid, setIsSchemaValid] = useState(false);

  //
  const validationSchemaEvents = React.useMemo(
    () => [
      {
        name: "integration_type",
        alternate: "@source",
        required: true,
        shouldNotExist: false,
        validMessage: ` - Used to map alerts to a BigPanda Alert integration`,
        invalidMessage: `Some events specify an integration_type not in this Demo Config. Create a custom integration type or change it in the events file.`,
        missingMessage: `One or more event records has a missing "integration_type" field!`,
        isValid: function ([...records]) {
          let invalidValues = [
            ...new Set(
              records
                .filter(
                  (r) =>
                    r.event_type !== "PAUSE" &&
                    !r["@offset"]?.startsWith("#pause")
                )
                .filter((r) => r.event_type !== "ITAG")
                .filter(
                  (r) =>
                    !demoConfig?.integrations.some(
                      (i) =>
                        i?.integration_type === r?.integration_type ||
                        i?.integration_type === r["@source"]
                    )
                )
                .map((r) => r.integration_type)
            ),
          ];
          if (invalidValues?.length) {
            logger.error("invalid integration_type/@source(s):", invalidValues);
            // append to this invalidMessage property
            this.invalidMessage += ` Invalid Values: ${invalidValues.join(", ")}`;
          }
          return !Boolean(invalidValues?.length);
        },
        isDefined: ([...records]) => {
          let missingField = records.filter(
            (r) =>
              !r.hasOwnProperty("integration_type") &&
              !r.hasOwnProperty("@source")
          );
          if (missingField?.length)
            logger.error(
              "Events file is missing a required 'integration_type' field:"
            );
          return !Boolean(missingField?.length);
        },
      },
      {
        name: "_offset",
        alternate: "@offset",
        required: true,
        shouldNotExist: false,
        validMessage: ` - Used to calculate the alert timestamp`,
        invalidMessage: `Events file is missing an "_offset" field!`,
        missingMessage: `One or more event records has a missing "_offset" field!`,
        isDefined: ([...records]) => {
          let missingField = records.filter(
            (r) => !r.hasOwnProperty("_offset") && !r.hasOwnProperty("@offset")
          );
          if (missingField?.length)
            logger.error("Changes file is missing a required '_offset' field:");
          return !Boolean(missingField?.length);
        },
        isValid: () => true,
      },
      {
        name: "status",
        alternate: undefined,
        required: true,
        shouldNotExist: false,
        validMessage: ` - required by BigPanda Alerts API`,
        missingMessage: `One or more event records has a missing "status" field!`,
        invalidMessage: `One or more event records has an invalid status value!`,
        isValid: ([...records]) => {
          let invalidValues = [];
          let validChangeStatuses = [
            "ok",
            "critical",
            "warning",
            "unknown",
            "acknowledged",
          ];
          invalidValues = records
            .filter(
              (r) =>
                r.event_type !== "PAUSE" && !r["@offset"]?.startsWith("#pause")
            )
            .filter((r) => r.event_type !== "ITAG")
            .filter(
              (r) =>
                r.hasOwnProperty("status") &&
                !validChangeStatuses.includes(`${r.status}`.toLocaleLowerCase())
            );
          if (invalidValues?.length)
            logger.error("invalid event record status values:", invalidValues);
          return !Boolean(invalidValues?.length);
        },
        isDefined: ([...records]) => {
          let missingField = records.filter((r) => !r.hasOwnProperty("status"));
          if (missingField?.length)
            logger.error("Changes file is missing a required 'status' field:");
          return !Boolean(missingField?.length);
        },
      },
      {
        name: "event_type",
        alternate: undefined,
        required: false,
        shouldNotExist: true,
        validMessage: undefined,
        invalidMessage: ` field; this is reserved for DemoSim and may only contain "PAUSE", "ITAG", or "ALERT".`,
        isValid: ([...records]) => {
          let invalidValues = records
            .filter((r) => r.hasOwnProperty("event_type"))
            .filter(
              (v) =>
                v?.event_type !== "PAUSE" &&
                v?.event_type !== "ALERT" &&
                v?.event_type !== "ITAG"
            );
          if (invalidValues?.length)
            logger.info("invalid event_type records:", invalidValues);
          // if any other values, it's invalid
          return !Boolean(invalidValues?.length);
        },
      },
      {
        name: "seconds",
        alternate: undefined,
        required: false,
        shouldNotExist: true,
        validMessage: undefined,
        invalidMessage: ` field; this is reserved for DemoSim and used in PAUSE events to indicate how long to pause the scenario.`,
        isValid: ([...records]) => {
          let invalidValues = records.filter(
            (r) => typeof secs !== "undefined" && isNaN(Number(r.seconds))
          );
          if (invalidValues?.length)
            logger.info("invalid seconds record values:", invalidValues);
          let invalidUsage = records
            .filter((r) => typeof secs !== "undefined")
            .filter((r) => r.event_type !== "ITAG")
            .filter(
              (r) => !r.hasOwnProperty("event_type") || r.event_type !== "PAUSE"
            );
          if (invalidUsage?.length)
            logger.info("invalid use of seconds field:", invalidUsage);
          return !Boolean([...invalidValues, ...invalidUsage].length);
        },
      },
      {
        name: "primary_property",
        alternate: undefined,
        required: false,
        shouldNotExist: false,
        validMessage:
          " - CSV primary_property values match your current integration types",
        invalidMessage: `Some events have a primary_property that doesn't match the associated integration_type in this Demo Config. 
      You should either change the integration_type's primary_property preference or change the events file before importing.
      If you proceed now, the primary_property field(s) will be set to this Org Config's preference (unless it references a missing field, in which case the imported value will be used and you'll have to fix it later).
      `,
        isValid: ([...records]) => {
          let invalidPrimaryProps = records
            .filter(
              (r) =>
                r.event_type !== "PAUSE" && !r["@offset"]?.startsWith("#pause")
            )
            .filter((r) => r.event_type !== "ITAG")
            .filter((r) => {
              const matchingIntegration = demoConfig?.integrations.find(
                (i) => i?.integration_type === r?.integration_type
              );
              return (
                matchingIntegration?.primary_property !== r?.primary_property
              );
            });
          if (invalidPrimaryProps?.length)
            logger.info(
              "unmatched integration_type primary_property's:",
              invalidPrimaryProps.map((r) => r.integration_type)
            );
          return !Boolean(invalidPrimaryProps.length);
        },
      },
      {
        name: "secondary_property",
        alternate: undefined,
        required: false,
        shouldNotExist: false,
        validMessage:
          " - CSV secondary_property values match your current integration types",
        invalidMessage: `Some events have a secondary_property that doesn't match the associated integration_type in this Demo Config. 
      You should either change the integration_type's secondary_property preference or change the events file before importing.
      If you proceed now, the secondary_property field(s) will be set to this Org Config's preference (unless it references a missing field, in which case the imported value will be used and you'll have to fix it later).
      `,
        isValid: ([...records]) => {
          let invalidSecondaryProps = records
            .filter(
              (r) =>
                r.event_type !== "PAUSE" && !r["@offset"]?.startsWith("#pause")
            )
            .filter((r) => r.event_type !== "ITAG")
            .filter((r) => {
              const matchingIntegration = demoConfig?.integrations.find(
                (i) => i?.integration_type === r?.integration_type
              );
              return (
                matchingIntegration?.secondary_property !==
                r?.secondary_property
              );
            });
          if (invalidSecondaryProps?.length)
            logger.info(
              "unmatched integration_type secondary_property's:",
              invalidSecondaryProps.map((r) => r.integration_type)
            );
          return !Boolean(invalidSecondaryProps.length);
        },
      },
    ],
    [demoConfig?.integrations]
  );

  const validationSchemaChanges = React.useMemo(
    () => [
      {
        name: "integration_type",
        alternate: "@source",
        required: true,
        shouldNotExist: false,
        validMessage: ` field (Used to map changes to a BigPanda Change integration)`,
        invalidMessage: `Some changes specify an integration_type not in this Demo Config. Create a custom integration type or change it in the changes file.`,
        missingMessage: `One or more change records has a missing or empty "integration_type" field!`,
        isDefined: ([...records]) => {
          let missingField = records.filter(
            (r) =>
              !r.hasOwnProperty("integration_type") &&
              !r.hasOwnProperty("@source")
          );
          if (missingField?.length)
            logger.error(
              "Changes file is missing a required 'integration_type' field:"
            );
          return !Boolean(missingField?.length);
        },
        isValid: ([...records]) => {
          let invalidValues = records.filter(
            (r) =>
              !demoConfig?.integrations.some(
                (i) =>
                  i?.integration_type === r?.integration_type ||
                  i?.integration_type === r["@source"]
              )
          );
          if (invalidValues?.length)
            logger.error(
              "invalid change integration_type/@source(s):",
              invalidValues
            );
          return !Boolean(invalidValues?.length);
        },
      },
      {
        name: "_offset",
        alternate: "@offset",
        required: true,
        shouldNotExist: false,
        validMessage: ` field (Used to calculate the change start & end timestamps)`,
        invalidMessage: ``,
        missingMessage: `One or more change records has a missing or empty "_offset" field!`,
        isValid: () => true,
        isDefined: ([...records]) => {
          let missingField = records.filter(
            (r) => !r.hasOwnProperty("_offset") && !r.hasOwnProperty("@offset")
          );
          if (missingField?.length)
            logger.error(
              "One or more change records has a missing or empty '_offset' field"
            );
          return !Boolean(missingField?.length);
        },
      },
      {
        name: "identifier",
        alternate: undefined,
        required: true,
        shouldNotExist: false,
        validMessage: ` field (required by BigPanda Changes API)`,
        invalidMessage: ``,
        missingMessage: `One or more change records has a missing or empty "identifier" field!`,
        isValid: () => true,
        isDefined: ([...records]) => {
          let missingField = records.filter(
            (r) => !r.hasOwnProperty("identifier")
          );
          if (missingField?.length)
            logger.error(
              "Changes file is missing a required 'identifier' field:"
            );
          return !Boolean(missingField?.length);
        },
      },
      {
        name: "status",
        alternate: undefined,
        required: true,
        shouldNotExist: false,
        validMessage: ` field (required by BigPanda Changes API)`,
        missingMessage: `One or more change records has a missing "status" field!`,
        invalidMessage: `One or more change records has an invalid status value!`,
        isValid: ([...records]) => {
          let invalidValues = [];
          let validChangeStatuses = [
            "Planned",
            "In Progress",
            "Done",
            "Canceled",
          ];
          invalidValues = records.filter(
            (r) => !validChangeStatuses.includes(r?.status)
          );
          if (invalidValues?.length)
            logger.error("invalid change record status values:", invalidValues);
          return !Boolean(invalidValues?.length);
        },
        isDefined: ([...records]) => {
          let missingField = records.filter((r) => !r.hasOwnProperty("status"));
          if (missingField?.length) {
            logger.error("change file is missing a required 'status' field.");
          }
          return !Boolean(missingField?.length);
        },
      },
      {
        name: "summary",
        alternate: undefined,
        required: true,
        shouldNotExist: false,
        validMessage: ` field (required by BigPanda Changes API)`,
        invalidMessage: ``,
        missingMessage: `One or more change records has a missing or empty "summary" field!`,
        isValid: () => true,
        isDefined: ([...records]) => {
          let missingField = records.filter(
            (r) => !r.hasOwnProperty("summary")
          );
          if (missingField?.length)
            logger.error("Changes file is missing a required 'summary' field:");
          return !Boolean(missingField?.length);
        },
      },
    ],
    [demoConfig?.integrations]
  );

  // process events file
  const [eventsFile, setEventsFile] = useState({ name: "" });
  const [origScenarioEventsRows, setOrigScenarioEventsRows] = useState([]);
  const [origScenarioEventsColumns, setOrigScenarioEventsColumns] = useState(
    []
  );
  useEffect(() => {
    if (Boolean(eventsFile.name)) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const csvResults = parse(e.target.result, {
          // columns: true,
          skip_empty_lines: true,
          trim: true,
          skip_records_with_empty_values: true,
          relax_column_count: true,
          // ignore_last_delimiters: true,
        });
        logger.info("Event CSV Results:", csvResults);
        let { headers, rows } = tableToHeadersColumns(csvResults);
        setOrigScenarioEventsColumns(
          headers.map((headerValue) => {
            return {
              field: headerValue,
              editable: false,
            };
          })
        );
        setOrigScenarioEventsRows(
          rows.map((obj) => {
            return {
              ...obj,
              // id: uuidv4(),
            };
          })
        );
      };
      reader.readAsText(eventsFile);
    }
  }, [eventsFile, setOrigScenarioEventsRows, setOrigScenarioEventsColumns]);

  // process changes file
  const [changesFile, setChangesFile] = useState({ name: "" });
  const [origScenarioChangesColumns, setOrigScenarioChangesColumns] = useState(
    []
  );
  const [origScenarioChangesRows, setOrigScenarioChangesRows] = useState([]);
  useEffect(() => {
    if (Boolean(changesFile.name)) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const csvResults = parse(e.target.result, {
          // columns: true,
          skip_empty_lines: true,
          trim: true,
          skip_records_with_empty_values: true,
          relax_column_count: true,
        });
        logger.info("Change CSV Results:", csvResults);
        let { headers, rows } = tableToHeadersColumns(csvResults);
        setOrigScenarioChangesRows(
          rows.map((obj) => {
            return {
              ...obj,
              // id: uuidv4(),
            };
          })
        );
        setOrigScenarioChangesColumns(
          headers.map((headerValue) => {
            return {
              field: headerValue,
              editable: false,
            };
          })
        );
      };
      reader.readAsText(changesFile);
    }
  }, [changesFile, setOrigScenarioChangesRows, setOrigScenarioChangesColumns]);

  // validate event and change files
  function validateSchema(schemaValidationData, columns, rows) {
    let isValid = true;
    let validationData = [];
    schemaValidationData.forEach((field) => {
      if (Boolean(columns.filter((c) => c.field === field.name))) {
        if (!field.required) {
          // if not required, just validate the data
          if (!field?.isValid(rows)) {
            validationData.push({
              status: "warning",
              message: `"${field.name}" - ` + field.invalidMessage,
            });
            // isValid = false;
          }
        } else if (field.required) {
          if (!field?.isDefined(rows)) {
            validationData.push({
              status: "error",
              message: field.missingMessage,
            });
            isValid = false;
          } else if (!field?.isValid(rows)) {
            validationData.push({
              status: "error",
              message: field.invalidMessage,
            });
            isValid = false;
          } else {
            validationData.push({
              status: "success",
              message: `"${field.name}"` + field.validMessage,
            });
          }
        }
      } else if (Boolean(columns.filter((c) => c.field === field?.alternate))) {
        validationData.push({
          status: "success",
          message:
            `"${field.alternate}" will be renamed to ${field.name}` +
            field.validMessage,
        });
      } else if (field.required) {
        // didn't find a required field; fail validation
        validationData.push({
          status: "error",
          message: `"${field.name}" is missing!`,
        });
        isValid = false;
      } // don't care if we don't find a non-required field
    });
    return { validationData, isValid };
  }

  useEffect(() => {
    let { validationData: eventsValidationData, isValid: isEventsValid } =
      validateSchema(
        validationSchemaEvents,
        origScenarioEventsColumns,
        origScenarioEventsRows
      );
    setEventSchemaValidationMessages(eventsValidationData);
    let { validationData: changesValidationData, isValid: isChangesValid } =
      validateSchema(
        validationSchemaChanges,
        origScenarioChangesColumns,
        origScenarioChangesRows
      );
    setChangeSchemaValidationMessages(changesValidationData);
    setIsSchemaValid(isEventsValid && isChangesValid);
  }, [
    origScenarioEventsColumns,
    origScenarioEventsRows,
    origScenarioChangesColumns,
    validationSchemaChanges,
    validationSchemaEvents,
    origScenarioChangesRows,
    setEventSchemaValidationMessages,
    setChangeSchemaValidationMessages,
  ]);

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const resetDefaults = () => {
    setPublishing(false);
    setEventSchemaValidationMessages([]);
    setChangeSchemaValidationMessages([]);
    setIsSchemaValid(false);
    setOrigScenarioEventsColumns([]);
    setOrigScenarioEventsRows([]);
    setOrigScenarioChangesColumns([]);
    setOrigScenarioChangesRows([]);
    setNewScenario(baseScenarioTemplate);
    setEventsFile({ name: "" });
    setChangesFile({ name: "" });
  };
  const handleClose = () => {
    resetDefaults();
    setActiveStep(0);
    setOpen(false);
  };

  // Stepper functions
  const steps = getSteps();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const isStepOptional = (step) => {
    // return step === 1;
  };
  const isStepSkipped = useCallback(
    (step) => {
      return skipped.has(step);
    },
    [skipped]
  );
  const handleNext = useCallback(() => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  }, [activeStep, isStepSkipped, skipped]);
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  function getSteps() {
    return ["Import", "Configure", "Publish"];
  }
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <CardContent>
            <Stack direction="column" spacing={2}>
              <Stack>
                <Typography>
                  Start by loading DemoSim scenario events (and optionally,
                  changes) files.
                </Typography>
                <Typography>
                  The content will be displayed below, so you can verify they
                  loaded correctly.
                </Typography>
              </Stack>
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Grid item xs={12} md={3}>
                  <label htmlFor="contained-button-file-events">
                    <Input
                      id="contained-button-file-events"
                      accept=".csv"
                      type="file"
                      onChange={(e) => {
                        setEventsFile(e.target.files[0]);
                      }}
                    />
                    <Button
                      id="btn-import-select-events-file"
                      data-test="btn-import-select-events-file"
                      variant="contained"
                      component="span"
                    >
                      Select Events File
                    </Button>
                  </label>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="events-file-display"
                    helperText="Choose a Scenario events file (CSV format only)"
                    disabled
                    variant="standard"
                    value={eventsFile.name}
                  />
                </Grid>
                {origScenarioEventsColumns.length > 0 && (
                  <Grid item xs={12}>
                    <SchemaAuditBox
                      validationSchema={eventSchemaValidationMessages}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Grid item xs={12} md={3}>
                  <label htmlFor="contained-button-file-changes">
                    <Input
                      id="contained-button-file-changes"
                      accept=".csv"
                      type="file"
                      onChange={(e) => {
                        setChangesFile(e.target.files[0]);
                      }}
                    />
                    <Button
                      id="btn-import-select-changes-file"
                      data-test="btn-import-select-changes-file"
                      variant="contained"
                      component="span"
                    >
                      Select Changes File
                    </Button>
                  </label>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="events-file-display"
                    helperText="Choose a Scenario changes file (CSV format only)"
                    disabled
                    variant="standard"
                    value={changesFile.name}
                  />
                </Grid>

                {origScenarioChangesColumns.length > 0 && (
                  <Grid item xs={12}>
                    <SchemaAuditBox
                      validationSchema={changeSchemaValidationMessages}
                    />
                  </Grid>
                )}
              </Grid>
            </Stack>
          </CardContent>
        );
      case 1:
        return (
          <CardContent>
            <Stack direction="column" spacing={2}>
              <Typography variant="h5">Configure the Scenario</Typography>
              <Typography>
                Provide a title, category, and description to help you (and
                others) locate and use your scenario.
              </Typography>
              <TextField
                id="scenario-name"
                label="Scenario Name"
                helperText="The name that will show on DemoSim Chrome Extension"
                variant="standard"
                value={newScenario.name}
                onChange={(e) =>
                  setNewScenario({ ...newScenario, name: e.target.value })
                }
              />
              <TextField
                id="select-scenario-category"
                select
                label="Category"
                value={newScenario.category}
                onChange={(e) =>
                  setNewScenario({ ...newScenario, category: e.target.value })
                }
                helperText="Select the category for display in the DemoSim Chrome Extension"
              >
                {menuCategories.map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="scenario-description"
                label="Scenario Description"
                helperText="Summarize the scenario script"
                variant="standard"
                value={newScenario.description}
                onChange={(e) =>
                  setNewScenario({
                    ...newScenario,
                    description: e.target.value,
                  })
                }
              />
            </Stack>
          </CardContent>
        );
      case 2:
        return (
          <CardContent>
            <Stack direction="column">
              <Typography variant="h5">
                Publish to the Scenario Library
              </Typography>
              <Typography>
                This will store the scenario in the Scenario Library, making it
                available for you to attach to your Demo Configs.
              </Typography>
              {Array.isArray(origScenarioEventsRows) &&
                origScenarioEventsRows.length < 1 && (
                  <Alert severity="error">
                    The Alerts table is empty; go back to the Import step!
                  </Alert>
                )}
              {!Boolean(newScenario.name) && (
                <Alert severity="error">
                  No scenario name; go back to the Configure step!
                </Alert>
              )}
              {!Boolean(newScenario.category) && (
                <Alert severity="error">
                  No scenario category; go back to the Configure step!
                </Alert>
              )}
              {!Boolean(newScenario.description) && (
                <Alert severity="error">
                  No scenario description; go back to the Configure step!
                </Alert>
              )}
              {Array.isArray(origScenarioChangesRows) &&
                origScenarioChangesRows.length < 1 && (
                  <Alert severity="warning">
                    Warning: No changes with this scenario.
                  </Alert>
                )}
            </Stack>
          </CardContent>
        );
      default:
        return <Typography variant="h2">All steps completed</Typography>;
    }
  }

  function handlePublishScenario() {
    setPublishing(true);
    logger.info("Publish Scenario starting. demoConfig:", demoConfig);
    logger.info("Publish Scenario starting. newScenario:", newScenario);
    logger.info(
      "Publish Scenario starting. origScenarioEventsRows:",
      origScenarioEventsRows
    );
    logger.info(
      "Publish Scenario starting. origScenarioChangesRows:",
      origScenarioChangesRows
    );

    let events = origScenarioEventsRows
      .reduce((arr, event) => {
        let newEvent = Object.assign({}, event);

        const event_type =
          newEvent.event_type &&
          (newEvent.event_type === "PAUSE" ||
            newEvent.event_type === "ALERT" ||
            newEvent.event_type === "ITAG")
            ? newEvent.event_type
            : newEvent["@offset"] && newEvent["@offset"].startsWith("#")
              ? "PAUSE"
              : "ALERT";

        const _offset = newEvent["_offset"]
          ? Number(newEvent["_offset"])
          : event_type === "PAUSE"
            ? arr.length >= 1 && arr[arr.length - 1]["_offset"]
              ? Number(arr[arr.length - 1]["_offset"])
              : 0
            : newEvent["@offset"]
              ? Number(newEvent["@offset"])
              : 0;

        const seconds =
          (typeof newEvent.seconds === "number" ||
            typeof newEvent.seconds === "string") &&
          newEvent.event_type === "PAUSE"
            ? Number(newEvent.seconds)
            : 0;

        const integration_type = newEvent.integration_type
          ? newEvent.integration_type
          : newEvent["@source"]
            ? newEvent["@source"]
            : "infra-mon";

        const integration = demoConfig?.integrations.find(
          (i) => i.integration_type === integration_type
        );
        const primary_property =
          integration?.primary_property &&
          newEvent.hasOwnProperty(integration?.primary_property)
            ? integration?.primary_property
            : Boolean(
                  newEvent?.primary_property &&
                    newEvent?.primary_property.trim()
                )
              ? newEvent.primary_property
              : undefined;

        const secondary_property =
          integration?.secondary_property &&
          newEvent.hasOwnProperty(integration?.secondary_property)
            ? integration?.secondary_property
            : Boolean(
                  newEvent?.secondary_property &&
                    newEvent?.secondary_property.trim()
                )
              ? newEvent.secondary_property
              : undefined;

        delete newEvent["@offset"];
        delete newEvent._offset;
        delete newEvent["@source"];
        delete newEvent.event_type;
        delete newEvent.seconds;
        delete newEvent.integration_type;
        delete newEvent.primary_property;
        delete newEvent.secondary_property;

        const tags =
          event_type === "ALERT" || event_type === "ITAG"
            ? JSON.stringify(newEvent)
            : undefined;

        return [
          ...arr,
          {
            event_type,
            _offset: Number(_offset),
            seconds,
            integration_type,
            primary_property,
            secondary_property,
            tags,
          },
        ];
      }, [])
      .sort((a, b) => a["_offset"] - b["_offset"]);
    logger.info("Publish Scenario parsed events:", events);

    let changes = Array.isArray(origScenarioChangesRows)
      ? origScenarioChangesRows
          .map((change) => {
            let newChange = Object.assign({}, change);

            const _offset = newChange._offset
              ? Number(newChange._offset)
              : newChange["@offset"]
                ? Number(newChange["@offset"])
                : 0;
            const integration_type = "change-man";
            const identifier = newChange.identifier || null;
            const status = newChange.status || null;
            const summary = newChange.summary || null;
            const ticket_url = newChange.ticket_url || null;

            delete newChange["@offset"];
            delete newChange._offset;
            delete newChange["@source"];
            delete newChange.integration_type;
            delete newChange.identifier;
            delete newChange.status;
            delete newChange.summary;
            delete newChange.ticket_url;

            const tags =
              Object.keys(newChange).length > 0
                ? JSON.stringify(newChange)
                : {};

            return {
              integration_type,
              _offset,
              identifier,
              status,
              summary,
              ticket_url,
              tags,
            };
          })
          .sort((a, b) => a["_offset"] - b["_offset"])
      : undefined;
    logger.info("Publish Scenario parsed changes:", changes);

    let finalScenario = {
      ...newScenario,
      events,
      changes,
      groupsCanRead: [],
      groupsCanEdit: ["SELT"],
      owners: [user.username],
    };

    createScenario
      .mutateAsync(finalScenario)
      .then((newScenario) =>
        addOrgScenarios.mutateAsync({
          orgID: demoConfig.id,
          scenarioIDs: [newScenario.id],
          owners: demoConfig.owners,
        })
      )
      .then(handleClose);
  }

  return (
    <Box>
      <Button
        id="btn-scenario-import"
        data-test="btn-scenario-import"
        sx={{ minWidth: "max-content" }}
        variant="outlined"
        color="info"
        size="small"
        onClick={handleClickOpen}
        startIcon={<UploadFileIcon />}
      >
        Import Scenario CSV
      </Button>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        disableEnforceFocus
      >
        <DialogTitle>DemoSim Scenario File Import</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2, mx: 2 }}>
            Use this wizard to import a DemoSim scenario's events and changes
            CSV files. This will create a new scenario in the Scenario Store.
          </DialogContentText>
          <Stack direction="column">
            <Stepper
              id="stepper-header"
              alternativeLabel
              activeStep={activeStep}
            >
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <Stack
                id="stepper-content-complete"
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Typography>All steps completed</Typography>
              </Stack>
            ) : (
              <Stack
                id="stepper-content-incomplete"
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                {getStepContent(activeStep)}
                <Stack
                  id="stepper-buttons"
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                  spacing={12}
                >
                  <Button
                    variant="outlined"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                  {activeStep === steps.length - 1 ? (
                    <Button
                      variant="outlined"
                      color="success"
                      disabled={
                        publishing ||
                        !isSchemaValid ||
                        (Array.isArray(origScenarioEventsRows) &&
                          origScenarioEventsRows.length < 1) ||
                        !Boolean(newScenario.name) ||
                        !Boolean(newScenario.category) ||
                        !Boolean(newScenario.description)
                      }
                      onClick={handlePublishScenario}
                    >
                      PUBLISH
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleNext}
                      disabled={!isSchemaValid}
                    >
                      NEXT
                    </Button>
                  )}
                </Stack>
                {publishing && <Alert severity="info">Publishing...</Alert>}
              </Stack>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ScenarioImport;
