import React, { useState, useEffect } from "react";
import useAppState from "store/appState";
// MUI components
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import BpIntegrationChooser from "./BpIntegrationChooser";
import DeleteIntegrationTypeDialog from "components/DeleteIntegrationTypeDialog/DeleteIntegrationTypeDialog";
import useServerStateMutations, { useDemoConfig } from "store/serverState";

const IntegrationEditor = ({ integration_type }) => {
  const { updateDemoConfig } = useServerStateMutations();
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);
  const integration = React.useMemo(() => demoConfig.integrations.find(i => i.integration_type === integration_type), [integration_type, demoConfig.integrations])
  const [integrationPrimaryProperty, setIntegrationPrimaryProperty] = useState(integration.primary_property);
  const [integrationSecondaryProperty, setIntegrationSecondaryProperty] = useState(integration.secondary_property);

  useEffect(() => {
    setIntegrationPrimaryProperty(integration.primary_property);
    setIntegrationSecondaryProperty(integration.secondary_property);
  }, [integration.primary_property, integration.secondary_property]);

  function handleChangeIntegrationPrimaryProperty(value) {
    if (value !== integration.primary_property) {
      setIntegrationPrimaryProperty(value)
      updateDemoConfig.mutate(
        {
          ...demoConfig,
          integrations: demoConfig.integrations.map((i) => {
            if (i.integration_type === integration_type) {
              return {
                ...i,
                primary_property: value,
              };
            } else return i;
          }),
        },
      );
    }
  }

  function handleIntegrationPrimaryPropertyKeyPress(event) {
    if (event.keyCode === 13) {
      if (event.target.value !== integration.primary_property) {
        handleChangeIntegrationPrimaryProperty(event.target.value);
      }
    }
  }

  function handleIntegrationPrimaryPropertyBlur(event) {
    if (event.target.value !== integration.primary_property) {
      handleChangeIntegrationPrimaryProperty(event.target.value);
    }
  }


  function handleChangeIntegrationSecondaryProperty(value) {
    if (value !== integration.secondary_property) {
      setIntegrationSecondaryProperty(value)
      updateDemoConfig.mutate(
        {
          ...demoConfig,
          integrations: demoConfig.integrations.map((i) => {
            if (i.integration_type === integration_type) {
              return {
                ...i,
                secondary_property: value,
              };
            } else return i;
          }),
        },
      );
    }
  }

  function handleIntegrationSecondaryPropertyKeyPress(event) {
    if (event.keyCode === 13) {
      if (event.target.value !== integration.secondary_property) {
        handleChangeIntegrationSecondaryProperty(event.target.value);
      }
    }
  }

  function handleIntegrationSecondaryPropertyBlur(event) {
    if (event.target.value !== integration.secondary_property) {
      handleChangeIntegrationSecondaryProperty(event.target.value);
    }
  }

  return (
    <Card variant="elevation" sx={{ minWidth: "225px", minHeight: "300px" }}>
      <CardHeader
        title={`Type: ${integration.integration_type}`}
        action={
          <DeleteIntegrationTypeDialog integrationType={integration.integration_type} />
        }
      />
      <CardContent>
        <Stack direction="column" spacing={2}>
          {integration.integration_type !== "change-man" && (
            <Stack
              id={`keyprops-${integration.integration_type}`}
              direction="row"
              spacing={2}
            >
              <TextField
                id={`integration-primary_property-${integration.integration_type}`}
                inputProps={{ 'data-test': `integration-primary_property-${integration.integration_type}` }}
                label="Primary Property"
                value={integrationPrimaryProperty}
                disabled={false}
                variant="standard"
                fullWidth={false}
                onChange={(e) => setIntegrationPrimaryProperty(e.target.value)}
                onKeyDown={handleIntegrationPrimaryPropertyKeyPress}
                onBlur={handleIntegrationPrimaryPropertyBlur}
              />
              <TextField
                id={`integration-secondary_property-${integration.integration_type}`}
                inputProps={{ 'data-test': `integration-secondary_property-${integration.integration_type}` }}
                label="Secondary Property"
                disabled={false}
                variant="standard"
                fullWidth={false}
                value={integrationSecondaryProperty}
                onChange={(e) =>
                  setIntegrationSecondaryProperty(e.target.value)
                }
                onKeyDown={handleIntegrationSecondaryPropertyKeyPress}
                onBlur={handleIntegrationSecondaryPropertyBlur}
              />
            </Stack>
          )}
          <BpIntegrationChooser integration_type={integration_type}/>
          <Stack
            id={`integrationProps-${integration.integration_type}`}
            direction="row"
            spacing={2}
          >
            { integration?.bp_integration?.parent_source_system && (
              <TextField
                id={`api-type-${integration.integration_type}`}
                inputProps={{ 'data-test': `api-type-${integration.integration_type}` }}
                label="API Type"
                value={
                  integration?.bp_integration?.parent_source_system === "api"
                    ? "REST"
                    : integration?.bp_integration?.parent_source_system
                }
                disabled={true}
                variant="standard"
                fullWidth={true}
              />
            )}
            { integration?.bp_integration?.parent_source_system && (
              <TextField
                id={`default-tags-${integration.integration_type}`}
                inputProps={{ 'data-test': `default-tags-${integration.integration_type}` }}
                label="Default Tags"
                value={getDefaultIntegrationTags(
                  integration?.bp_integration?.dataset_options
                ) || " "}
                disabled={true}
                variant="standard"
                fullWidth={true}
              />
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default IntegrationEditor;

function getDefaultIntegrationTags(dataset_options) {
  if (dataset_options == null) return "";
  try {
    let datasetOptions = JSON.parse(dataset_options);
    if (datasetOptions.tags) {
      return datasetOptions.tags.join();
    } else return "";
  } catch (error) {
    return "";
  }
}
