// icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SchoolIcon from '@mui/icons-material/School';
import InsightsIcon from '@mui/icons-material/Insights';
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import ScienceIcon from "@mui/icons-material/Science";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
// views
import Dashboard from "views/admin/Dashboard";
import DemoAdmin from "views/DemoAdmin/DemoAdmin";
import SeBootcamp from "views/SeBootcamp/SeBootcamp";
import Analytics from "views/Analytics/Analytics";
import DemoSim from "views/DemoSim/DemoSim";
import ScenarioLab from "views/ScenarioLab/ScenarioLab.tsx";
import SeTools from "views/SeTools/SeTools";
import ResourceScheduler from "views/ResourceScheduler/ResourceScheduler";
import AiAssist from "views/AiAssist/AiAssist";

var appRoutes = [
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    auth: ["Admins", "Everyone", "SalesEngineers", "Guests"],
  },
  {
    path: "/demoadmin",
    name: "Demo Admin",
    icon: ListAltIcon,
    component: DemoAdmin,
    auth: ["Admins", "Everyone", "SalesEngineers", "Guests"],
  },
  {
    path: "/demosim",
    name: "DemoSim Player",
    icon: SmartDisplayIcon,
    component: DemoSim,
    auth: ["Admins", "Everyone", "SalesEngineers", "Guests"],
  },
  {
    path: "/scenariolab",
    name: "Scenario Lab",
    icon: ScienceIcon,
    component: ScenarioLab,
    auth: ["Admins", "SalesEngineers", "Everyone", "Guests"],
  },
  {
    path: "/aiassist",
    name: "AI Assist",
    icon: SettingsSuggestIcon,
    component: AiAssist,
    auth: ["Admins", "SalesEngineers", "Everyone", "Guests"],
  },
  {
    path: "/setools",
    name: "SE Tools",
    icon: HomeRepairServiceIcon,
    component: SeTools,
    auth: [
      "Admins",
      "SalesEngineers",
      "Enablement",
      "RnD",
      "Everyone",
      "Guests",
    ],
  },
  {
    path: "/scheduler",
    name: "Resource Scheduler",
    icon: CalendarMonthIcon,
    component: ResourceScheduler,
    auth: ["Admins", "SalesEngineers"],
  },
  {
    path: "/sebootcamp",
    name: "SE Bootcamp",
    icon: SchoolIcon,
    component: SeBootcamp,
    auth: ["Admins", "SalesEngineers"],
  },
  {
    path: "/analytics",
    name: "SE Demo Analytics",
    icon: InsightsIcon,
    component: Analytics,
    auth: ["Admins", "SalesEngineers"],
  },
];

export default appRoutes;
