/**
 * @abstract Provide site-wide context and theme; wrap Application or Login, controlled by Authentication.
 */
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "./store/AuthContext";
import { SnackbarProvider } from "notistack";
import { Provider } from "jotai";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { get, set, del } from "idb-keyval";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

export function createIDBPersister(idbValidKey = "bpSalesEngineering") {
  return {
    persistClient: async (client) => {
      await set(idbValidKey, client);
    },
    restoreClient: async () => {
      return await get(idbValidKey);
    },
    removeClient: async () => {
      await del(idbValidKey);
    },
  };
}

const persister = createIDBPersister();
/**
 * @description Set theme. Theme created using https://bareynol.github.io/mui-theme-creator/
 */


const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider>
    <AuthContextProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{ persister }}
        >
          <BrowserRouter>
            <SnackbarProvider maxSnack={3}>
              <App />
            </SnackbarProvider>
          </BrowserRouter>
        </PersistQueryClientProvider>
      </LocalizationProvider>
    </AuthContextProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
