import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import { parseISO, compareAsc } from "date-fns";
import AuthContext from "../../store/AuthContext";
import { useSchedule } from "../../store/serverState";
import DeleteScheduleDialog from "./DeleteScheduleDialog";
import { Logger } from "aws-amplify";
import Alert from "@mui/material/Alert";

const logger = new Logger("ResourceReservationCard", "INFO");

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ResourceReservationCard({
  scheduleId,
  handleEditScheduleButtonClick,
}: {
  scheduleId: string;
  handleEditScheduleButtonClick: () => void;
}) {
  const [expanded, setExpanded] = React.useState(false);
  const { user } = useContext(AuthContext);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const {
    data: schedule,
    error: scheduleError,
    isLoading: scheduleLoading,
    isFetching: scheduleFetching,
  } = useSchedule(scheduleId);

  if (scheduleError) {
    logger.error("scheduleError", scheduleError);
    return <div>Error: {scheduleError.message}</div>;
  }
  if (scheduleLoading) {
    return <div>Loading...</div>;
  }
  if (scheduleFetching) {
    return <div>Fetching...</div>;
  }

  logger.info(compareAsc(new Date(), parseISO(schedule.end)));
  return (
    schedule && (
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {schedule.resource?.name}
          </Typography>
          <Typography variant="h5" component="div">
            {schedule.title}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {schedule.owner}
            <br />
            {schedule.project}
          </Typography>
          <DateRangeCalendar
            calendars={1}
            value={[parseISO(schedule?.start), parseISO(schedule?.end)]}
            readOnly
          />
          <Typography paragraph>{schedule.comment}</Typography>
          {compareAsc(new Date(), parseISO(schedule.end)) === 1 && (
            <Alert severity="warning">EXPIRED</Alert>
          )}
        </CardContent>
        <CardActions disableSpacing>
          {user.username === schedule.owner && (
            <div>
              <Button
                size="small"
                onClick={handleEditScheduleButtonClick}
                disabled={compareAsc(new Date(), parseISO(schedule.end)) === 1}
              >
                Edit
              </Button>
              <DeleteScheduleDialog scheduleId={schedule.event_id} />
            </div>
          )}
          {/* <Typography variant="subtitle1">Resource Details</Typography> */}
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography variant="h5">Resource Details</Typography>
            <Typography paragraph>{schedule.resource.description}</Typography>
            <Typography paragraph>
              Instructions: {schedule.resource.instructions}
            </Typography>
            <Typography paragraph>
              URL: <a href={schedule.resource.url}>{schedule.resource.url}</a>
            </Typography>
            <Typography paragraph>
              Admin ID: {schedule.resource.admin_id}
            </Typography>
            {schedule.resource.secret && (
              <Typography paragraph>
                Secret: {schedule.resource.secret}
              </Typography>
            )}
          </CardContent>
        </Collapse>
      </Card>
    )
  );
}
