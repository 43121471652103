import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import useAppState from "store/appState";

export default function LoadingSpinner() {
  const { loading } = useAppState();
  return (
    <div id="loading-spinner-component">
      {loading && (
        <CircularProgress
          id="loading-spinner-active"
          data-test="loading-spinner-active"
          size={200}
          sx={{
            // color: green[500],
            position: "absolute",
            top: "45%",
            left: "45%",
            zIndex: 1,
          }}
        />
      )}
    </div>
  );
}
