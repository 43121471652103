import React, { useState, useEffect, useCallback } from "react";
import { Logger } from "aws-amplify";

// MUI components
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SegmentIcon from "@mui/icons-material/Segment";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
// Custom components
import OrgSelector from "components/OrgSelector/OrgSelector";
import useAppState from "store/appState";
import useScenarios from "hooks/scenarioFunctions";
import useParamCache from "hooks/paramCache";
import { useDemoConfig, useOrgScenarios } from "store/serverState";
import { fetchScenarioRecord } from "store/graphql-functions";
import { useSnackbar } from "notistack";
import isJSON from "is-json";
import useNoticeAction from "hooks/noticeAction";

const logger = new Logger("DemoSim", "INFO");

export default function DemoSim() {
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);
  const { orgScenerios } = useOrgScenarios(currentDemoConfigId);
  const { paramCacheClear, hydrateScenarioVariables } = useParamCache();
  const { postAlerts, postITAGS, postChanges } = useScenarios();
  const { enqueueSnackbar } = useSnackbar();
  const [eventLog, setEventLog] = useState([]);
  // const [showCategories, setShowCategories] = useState(true);
  const showCategories = true;
  const [categories, setCategories] = useState([]);
  const [alertCache, setAlertCache] = useState([]);
  const [steps, setSteps] = useState(0);
  const [stepNumber, setStepNumber] = useState(1);
  const [pauseObject, setPauseObject] = useState({});
  const [resumeDisabled, setResumeDisabled] = useState(true);
  const [cancelDisabled, setCancelDisabled] = useState(true);
  const [statusMessage, setStatusMessage] = useState("Ready");
  const [currentScenario, setCurrentScenario] = useState({});

  const noticeAction = useNoticeAction();

  const appendEventLog = (text) => {
    let now = Date.now();
    // get hours, minutes and seconds
    let hours = `${new Date(now).getHours()}`.padStart(2, "0");
    let minutes = `${new Date(now).getMinutes()}`.padStart(2, "0");
    let seconds = `${new Date(now).getSeconds()}`.padStart(2, "0");
    let time = `${hours}:${minutes}:${seconds}`;
    setEventLog((loghistory) => [time + "\t" + text + "\n", ...loghistory]);
  };

  useEffect(() => {
    if (demoConfig.id) {
      paramCacheClear();
      setEventLog([]);
      appendEventLog(`Loaded Demo Config ${demoConfig.name}`);
      setCategories([]);
      setAlertCache([]);
      setSteps(0);
      setStepNumber(1);
      setPauseObject({});
      setResumeDisabled(true);
      setCancelDisabled(true);
      setStatusMessage("Ready");
      setCurrentScenario({});
    }
  }, [demoConfig.id, demoConfig.name, paramCacheClear]);

  // handle categories
  useEffect(() => {
    if (orgScenerios.length) {
      let scenarioCategories = orgScenerios
        .reduce((categoryArray, orgScenario) => {
          if (!categoryArray.includes(orgScenario.scenario.category))
            categoryArray.push(orgScenario.scenario.category);
          return [...categoryArray];
        }, [])
        .sort();
      if (scenarioCategories.includes("CUSTOMER")) {
        scenarioCategories.splice(scenarioCategories.indexOf("CUSTOMER"), 1);
        scenarioCategories = [...scenarioCategories, "CUSTOMER"];
      }
      if (scenarioCategories.includes("POV")) {
        scenarioCategories.splice(scenarioCategories.indexOf("POV"), 1);
        scenarioCategories = [...scenarioCategories, "POV"];
      }
      setCategories([...scenarioCategories]);
    } else setCategories([]);
  }, [orgScenerios]);
  const [menuGroups, setMenuGroups] = useState(
    categories.reduce((o, c, i) => {
      return { ...o, [i]: true };
    }, {})
  );
  const handleMenuGroupClick = (catIdx) => {
    setMenuGroups((state) => {
      return { ...state, [catIdx]: !state[catIdx] };
    });
  };

  // handle scenario submission
  const scenarioData = React.useMemo(async () => {
    logger.info("Selected scenario:", currentScenario);
    if (currentScenario.id) {
      paramCacheClear();
      let scenarioConfig = await fetchScenarioRecord(currentScenario.id);
      let { newEventData, newChangeData } = hydrateScenarioVariables({
        eventData: eventsToRows(scenarioConfig.events),
        changeData: changesToRows(scenarioConfig.changes),
        orgIntegrations: demoConfig.integrations,
      });
      logger.info("Hydrated event data:", newEventData);
      logger.info("Hydrated change data:", newChangeData);
      return { events: newEventData, changes: newChangeData };
    } else return { events: [], changes: [] };
  }, [
    currentScenario,
    demoConfig.integrations,
    hydrateScenarioVariables,
    paramCacheClear,
  ]);

  const handlePostChanges = useCallback(
    async (changes) => {
      let changesToPost = changes.map((change) => {
        let { tags, id, ...rest } = change;
        return {
          ...rest,
          ...tags,
        };
      });
      await postChanges(changesToPost)
        .then((res) => {
          logger.info(
            "postChanges response:",
            JSON.parse(res.data.postChanges)
          );
          let responses = JSON.parse(res.data.postChanges);
          responses.forEach((result) => {
            if (result.status === "fulfilled") {
              appendEventLog(
                `> Posted change: ${result.value.change_identifier}`
              );
            }
            if (result.status === "rejected") {
              appendEventLog(
                `Failed to post change: ${JSON.stringify(result.reason)}`
              );
              enqueueSnackbar(
                `Failed to post change: ${JSON.stringify(result.reason)}`,
                {
                  variant: "error",
                  persist: true,
                  action: noticeAction,
                }
              );
            }
          });
        })
        .catch((err) => {
          logger.error("postChanges", err);
          if (typeof err == "object" && err.errors)
            err.errors.forEach((error) =>
              enqueueSnackbar(error.message, {
                variant: "error",
                persist: true,
                action: noticeAction,
              })
            );
        });
    },
    [postChanges, enqueueSnackbar, noticeAction]
  );

  const [incidentIdentifiers, setIncidentIdentifiers] = useState([]);

  const handleRunEvents = useCallback(
    async (scenarioName, events) => {
      let runtimeIncidentIdentifiers = [];

      // equiv to setEventState in extension
      if (stepNumber > 1)
        appendEventLog(
          `> Resumed ${currentScenario.name} at step ${stepNumber}`
        );

      const { activeEvents, remainingEvents } =
        extractActiveFromEventCache(events);
      setAlertCache(remainingEvents);
      // let { activeAlerts, pauseObj } = stripPause(activeEvents);
      let { alerts, itags, pauses } = parseEvents(activeEvents);
      logger.info("extracted active alerts", alerts);
      logger.info("extracted active itags", itags);
      logger.info("extracted active pauses", pauses);

      // setup delay to trigger next step
      if (pauses.length) {
        setPauseObject(pauses[0]); // equiv to setPauseObject in extension
        if (pauses[0]?.event_type === "PAUSE") {
          logger.info("extracted pauseObj", pauses[0]);
          setStepNumber((step) => step + 1);
          setResumeDisabled(false);
          setCancelDisabled(false);
        }
      }

      if (alerts.length) {
        let alertsToPost = alerts.map((alert) => {
          let { tags, id, ...rest } = alert;
          return {
            ...rest,
            ...tags,
          };
        });
        logger.info("posting alerts:", alertsToPost);
        await postAlerts(alertsToPost)
          .then((res) => {
            logger.info("postAlerts response:", res.data.postAlerts);
            let responses = isJSON(res.data.postAlerts)
              ? JSON.parse(res.data.postAlerts)
              : res.data.postAlerts;
            if (Array.isArray(responses)) {
              responses.forEach((result) => {
                if (result.status === "fulfilled") {
                  if (result.value?.status === "rejected") {
                    logger.error(`postAlerts: Failed payload:`, alertsToPost);
                    appendEventLog(
                      `Failed to post alerts. ${JSON.stringify(
                        result.value.reason
                      )} (hint: locate ${
                        result.value.incident_identifiers[0]
                      } in console log.)`
                    );
                    enqueueSnackbar(
                      `${result.value.response.status} (hint: locate ${result.value.incident_identifiers[0]} in console log.)`,
                      {
                        variant: "error",
                        persist: true,
                        action: noticeAction,
                      }
                    );
                  }
                  if (
                    result.value.status === "fulfilled" &&
                    result.value.incident_identifiers
                  ) {
                    runtimeIncidentIdentifiers = [
                      ...result.value.incident_identifiers,
                      ...runtimeIncidentIdentifiers,
                    ];
                    let integration = demoConfig.integrations.find(
                      (integration) =>
                        integration.bp_integration.stream_id ===
                        result.value.app_key
                    );
                    appendEventLog(
                      `> Posted ${result.value.incident_identifiers.length} ${integration.integration_type} alerts`
                    );
                  }
                }
                if (result.status === "rejected") {
                  logger.error(
                    "postAlerts: Alert post failure reason:",
                    result.reason
                  );
                  logger.error(`postAlerts: Failed payload:`, alertsToPost);
                  appendEventLog(
                    `${JSON.stringify(
                      result.reason
                    )} See Failed Payload in console log.`
                  );
                  enqueueSnackbar(
                    `${JSON.stringify(
                      result.reason
                    )} See Failed Payload in console log.`,
                    {
                      variant: "error",
                      persist: true,
                      action: noticeAction,
                    }
                  );
                }
              });
            } else if (responses?.statusCode && responses?.body) {
              logger.error(`handling converted response:`, responses);
              // postAlert function defaults to returning an object with statusCode and body
              appendEventLog(
                `PostAlerts Error ${responses.statusCode}: ${responses.body}`
              );
              enqueueSnackbar(
                `PostAlerts Error ${responses.statusCode}: ${responses.body}`,
                {
                  variant: "error",
                  persist: true,
                  action: noticeAction,
                }
              );
            } else {
              logger.error(`Error processing postAlerts response:`, responses);
              appendEventLog(
                `Error processing postAlerts response: ${responses}`
              );
              enqueueSnackbar(
                `Error processing postAlerts response: ${responses}`,
                {
                  variant: "error",
                  persist: true,
                  action: noticeAction,
                }
              );
            }
          })
          .catch((err) => {
            logger.error("postAlerts", err);
            if (typeof err == "object" && err.errors)
              err.errors.forEach((error) =>
                enqueueSnackbar(error.message, {
                  variant: "error",
                  persist: true,
                  action: noticeAction,
                })
              );
          });
      }

      if (
        itags.length > 0 &&
        [...runtimeIncidentIdentifiers, ...incidentIdentifiers].length > 0
      ) {
        let itagsToPost = itags.map((event) => {
          let { tags, id, ...rest } = event;
          return {
            ...rest,
            ...tags,
          };
        });
        logger.info("posting itags:", itagsToPost);
        appendEventLog(`> Tagging incident(s)...`);
        await postITAGS(itagsToPost, [
          ...runtimeIncidentIdentifiers,
          ...incidentIdentifiers,
        ])
          .then((res) => {
            logger.info("postITAGS response:", res.data.postITAGS);
            let responses = isJSON(res.data.postITAGS)
              ? JSON.parse(res.data.postITAGS)
              : res.data.postITAGS;
            if (Array.isArray(responses)) {
              responses.forEach((result) => {
                if (result.status === "fulfilled") {
                  if (result.value.incidentId) {
                    appendEventLog(
                      `> Tagged incident ${result.value.incidentId}`
                    );
                  }
                }
                if (result.status === "rejected") {
                  logger.error(
                    "postITAGS: Incident Tag failure reason:",
                    result.reason
                  );
                  logger.error(`postITAGS: Failed payload:`, itagsToPost);
                  appendEventLog(
                    `${JSON.stringify(
                      result.reason
                    )} See "postITAGS: Failed payload" in console log.`
                  );
                  enqueueSnackbar(
                    `${JSON.stringify(
                      result.reason
                    )} See "postITAGS: Failed payload" in console log.`,
                    {
                      variant: "error",
                      persist: true,
                      action: noticeAction,
                    }
                  );
                }
              });
            } else if (responses?.statusCode && responses?.body) {
              logger.error(`handling converted response:`, responses);
              // postAlert function defaults to returning an object with statusCode and body
              appendEventLog(
                `postITAGS Error ${responses.statusCode}: ${responses.body}`
              );
              enqueueSnackbar(
                `postITAGS Error ${responses.statusCode}: ${responses.body}`,
                {
                  variant: "error",
                  persist: true,
                  action: noticeAction,
                }
              );
            } else {
              logger.error(`Error processing postITAGS response:`, responses);
              appendEventLog(
                `Error processing postITAGS response: ${responses}`
              );
              enqueueSnackbar(
                `Error processing postITAGS response: ${responses}`,
                {
                  variant: "error",
                  persist: true,
                  action: noticeAction,
                }
              );
            }
          })
          .catch((err) => {
            logger.error("postITAGS", err);
            if (typeof err == "object" && err.errors)
              err.errors.forEach((error) =>
                enqueueSnackbar(error.message, {
                  variant: "error",
                  persist: true,
                  action: noticeAction,
                })
              );
          });
      }

      if (remainingEvents.length === 0) {
        runtimeIncidentIdentifiers = [];
        setStatusMessage(`Ready`);
        appendEventLog(`Completed ${scenarioName}`);
        setIncidentIdentifiers([]);
        setSteps(0);
        setCurrentScenario({});
        setAlertCache([]);
        setStepNumber(1);
        setPauseObject({});
        setResumeDisabled(true);
        setCancelDisabled(true);
      } else {
        setIncidentIdentifiers([
          ...runtimeIncidentIdentifiers,
          ...incidentIdentifiers,
        ]);

        if (pauses[0]?.seconds > 0) {
          let delay = Number(pauses[0]?.seconds);
          appendEventLog(
            `> Paused. Step ${
              stepNumber + 1
            } will auto-resume in ${delay} seconds`
          );
        } else appendEventLog(`> Paused. Waiting for manual trigger to resume`);
      }
    },
    [
      enqueueSnackbar,
      noticeAction,
      postAlerts,
      postITAGS,
      stepNumber,
      demoConfig.integrations,
      currentScenario.name,
      setIncidentIdentifiers,
      incidentIdentifiers,
    ]
  );

  // Schedule paused scenario events that have a delay (seconds)
  useEffect(() => {
    let interval;
    if (
      pauseObject.hasOwnProperty("seconds") &&
      pauseObject?.seconds > 0 &&
      alertCache.length > 0
    ) {
      interval = setInterval(
        () => handleRunEvents(currentScenario.name, alertCache),
        pauseObject?.seconds * 1000
      );
    } else interval = setInterval(() => {}, 1);

    return () => clearInterval(interval);
  }, [
    pauseObject,
    alertCache,
    currentScenario.name,
    handleRunEvents,
    // logPause,
  ]);

  // This runs once, when the scenarioData is hydrated.
  useEffect(() => {
    if (currentScenario.id && stepNumber === 1) {
      setStatusMessage(`Running: ${currentScenario.name}`);
      appendEventLog(`Running: ${currentScenario.name}\n`);
      scenarioData.then((hydratedScenario) => {
        logger.info("working with hydrated scenarioData", hydratedScenario);
        // equiv to setTotalPauseCount in extension
        // only done once per scenario, here.
        let numTotalPauses = hydratedScenario.events.filter(
          (event) => event.event_type === "PAUSE"
        ).length;
        setSteps(numTotalPauses + 1);

        // equiv to postChanges in extension
        // only done once per scenario, here.
        if (hydratedScenario.changes.length) {
          handlePostChanges(hydratedScenario.changes).then(() =>
            handleRunEvents(currentScenario.name, hydratedScenario.events)
          );
          // .then(logPause);
        } else handleRunEvents(currentScenario.name, hydratedScenario.events);
        // .then( logPause );
      });
    }
  }, [
    currentScenario,
    handlePostChanges,
    handleRunEvents,
    scenarioData,
    stepNumber,
    // logPause,
  ]);

  const handleResumeClick = () => {
    // appendEventLog(
    //   `> Resumed ${currentScenario.name} at step ${stepNumber} of ${steps}`
    // );
    handleRunEvents(currentScenario.name, alertCache);
  };

  const handleCancelClick = () => {
    setStatusMessage(`Ready`);
    appendEventLog(
      `> Canceled ${currentScenario.name} at step ${stepNumber} of ${steps}`
    );
    setSteps(0);
    setCurrentScenario({});
    setAlertCache([]);
    setStepNumber(1);
    setPauseObject({});
    setResumeDisabled(true);
    setCancelDisabled(true);
  };

  const ScenarioMenuByCategory = () => {
    return (
      <MenuList dense={true}>
        {categories.map((category, catIdx) => (
          <div key={catIdx}>
            <MenuItem onClick={() => handleMenuGroupClick(catIdx)}>
              <ListItemIcon>
                <SegmentIcon />
              </ListItemIcon>
              <ListItemText primary={category} />
              {menuGroups[catIdx] ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>
            <Collapse in={menuGroups[catIdx]} timeout="auto">
              <MenuList dense={true}>
                {orgScenerios.length &&
                  orgScenerios
                    .map((orgScenario) => orgScenario.scenario)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .filter((scenario) => scenario.category === category)
                    .map((scenario) => (
                      <ListItemButton
                        key={scenario.id}
                        id={`scene-${scenario.id}`}
                        sx={{ pl: 3 }}
                        onClick={() => setCurrentScenario(scenario)}
                      >
                        <ListItemText primary={scenario.name} />
                      </ListItemButton>
                    ))}
              </MenuList>
            </Collapse>
          </div>
        ))}
      </MenuList>
    );
  };

  const FlatScenarioMenu = () => {
    return (
      <MenuList dense={true}>
        {orgScenerios.length &&
          orgScenerios
            .map((orgScenario) => orgScenario.scenario)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((scenario) => (
              <ListItemButton
                key={scenario.id}
                id={`scene-${scenario.id}`}
                onClick={() => setCurrentScenario(scenario)}
              >
                <ListItemText primary={scenario.name} />
              </ListItemButton>
            ))}
      </MenuList>
    );
  };

  return (
    <Paper className="RouteContainer">
      <Typography variant="h3">DemoSim Player</Typography>
      <OrgSelector />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "fill-available",
          flexWrap: "wrap",
          m: 2,
        }}
      >
        <Stack
          direction="column"
          // alignItems="center"
          spacing={2}
          sx={{ width: "50%" }}
        >
          <Stack direction="row">
            <Paper
              id="DemoSim Player Controls"
              sx={{
                borderRadius: 2,
                padding: { xs: 1, sm: 2 },
                ml: 3,
                mr: 4,
                width: "100%",
              }}
              elevation={1}
            >
              <Typography variant="body1">{statusMessage}</Typography>
              {stepNumber > 1 && (
                <Typography variant="caption">{`Paused at step ${stepNumber}/${steps}`}</Typography>
              )}
              <Tooltip title="Resume Scenario">
                <span>
                  <IconButton
                    aria-label="resume scenario"
                    onClick={handleResumeClick}
                    disabled={resumeDisabled}
                  >
                    <NavigateNextIcon
                      color={resumeDisabled ? "disabled" : "success"}
                    />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Stop Scenario">
                <span>
                  <IconButton
                    aria-label="stop scenario"
                    onClick={handleCancelClick}
                    disabled={cancelDisabled}
                  >
                    <CancelPresentationIcon
                      color={cancelDisabled ? "disabled" : "error"}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </Paper>
          </Stack>

          <Typography variant="body1">Select a scenario to play it.</Typography>
          {showCategories ? <ScenarioMenuByCategory /> : <FlatScenarioMenu />}
        </Stack>
        <Paper
          id="DemoSim Player Log"
          sx={{
            borderRadius: 2,
            padding: { xs: 1, sm: 2 },
            height: "100%",
            width: "50%",
          }}
          elevation={1}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">Log</Typography>
            <Tooltip title="Copy to clipboard">
              <span>
                <IconButton
                  aria-label="copy to clipboard"
                  onClick={() => {
                    navigator.clipboard.writeText(eventLog.join(""));
                  }}
                >
                  <ContentCopyIcon color="secondary" />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
          <Divider sx={{ m: 1 }} />
          {eventLog.map((entry, idx) => (
            <Typography
              key={idx}
              variant="body2"
              sx={{ whiteSpace: "pre-wrap" }}
            >{`${entry}`}</Typography>
          ))}
        </Paper>
      </Box>
    </Paper>
  );
}

function eventsToRows(events) {
  return events.map((event) => {
    return {
      event_type: event.event_type,
      _offset: Number(event._offset),
      seconds: Number(event.seconds),
      integration_type: event.integration_type,
      primary_property: event.primary_property,
      secondary_property: event.secondary_property,
      tags: JSON.parse(event.tags),
    };
  });
}

function changesToRows(changes) {
  return changes.map((change) => {
    return {
      _offset: Number(change._offset),
      integration_type: change.integration_type,
      identifier: change.identifier,
      status: change.status,
      summary: change.summary,
      ticket_url: change.ticket_url,
      tags: JSON.parse(change.tags),
    };
  });
}

function extractActiveFromEventCache([...events]) {
  let activeEvents = [];
  for (const event of [...events]) {
    activeEvents.push(events.shift());
    if (event?.event_type === "PAUSE") break;
  }
  return { activeEvents, remainingEvents: events };
}

// function stripPause(events) {
//   let activeAlerts = [...events];
//   let pauseObj = {};
//   if (activeAlerts[activeAlerts.length - 1]?.event_type === "PAUSE") {
//     pauseObj = activeAlerts.pop();
//   }
//   return { activeAlerts, pauseObj };
// }

// parse out alerts, itags, and pauses
function parseEvents(events) {
  let alerts = [];
  let itags = [];
  let pauses = [];
  events.forEach((event) => {
    if (event.event_type === "ALERT") alerts.push(event);
    if (event.event_type === "ITAG") itags.push(event);
    if (event.event_type === "PAUSE") pauses.push(event);
  });
  return { alerts, itags, pauses };
}
