// MUI components
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";

export default function BpAlertLoader() {
  return (
    <Paper
      sx={{
        borderRadius: 2,
        padding: 2,
        margin: 2,
        height: "100%",
      }}
      elevation={1}
    >
      <Typography variant="h5">
        Convert JSON files to CSV format and vice versa
      </Typography>
      <Divider sx={{ margin: 2 }} />

      <Typography variant="h6">
        This space reserved for future functionality
      </Typography>
    </Paper>
  );
}
