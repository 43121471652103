import React from "react";

// mui components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useServerStateMutations from "../../store/serverState";

// custom components

export default function DeleteScheduleDialog({
  scheduleId,
}: {
  scheduleId: string;
}) {
  const [open, setOpen] = React.useState(false);
  const { deleteScheduleMutation } = useServerStateMutations();

  const handleDelete = async (deletedId: string) => {
    console.log("handleDelete =", deletedId);
    // @ts-ignore
    deleteScheduleMutation.mutateAsync(deletedId);
  };

  const handleDeleteSchedule = () => {
    handleDelete(scheduleId);
    setOpen(false);
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>Delete</Button>
      <Dialog
        id="dlg-confirm-schedule-delete"
        data-test="dlg-confirm-schedule-delete"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="dlg-confirm-schedule-delete"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="dlg-confirm-schedule-delete">
          {"Delete this Schedule?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will PERMANENTLY delete this Schedule!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            id="btn-cancel-schedule-delete"
            data-test="btn-cancel-schedule-delete"
            onClick={() => setOpen(false)}
            autoFocus
          >
            Cancel
          </Button>
          <Button
            id="btn-confirm-schedule-delete"
            data-test="btn-confirm-schedule-delete"
            onClick={handleDeleteSchedule}
          >
            Yes, Delete this Schedule
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
