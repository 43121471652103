import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from "react";

// MUI components
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DateRange } from "@mui/x-date-pickers-pro";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { v4 as uuidv4 } from "uuid";
import {
  parseISO,
  areIntervalsOverlapping,
  isWithinInterval,
  addBusinessDays,
  subBusinessDays,
  min,
  isEqual,
} from "date-fns";

// custom components
import AuthContext from "../../store/AuthContext";
import useServerStateMutations, {
  useResources,
  useSchedules,
} from "../../store/serverState";
import {
  getNextAvailableWeekdayAt8AM,
  getFourWeeksFromDate,
} from "../../lib/date-utils";

import { Logger } from "aws-amplify";
const logger = new Logger("CreateScheduleDialog", "INFO");


interface Resource {
  id: string;
  name: string;
  type: string;
  owner: string;
  description?: string;
  instructions?: string;
  secret?: string;
  url?: string;
  admin_id?: string;
}

export default function CreateScheduleDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // global state
  const { user } = useContext(AuthContext);
  const {
    data: resources,
  } = useResources();
  const {
    data: schedules,
  } = useSchedules();
  const {
    createScheduleMutation,
  } = useServerStateMutations();

  // create local state for form fields

  const [title, setTitle] = useState(null);
  const [range, setRange] = React.useState<DateRange<Date>>(() => {
    // If tomorrow is a weekend then set start date to next Monday at 8AM local time, otherwise set start date to tomorrow at 8AM local time.
    let nextAvailableStartDate = getNextAvailableWeekdayAt8AM();
    // set end date to two weeks from nextAvailableStartDate
    let nextAvailableEndDate = getFourWeeksFromDate(nextAvailableStartDate);
    return [nextAvailableStartDate, nextAvailableEndDate];
  });

  const handleChangeRange = (newValue) => {
    // only setRange if both values are provided
    if (newValue[0] && newValue[1]) setRange(newValue);
  };

  const [scheduleResourceId, setScheduleResourceId] = useState(
    resources[0]?.id || null
  );
  const [project, setProject] = useState(null);
  const [history, setHistory] = useState(null);
  const [comment, setComment] = useState(null);
  const [owner, setOwner] = useState(user?.username || null);

  const handleResourceChange = (event: SelectChangeEvent) => {
    setScheduleResourceId(event.target.value);
  };

  const handleCloseDialog = () => {
    setTitle(null);
    setRange([
      getNextAvailableWeekdayAt8AM(),
      getFourWeeksFromDate(getNextAvailableWeekdayAt8AM()),
    ]);
    setScheduleResourceId(resources[0]?.id || null);
    setProject(null);
    setHistory(null);
    setComment(null);
    setOwner(user?.username || null);

    setOpen(false);
  };

  async function handleCreateScheduleDialogSubmit(event) {
    event.preventDefault();
    const newSchedule = {
      event_id: uuidv4(),
      title,
      start: range[0].toISOString(),
      end: range[1].toISOString(),
      scheduleResourceId,
      project,
      history,
      comment,
      owner,
    };
    logger.info("newSchedule", newSchedule);
    // @ts-ignore
    await createScheduleMutation.mutateAsync(newSchedule);
    handleCloseDialog();
  }

  const [rangeError, setRangeError] = useState(false);
  // reducer to evaluate all schedules in order to determine if the chosen resource is available for the chosen date range
  const isResourceAvailable = schedules?.reduce((accumulator, schedule) => {
    if (
      schedule.scheduleResourceId === scheduleResourceId &&
      areIntervalsOverlapping(
        {
          start: parseISO(schedule.start),
          end: parseISO(schedule.end),
        },
        {
          start: range[0],
          end: range[1],
        },
        { inclusive: true }
      )
    ) {
      return false;
    }
    return accumulator;
  }, true);

  /* reducer to return the next available date range for the chosen resource.
    Filter resources to find schedules for the chosen resource then sort by start date.
    If the current day is within the date range of the first schedule then return the end date of the first schedule plus one business day as the start date of the next available date range.
    If that day is within the next schedule then return the end date of the next schedule plus one business day as the start date of the next available date range. 
    Continue to loop through schedules until a range is found that does not overlap with any schedule, is at least 5 business days in length and up to 4 weeks long, and starts on a weekday.
  */
  const getNextAvailableDateRange = useCallback(() => {
    logger.info("getNextAvailableDateRange schedules", schedules);
    logger.info(
      "getNextAvailableDateRange scheduleResourceId",
      scheduleResourceId
    );
    if (schedules.length && scheduleResourceId) {
      const currentSchedules = schedules
        .filter(
          (schedule) => schedule.scheduleResourceId === scheduleResourceId
        )
        .sort(
          (a, b) => parseISO(a.start).getTime() - parseISO(b.start).getTime()
        );
      if (currentSchedules.length === 0)
        return [
          getNextAvailableWeekdayAt8AM(),
          getFourWeeksFromDate(getNextAvailableWeekdayAt8AM()),
        ];
      const today = new Date();
      // if (isWithinInterval(today, { start: parseISO(currentSchedules[0].start), end: parseISO(currentSchedules[0].end) })) {
      //   let nextAvailableStartDate = addBusinessDays(parseISO(currentSchedules[0].end), 1);
      //   let nextAvailableEndDate = getFourWeeksFromDate(nextAvailableStartDate);
      //   return [nextAvailableStartDate, nextAvailableEndDate];
      // }

      let nextAvailableStartDate = currentSchedules.reduce(
        (accumulator, schedule) => {
          if (
            isWithinInterval(accumulator, {
              start: subBusinessDays(parseISO(schedule.start), 1),
              end: parseISO(schedule.end),
            })
          ) {
            return addBusinessDays(parseISO(schedule.end), 1);
          }
          return accumulator;
        },
        today
      );

      //   let nextAvailableStartDate = isWithinInterval(today, {
      //     start: subBusinessDays(parseISO(currentSchedules[0].start), 1),
      //     end: parseISO(currentSchedules[0].end),
      //   })
      // ? addBusinessDays(parseISO(currentSchedules[0].end), 1)
      // : today;

      let nextAvailableEndDate = isEqual(today, nextAvailableStartDate)
        ? min([
            subBusinessDays(parseISO(currentSchedules[0].start), 1),
            addBusinessDays(nextAvailableStartDate, 20),
          ])
        : getFourWeeksFromDate(nextAvailableStartDate);
      return [nextAvailableStartDate, nextAvailableEndDate];
    } else
      return [
        getNextAvailableWeekdayAt8AM(),
        getFourWeeksFromDate(getNextAvailableWeekdayAt8AM()),
      ];
  }, [schedules, scheduleResourceId]);

  const nextAvailableDateRange = useMemo(
    () => getNextAvailableDateRange(),
    [getNextAvailableDateRange]
  );

  useEffect(() => {
    if (isResourceAvailable) {
      setRangeError(false);
    } else {
      setRangeError(true);
      logger.info("nextAvailableDateRange", nextAvailableDateRange);
    }
  }, [range, scheduleResourceId, schedules, isResourceAvailable, nextAvailableDateRange]);

  return (
    <Dialog
      id="create-schedule-dialog"
      data-test="create-schedule-dialog"
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="create-schedule-dialog"
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
      // disableEnforceFocus
      // keepMounted
    >
      <form onSubmit={handleCreateScheduleDialogSubmit}>
        <DialogTitle>New Reservation</DialogTitle>
        <DialogContent>
          <Stack sx={{ width: "100%" }} spacing={2}>
            {rangeError && (
              <Collapse in={open}>
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  Resource{" "}
                  {
                    resources.find(
                      (resource) => resource.id === scheduleResourceId
                    ).name
                  }{" "}
                  is not available for the chosen date range.
                </Alert>
                <Alert severity="info">
                  <AlertTitle>Next Available Date Range</AlertTitle>
                  {nextAvailableDateRange[0].toLocaleDateString()} to{" "}
                  {nextAvailableDateRange[1].toLocaleDateString()}
                </Alert>
              </Collapse>
            )}
            <TextField
              id="input-schedule-title"
              inputProps={{ "data-test": "input-schedule-title" }}
              autoFocus
              required
              margin="dense"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              label="Schedule Title"
              type="text"
              variant="standard"
            />
            <FormControl fullWidth>
              <InputLabel id="resource-select-label">Resource</InputLabel>
              <Select
                labelId="resource-select-label"
                id="resource-select"
                value={scheduleResourceId}
                label="Resource"
                onChange={handleResourceChange}
              >
                {resources?.map((resource: Resource) => (
                  <MenuItem key={resource.id} value={resource.id}>
                    {resource.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <DemoItem label="Reservation Dates" component="DateRangePicker">
              <DateRangePicker value={range} onChange={handleChangeRange} />
            </DemoItem>
            <TextField
              id="input-schedule-project"
              inputProps={{ "data-test": "input-schedule-project" }}
              margin="dense"
              value={project}
              onChange={(event) => setProject(event.target.value)}
              label="Project"
              type="text"
              variant="standard"
              required
            />
            <TextField
              id="input-schedule-history"
              inputProps={{ "data-test": "input-schedule-history" }}
              margin="dense"
              value={history}
              onChange={(event) => setHistory(event.target.value)}
              label="History"
              type="text"
              variant="standard"
            />
            <TextField
              id="input-schedule-comment"
              inputProps={{ "data-test": "input-schedule-comment" }}
              margin="dense"
              value={comment}
              onChange={(event) => setComment(event.target.value)}
              label="Comment"
              type="text"
              variant="standard"
            />
            <TextField
              id="input-schedule-owner"
              inputProps={{ "data-test": "input-schedule-owner" }}
              margin="dense"
              value={owner}
              onChange={(event) => setOwner(event.target.value)}
              label="Owner"
              type="text"
              variant="standard"
              required
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            id="btn-clone-form-submit"
            data-test="btn-clone-form-submit"
            type={"submit"}
            disabled={rangeError}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
