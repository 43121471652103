import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

export type WizardStepProps = {
  label: string;
  description: string;
  contextComponent?: React.ReactNode;
  component: React.ReactNode;
  gate: boolean;
  optional: boolean;
};

export type WizardStepperProps = {
  steps: WizardStepProps[];
  resetFunction?: () => void;
};

export default function HorizontalLinearStepper({
  steps,
  resetFunction,
}: WizardStepperProps) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const isStepOptional = (step: number): Boolean => {
    return steps[step].optional;
  };

  const isStepSkipped = (step: number): Boolean => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
    if (resetFunction) resetFunction();
  };

  // return a box that has a height that dynamically adjusts to the content
  // add a shadow to the box
  return (
    <Box
      id="Horizontal Linear Stepper Container"
      sx={{
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        justifyContent: "space-evenly",
        alignItems: "center",
        width: "fill-available",
        flexWrap: "wrap",
        m: 2,
      }}
    >
      <Stepper nonLinear activeStep={activeStep} sx={{ mb: 2 }}>
        {steps.map((step, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={step.label} {...stepProps} sx={{ mb: 2, mr: 2 }}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <React.Fragment>
        <Box
          id="active-step-content"
          sx={{
            width: "100%",
            margin: 2,
            height: "fit-content",
          }}
        >
          <Box
            id="active-step-section-1"
            sx={{
              display: "flex",
              flexDirection: "row",
              pb: 2,
              justifyContent: "space-around",
            }}
          >
            <Typography sx={{ whiteSpace: "pre-line" }}>
              {steps[activeStep].description}
            </Typography>
            {steps[activeStep]?.contextComponent}
          </Box>
          <Divider variant="middle" sx={{ my: 1 }} />
          {steps[activeStep].component}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            variant="outlined"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          {isStepOptional(activeStep) && (
            <Button
              color="inherit"
              onClick={handleSkip}
              sx={{ mr: 1 }}
              disabled={!steps[activeStep].gate}
              variant="outlined"
            >
              Skip
            </Button>
          )}
          <Button
            onClick={activeStep === steps.length - 1 ? handleReset : handleNext}
            disabled={!steps[activeStep].gate}
            variant="outlined"
          >
            {activeStep === steps.length - 1 ? "Reset" : "Next"}
          </Button>
        </Box>
      </React.Fragment>
    </Box>
  );
}
