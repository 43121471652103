import React, { useState, useCallback } from "react";
import {
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Select,
  MenuItem as MuiMenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { MRT_Column } from "material-react-table";
import { removeUndefined } from "../../lib/transform_funcs";
import { Logger } from "aws-amplify";

const logger = new Logger("TableColumnMenuInsert", "INFO");

// Define component props
interface TableColumnMenuInsertProps<T> {
  data: T[];
  setData: React.Dispatch<React.SetStateAction<T[]>>;
  updateColumns: (data: T[]) => void;
  column: MRT_Column<T>;
  columnDescription?: string;
  closeMenu: () => void;
  setIsDataChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  setValidationErrors?: (
    callback: (prev: Record<string, any>) => Record<string, any>
  ) => void;
  validateFn?: (data: T) => Record<string, any>; // Validation function for each row
}

const TableColumnMenuInsert = <T extends { id: string }>({
  data,
  setData,
  updateColumns,
  column,
  columnDescription = "column",
  closeMenu,
  setIsDataChanged = () => {},
  setValidationErrors = () => {},
  validateFn = () => ({}),
}: TableColumnMenuInsertProps<T>) => {
  const [open, setOpen] = useState(false); // For controlling the dialog
  const [newColumnName, setNewColumnName] = useState(""); // New column name input
  const [newColumnType, setNewColumnType] = useState<"string" | "number">(
    "string"
  ); // New column type

  // Open the dialog
  const handleOpenDialog = () => {
    logger.info("Opening dialog to insert new column");
    logger.info("Selected column:", column);
    //TODO set the column def id to the original object path. ie tags.app instead of just app
    setOpen(true);
  };

  // Close the dialog
  const handleCloseDialog = useCallback(() => {
    setOpen(false);
    closeMenu();
  }, [closeMenu]);

  // Handle the addition of a new column
  const handleAddNewColumn = useCallback(() => {
    if (!newColumnName) return;

    const newValue = newColumnType === "string" ? "" : 0; // Set empty string or zero based on type

    // Add the new column next to the selected column in the data
    setData((prevRows) => {
      const newRows = prevRows.map((row) => {
        const updatedRow = { ...row };
        // Find the object level where to add the new column
        const keys = column.id.split("."); // Break the path into its levels
        let targetObject = updatedRow;
        logger.info("targetObject keys:", keys, targetObject);
        if (targetObject["event_type"] === "PAUSE") return updatedRow;

        // Traverse the object until reaching the appropriate level
        for (let i = 0; i < keys.length - 1; i++) {
          targetObject = targetObject[keys[i]];
        }
        logger.info("targetObject:", targetObject);
        // Add the new property at the same level as the selected column
        targetObject[newColumnName] = newValue;
        return updatedRow;
      });
      logger.info(
        `Added new ${columnDescription} ${newColumnName} to rows:`,
        newRows
      );
      updateColumns(newRows);
      setValidationErrors(() =>
        removeUndefined(
          newRows.reduce((acc, row) => ({ ...acc, ...validateFn(row) }), {})
        )
      );
      return newRows;
    });

    // Mark the scenario as changed
    setIsDataChanged(true);
    handleCloseDialog();
  }, [
    column.id,
    columnDescription,
    updateColumns,
    handleCloseDialog,
    validateFn,
    newColumnName,
    newColumnType,
    setData,
    setIsDataChanged,
    setValidationErrors,
  ]);

  return (
    <>
      <MenuItem key="insert-column" onClick={handleOpenDialog}>
        Insert {columnDescription}
      </MenuItem>

      {/* Dialog for adding a new column */}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{`Add New ${columnDescription}`}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={`${columnDescription} Name`}
            fullWidth
            value={newColumnName}
            onChange={(e) => setNewColumnName(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="column-type-label">Type</InputLabel>
            <Select
              labelId="column-type-label"
              label="Type"
              value={newColumnType}
              onChange={(e) => {
                e.stopPropagation(); // Stop the event from bubbling up
                setNewColumnType(e.target.value as "string" | "number");
              }}
              fullWidth
            >
              <MuiMenuItem value="string">String</MuiMenuItem>
              <MuiMenuItem value="number">Number</MuiMenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddNewColumn} color="primary">
            {`Add ${columnDescription}`}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TableColumnMenuInsert;
