/* 
Downloads these files:
bpAlertTags-linux-x64
bpAlertTags-macos-arm64
bpAlertTags-macos-x64
bpAlertTags-win-x64.exe
*/

// MUI components
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";

export default function BpOrgTags() {
  return (
    <Paper
      sx={{
        borderRadius: 2,
        padding: 2,
        margin: 2,
        height: "100%",
      }}
      elevation={1}
    >
      <Typography variant="h5">
        Retrieve all tags in use by a BigPanda org
      </Typography>
      <Divider sx={{ margin: 2 }} />

      <Typography variant="h6">
        This space reserved for future functionality
      </Typography>
    </Paper>
  );
}
