/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEventPage = /* GraphQL */ `
  mutation CreateEventPage(
    $input: CreateEventPageInput!
    $condition: ModelEventPageConditionInput
  ) {
    createEventPage(input: $input, condition: $condition) {
      page
      events {
        event_type
        _offset
        seconds
        integration_type
        primary_property
        secondary_property
        tags
        __typename
      }
      groupsCanEdit
      groupsCanRead
      owners
      scenario {
        id
        name
        description
        category
        events {
          event_type
          _offset
          seconds
          integration_type
          primary_property
          secondary_property
          tags
          __typename
        }
        changes {
          integration_type
          _offset
          identifier
          status
          summary
          ticket_url
          tags
          __typename
        }
        orgs {
          nextToken
          __typename
        }
        groupsCanEdit
        groupsCanRead
        owners
        eventPages {
          nextToken
          __typename
        }
        changePages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      scenarioEventPagesId
      __typename
    }
  }
`;
export const updateEventPage = /* GraphQL */ `
  mutation UpdateEventPage(
    $input: UpdateEventPageInput!
    $condition: ModelEventPageConditionInput
  ) {
    updateEventPage(input: $input, condition: $condition) {
      page
      events {
        event_type
        _offset
        seconds
        integration_type
        primary_property
        secondary_property
        tags
        __typename
      }
      groupsCanEdit
      groupsCanRead
      owners
      scenario {
        id
        name
        description
        category
        events {
          event_type
          _offset
          seconds
          integration_type
          primary_property
          secondary_property
          tags
          __typename
        }
        changes {
          integration_type
          _offset
          identifier
          status
          summary
          ticket_url
          tags
          __typename
        }
        orgs {
          nextToken
          __typename
        }
        groupsCanEdit
        groupsCanRead
        owners
        eventPages {
          nextToken
          __typename
        }
        changePages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      scenarioEventPagesId
      __typename
    }
  }
`;
export const deleteEventPage = /* GraphQL */ `
  mutation DeleteEventPage(
    $input: DeleteEventPageInput!
    $condition: ModelEventPageConditionInput
  ) {
    deleteEventPage(input: $input, condition: $condition) {
      page
      events {
        event_type
        _offset
        seconds
        integration_type
        primary_property
        secondary_property
        tags
        __typename
      }
      groupsCanEdit
      groupsCanRead
      owners
      scenario {
        id
        name
        description
        category
        events {
          event_type
          _offset
          seconds
          integration_type
          primary_property
          secondary_property
          tags
          __typename
        }
        changes {
          integration_type
          _offset
          identifier
          status
          summary
          ticket_url
          tags
          __typename
        }
        orgs {
          nextToken
          __typename
        }
        groupsCanEdit
        groupsCanRead
        owners
        eventPages {
          nextToken
          __typename
        }
        changePages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      scenarioEventPagesId
      __typename
    }
  }
`;
export const createChangePage = /* GraphQL */ `
  mutation CreateChangePage(
    $input: CreateChangePageInput!
    $condition: ModelChangePageConditionInput
  ) {
    createChangePage(input: $input, condition: $condition) {
      page
      changes {
        integration_type
        _offset
        identifier
        status
        summary
        ticket_url
        tags
        __typename
      }
      groupsCanEdit
      groupsCanRead
      owners
      scenario {
        id
        name
        description
        category
        events {
          event_type
          _offset
          seconds
          integration_type
          primary_property
          secondary_property
          tags
          __typename
        }
        changes {
          integration_type
          _offset
          identifier
          status
          summary
          ticket_url
          tags
          __typename
        }
        orgs {
          nextToken
          __typename
        }
        groupsCanEdit
        groupsCanRead
        owners
        eventPages {
          nextToken
          __typename
        }
        changePages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      scenarioChangePagesId
      __typename
    }
  }
`;
export const updateChangePage = /* GraphQL */ `
  mutation UpdateChangePage(
    $input: UpdateChangePageInput!
    $condition: ModelChangePageConditionInput
  ) {
    updateChangePage(input: $input, condition: $condition) {
      page
      changes {
        integration_type
        _offset
        identifier
        status
        summary
        ticket_url
        tags
        __typename
      }
      groupsCanEdit
      groupsCanRead
      owners
      scenario {
        id
        name
        description
        category
        events {
          event_type
          _offset
          seconds
          integration_type
          primary_property
          secondary_property
          tags
          __typename
        }
        changes {
          integration_type
          _offset
          identifier
          status
          summary
          ticket_url
          tags
          __typename
        }
        orgs {
          nextToken
          __typename
        }
        groupsCanEdit
        groupsCanRead
        owners
        eventPages {
          nextToken
          __typename
        }
        changePages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      scenarioChangePagesId
      __typename
    }
  }
`;
export const deleteChangePage = /* GraphQL */ `
  mutation DeleteChangePage(
    $input: DeleteChangePageInput!
    $condition: ModelChangePageConditionInput
  ) {
    deleteChangePage(input: $input, condition: $condition) {
      page
      changes {
        integration_type
        _offset
        identifier
        status
        summary
        ticket_url
        tags
        __typename
      }
      groupsCanEdit
      groupsCanRead
      owners
      scenario {
        id
        name
        description
        category
        events {
          event_type
          _offset
          seconds
          integration_type
          primary_property
          secondary_property
          tags
          __typename
        }
        changes {
          integration_type
          _offset
          identifier
          status
          summary
          ticket_url
          tags
          __typename
        }
        orgs {
          nextToken
          __typename
        }
        groupsCanEdit
        groupsCanRead
        owners
        eventPages {
          nextToken
          __typename
        }
        changePages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      scenarioChangePagesId
      __typename
    }
  }
`;
export const createScenario = /* GraphQL */ `
  mutation CreateScenario(
    $input: CreateScenarioInput!
    $condition: ModelScenarioConditionInput
  ) {
    createScenario(input: $input, condition: $condition) {
      id
      name
      description
      category
      events {
        event_type
        _offset
        seconds
        integration_type
        primary_property
        secondary_property
        tags
        __typename
      }
      changes {
        integration_type
        _offset
        identifier
        status
        summary
        ticket_url
        tags
        __typename
      }
      orgs {
        items {
          id
          orgID
          scenarioID
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      groupsCanEdit
      groupsCanRead
      owners
      eventPages {
        items {
          page
          groupsCanEdit
          groupsCanRead
          owners
          id
          createdAt
          updatedAt
          scenarioEventPagesId
          __typename
        }
        nextToken
        __typename
      }
      changePages {
        items {
          page
          groupsCanEdit
          groupsCanRead
          owners
          id
          createdAt
          updatedAt
          scenarioChangePagesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateScenario = /* GraphQL */ `
  mutation UpdateScenario(
    $input: UpdateScenarioInput!
    $condition: ModelScenarioConditionInput
  ) {
    updateScenario(input: $input, condition: $condition) {
      id
      name
      description
      category
      events {
        event_type
        _offset
        seconds
        integration_type
        primary_property
        secondary_property
        tags
        __typename
      }
      changes {
        integration_type
        _offset
        identifier
        status
        summary
        ticket_url
        tags
        __typename
      }
      orgs {
        items {
          id
          orgID
          scenarioID
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      groupsCanEdit
      groupsCanRead
      owners
      eventPages {
        items {
          page
          groupsCanEdit
          groupsCanRead
          owners
          id
          createdAt
          updatedAt
          scenarioEventPagesId
          __typename
        }
        nextToken
        __typename
      }
      changePages {
        items {
          page
          groupsCanEdit
          groupsCanRead
          owners
          id
          createdAt
          updatedAt
          scenarioChangePagesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteScenario = /* GraphQL */ `
  mutation DeleteScenario(
    $input: DeleteScenarioInput!
    $condition: ModelScenarioConditionInput
  ) {
    deleteScenario(input: $input, condition: $condition) {
      id
      name
      description
      category
      events {
        event_type
        _offset
        seconds
        integration_type
        primary_property
        secondary_property
        tags
        __typename
      }
      changes {
        integration_type
        _offset
        identifier
        status
        summary
        ticket_url
        tags
        __typename
      }
      orgs {
        items {
          id
          orgID
          scenarioID
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      groupsCanEdit
      groupsCanRead
      owners
      eventPages {
        items {
          page
          groupsCanEdit
          groupsCanRead
          owners
          id
          createdAt
          updatedAt
          scenarioEventPagesId
          __typename
        }
        nextToken
        __typename
      }
      changePages {
        items {
          page
          groupsCanEdit
          groupsCanRead
          owners
          id
          createdAt
          updatedAt
          scenarioChangePagesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrg = /* GraphQL */ `
  mutation CreateOrg(
    $input: CreateOrgInput!
    $condition: ModelOrgConditionInput
  ) {
    createOrg(input: $input, condition: $condition) {
      id
      name
      spoc_email
      api_key
      region
      params {
        name
        values
        __typename
      }
      integrations {
        integration_type
        primary_property
        secondary_property
        bp_integration {
          _id
          updated_at
          name
          stream_id
          type
          source_system
          transport
          parent_source_system
          dataset_options
          agent_ids
          created_at
          config_secrets
          last_event
          activated_at
          last_day_error_count
          status
          __typename
        }
        __typename
      }
      scenarios {
        items {
          id
          orgID
          scenarioID
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owners
      bporgname
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrg = /* GraphQL */ `
  mutation UpdateOrg(
    $input: UpdateOrgInput!
    $condition: ModelOrgConditionInput
  ) {
    updateOrg(input: $input, condition: $condition) {
      id
      name
      spoc_email
      api_key
      region
      params {
        name
        values
        __typename
      }
      integrations {
        integration_type
        primary_property
        secondary_property
        bp_integration {
          _id
          updated_at
          name
          stream_id
          type
          source_system
          transport
          parent_source_system
          dataset_options
          agent_ids
          created_at
          config_secrets
          last_event
          activated_at
          last_day_error_count
          status
          __typename
        }
        __typename
      }
      scenarios {
        items {
          id
          orgID
          scenarioID
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owners
      bporgname
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrg = /* GraphQL */ `
  mutation DeleteOrg(
    $input: DeleteOrgInput!
    $condition: ModelOrgConditionInput
  ) {
    deleteOrg(input: $input, condition: $condition) {
      id
      name
      spoc_email
      api_key
      region
      params {
        name
        values
        __typename
      }
      integrations {
        integration_type
        primary_property
        secondary_property
        bp_integration {
          _id
          updated_at
          name
          stream_id
          type
          source_system
          transport
          parent_source_system
          dataset_options
          agent_ids
          created_at
          config_secrets
          last_event
          activated_at
          last_day_error_count
          status
          __typename
        }
        __typename
      }
      scenarios {
        items {
          id
          orgID
          scenarioID
          owners
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owners
      bporgname
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrgScenario = /* GraphQL */ `
  mutation CreateOrgScenario(
    $input: CreateOrgScenarioInput!
    $condition: ModelOrgScenarioConditionInput
  ) {
    createOrgScenario(input: $input, condition: $condition) {
      id
      orgID
      scenarioID
      org {
        id
        name
        spoc_email
        api_key
        region
        params {
          name
          values
          __typename
        }
        integrations {
          integration_type
          primary_property
          secondary_property
          __typename
        }
        scenarios {
          nextToken
          __typename
        }
        owners
        bporgname
        createdAt
        updatedAt
        __typename
      }
      scenario {
        id
        name
        description
        category
        events {
          event_type
          _offset
          seconds
          integration_type
          primary_property
          secondary_property
          tags
          __typename
        }
        changes {
          integration_type
          _offset
          identifier
          status
          summary
          ticket_url
          tags
          __typename
        }
        orgs {
          nextToken
          __typename
        }
        groupsCanEdit
        groupsCanRead
        owners
        eventPages {
          nextToken
          __typename
        }
        changePages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      owners
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrgScenario = /* GraphQL */ `
  mutation UpdateOrgScenario(
    $input: UpdateOrgScenarioInput!
    $condition: ModelOrgScenarioConditionInput
  ) {
    updateOrgScenario(input: $input, condition: $condition) {
      id
      orgID
      scenarioID
      org {
        id
        name
        spoc_email
        api_key
        region
        params {
          name
          values
          __typename
        }
        integrations {
          integration_type
          primary_property
          secondary_property
          __typename
        }
        scenarios {
          nextToken
          __typename
        }
        owners
        bporgname
        createdAt
        updatedAt
        __typename
      }
      scenario {
        id
        name
        description
        category
        events {
          event_type
          _offset
          seconds
          integration_type
          primary_property
          secondary_property
          tags
          __typename
        }
        changes {
          integration_type
          _offset
          identifier
          status
          summary
          ticket_url
          tags
          __typename
        }
        orgs {
          nextToken
          __typename
        }
        groupsCanEdit
        groupsCanRead
        owners
        eventPages {
          nextToken
          __typename
        }
        changePages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      owners
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrgScenario = /* GraphQL */ `
  mutation DeleteOrgScenario(
    $input: DeleteOrgScenarioInput!
    $condition: ModelOrgScenarioConditionInput
  ) {
    deleteOrgScenario(input: $input, condition: $condition) {
      id
      orgID
      scenarioID
      org {
        id
        name
        spoc_email
        api_key
        region
        params {
          name
          values
          __typename
        }
        integrations {
          integration_type
          primary_property
          secondary_property
          __typename
        }
        scenarios {
          nextToken
          __typename
        }
        owners
        bporgname
        createdAt
        updatedAt
        __typename
      }
      scenario {
        id
        name
        description
        category
        events {
          event_type
          _offset
          seconds
          integration_type
          primary_property
          secondary_property
          tags
          __typename
        }
        changes {
          integration_type
          _offset
          identifier
          status
          summary
          ticket_url
          tags
          __typename
        }
        orgs {
          nextToken
          __typename
        }
        groupsCanEdit
        groupsCanRead
        owners
        eventPages {
          nextToken
          __typename
        }
        changePages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      owners
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCronTab = /* GraphQL */ `
  mutation CreateCronTab(
    $input: CreateCronTabInput!
    $condition: ModelCronTabConditionInput
  ) {
    createCronTab(input: $input, condition: $condition) {
      id
      schedule
      orgScenarioID
      owners
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCronTab = /* GraphQL */ `
  mutation UpdateCronTab(
    $input: UpdateCronTabInput!
    $condition: ModelCronTabConditionInput
  ) {
    updateCronTab(input: $input, condition: $condition) {
      id
      schedule
      orgScenarioID
      owners
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCronTab = /* GraphQL */ `
  mutation DeleteCronTab(
    $input: DeleteCronTabInput!
    $condition: ModelCronTabConditionInput
  ) {
    deleteCronTab(input: $input, condition: $condition) {
      id
      schedule
      orgScenarioID
      owners
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createResource = /* GraphQL */ `
  mutation CreateResource(
    $input: CreateResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    createResource(input: $input, condition: $condition) {
      name
      description
      instructions
      type
      url
      admin_id
      owner
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateResource = /* GraphQL */ `
  mutation UpdateResource(
    $input: UpdateResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    updateResource(input: $input, condition: $condition) {
      name
      description
      instructions
      type
      url
      admin_id
      owner
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteResource = /* GraphQL */ `
  mutation DeleteResource(
    $input: DeleteResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    deleteResource(input: $input, condition: $condition) {
      name
      description
      instructions
      type
      url
      admin_id
      owner
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSchedule = /* GraphQL */ `
  mutation CreateSchedule(
    $input: CreateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    createSchedule(input: $input, condition: $condition) {
      event_id
      title
      start
      end
      disabled
      color
      textColor
      editable
      deletable
      draggable
      allDay
      resource {
        name
        description
        instructions
        type
        url
        admin_id
        owner
        id
        createdAt
        updatedAt
        __typename
      }
      project
      history
      comment
      owner
      createdAt
      updatedAt
      scheduleResourceId
      __typename
    }
  }
`;
export const updateSchedule = /* GraphQL */ `
  mutation UpdateSchedule(
    $input: UpdateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    updateSchedule(input: $input, condition: $condition) {
      event_id
      title
      start
      end
      disabled
      color
      textColor
      editable
      deletable
      draggable
      allDay
      resource {
        name
        description
        instructions
        type
        url
        admin_id
        owner
        id
        createdAt
        updatedAt
        __typename
      }
      project
      history
      comment
      owner
      createdAt
      updatedAt
      scheduleResourceId
      __typename
    }
  }
`;
export const deleteSchedule = /* GraphQL */ `
  mutation DeleteSchedule(
    $input: DeleteScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    deleteSchedule(input: $input, condition: $condition) {
      event_id
      title
      start
      end
      disabled
      color
      textColor
      editable
      deletable
      draggable
      allDay
      resource {
        name
        description
        instructions
        type
        url
        admin_id
        owner
        id
        createdAt
        updatedAt
        __typename
      }
      project
      history
      comment
      owner
      createdAt
      updatedAt
      scheduleResourceId
      __typename
    }
  }
`;
export const postChanges = /* GraphQL */ `
  mutation PostChanges(
    $access_token: String!
    $changes: AWSJSON
    $region: String
  ) {
    postChanges(access_token: $access_token, changes: $changes, region: $region)
  }
`;
export const postAlerts = /* GraphQL */ `
  mutation PostAlerts(
    $access_token: String!
    $alerts: AWSJSON
    $integrations: AWSJSON
    $region: String
  ) {
    postAlerts(
      access_token: $access_token
      alerts: $alerts
      integrations: $integrations
      region: $region
    )
  }
`;
export const postITAGS = /* GraphQL */ `
  mutation PostITAGS(
    $access_token: String!
    $itags: AWSJSON!
    $incident_identifiers: AWSJSON!
    $region: String
  ) {
    postITAGS(
      access_token: $access_token
      itags: $itags
      incident_identifiers: $incident_identifiers
      region: $region
    )
  }
`;
export const postUtil = /* GraphQL */ `
  mutation PostUtil(
    $access_token: String!
    $region: String!
    $params: AWSJSON!
    $payload: AWSJSON
  ) {
    postUtil(
      access_token: $access_token
      region: $region
      params: $params
      payload: $payload
    )
  }
`;
export const queryOpenAI = /* GraphQL */ `
  mutation QueryOpenAI($config: AWSJSON!) {
    queryOpenAI(config: $config)
  }
`;
