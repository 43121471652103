/* validators for scenario event (as stored in the scenario object), scenario 
change (as stored in the scenario object), scenario (as stored in the scenario 
object), flattened events, and flattened changes
*/
import type { Event, Change } from "../types/API";
import {
  removeUndefined,
  extractUniqueKeysFromProperty,
} from "../lib/transform_funcs";
import { Logger } from "aws-amplify";

const logger = new Logger("scenarioValidators", "INFO");

type EventWithId = Event & {
  id: string;
  tags: Record<string, any>;
};

type ChangeWithId = Change & {
  id: string;
  tags: Record<string, any>;
};

type InstantiatedEvent = {
  id: string;
  event_type: string;
  timestamp: number;
  app_key: string;
  primary_property: string;
  secondary_property: string;
  tags: Record<string, any>;
};

type InstantiatedChange = {
  id: string;
  app_key: string;
  identifier: string;
  summary: string;
  status: string;
  ticket_url: string;
  start: number;
  end: number;
  tags: Record<string, any>;
};

type ValidationState = {
  [uuid: string]: {
    [property: string]: string; // Each uuid has string properties
  };
};

const validAlertStatuses = [
  "ok",
  "critical",
  "warning",
  "unknown",
  "acknowledged",
];
const validEventTypes = ["ALERT", "PAUSE", "ITAG"];

export const validateAlertStatus = (value: any): string | undefined => {
  if (!value) return "Alert status is required for ALERT events";
  if (
    typeof value === "string" &&
    validAlertStatuses.includes(value.toLowerCase())
  ) {
    return undefined;
  }
  return "Invalid alert status";
};

export const validateEventType = (value: any): string | undefined => {
  if (!value) return "Event type is required";
  if (
    typeof value === "string" &&
    validEventTypes.includes(value.toUpperCase())
  ) {
    return undefined;
  }
  return "Invalid event type";
};

export const validateEventOffset = (value: any): string | undefined => {
  if (typeof value === "undefined" || value === null)
    return "Offset is required";
  if (
    typeof value === "number"
    // && value >= 0
  ) {
    return undefined;
  }
  return "Offset must be a number greater than or equal to zero";
};
export const validateTimestamp = (value: any): string | undefined => {
  if (typeof value === "undefined" || value === null)
    return "Timestamp is required";
  if (
    typeof value === "number"
    // && value >= 0
  ) {
    return undefined;
  }
  return "Timestamp must be a number";
};

export const validateEventSeconds = (value: any): string | undefined => {
  if (typeof value === "undefined" || value === null)
    return "Seconds is required";
  if (typeof value === "number" && value >= 0) {
    return undefined;
  }
  return "Seconds must be a number greater than or equal to zero";
};

export const validateIntegrationType = (
  integrationTypes: string[],
  value: any
): string | undefined => {
  if (!value) return "Integration type is required for ALERT events";
  if (!integrationTypes.includes(value))
    return `Invalid integration type ${value}`;
  if (integrationTypes.includes(value)) {
    return undefined;
  }
  return "Invalid integration type";
};

// event app_key is required and must be a random guid of 32 characters. It also must not contain the work "UNASSIGNED"
export const validateAppKey = (value: any): string | undefined => {
  if (!value)
    return "Missing integration type (or not assigned to an integration)";
  // if value contains "UNASSIGNED" return error
  if (typeof value === "string" && value.includes("UNASSIGNED")) {
    return `Invalid app key ${value}: Assign integration type to a BigPanda integration.`;
  }
  if (typeof value === "string" && value.length === 32) {
    return undefined;
  }
  return "App key must be a random guid of 32 characters";
};

export const validateAlertPrimaryProperty = (
  tagKeys: string[],
  value: any
): string | undefined => {
  if (!value) return "primary_property is required for ALERT events";
  if (tagKeys.includes(value)) {
    return undefined;
  }
  return "primary_property must refer to a tag";
};

export const validateAlertSecondaryProperty = (
  tagKeys: string[],
  value: any
): string | undefined => {
  if (!value) return "secondary_property is required for ALERT events";
  if (tagKeys.includes(value)) {
    return undefined;
  }
  return "secondary_property must refer to a tag";
};

export const validateItd_ai_reasoning_1 = (value: any): string | undefined => {
  if (!value) return "Reasoning is required for ITAG events";
  return undefined;
};

export const validateItd_ai_root_cause_1 = (value: any): string | undefined => {
  if (!value) return "Root cause is required for ITAG events";
  return undefined;
};

export const validateItd_ai_title_1 = (value: any): string | undefined => {
  if (!value) return "Title is required for ITAG events";
  return undefined;
};

export const validateItd_ai_summary_1 = (value: any): string | undefined => {
  if (!value) return "Summary is required for ITAG events";
  return undefined;
};

export const useEventValidation = (integrationTypes) => {
  const validIntegrationTypes = integrationTypes.filter(
    (t) => t !== "change-man"
  );
  const validateEvent = (event: EventWithId): ValidationState => {
    let eventErrors = {};

    // validate metadata
    eventErrors = removeUndefined({
      ...eventErrors,
      event_type: validateEventType(event.event_type),
      _offset: validateEventOffset(event._offset),
    });

    if (event.event_type === "ALERT") {
      //@ts-ignore
      const tagKeys = extractUniqueKeysFromProperty([event], "tags").filter(
        (tag) =>
          ![
            "id",
            "event_type",
            "_offset",
            "seconds",
            "integration_type",
            "primary_property",
            "secondary_property",
            "status",
          ].includes(tag)
      );
      eventErrors = removeUndefined({
        ...eventErrors,
        integration_type: validateIntegrationType(
          validIntegrationTypes,
          event.integration_type
        ),
        primary_property: validateAlertPrimaryProperty(
          tagKeys,
          event.primary_property
        ),
        secondary_property: validateAlertSecondaryProperty(
          tagKeys,
          event.secondary_property
        ),
        status: validateAlertStatus(event.tags["status"]),
      });
    }

    if (event.event_type === "PAUSE") {
      eventErrors = removeUndefined({
        ...eventErrors,
        seconds: validateEventSeconds(event.seconds),
      });
    }

    if (event.event_type === "ITAG") {
      eventErrors = removeUndefined({
        ...eventErrors,
        itd_ai_reasoning_1: validateItd_ai_reasoning_1(
          event.tags["itd_ai_reasoning_1"]
        ),
        itd_ai_root_cause_1: validateItd_ai_root_cause_1(
          event.tags["itd_ai_root_cause_1"]
        ),
        itd_ai_title_1: validateItd_ai_title_1(event.tags["itd_ai_title_1"]),
        itd_ai_summary_1: validateItd_ai_summary_1(
          event.tags["itd_ai_summary_1"]
        ),
      });
    }
    return { [event["id"]]: eventErrors };
  };

  return validateEvent;
};

export const useInstantiatedEventValidation = (integrationTypes) => {
  const validIntegrationTypes = integrationTypes.filter(
    (t) => t !== "change-man"
  );
  const validateEvent = (event: InstantiatedEvent): ValidationState => {
    logger.info(
      "validateEvent event, integrationTypes:",
      event,
      validIntegrationTypes
    );
    let eventErrors = {};

    // validate metadata
    eventErrors = removeUndefined({
      ...eventErrors,
      event_type: validateEventType(event.event_type),
      _offset: validateTimestamp(event.timestamp),
    });

    if (event.event_type === "ALERT") {
      //@ts-ignore
      const tagKeys = extractUniqueKeysFromProperty([event], "tags").filter(
        (tag) =>
          ![
            "id",
            "event_type",
            "_offset",
            "seconds",
            "integration_type",
            "primary_property",
            "secondary_property",
            "status",
          ].includes(tag)
      );
      eventErrors = removeUndefined({
        ...eventErrors,
        app_key: validateAppKey(event.app_key),
        primary_property: validateAlertPrimaryProperty(
          tagKeys,
          event.primary_property
        ),
        secondary_property: validateAlertSecondaryProperty(
          tagKeys,
          event.secondary_property
        ),
        status: validateAlertStatus(event.tags["status"]),
      });
    }

    if (event.event_type === "ITAG") {
      eventErrors = removeUndefined({
        ...eventErrors,
        itd_ai_reasoning_1: validateItd_ai_reasoning_1(
          event.tags["itd_ai_reasoning_1"]
        ),
        itd_ai_root_cause_1: validateItd_ai_root_cause_1(
          event.tags["itd_ai_root_cause_1"]
        ),
        itd_ai_title_1: validateItd_ai_title_1(event.tags["itd_ai_title_1"]),
        itd_ai_summary_1: validateItd_ai_summary_1(
          event.tags["itd_ai_summary_1"]
        ),
      });
    }
    return { [event["id"]]: eventErrors };
  };

  return validateEvent;
};

export const useChangeValidation = (integrationTypes) => {
  const validIntegrationTypes = integrationTypes.filter(
    (t) => t === "change-man"
  );
  const validateChange = (change: ChangeWithId): ValidationState => {
    logger.info(
      "validateChange change, integrationTypes:",
      change,
      integrationTypes
    );
    let changeErrors = {};
    // const tagKeys = Object.keys(change.tags).filter(
    //   (tag) => !["id", "_offset", "integration_type", "status"].includes(tag)
    // );

    // validate metadata
    changeErrors = removeUndefined({
      ...changeErrors,
      _offset: validateEventOffset(change._offset),
      integration_type: validateIntegrationType(
        validIntegrationTypes,
        change.integration_type
      ),
      identifier: change.identifier ? undefined : "Identifier is required",
      // status: validateChangeStatus(change.status), //TODO: validateChangeStatus
      summary: change.summary ? undefined : "Summary is required",
    });

    return { [change["id"]]: changeErrors };
  };

  return validateChange;
};
export const useInstantiatedChangeValidation = (integrationTypes) => {
  const validIntegrationTypes = integrationTypes.filter(
    (t) => t === "change-man"
  );
  const validateChange = (change: InstantiatedChange): ValidationState => {
    logger.info(
      "validateChange change, integrationTypes:",
      change,
      validIntegrationTypes
    );
    let changeErrors = {};
    // const tagKeys = Object.keys(change.tags).filter(
    //   (tag) => !["id", "_offset", "integration_type", "status"].includes(tag)
    // );

    // validate metadata
    changeErrors = removeUndefined({
      ...changeErrors,
      app_key: validateAppKey(change.app_key),
      start: validateTimestamp(change.start),
      end: validateTimestamp(change.end),

      identifier: change.identifier ? undefined : "Identifier is required",
      // status: validateChangeStatus(change.status), //TODO: validateChangeStatus
      summary: change.summary ? undefined : "Summary is required",
    });

    return { [change["id"]]: changeErrors };
  };

  return validateChange;
};
