import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import useAppState from "store/appState";
import useServerStateMutations, { useDemoConfig } from "store/serverState";

const AddVariableDialog = () => {
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);
  const { updateDemoConfig } = useServerStateMutations();
  const [open, setOpen] = React.useState(false);
  const [variableCode, setVariableCode] = useState("");

  const handleAddVariableButtonClick = () => {
    if (!demoConfig.params.find((p) => p.name === variableCode)) {
      updateDemoConfig
        .mutateAsync({
          ...demoConfig,
          params: [
            ...demoConfig.params,
            {
              name: variableCode,
              values: [],
            },
          ],
        })
        .then(() => setOpen(false));
    } else setOpen(false);
  };

  return (
    <div>
      <Button
        data-test="add-variable-button"
        variant="contained"
        size="small"
        color="success"
        startIcon={<AddIcon />}
        onClick={() => setOpen(true)}
      >
        New Org Variable
      </Button>
      <Dialog
        data-test="add-variable-dialog"
        open={open}
        onClose={() => setOpen(false)}
        disableEnforceFocus
      >
        <DialogTitle>New Org Variable</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <DialogContentText>
              Fill in these fields to create the new variable
            </DialogContentText>
            <Divider variant="middle" />
            <TextField
              required
              label="Variable Code"
              inputProps={{ "data-test": "new-variable-input" }}
              helperText="This is the code that you'll use in your scenario tags"
              margin="dense"
              value={variableCode}
              onChange={(e) => {
                setVariableCode(e.target.value);
              }}
            >
              Variable Code
            </TextField>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            data-test="new-variable-cancel-button"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            data-test="new-variable-create-button"
            onClick={handleAddVariableButtonClick}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddVariableDialog;
