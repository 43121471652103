import { useState, useCallback, useMemo } from "react";
import { Logger } from "aws-amplify";
import { zipSync, strToU8 } from "fflate";
import { saveAs } from "file-saver";

// MUI components
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Alert from "@mui/material/Alert";

// Custom components
import OrgSelector from "../../../components/OrgSelector/OrgSelector.js";
import HorizontalLinearStepper, {
  WizardStepProps,
} from "../../../components/WizardStepper/HorizontalLinearStepper";
import ScenarioMetadataForm from "../../../components/ScenarioMetaData/ScenarioMetaData";
import LinearWithValueLabel from "../../../components/Progress/LinearWithValueLabel";

// Custom hooks and functions
import useAppState from "../../../store/appState.js";
import { useDemoConfig, useOrgScenarios } from "../../../store/serverState.js";
import { fetchScenarioRecord } from "../../../store/graphql-functions.js";
import { queryOpenAI, tightStringify } from "../../../lib/ai_funcs";
import useParamCache from "../../../hooks/paramCache.js";
import Bottleneck from "bottleneck";
import { mkConfig, generateCsv } from "export-to-csv";

const logger = new Logger("AiAssist/BiggyContentGen", "INFO");

const model = "gpt-4o-mini";

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

// Setup throttling handler
const queue = new Bottleneck({
  reservoir: 5, // initial value
  reservoirRefreshAmount: 5,
  reservoirRefreshInterval: 250, // must be divisible by 250
  minTime: 100,
  maxConcurrent: 5,
});
queue.on("idle", function () {
  logger.info("at this point we should be done with all stage 1 posting");
});
queue.on("failed", async (error, jobInfo: any) => {
  // if (error.code === "ECONNABORTED") {
  logger.warn(
    `Throttle error posting prompt ${jobInfo.options.id}, Retrying after 1 second.`,
    error
  );
  return 1000; // wait 1000ms before retrying
  // } else {
  //   logger.error(`Failed to post prompt ${jobInfo.options.id}`, error);
  // }
});
queue.on("done", function () {});

// Types
interface DemosimScenarioMetadata {
  name: string;
  description: string;
  symptoms: string[];
  rootCause: string;
  resolution: string;
  impactedServices: string[];
  tags: string[];
}

export default function BiggyContentGen() {
  // Global state
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);
  const { orgScenerios } = useOrgScenarios(currentDemoConfigId);
  const { paramCacheClear, hydrateScenarioVariables } = useParamCache();

  // Local state
  const [selectedScenarioId, setSelectedScenarioId] = useState("");
  const [demosimScenarioTemplate, setDemosimScenarioTemplate] = useState();
  const [demosimScenarioMetadata, setDemosimScenarioMetadata] =
    useState<DemosimScenarioMetadata>({
      name: "",
      description: "",
      symptoms: [],
      rootCause: "",
      resolution: "",
      impactedServices: [],
      tags: [],
    });
  const [example1, setExample1] = useState<any>();
  const [gettingScenarioContent, setGettingScenarioContent] = useState(false);
  const [scenarioContentPopulated, setScenarioContentPopulated] =
    useState(false);
  const [prompt, setPrompt] = useState("");
  const [llmResponseLoading, setLlmResponseLoading] = useState(false);
  const [normalizedProgress, setNormalizedProgress] = useState(0);
  const [llmResponseError, setLlmResponseError] = useState([]);

  const [tickets, setTickets] = useState("");
  const [wiki, setWiki] = useState("");
  const [SOPs, setSOPs] = useState("");
  const [postMortems, setPostMortems] = useState("");
  const [chatLogs, setChatLogs] = useState("");

  const eventsToCsv = (events) => {
    return generateCsv(csvConfig)(
      events
        .map((event) => {
          return {
            timestamp: event.timestamp,
            ...event.tags,
          };
        })
        .map((obj) => {
          if (obj.tags) {
            let { tags } = obj;
            delete obj.tags;
            return { ...obj, ...tags };
          }
          return obj;
        })
        // convert nested arrays to strings
        .map((obj) => {
          for (let [key, value] of Object.entries(obj)) {
            if (Array.isArray(value)) {
              obj[key] = value.join(", ");
            }
          }
          return obj;
        })
    );
  };

  const changesToCsv = (changes) => {
    return generateCsv(csvConfig)(
      changes
        .map((change) => {
          return {
            identifier: change.identifier,
            start: change.start,
            end: change.end,
            status: change.status,
            summary: change.summary,
            ticket_url: change.ticket_url,
            tags: change.tags,
          };
        })
        .map((obj) => {
          if (obj.tags) {
            let { tags } = obj;
            delete obj.tags;
            return { ...obj, ...tags };
          }
          return obj;
        })
        .map((obj) => {
          for (let [key, value] of Object.entries(obj)) {
            if (Array.isArray(value)) {
              obj[key] = value.join(", ");
            }
          }
          return obj;
        })
    );
  };

  const instantiateScenario = useCallback(
    (scenarioRecord) => {
      paramCacheClear();
      let { newEventData, newChangeData } = hydrateScenarioVariables({
        eventData: scenarioRecord?.events
          .filter((event) => event.event_type === "ALERT")
          .map((event) => {
            return {
              ...event,
              tags: JSON.parse(event.tags),
            };
          }),
        changeData: scenarioRecord?.changes.map((change) => {
          return {
            ...change,
            tags: JSON.parse(change.tags),
          };
        }),
        orgIntegrations: demoConfig.integrations,
      });
      paramCacheClear();
      logger.info("newEventData", newEventData);
      logger.info("newChangeData", newChangeData);
      let instantiatedEvents = eventsToCsv(newEventData);
      let instantiatedChanges = newChangeData.length
        ? changesToCsv(newChangeData)
        : "";
      logger.info("instantiatedEvents", instantiatedEvents);
      logger.info("instantiatedChanges", instantiatedChanges);
      return {
        alerts: instantiatedEvents,
        changes: instantiatedChanges,
      };
    },
    [hydrateScenarioVariables, demoConfig.integrations, paramCacheClear]
  );

  // Load the scenario template and instantiate examples when a scenario is selected
  const handleSelectScenario = useCallback(
    async (scenarioId: string) => {
      setScenarioContentPopulated(false);
      setGettingScenarioContent(true);
      logger.info("selectedScenarioId", scenarioId);
      setSelectedScenarioId(scenarioId);
      fetchScenarioRecord(scenarioId)
        .then((scenarioRecord) => {
          logger.info("scenarioRecord", scenarioRecord);
          setDemosimScenarioTemplate(scenarioRecord);
          // reduce all tags from all events into a single array
          let eventTags = scenarioRecord?.events.reduce(
            (acc, event) => [...acc, ...Object.keys(JSON.parse(event?.tags))],
            []
          );
          // reduce all tags from all changes into a single array
          let changeTags = scenarioRecord?.changes.reduce(
            (acc, change) => [...acc, ...Object.keys(JSON.parse(change?.tags))],
            []
          );
          setDemosimScenarioMetadata({
            name: scenarioRecord?.name,
            description: scenarioRecord?.description,
            symptoms: [],
            rootCause: "",
            resolution: "",
            impactedServices: [],
            //@ts-ignore
            tags: [...new Set([...eventTags, ...changeTags])],
          });

          // instantiate the examples
          let instantiation1 = instantiateScenario(scenarioRecord);
          logger.info("instantiation1", instantiation1);
          setExample1(instantiateScenario(scenarioRecord));
        })

        .then(() => {
          setGettingScenarioContent(false);
          setScenarioContentPopulated(true);
        });
    },
    [
      setDemosimScenarioTemplate,
      setDemosimScenarioMetadata,
      setExample1,
      instantiateScenario,
    ]
  );

  // Generate content based on the scenario template
  const handleGenContent = useCallback(() => {
    setLlmResponseLoading(true);
    setLlmResponseError([]);
    setNormalizedProgress(0);

    setTickets("");
    setWiki("");
    setSOPs("");
    setPostMortems("");
    setChatLogs("");

    let promptBase = getPrompt(
      demosimScenarioTemplate,
      demosimScenarioMetadata,
      demoConfig.params,
      example1
    );
    logger.info("prompt", promptBase);
    setPrompt(
      promptBase[1].content[0].text +
        "\n" +
        prompt1.content[0].text +
        "\n" +
        prompt2.content[0].text +
        "\n" +
        prompt3.content[0].text +
        "\n" +
        prompt4.content[0].text +
        "\n" +
        prompt5.content[0].text
    );

    const queries = [
      queryOpenAI({
        messages: [...promptBase, prompt1],
        model: model,
        temperature: 0,
      })
        .then((response) => {
          setTickets(response.choices[0]?.message?.content); // result of prompt 1
          setNormalizedProgress((progress) => progress + 20);
        })
        .catch((error) => {
          logger.error("Error in getLlmResponse", error);
          setLlmResponseError((prev) => [
            `${error.name}: ${error.message}`,
            ...prev,
          ]);
        }),
      queryOpenAI({
        messages: [...promptBase, prompt2],
        model: model,
        temperature: 0,
      })
        .then((response) => {
          setWiki(response.choices[0]?.message?.content); // result of prompt 2
          setNormalizedProgress((progress) => progress + 20);
        })
        .catch((error) => {
          logger.error("Error in getLlmResponse", error);
          setLlmResponseError((prev) => [
            `${error.name}: ${error.message}`,
            ...prev,
          ]);
        }),
      queryOpenAI({
        messages: [...promptBase, prompt3],
        model: model,
        temperature: 0,
      })
        .then((response) => {
          setSOPs(response.choices[0]?.message?.content); // result of prompt 3
          setNormalizedProgress((progress) => progress + 20);
        })
        .catch((error) => {
          logger.error("Error in getLlmResponse", error);
          setLlmResponseError((prev) => [
            `${error.name}: ${error.message}`,
            ...prev,
          ]);
        }),
      queryOpenAI({
        messages: [...promptBase, prompt4],
        model: model,
        temperature: 0,
      })
        .then((response) => {
          setPostMortems(response.choices[0]?.message?.content); // result of prompt 4
          setNormalizedProgress((progress) => progress + 20);
        })
        .catch((error) => {
          logger.error("Error in getLlmResponse", error);
          setLlmResponseError((prev) => [
            `${error.name}: ${error.message}`,
            ...prev,
          ]);
        }),
      queryOpenAI({
        messages: [...promptBase, prompt5],
        model: model,
        temperature: 0,
      })
        .then((response) => {
          setChatLogs(response.choices[0]?.message?.content); // result of prompt 5
          setNormalizedProgress((progress) => progress + 20);
        })
        .catch((error) => {
          logger.error("Error in getLlmResponse", error);
          setLlmResponseError((prev) => [
            `${error.name}: ${error.message}`,
            ...prev,
          ]);
        }),
    ];
    Promise.all(queries).then(() => {
      setLlmResponseLoading(false);
    });
  }, [
    demosimScenarioTemplate,
    demosimScenarioMetadata,
    demoConfig.params,
    example1,
  ]);

  // reset local state
  const handleReset = () => {
    setSelectedScenarioId("");
    setDemosimScenarioTemplate(undefined);
    setDemosimScenarioMetadata({
      name: "",
      description: "",
      symptoms: [],
      rootCause: "",
      resolution: "",
      impactedServices: [],
      tags: [],
    });
    setExample1("");
    setGettingScenarioContent(false);
    setScenarioContentPopulated(false);
    setPrompt("");
    setNormalizedProgress(0);
    setLlmResponseLoading(false);
    setLlmResponseError([]);
    setTickets("");
    setWiki("");
    setSOPs("");
    setPostMortems("");
    setChatLogs("");
  };

  const handleDownloadAll = useCallback(() => {
    const files = {
      metadata: strToU8(JSON.stringify(demosimScenarioMetadata, null, 2)),
      "tickets.txt": strToU8(tickets),
      "wiki.txt": strToU8(wiki),
      "SOPs.txt": strToU8(SOPs),
      "postMortems.txt": strToU8(postMortems),
      "chatLogs.txt": strToU8(chatLogs),
    };

    // Create the zip file
    const zipped = zipSync(files);

    // Create a blob from the zipped content
    const zippedContent = new Blob([zipped], { type: "application/zip" });
    saveAs(
      zippedContent,
      `${demosimScenarioMetadata.name.replace(/\W/g, "_")}.zip`
    );
  }, [demosimScenarioMetadata, tickets, wiki, SOPs, postMortems, chatLogs]);

  const steps: WizardStepProps[] = useMemo(() => {
    return [
      {
        label: `${
          demoConfig.bporgname
            ? `Using BigPanda Org:  ${demoConfig.bporgname}`
            : "Select/Create a Demo Config"
        }`,
        optional: false,
        description: `Biggy Content Generator enables you to use AI to create scenario-specific context and supporting content for use in Biggy demos.
      - 5 SNOW tickets
      - a 10-page Confluence/wiki describing different critical applications, what they do, are they revenue generating, who supports them, what components are involved, common issues/remediation, etc.
      - 5 SOPs / Runbooks walking thru step by step how to do certain things like "fail over a data center" or "vmotion vms off an unhealthy esx host" or whatever
      - 5 Post-mortems, detailed writeups of critical incidents, how they were identified, what was impacted, what was attempted, MTTx, how it was resolved, and after action steps to prevent recurrence
      - 5 psuedo chat conversations about ITOps stuff (specific incidents, how to do certain things, whatever)

      To perform the following steps you need a "Demo Config" with a BigPanda Org User API Key.

      If you've already created a Demo Config, select it.
      
      If you need to create a Demo Config, type a new record name into the Demo Config Selector.
      This will open a dialog; enter your BigPanda org's User API Key and your BigPanda email address.
      
      If you've entered the correct User API key, you'll see your BigPanda Org name in the box on the right
      and the step label will display the name of your BigPanda Org.`,
        // contextComponent: (

        // ),
        component: <OrgSelector />,
        gate: Boolean(demoConfig.bporgname),
      },
      {
        label: "Select Scenario",
        optional: false,
        description: `Content is generated based on the scenario template and variables from this Demo Config.
        
        I'ts important to select a scenario that is a good template and represents a real world incident.
        Select a DemoSim scenario that has the following attributes:
          Required:
          - Uses variables. (e.g., \${rnd.[variable_name].s1})
          - Includes resolution ("OK") events
          - Includes no more than one change (root cause or at least a contributing factor)
          - You can explain the incident symptoms, cause, and resolution in a few sentences
          Recommended:
          - Was originally a real world incident (i.e. not made up); this helps with internal consistency
          - Includes one root-cause change (e.g., "Security policy change blocked PersistentVolumeClaim access.")
        `,
        component: (
          // select one of the scenarios from the orgScenerios
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: 2,
            }}
          >
            <TextField
              id="scenario-selector"
              select
              label="Scenario"
              value={selectedScenarioId}
              onChange={(e) => handleSelectScenario(e.target.value)}
              helperText="Please select a scenario"
            >
              {orgScenerios
                .sort((a, b) =>
                  a?.scenario?.name.localeCompare(b?.scenario?.name)
                )
                .map((option) => (
                  <MenuItem key={option.scenarioID} value={option.scenarioID}>
                    {option?.scenario?.name}
                  </MenuItem>
                ))}
            </TextField>
            {gettingScenarioContent && (
              <Alert severity="info">Fetching scenario content...</Alert>
            )}
          </Box>
        ),
        gate: !gettingScenarioContent && scenarioContentPopulated,
      },
      {
        label: "Metadata",
        optional: false,
        description: `Enter metadata for this scenario.
        
        This metadata will be used to generate content based on the scenario template.
        AI will use this metadata to generate content that is consistent with the scenario.
        Be succinct and descriptive. 
        Avoid instance-specific details like specific hostnames or IP addresses; use descriptors like "the application" or "the database" instead.
        `,
        component: (
          <ScenarioMetadataForm
            demosimScenarioMetadata={demosimScenarioMetadata}
            setDemosimScenarioMetadata={setDemosimScenarioMetadata}
          />
        ),
        gate:
          Boolean(demosimScenarioMetadata.name) &&
          Boolean(demosimScenarioMetadata.description),
      },
      {
        label: "Generate Content",
        optional: false,
        description: "Generate content based on the scenario template.",
        component: (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              padding: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "max-content", alignSelf: "center" }}
              onClick={handleGenContent}
            >
              Generate Content
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "max-content", marginTop: 2, alignSelf: "center" }}
              onClick={handleDownloadAll}
              disabled={!tickets || !wiki || !SOPs || !postMortems || !chatLogs}
            >
              Download All
            </Button>
            {llmResponseLoading && (
              <Box sx={{ width: "100%" }}>
                <Typography variant="body1">Generating content...</Typography>
                <LinearWithValueLabel progress={normalizedProgress} />
              </Box>
            )}
            {llmResponseError.length > 0 && (
              <Alert severity="error">
                {llmResponseError.map((error, index) => (
                  <Typography key={index}>{error}</Typography>
                ))}
              </Alert>
            )}

            {/* Prompt TextField and Copy button */}
            <Box
              sx={{
                position: "relative",
                width: "100%",
                marginTop: 2,
              }}
            >
              <TextField
                id="prompt"
                label="Prompt"
                multiline
                rows={10}
                value={prompt}
                InputProps={{
                  readOnly: true,
                }}
                sx={{ width: "100%" }}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 40,
                  padding: "4px 8px",
                  minWidth: "unset",
                }}
                onClick={() => navigator.clipboard.writeText(prompt)}
                disabled={prompt.length === 0}
              >
                Copy
              </Button>
            </Box>

            {/* Tickets TextField and Copy button */}
            <Box
              sx={{
                position: "relative",
                width: "100%",
                marginTop: 2,
              }}
            >
              <TextField
                id="tickets"
                label="Tickets"
                multiline
                rows={10}
                value={tickets}
                InputProps={{
                  readOnly: true,
                }}
                sx={{ width: "100%" }}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 40,
                  padding: "4px 8px",
                  minWidth: "unset",
                }}
                onClick={() => navigator.clipboard.writeText(tickets)}
                disabled={tickets.length === 0}
              >
                Copy
              </Button>
            </Box>

            {/* Wiki TextField and Copy button */}
            <Box
              sx={{
                position: "relative",
                width: "100%",
                marginTop: 2,
              }}
            >
              <TextField
                id="wiki"
                label="Wiki"
                multiline
                rows={10}
                value={wiki}
                InputProps={{
                  readOnly: true,
                }}
                sx={{ width: "100%" }}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 40,
                  padding: "4px 8px",
                  minWidth: "unset",
                }}
                onClick={() => navigator.clipboard.writeText(wiki)}
                disabled={wiki.length === 0}
              >
                Copy
              </Button>
            </Box>

            {/* SOPs TextField and Copy button */}
            <Box
              sx={{
                position: "relative",
                width: "100%",
                marginTop: 2,
              }}
            >
              <TextField
                id="SOPs"
                label="SOPs"
                multiline
                rows={10}
                value={SOPs}
                InputProps={{
                  readOnly: true,
                }}
                sx={{ width: "100%" }}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 40,
                  padding: "4px 8px",
                  minWidth: "unset",
                }}
                onClick={() => navigator.clipboard.writeText(SOPs)}
                disabled={SOPs.length === 0}
              >
                Copy
              </Button>
            </Box>

            {/* Post-Mortems TextField and Copy button */}
            <Box
              sx={{
                position: "relative",
                width: "100%",
                marginTop: 2,
              }}
            >
              <TextField
                id="postMortems"
                label="Post-Mortems"
                multiline
                rows={10}
                value={postMortems}
                InputProps={{
                  readOnly: true,
                }}
                sx={{ width: "100%" }}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 40,
                  padding: "4px 8px",
                  minWidth: "unset",
                }}
                onClick={() => navigator.clipboard.writeText(postMortems)}
                disabled={postMortems.length === 0}
              >
                Copy
              </Button>
            </Box>

            {/* Chat Logs TextField and Copy button */}
            <Box
              sx={{
                position: "relative",
                width: "100%",
                marginTop: 2,
              }}
            >
              <TextField
                id="chatLogs"
                label="Chat Logs"
                multiline
                rows={10}
                value={chatLogs}
                InputProps={{
                  readOnly: true,
                }}
                sx={{ width: "100%" }}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 40,
                  padding: "4px 8px",
                  minWidth: "unset",
                }}
                onClick={() => navigator.clipboard.writeText(chatLogs)}
                disabled={chatLogs.length === 0}
              >
                Copy
              </Button>
            </Box>
          </Box>
        ),
        gate: true,
      },
    ];
  }, [
    handleDownloadAll,
    demoConfig.bporgname,
    selectedScenarioId,
    orgScenerios,
    handleSelectScenario,
    demosimScenarioMetadata,
    setDemosimScenarioMetadata,
    gettingScenarioContent,
    scenarioContentPopulated,
    handleGenContent,
    prompt,
    llmResponseLoading,
    normalizedProgress,
    llmResponseError,
    tickets,
    wiki,
    SOPs,
    postMortems,
    chatLogs,
  ]);

  return <HorizontalLinearStepper steps={steps} resetFunction={handleReset} />;
}

function getPrompt(
  demosimScenarioTemplate: any,
  demosimScenarioMetadata: DemosimScenarioMetadata,
  variables: any,
  example1: any
) {
  return [
    {
      role: "system",
      content: [
        {
          type: "text",
          text: "You are an BigPanda.io administrator with expertise in IT monitoring and observability, the creation of alert correlation patterns, and alert enrichment. Your overall goal is to use your understanding of IT systems, data, and observability systems to suggest improvement to BigPanda.io alert correlation and enrichment.",
        },
      ],
    },
    {
      role: "user",
      content: [
        {
          type: "text",
          text: `
#Instructions:

You are tasked with creating a suite of mock content for Biggy, an AI assistant for IT operations, based on an incident scenario. 
To ensure consistency and contextual relevance, a DemoSim Scenario template, variables, metadata, and one example incident are provided. 
These examples illustrate the types of alerts and changes involved in the incident and how variable data is instantiated. 
Your job is to use this information to generate simulation content for use in a BigPanda demonstration.
The content will be used to recognize and respond to similar incidents, document critical systems, and facilitate operational procedures.

#Provided Data:

1. DemoSim Scenario Template:

The following template defines the structure of alerts (Events) and changes associated with an incident scenario. 

It follows this schema:
type Event {
  _offset: Int!            // Timestamp offset for sequencing
  integration_type: String // Alert type (e.g., infrastructure, network, database)
  primary_property: String // Key identifying the alert object (e.g., hostname)
  secondary_property: String // Key identifying the alert definition or type
  tags: String[]           // Tags or properties of the alert (e.g., status, description, hostname)
}

type Change {
  _offset: Int!            // Timestamp offset for sequencing
  identifier: String!      // Unique identifier for the change
  status: String!          // Status of the change (e.g., Planned, In Process, Completed)
  summary: String!         // Description of the change
  ticket_url: String       // Optional link to the change ticket
  tags: String[]           // Tags or properties describing the change (e.g., source, CI, impacted service)
}

Variables in the template follow this schema:
\${rnd.[variable_name].s1}
- a dollar sign followed by curly-braces
- "rnd" to indicate randomization
- a period followed by the variable name
- a period followed by a seed number (s1, s2, s3, etc.)

Missing variable codes use "a" for an alpha character and "d" for a digit. For example, \${rnd.aaadd.a1} generates a random string with three alpha characters followed by two digits.

Scenario Template:
${tightStringify(demosimScenarioTemplate)}

2. Variables
You will use these variables to instantiate the template (to create example incident scenarios) and populate the content:

${tightStringify(variables)}

3. Example Incident:

Here is an instantiated example of alerts and changes following the DemoSim Scenario template. This example illustrates how variable data is populated.

Example 1:

	•	Alerts: ${example1?.alerts}
	•	Changes: ${example1?.changes}

4. Scenario Metadata:

	•	Name: ${demosimScenarioMetadata.name}
	•	Description: ${demosimScenarioMetadata.description}
  •	Symptoms: ${demosimScenarioMetadata.symptoms}
  •	Root Cause: ${demosimScenarioMetadata.rootCause}
  •	Resolution: ${demosimScenarioMetadata.resolution}
	•	Applications/Services Impacted: ${demosimScenarioMetadata.impactedServices}
	•	Significant Tags: ${demosimScenarioMetadata.tags}


# Task Overview

Using the provided data, generate the following content will be used to train Biggy to recognize and respond to similar incidents.

Formatting Guidelines

	1.	Use professional and technical language suitable for ServiceNow tickets.
	2.	Titles should reflect specific symptoms of the incident.
	3.	Descriptions should outline observed symptoms, their impact, and initial observations or alerts.
	4.	Include troubleshooting steps that highlight realistic investigation paths, like log analysis, application or system commands, and infrastructure monitoring.
	5.	All tickets must have a resolution pointing to the same root cause and solution, albeit with some slight variation in wording.
	6.	Ensure internal consistency in timing, system names, and patterns across tickets.
	7.	Use realistic timestamps and identify affected systems and services clearly.
	8.	Include some variance in alert types, symptoms, and steps, but always maintain a logical flow to the shared resolution.
	9.	Return only the content requested (ie don't provide a concluding summary).

# Content to Generate:
`,
        },
      ],
    },
  ];
}

const prompt1 = {
  role: "user",
  content: [
    {
      type: "text",
      text: `Create 5 detailed historical SNOW tickets that simulate similar incidents related to the provided scenario. 
The tickets should represent variations in symptoms and initial troubleshooting steps, while leading to a consistent root cause and resolution.
These tickets will be used to train Biggy to recognize and respond to similar incidents, so they must include realistic variations in symptoms and context while maintaining internal consistency.
`,
    },
  ],
};

const prompt2 = {
  role: "user",
  content: [
    {
      type: "text",
      text: `Create some Confluence/Wiki Documentation (10 pages) based on the preceding data, instructions, and response: Documentation describing critical systems and services, including their architecture, common issues, and troubleshooting practices, written from the point of view of Operations personel. As before, Return only the content requested (ie don't provide a concluding summary).
`,
    },
  ],
};

const prompt3 = {
  role: "user",
  content: [
    {
      type: "text",
      text: `Create 5 Standard Operating Procedures (SOPs): Step-by-step guides for handling similar scenarios. As before, Return only the content requested (ie don't provide a concluding summary).
`,
    },
  ],
};

const prompt4 = {
  role: "user",
  content: [
    {
      type: "text",
      text: `Create 5 detailed Post-Mortems: Detailed incident analyses, including a timeline, resolution steps, and lessons learned. As before, Return only the content requested (ie don't provide a concluding summary).
`,
    },
  ],
};

const prompt5 = {
  role: "user",
  content: [
    {
      type: "text",
      text: `Create 5 detailed Simulated IT Ops Chat Logs: Realistic chat logs between IT team members supporting and aligning with the heretofore mentioned incidents, SNOW tickets, Post-Mortems, troubleshooting, or related operational topics. As before, Return only the content requested (ie don't provide a concluding summary).
`,
    },
  ],
};
