import React, { useState, useEffect, Suspense } from "react";
import { Logger } from "aws-amplify";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import ScenarioImport from "../../components/ScenarioImport/ScenarioImport";
import ScenarioStore from "components/ScenarioStore/ScenarioStore";
import ScenarioEditor from "components/ScenarioEditor/ScenarioEditor";
import ScenarioCreator from "components/ScenarioCreator/ScenarioCreator";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import useAppState from "store/appState";
import { useDemoConfig, useOrgScenarios } from "store/serverState";

const logger = new Logger("OrgScenariosTab", "INFO");

const OrgScenariosTab = () => {
  const { currentDemoConfigId } = useAppState();
  const { bpOrgInfo } = useDemoConfig(currentDemoConfigId);
  const { orgScenerios } = useOrgScenarios(currentDemoConfigId);
  const [openStoreDialog, setOpenStoreDialog] = useState(false);

  useEffect(() => {
    logger.info("Loaded OrgScenarios:", orgScenerios);
  }, [orgScenerios]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2,
        boxSizing: "border-box",
        // backgroundColor: "lightgreen",
        // border: "1px solid green", // Debug boundary
        width: "100%",
        height: "100%", // Ensure full height
        overflowY: "auto", // Enable scrolling if content overflows
        flexGrow: 1, // Allow it to grow to fill the container
      }}
    >
      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          gap: 2,
          width: "100%",
          marginBottom: 2,
        }}
      >
        <Typography variant="h5">
          {`DemoSim Scenarios for ${bpOrgInfo.organization.name}`}
        </Typography>
        <Typography variant="caption">
          You can add, remove, and edit DemoSim scenarios here.
        </Typography>
      </Box>

      {/* Action Buttons */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: 2,
          marginBottom: 2,
        }}
      >
        <Button
          id="btn-scenario-store-open"
          data-test="btn-scenario-store-open"
          sx={{ minWidth: "max-content" }}
          variant="outlined"
          color="info"
          size="small"
          onClick={() => setOpenStoreDialog(true)}
          startIcon={<LocalGroceryStoreIcon />}
        >
          Scenario Store
        </Button>
        <ScenarioStore
          openStoreDialog={openStoreDialog}
          setOpenStoreDialog={setOpenStoreDialog}
        />
        <ScenarioImport />
        <ScenarioCreator />
      </Box>

      <Divider sx={{ width: "100%", marginBottom: 2 }} />

      {/* Scenario Accordion Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "100%",
        }}
      >
        {orgScenerios.length ? (
          orgScenerios
            .sort((a, b) => a?.scenario?.name.localeCompare(b?.scenario?.name))
            .map((orgScenario) => (
              <Accordion
                id="scenario-accordian"
                key={`scenarioID-${orgScenario.scenarioID}`}
                sx={{
                  // border: "1px solid blue",
                  width: "100%", // Full width of the container
                  boxSizing: "border-box",
                }}
                slotProps={{ transition: { unmountOnExit: true } }}
              >
                <AccordionSummary
                  id={`${orgScenario.scenarioID}-ScenarioEdit-header`}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${orgScenario.scenarioID}-ScenarioEdit-content`}
                  data-test={`scenario-accordian-${orgScenario.scenarioID}`}
                  sx={{ boxShadow: 1, borderRadius: 1 }}
                >
                  <Typography
                    variant="h5"
                    sx={{ boxShadow: 1, p: 1, borderRadius: 3 }}
                  >
                    {orgScenario.scenario.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  id="ScenarioEdit Details"
                  sx={{ mt: 2, width: "100%" }}
                >
                  <Suspense fallback="...loading Scenario">
                    <ScenarioEditor scenarioID={orgScenario.scenarioID} />
                  </Suspense>
                </AccordionDetails>
              </Accordion>
            ))
        ) : (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <LoadingSpinner />
            <Alert
              data-test="alert-empty-scenarios"
              severity="info"
              sx={{ marginTop: 2, padding: 2 }}
            >
              You have no DemoSim scenarios linked to this Demo Config
            </Alert>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OrgScenariosTab;
