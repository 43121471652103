// component that uses the current demo config api key to search for and choose incidents
import {
  useState,
  useCallback,
  useMemo,
  useLayoutEffect,
  ChangeEvent,
} from "react";
import { subHours, getUnixTime } from "date-fns";
import { Logger } from "aws-amplify";
import { useQueryClient } from "@tanstack/react-query";
import { pipe, prop, chain, filter, map } from "ramda";

// @mui/icons-material

// MUI components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import { DateTimePicker } from "@mui/x-date-pickers-pro";

// Custom components
import CheckList from "../CheckList/CheckList";
import IncidentSummary from "../DataItemDisplay/IncidentSummary.jsx";
import CheckboxesTags from "../Inputs/CheckboxesTags";

// Custom hooks and functions
import useAppState from "../../store/appState";
import {
  useDemoConfig,
  useBpEnvironments,
  useBpIncidentsSearch,
} from "../../store/serverState";
import { deduplicateRecords } from "../../lib/transform_funcs";

const logger = new Logger("IncidentSearch", "INFO");

// selectedIncidents and setSelectedIncidents are externally provided state
type Props = {
  selectedIncidents: any[];
  setSelectedIncidents: (incidents: any[]) => void;
  expanded?: boolean;
};

const sortOptions = [
  { label: "Last Change", value: "last_change" },
  { label: "Start", value: "start" },
  { label: "Alerts", value: "alerts" },
  { label: "Status", value: "status" },
];

const folderOptions = [
  { label: "All", value: "All" },
  { label: "Active", value: "active" },
  { label: "Shared", value: "shared" },
  { label: "Snoozed", value: "snoozed" },
  { label: "Resolved", value: "resolved" },
  { label: "Unhandled", value: "unhandled" },
  { label: "Maintenance", value: "maintenance" },
];

export default function IncidentSearch({
  selectedIncidents,
  setSelectedIncidents,
  expanded,
}: Props) {
  // Global state
  const { currentDemoConfigId } = useAppState();
  const { demoConfig, bpOrgIntegrations } = useDemoConfig(currentDemoConfigId);
  const { data: bpEnvironments } = useBpEnvironments({
    region: demoConfig.region,
    api_key: demoConfig.api_key,
  });
  const queryClient = useQueryClient();
  const integrationOptions = useMemo(
    () =>
      bpOrgIntegrations
        .filter((i) => i.parent_source_system !== "changes")
        .filter((i) => i.source_system)
        .map((i) => {
          return {
            name: i.name,
            source_system: i.source_system,
          };
        }),
    [bpOrgIntegrations]
  );
  // Local state
  const [query, setQuery] = useState<String | null>();
  const [from, setFrom] = useState(subHours(new Date(), 1));
  const [to, setTo] = useState(new Date());
  const [sort_by, setSort_by] = useState<String>("last_change");
  const [folder, setFolder] = useState<String | null>("All");
  const [environmentName, setEnvironmentName] = useState<String>("All");
  const [integrationDest, setIntegrationDest] = useState<any[]>([
    ...integrationOptions,
  ]);
  const [integrationDestInput, setIntegrationDestInput] =
    useState<String | null>("");

  const envId = useMemo(() => {
    return bpEnvironments.find((env: any) => env.name === environmentName)?.id;
  }, [bpEnvironments, environmentName]);

  const {
    incidentsData,
    incidentsError,
    fetchNextIncidentsPage,
    hasNextIncidentsPage,
    isFetchingIncidents,
    isFetchingNextIncidentsPage,
  } = useBpIncidentsSearch({
    access_token: demoConfig.api_key,
    region: demoConfig.region,
    envId,
    filter: {
      query,
      from: getUnixTime(from),
      to: getUnixTime(to),
      sort_by,
      folder,
      page_size: expanded ? 10 : 100,
      expand: expanded ? "alerts" : undefined,
    },
  });

  const resetIncidentsQuery = useCallback(() => {
    queryClient.resetQueries({
      queryKey: [
        "bpIncidentsQuery",
        demoConfig.api_key,
        demoConfig.region,
        envId,
        {
          query,
          from: getUnixTime(from),
          to: getUnixTime(to),
          sort_by,
          folder,
          page_size: expanded ? 10 : 100,
          expand: expanded ? "alerts" : undefined,
        },
      ],
    });
  }, [
    queryClient,
    demoConfig.api_key,
    demoConfig.region,
    envId,
    query,
    from,
    to,
    sort_by,
    folder,
    expanded,
  ]);

  useLayoutEffect(() => {
    if (expanded) {
      if (
        hasNextIncidentsPage &&
        !isFetchingIncidents &&
        incidentsData.pages.flatMap((page) => page.items).length <
          incidentsData.pages[0]?.total &&
        incidentsData.pageParams.length % 10 !== 0
      ) {
        fetchNextIncidentsPage();
      }
    }
  }, [
    hasNextIncidentsPage,
    isFetchingIncidents,
    isFetchingNextIncidentsPage,
    incidentsData,
    fetchNextIncidentsPage,
    expanded,
  ]);

  return (
    <Grid container>
      <Grid item xs={5}>
        <Stack direction="column" spacing={2} sx={{ m: 2, p: 1 }}>
          <TextField
            variant="outlined"
            label="Query"
            value={query}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setQuery(`${event.target.value}`);
            }}
            helperText="Define a query to filter the incidents"
            inputProps={{
              maxLength: 2000,
            }}
          />
          <Stack direction="row" spacing={2}>
            <DateTimePicker
              label="From"
              disableFuture
              maxDateTime={to}
              value={from}
              onChange={(newDate) => {
                setFrom(newDate);
              }}
            />
            <Typography variant="h6">-</Typography>
            <DateTimePicker
              label="To"
              minDateTime={from}
              value={to}
              onChange={(newDate) => {
                setTo(newDate);
              }}
            />
          </Stack>
          <TextField
            variant="outlined"
            select
            label="Sort By"
            value={sort_by}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setSort_by(`${event.target.value}`);
            }}
            helperText=" "
          >
            {sortOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            variant="outlined"
            select
            label="Folder"
            value={folder}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              logger.info("event.target.value", event.target.value);
              setFolder(`${event.target.value}`);
            }}
            helperText=" "
          >
            {folderOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            variant="outlined"
            select
            label="Environment"
            value={environmentName}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              logger.info("event.target.value", event.target.value);
              setEnvironmentName(`${event.target.value}`);
            }}
            helperText=" "
          >
            {bpEnvironments.map((option: any) => (
              <MenuItem key={option.name} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          {expanded && (
            <CheckboxesTags
              label="Filter by Integration Source(s)"
              options={integrationOptions}
              optionNameField="name"
              optionValueField="source_system"
              integrationDest={integrationDest}
              setIntegrationDest={setIntegrationDest}
              integrationDestInput={integrationDestInput}
              setIntegrationDestInput={setIntegrationDestInput}
            />
          )}
        </Stack>
      </Grid>
      <Grid item xs={7}>
        <Stack direction="column" sx={{ mt: 2, mb: 2, p: 1 }}>
          <Stack
            direction="row"
            spacing={2}
            width={"100%"}
            justifyContent="space-between"
          >
            <Typography variant="h6">{`Retrieved ${incidentsData.pages.reduce(
              (acc, page) => {
                return acc + page.items.length;
              },
              0
            )} of ${incidentsData.pages[0]?.total || 0} incidents (
            ${incidentsData.pages.reduce((acc, page) => {
              return (
                acc +
                page.items
                  .filter((incident) =>
                    expanded
                      ? integrationDest.some((i) =>
                          incident.alerts.some(
                            (alert) => alert.source_system === i.source_system
                          )
                        )
                      : true
                  )
                  .reduce((acc, item) => {
                    return acc + item.alerts.length;
                  }, 0)
              );
            }, 0)} alerts)`}</Typography>
            <Stack
              id="incident-search-buttons"
              direction="row"
              justifyContent="end"
            >
              <Button
                id="load-more-incidents"
                variant="contained"
                size="small"
                sx={{ width: "max-content", alignSelf: "flex-end" }}
                color="primary"
                onClick={() => {
                  fetchNextIncidentsPage();
                }}
                disabled={!hasNextIncidentsPage || isFetchingNextIncidentsPage}
              >
                Load More
              </Button>
              <Button
                id="restart-search"
                variant="contained"
                size="small"
                sx={{ width: "max-content", alignSelf: "flex-end" }}
                color="primary"
                onClick={() => {
                  resetIncidentsQuery();
                }}
              >
                Restart Search
              </Button>
            </Stack>
          </Stack>
          <CheckList
            itemList={pipe(
              prop("pages"), // Get the pages property from incidentsData
              chain(prop("items")), // Flatten pages to a single list of items
              filter(
                (
                  incident // Filter incidents
                ) =>
                  expanded
                    ? integrationDest.some((i) =>
                        incident.alerts.some(
                          (alert: any) =>
                            alert.source_system === i.source_system
                        )
                      )
                    : true
              ),
              deduplicateRecords, // Deduplicate the entire array
              map((incident) =>
                addIncidentUrl(envId, demoConfig.region, incident)
              ) // Map over the deduplicated array
            )(incidentsData)}
            selectedItems={selectedIncidents}
            setSelectedItems={setSelectedIncidents}
            isError={Boolean(incidentsError)}
            error={incidentsError}
            isLoading={isFetchingIncidents}
            matchProp={"id"}
            DisplayComponent={IncidentSummary}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}



function addIncidentUrl(envId: string, region: string, incident: any) {
  const baseURL =
    region === "EU" ? "https://eu-a.bigpanda.io" : "https://a.bigpanda.io";
  const incidentUrl = `${baseURL}/v2/feed/${envId}/${incident.id}?folder=active&tab=overview`;
  return { ...incident, incidentUrl };
}
