import React, { useState, useEffect } from "react";
// import { Logger } from "aws-amplify";


// MUI components
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
// Custom components
import OrgSelector from "components/OrgSelector/OrgSelector";
import useAppState from 'store/appState';
import useScenarios from "hooks/scenarioFunctions";
import { useDemoConfig } from "store/serverState";

// const logger = new Logger("SeBootcamp", "INFO");


const SeBootcamp = () => {
  const { currentDemoConfigId } = useAppState();
  const { demoConfig, bpOrgInfo } = useDemoConfig(currentDemoConfigId);
  const { queueFromS3 } = useScenarios();

  // Test Newrelic Integration
  const [newrelicOimAppKey, setNewrelicOimAppKey] = useState(() => {
    return window.localStorage.getItem(`sebootcamp_${demoConfig.name}_newrelicOimAppKey`) ?? ''
  });
  const handleSetNewrelicOimAppKey = e => {
    setNewrelicOimAppKey(() => {
      window.localStorage.setItem(`sebootcamp_${demoConfig.name}_newrelicOimAppKey`, e.target.value)
      return e.target.value
    })
  }
  const [testNewrelicIntegration, setTestNewrelicIntegration] = useState(false);
  const [triggerFullNewrelicIntegration, setTriggerFullNewrelicIntegration] = useState(false);
  useEffect(() => {
    if (testNewrelicIntegration || triggerFullNewrelicIntegration) {
      const fast = triggerFullNewrelicIntegration ? false : true
      setTriggerFullNewrelicIntegration(false)
      setTestNewrelicIntegration(false)
      const endpoint = demoConfig.region === "EU" ?
        new URL(`https://eu.integrations.bigpanda.io/oim/api/alerts?access_token=${bpOrgInfo?.organization?.api_token}%26app_key=${newrelicOimAppKey}`) :
        new URL(`https://integrations.bigpanda.io/oim/api/alerts?access_token=${bpOrgInfo?.organization?.api_token}%26app_key=${newrelicOimAppKey}`)
      queueFromS3({
        endpoint,
        folder: 'se-bootcamp',
        file: 'lab_alerts_newrelic.csv',
        fast
      })
        .then(results => setTestLog(loghistory => ["\nTesting Newrelic integration\n" + results, ...loghistory,]))
    }
  }, [testNewrelicIntegration, triggerFullNewrelicIntegration, newrelicOimAppKey, demoConfig.api_key, demoConfig.region, queueFromS3, bpOrgInfo?.organization?.api_token]);

  // Test Spectrum Integration
  const [spectrumEmailAddress, setSpectrumEmailAddress] = useState(() => {
    return window.localStorage.getItem(`sebootcamp_${demoConfig.name}_spectrumEmailAddress`) ?? ''
  });
  const handleSetSpectrumEmailAddress = e => {
    setSpectrumEmailAddress(() => {
      window.localStorage.setItem(`sebootcamp_${demoConfig.name}_spectrumEmailAddress`, e.target.value)
      return e.target.value
    })
  };
  const [testSpectrumIntegration, setTestSpectrumIntegration] = useState(false);
  const [triggerSpectrumIntegration, setTriggerSpectrumIntegration] = useState(false);
  useEffect(() => {
    if (testSpectrumIntegration || triggerSpectrumIntegration) {
      const fast = triggerSpectrumIntegration ? false : true
      setTestSpectrumIntegration(false)
      setTriggerSpectrumIntegration(false)
      queueFromS3({
        endpoint: `${spectrumEmailAddress.replace('+', '%2b')}`,
        folder: 'se-bootcamp',
        file: 'lab_alerts_spectrum.csv',
        fast
      })
        .then(results => setTestLog(loghistory => ["\nTesting Spectrum integration\n" + results, ...loghistory,]))
    }
  }, [testSpectrumIntegration, triggerSpectrumIntegration, spectrumEmailAddress, queueFromS3, demoConfig.spoc_email]);

  // Test SCOM Integration
  const [scomOimAppKey, setScomOimAppKey] = useState(() => {
    return window.localStorage.getItem(`sebootcamp_${demoConfig.name}_scomOimAppKey`) ?? ''
  });
  const handleSetScomOimAppKey = e => {
    setScomOimAppKey(() => {
      window.localStorage.setItem(`sebootcamp_${demoConfig.name}_scomOimAppKey`, e.target.value)
      return e.target.value
    })
  };
  const [testScomIntegration, setTestScomIntegration] = useState(false);
  const [triggerScomIntegration, setTriggerScomIntegration] = useState(false);
  useEffect(() => {
    if (testScomIntegration || triggerScomIntegration) {
      const fast = triggerScomIntegration ? false : true
      setTestScomIntegration(false)
      setTriggerScomIntegration(false)
      const endpoint = demoConfig.region === "EU" ?
        new URL(`https://eu.integrations.bigpanda.io/oim/api/alerts?access_token=${bpOrgInfo?.organization?.api_token}%26app_key=${scomOimAppKey}`) :
        new URL(`https://integrations.bigpanda.io/oim/api/alerts?access_token=${bpOrgInfo?.organization?.api_token}%26app_key=${scomOimAppKey}`)
      queueFromS3({
        endpoint,
        folder: 'se-bootcamp',
        file: 'lab_alerts_scom.csv',
        fast
      })
        .then(results => setTestLog(loghistory => ["\nTesting SCOM integration\n" + results, ...loghistory,]))
    }
  }, [testScomIntegration, triggerScomIntegration, scomOimAppKey, queueFromS3, bpOrgInfo?.organization?.api_token, demoConfig.region]);

  // Test Splunk Integration
  const [splunkEmailAddress, setSplunkEmailAddress] = useState(() => {
    return window.localStorage.getItem(`sebootcamp_${demoConfig.name}_splunkEmailAddress`) ?? ''
  });
  const handleSetSplunkEmailAddress = e => {
    setSplunkEmailAddress(() => {
      window.localStorage.setItem(`sebootcamp_${demoConfig.name}_splunkEmailAddress`, e.target.value)
      return e.target.value
    })
  };
  const [testSplunkIntegration, setTestSplunkIntegration] = useState(false);
  const [triggerSplunkIntegration, setTriggerSplunkIntegration] = useState(false);
  useEffect(() => {
    if (testSplunkIntegration || triggerSplunkIntegration) {
      const fast = triggerSplunkIntegration ? false : true
      setTestSplunkIntegration(false)
      setTriggerSplunkIntegration(false)
      queueFromS3({
        endpoint: `${splunkEmailAddress.replace('+', '%2b')}`,
        folder: 'se-bootcamp',
        file: 'lab_alerts_splunk.csv',
        fast
      })
        .then(results => setTestLog(loghistory => ["\nTesting Splunk integration\n" + results, ...loghistory,]))
    }
  }, [testSplunkIntegration, triggerSplunkIntegration, splunkEmailAddress, queueFromS3, demoConfig.spoc_email]);


  const [testLog, setTestLog] = useState([]);

  // populate variables when org is set/changed
  useEffect(() => {
    if (demoConfig.name) {
      setNewrelicOimAppKey(() => window.localStorage.getItem(`sebootcamp_${demoConfig.name}_newrelicOimAppKey`) ?? '')
      setSpectrumEmailAddress(() => window.localStorage.getItem(`sebootcamp_${demoConfig.name}_spectrumEmailAddress`) ?? '')
      setScomOimAppKey(() => window.localStorage.getItem(`sebootcamp_${demoConfig.name}_scomOimAppKey`) ?? '')
      setSplunkEmailAddress(() => window.localStorage.getItem(`sebootcamp_${demoConfig.name}_splunkEmailAddress`) ?? '')
    }
  }, [demoConfig.name]);

  const triggerFullIntegrationsTest = (event) => {
    event.preventDefault();
    setTriggerFullNewrelicIntegration(true)
    setTriggerSpectrumIntegration(true)
    setTriggerScomIntegration(true)
    setTriggerSplunkIntegration(true)
  }

  // Test 

  const steps = [
    {
      label: `${demoConfig.bporgname ? `Using BigPanda Org:  ${demoConfig.bporgname}` : 'Configure a BigPanda Org'}`,
      description:
        `To perform the following steps you need a Demo Config record with a BigPanda Org User API Key.

      If you've already created a Demo Config record, select it.
      
      If you need to create a Demo Config, type a new record name into the Demo Config Selector.
      This will open a dialog; enter your BigPanda org's User API Key and your BigPanda email address.
      
      If you've entered the correct User API key, you'll see your BigPanda Org name in the box on the right
      and the step label will display the name of your BigPanda Org.`,
      component: <OrgSelector />
    },
    {
      label: 'Create and test an OIM integration for NewRelic alerts',
      description:
        `In your BigPanda Org, create an OIM integration then put its APP key here.

        Use the TEST button to send some test events and verify that the alerts 
        show up in your BigPanda Org's Incidents pane.`,
      component: <Stack direction="row" spacing={2} sx={{ m: 2, p: 1 }}>
        <TextField
          sx={{ width: "360px" }}
          required
          label="NewRelic OIM Integration APP Key"
          margin='dense'
          value={newrelicOimAppKey}
          onChange={handleSetNewrelicOimAppKey}
          // type="password"
        />
        <Button variant="outlined" onClick={() => setTestNewrelicIntegration(true)}>TEST</Button>
      </Stack>
    },
    {
      label: 'Create and test a Self Service Email Parser integration for Spectrum alerts',
      description:
        `In your BigPanda Org, create a Self Service Email Parser integration 
      then put its EMail address here.

      NOTE: Make sure to set Authorized Sources to "no-reply@bpintegrations.com"

      Use the TEST button to send some test events and verify that the alerts 
      show up in your BigPanda Org's Incidents pane.`,
      component: <Stack direction="row" spacing={2} sx={{ m: 2, p: 1 }}>
        <TextField
          sx={{ width: "360px" }}
          required
          type="email"
          label="Spectrum Self Service Email Address"
          margin='dense'
          value={spectrumEmailAddress}
          onChange={handleSetSpectrumEmailAddress}
        />
        <Button variant="outlined" onClick={() => setTestSpectrumIntegration(true)}>TEST</Button>
      </Stack>
    },
    {
      label: 'Create and test an OIM integration for SCOM alerts',
      description:
        `In your BigPanda Org, create an OIM integration then put its APP key here.

      Use the TEST button to send some test events and verify that the alerts 
      show up in your BigPanda Org's Incidents pane.`,
      component: <Stack direction="row" spacing={2} sx={{ m: 2, p: 1 }}>
        <TextField
          sx={{ width: "600px" }}
          required
          label="SCOM OIM Integration APP Key"
          margin='dense'
          value={scomOimAppKey}
          onChange={handleSetScomOimAppKey}
          // type="password"
        />
        <Button variant="outlined" onClick={() => setTestScomIntegration(true)}>TEST</Button>
      </Stack>
    },
    {
      label: 'Create and test a Self Service Email Parser integration for Splunk alerts',
      description:
        `In your BigPanda Org, create a Self Service Email Parser integration 
        then put its EMail address here.

      Use the TEST button to send some test events and verify that the alerts 
      show up in your BigPanda Org's Incidents pane.`,
      component:
        <Stack direction="row" spacing={2} sx={{ m: 2, p: 1 }}>
          <TextField
            sx={{ width: "360px" }}
            required
            type="email"
            label="Splunk Self Service Email Address"
            margin='dense'
            value={splunkEmailAddress}
            onChange={handleSetSplunkEmailAddress}
          />
          <Button variant="outlined" onClick={() => setTestSplunkIntegration(true)}>TEST</Button>
        </Stack>
    },
    {
      label: 'Test and Tune',
      description:
        `Now that you've initially configured the four integrations, use the POST ALL button
      to post all four alert payload to your BigPanda Org.
      
      NOTE: The alerts will post "real-time" and you cannot stop them. 
      Make sure you're ready before pulling the trigger!`,
      component: <Stack direction="row" spacing={2} sx={{ m: 2, p: 1 }}>
        <Button variant="outlined" onClick={triggerFullIntegrationsTest}>POST ALL</Button>
      </Stack>
    },
  ];


  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setNewrelicOimAppKey(() => {
      window.localStorage.setItem(`sebootcamp_${demoConfig.name}_newrelicOimAppKey`, '')
      return ''
    })
    setSpectrumEmailAddress(() => {
      window.localStorage.setItem(`sebootcamp_${demoConfig.name}_spectrumEmailAddress`, '')
      return ''
    })
    setScomOimAppKey(() => {
      window.localStorage.setItem(`sebootcamp_${demoConfig.name}_scomOimAppKey`, '')
      return ''
    })
    setSplunkEmailAddress(() => {
      window.localStorage.setItem(`sebootcamp_${demoConfig.name}_splunkEmailAddress`, '')
      return ''
    })
    setActiveStep(0);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <Paper className="RouteContainer">
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <Typography sx={{ mb: 1 }} variant="h3">
            SE Bootcamp Integrations Lab
          </Typography>
          <Stepper nonLinear activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepButton
                  color="inherit"
                  onClick={handleStep(index)}
                  optional={
                    index === steps.length - 1 ? (
                      <Typography variant="caption">Last step</Typography>
                    ) : null
                  }
                >
                  {step.label}
                </StepButton>
                <StepContent>
                  <Typography sx={{ whiteSpace: "pre-line" }}>
                    {step.description}
                  </Typography>
                  {step.component}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {index === steps.length - 1 ? "Finish" : "Continue"}
                      </Button>
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography>
                All steps completed - you&apos;re finished
              </Typography>
              <Typography>
                NOTE: Resetting will clear settings from steps 2-5.
              </Typography>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                Reset
              </Button>
            </Paper>
          )}
        </Grid>
        <Grid item xs={6}>
          <Paper
            sx={{
              borderRadius: 2,
              padding: { xs: 1, sm: 2 },
              height: "100%",
            }}
            elevation={1}
          >
            <Typography variant="h4">Log</Typography>
            <Divider sx={{ m: 1 }} />
            {testLog.map((entry, idx) => (
              <Typography
                key={idx}
                variant="body2"
                sx={{ whiteSpace: "pre-wrap" }}
              >{`${entry}`}</Typography>
            ))}
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default SeBootcamp;
