import React, { useState, useCallback, useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { menuCategories } from "../../templates/initialStateTemplates";

import useServerStateMutations, {
  useDemoConfig,
  useScenario,
} from "../../store/serverState";
import AuthContext from "../../store/AuthContext";
import useAppState from "../../store/appState";

type Props = {
  scenarioID: string;
};

export default function CloneScenarioDialog({ scenarioID }: Props) {
  // Global state
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);
  const { user } = useContext(AuthContext);

  // Local state
  const [openCloneScenarioDialog, setOpenCloneScenarioDialog] = useState(false);

  const {
    data: scenarioData,
    // isLoading: scenerioLoading,
    // error: scenarioError,
    // isFetching: scenarioFetching,
  } = useScenario(scenarioID);

  const [newScenarioData, setNewScenarioData] = useState(() => {
    let newData = {
      ...scenarioData,
      owners: [user.username],
      name: "",
    };
    delete newData.id;
    return newData;
  });

  // Functions
  const { addOrgScenarios, createScenario } = useServerStateMutations();

  const handleCloneScenario = useCallback(() => {
    createScenario.mutateAsync(newScenarioData).then((newScenario) =>
      //@ts-ignore
      addOrgScenarios.mutateAsync({
        orgID: demoConfig.id,
        scenarioIDs: [newScenario.id],
        owners: demoConfig.owners,
      })
    );
    setNewScenarioData(() => {
      let newData = {
        ...scenarioData,
        owners: [user.username],
        name: "",
      };
      delete newData.id;
      return newData;
    });
    setOpenCloneScenarioDialog(false);
  }, [
    newScenarioData,
    scenarioData,
    addOrgScenarios,
    createScenario,
    demoConfig.id,
    demoConfig.owners,
    user.username,
  ]);

  return (
    <>
      <Button
        color="success"
        variant="contained"
        size="medium"
        onClick={() => setOpenCloneScenarioDialog(true)}
      >
        CLONE SCENARIO
      </Button>
      <Dialog
        id="clone-scenario-dialog"
        open={openCloneScenarioDialog}
        onClose={() => setOpenCloneScenarioDialog(false)}
        disableEnforceFocus
      >
        <DialogTitle>Clone Scenario</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <DialogContentText>
              This will clone the scenario from the store (unsaved changes will
              be lost).
            </DialogContentText>
            <Divider variant="middle" />
            <Typography>
              Provide a title, category, and description to help you (and
              others) locate and use your scenario.
            </Typography>
            <TextField
              id="scenario-name"
              label="Scenario Name"
              helperText="The name that will show on DemoSim Chrome Extension"
              variant="standard"
              value={newScenarioData.name}
              onChange={(e) =>
                setNewScenarioData({ ...newScenarioData, name: e.target.value })
              }
              required
            />
            <TextField
              id="select-scenario-category"
              select
              label="Category"
              value={newScenarioData.category}
              onChange={(e) => {
                setNewScenarioData({
                  ...newScenarioData,
                  category: e.target.value,
                });
              }}
              helperText="Select the category for display in the DemoSim player"
              required
            >
              {menuCategories.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="scenario-description"
              label="Scenario Description"
              helperText="Summarize the scenario script"
              variant="standard"
              value={newScenarioData.description}
              onChange={(e) =>
                setNewScenarioData({
                  ...newScenarioData,
                  description: e.target.value,
                })
              }
              required
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenCloneScenarioDialog(false);
              setNewScenarioData(scenarioData);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleCloneScenario}
            disabled={
              !newScenarioData.name ||
              !newScenarioData.category ||
              !newScenarioData.description
            }
          >
            Clone
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
