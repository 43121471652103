import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import IntegrationEditor from "components/IntegrationEditor/IntegrationEditor";
import AddIntegrationTypeDialog from "components/AddIntegrationTypeDialog/AddIntegrationTypeDialog";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { useTheme } from "@mui/material/styles";
import useAppState from "store/appState";
import { useDemoConfig } from "store/serverState";

const OrgIntegrationsTab = () => {
  const theme = useTheme();
  const { currentDemoConfigId } = useAppState();
  const { demoConfig, bpOrgInfo } = useDemoConfig(currentDemoConfigId);

  return demoConfig?.integrations ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      <LoadingSpinner />

      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column", // Stack vertically by default
          alignItems: "center", // Center Typography components at all times
          padding: theme.spacing(2),
          boxSizing: "border-box",
          gap: theme.spacing(2), // Spacing between Typography and button
          position: "relative", // Allows positioning of the button on large screens
        }}
      >
        {/* Typography Section */}
        <Box
          sx={{
            textAlign: "center", // Center text alignment
          }}
        >
          <Typography variant="h5">Integration Type Mapping</Typography>
          <Typography variant="caption" sx={{ marginTop: theme.spacing(1) }}>
            {`Assign each integration type to one of ${bpOrgInfo.organization.name}'s integrations`}
          </Typography>
        </Box>

        {/* AddIntegrationTypeDialog Button */}
        <Box
          sx={{
            position: { xs: "static", lg: "absolute" }, // Static on small screens, absolute for large
            top: 0, // Position it aligned with the top of the section on large screens
            right: theme.spacing(2), // Align it to the right edge of the container
            marginTop: { xs: theme.spacing(2), lg: 0 }, // Add spacing below Typography for small screens
          }}
        >
          <AddIntegrationTypeDialog />
        </Box>
      </Box>

      {/* IntegrationEditor Section */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexWrap: "wrap",
          gap: theme.spacing(2),
          justifyContent: "center", // Adjust alignment
          alignItems: "flex-start", // Align top-to-bottom
          padding: theme.spacing(2),
          // backgroundColor: "lightgreen",
          // border: "1px solid green", // For debugging bounds
          overflow: "auto", // Allow scrolling if content exceeds bounds
          boxSizing: "border-box",
        }}
      >
        {demoConfig.integrations.map((integration) => (
          <Box
            key={integration.integration_type}
            sx={{
              flex: "1 1 calc(25% - 16px)", // Adjust width: 25% of container minus spacing
              minWidth: "200px", // Minimum width for smaller screens
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IntegrationEditor
              integration={integration}
              integration_type={integration.integration_type}
            />
          </Box>
        ))}
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
      }}
    >
      <LoadingSpinner />
      <Box
        sx={{
          textAlign: "center",
          padding: theme.spacing(2),
        }}
      >
        <Typography variant="h5">Integration Type Mapping</Typography>
        <Typography
          variant="caption"
          sx={{ display: "block", marginTop: theme.spacing(1) }}
        >
          Assign each source type to one of your Org's integrations
        </Typography>
        <Alert
          data-test="alert-no-integrations"
          sx={{ marginTop: theme.spacing(2) }}
          elevation={6}
          variant="filled"
          severity="info"
        >
          Looking for inbound and change integrations in {demoConfig.bporgname}
        </Alert>
      </Box>
    </Box>
  );
};

export default OrgIntegrationsTab;
