import Button from "@mui/material/Button";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Tooltip from "@mui/material/Tooltip";
import { type MRT_RowSelectionState } from "material-react-table";

interface MrtToolbarButtonDeleteProps {
  recordDescription: string;
  dataRowsSelection: MRT_RowSelectionState;
  onDelete: () => void;
}

export default function MrtToolbarButtonDelete({
  recordDescription,
  dataRowsSelection,
  onDelete,
}: MrtToolbarButtonDeleteProps) {
  return (
    <Tooltip title={`Delete selected ${recordDescription}(s)`}>
      <span>
        <Button
          variant="contained"
          size="small"
          startIcon={<RemoveCircleOutlineIcon />}
          onClick={onDelete}
          disabled={Object.keys(dataRowsSelection).length === 0}
        >
          {`Delete ${recordDescription}(s)`}
        </Button>
      </span>
    </Tooltip>
  );
}
