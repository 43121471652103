import React, { useCallback } from "react";
import { MenuItem } from "@mui/material";
import { type MRT_Column } from "material-react-table";
import { Logger } from "aws-amplify";
import { removeUndefined } from "../../lib/transform_funcs";

const logger = new Logger("TableColumnMenuDelete", "INFO");

interface TableColumnMenuDeleteProps<T> {
  column: MRT_Column<T>;
  columnDescription?: string;
  setData: React.Dispatch<React.SetStateAction<T[]>>;
  updateColumns: (data: T[]) => void;
  closeMenu: () => void;
  setIsDataChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  setValidationErrors?: (
    callback: (prev: Record<string, any>) => Record<string, any>
  ) => void;
  validateFn?: (data: T) => Record<string, any>; // Validation function for each row
}

const TableColumnMenuDelete = <T extends { id: string }>({
  column,
  columnDescription = "column",
  setData,
  updateColumns,
  closeMenu,
  setIsDataChanged = () => {},
  setValidationErrors = () => {},
  validateFn = () => ({}),
}: TableColumnMenuDeleteProps<T>) => {
  // Utility function to access deep properties using a string path
  const deleteColumnByPath = (obj: any, path: string) => {
    const keys = path.split(".");
    let current = obj;
    for (let i = 0; i < keys.length - 1; i++) {
      if (current[keys[i]]) {
        current = current[keys[i]];
      }
    }
    delete current[keys[keys.length - 1]];
  };

  const handleDeleteColumn = useCallback(() => {
    // Update the data by removing the specified tag/column from each row
    setData((prevRows) => {
      // Create new rows with the column removed
      const newRows = prevRows.map((row) => {
        const updatedRow = { ...row };
        deleteColumnByPath(updatedRow, column.id);
        return updatedRow;
      });
      logger.info(`Deleted column ${column.id} from rows`, newRows);
      updateColumns(newRows);
      // lazily set validation within the data update function
      setValidationErrors(() =>
        removeUndefined(
          newRows.reduce((acc, row) => ({ ...acc, ...validateFn(row) }), {})
        )
      );
      return newRows;
    });

    setIsDataChanged(true);
    closeMenu(); // Close the menu after deletion
  }, [
    column.id,
    setData,
    updateColumns,
    setIsDataChanged,
    setValidationErrors,
    validateFn,
    closeMenu,
  ]);

  return (
    <MenuItem key="delete-column" onClick={handleDeleteColumn}>
      Delete {columnDescription}
    </MenuItem>
  );
};

export default TableColumnMenuDelete;
