import React from "react";

const AuthContext = React.createContext({
  user: undefined,
  authState: "",
  setUser: () => {},
  setAuthState: () => {},
});

export const AuthContextProvider = (props) => {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        authState,
        setAuthState,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
