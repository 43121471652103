/**
 * @module transform_funcs
 * @author Sean Onion 
 * @description Composable data transform functions
 */

import { v4 as uuidv4 } from "uuid";
var R = require('ramda');

// types
type FlatObject = { [key: string]: any };
// type NestedObject = { [key: string]: any };

type EnforcedTypes<T> = {
    [K in keyof T]: T[K] extends number | null
    ? number | null
    : T[K] extends string | null
    ? string | null
    : T[K] extends boolean | null
    ? boolean | null
    : T[K] extends object | null
    ? EnforcedTypes<T[K]>
    : T[K];
};

// Utility type for deep paths within an object, restricting to string and number keys
// type PathOf<T> = T extends object
//     ? {
//         [K in keyof T]: K extends string | number
//         ? K | `${K}.${PathOf<T[K]>}`
//         : never;
//     }[keyof T]
//     : never;


export const enforceSchema = function enforceSchema<T>(typeDefinition: T, input: T | T[],): EnforcedTypes<T>[] | EnforcedTypes<T> {
    const coerceValue = (value: any, expectedType: any): any => {
        if (value === null || value === undefined) return null;

        const expectedTypeOf = typeof expectedType;
        const actualTypeOf = typeof value;

        if (expectedTypeOf === 'number' && actualTypeOf !== 'number') return Number(value);
        if (expectedTypeOf === 'string' && actualTypeOf !== 'string') return String(value);
        if (expectedTypeOf === 'boolean' && actualTypeOf !== 'boolean') return Boolean(value);

        if (expectedTypeOf === 'object') {
            if (Array.isArray(expectedType) && Array.isArray(value)) {
                return value.map((v) => coerceValue(v, expectedType[0]));
            } else if (!Array.isArray(expectedType) && actualTypeOf === 'object') {
                // Recursively enforce types for nested objects
                return enforceSchema(value, expectedType);
            }
        }

        return value;
    };

    const processObject = (item: any): EnforcedTypes<T> => {
        return Object.keys(item).reduce((acc, key) => {
            if (isObject(typeDefinition) && key in typeDefinition) {
                acc[key] = coerceValue(item[key], (typeDefinition as any)[key]);
            } else if (typeof item[key] === 'object' && item[key] !== null) {
                // Handle nested unknown sub-properties, recursively processing objects
                acc[key] = enforceSchema(item[key], item[key]);
            } else {
                acc[key] = item[key]; // If the key isn't in typeDefinition, keep the original value
            }
            return acc;
        }, {} as EnforcedTypes<T>);
    };

    if (Array.isArray(input)) {
        return input.map(processObject);
    } else {
        return processObject(input);
    }
}


// function isString(x: string): boolean {
//     return Object.prototype.toString.call(x) === '[object String]';
// }

// function isObject(obj: any): boolean {
//     return Object.prototype.toString.call(obj) === '[object Object]';
// }

// const isObject = (obj: any): obj is object => obj !== null && typeof obj === 'object';
export const isObject = (value: unknown): value is Record<string, unknown> => {
    return value !== null && typeof value === 'object' && !Array.isArray(value);
};

// Recursive function to gather all unique property paths
export const gatherPropertyPaths = (
    obj: Record<string, unknown>,
    parentKey: string = "",
    paths: Set<string> = new Set()
): Set<string> => {
    Object.keys(obj).forEach((key) => {
        const fullPath = parentKey ? `${parentKey}.${key}` : key;
        const value = obj[key];

        if (isObject(value)) {
            // Recursively handle nested objects
            gatherPropertyPaths(value as Record<string, unknown>, fullPath, paths);
        } else if (Array.isArray(value)) {
            // Handle arrays by appending an index placeholder to the path
            paths.add(fullPath); // Path to the array itself
            value.forEach((item, index) => {
                const arrayPath = `${fullPath}[${index}]`;
                if (isObject(item)) {
                    gatherPropertyPaths(item as Record<string, unknown>, arrayPath, paths);
                } else {
                    paths.add(arrayPath); // Primitive or non-object in the array
                }
            });
        } else {
            // Add primitive values or leaf node paths
            paths.add(fullPath);
        }
    });

    return paths;
};

export const isValidJSON = (str: string): boolean => {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
};

/**
 * @summary Takes Object or JSON and returns Object
 * @function toObject
 * @param {string|Object} payload
 * @requires isValidJSON
 * @requires Ramda
 * @returns {Object} (immutable)
 */
export const toObject = R.cond([
    [isValidJSON, JSON.parse],
    [isObject, R.identity],
    [R.T, () => { throw new Error('ValidationError: Payload is not an object and cannot be parsed as JSON') }]
]);

const pipe = <T>(...fns: Array<(arg: T) => T>) => (value: T) => fns.reduce((acc, fn) => fn(acc), value);

/**
 * @summary Compose promises and non-promises in reverse order (eg left to right) 
 * @param {Object} funcs One or more higher order functions
 * @async 
 * @returns composed functions in reverse order (ie left to right)
 * @requires Ramda
 */
// var pipeP = <T>(...fns: Array<(arg: T) => T>) => (value: T) => fns.reduce((acc, fn) => acc.then(fn), Promise.resolve(value))
export var pipeP = (...fns: any) => (x: any) => fns.reduce((y: any, f: any) => y.then(f), Promise.resolve(x))

/**
 * @summary Formatted mid-composition logger. Used to print out the value in the pipe without altering it.
 * @param {string} msg - A message to prepend to the passed-through value for logging
 * @param {*} x - Any value to write to log and then pass through
 * @returns {*} x
 * @requires Ramda 
 * @example <caption>Example of logging an object and passing through.</caption>
 * // Outputs x and writes to log: "My object: {key1: 'val1'}"
 * trace("My object:")({key1: 'val1'})
 * @example <caption>Example of specializing (curry) and composition.</caption>
 * const traceObject = trace("My object:")
 * let cleanedEvent = pipe(traceObject,removeNewLine,traceObject,removeTab,traceObject)(event)
 */
var trace = (msg: string) => R.tap((x: any) => { void (console.log(msg, x)) })

/**
 * kvPropNames is an array of key/value pairs; used to flatten array of objects where object has key and value properties. eg [{name: "host", value: "myhost"},{name: "check", value: "mycheck"}]
 * @summary Convert a nested object to an array of key/value pair arrays
 * @description Unique to BigPanda alert schema, final object is flat, consisting of native types or flat arrays of native types.
 * @param {Array} kvPropNames An array of key/value pair arrays
 * @param {Object} origObj A POJO with any number of levels
 * @requires Ramda
 * @returns {Object} Original object has been flattened (immutable)
 * @example <caption>See tests for examples.</caption>
 */
export var flattenObject = (kvPropNames = [
    ["key", "value"],
    ["name", "value"],
    ["name", "content"],
]) => {
    const flatten: any = (obj_: object) => R.chain(([k, v]: [string, any]) => {
        for (let [keyName, valName] of kvPropNames) {
            if (R.type(v) === 'Object' && R.has(keyName, v) && R.has(valName, v)) {
                return [[v[keyName], v[valName]]]
            }
        }
        if (R.type(v) === 'Object') {
            return R.map(([k_, v_]: [string, any]) => [`${k}_${k_}`, v_], flatten(v))
        }
        if (R.type(v) === 'Array') {
            if (R.any((e: any) => R.type(e) === 'Array')(v)) return R.map(([k_, v_]: [string, any]) => [`${k}_${k_}`, v_], flatten(v))
            if (R.any((e: any) => R.type(e) === 'Object')(v)) return R.map(([k_, v_]: [string, any]) => [`${k}_${k_}`, v_], flatten(v))
        }
        return [[k, v]]
    }, Object.entries(obj_))
    return pipe(flatten, Object.fromEntries)
};

type KeyLTrimMap = string[]
/**
 * @summary Left-trim object keys
 * @param {Array} keyLTrimMap A list used to "left-trim" keys. eg [ "_", "instance_"]
 * @param {Object} origObj flat object
 * @returns {Object} Clone of object with property names (keys) trimmed
 * @example See tests for examples.
 */
export var keyLTrim = (keyLTrimMap: KeyLTrimMap = []) => (origObj: object) =>
    Object.fromEntries(
        Object.entries(origObj).map(pair => {
            let key = pair[0]
            keyLTrimMap.forEach(val => {
                if (key.startsWith(val)) {
                    key = key.substring(key.indexOf(val) + val.length)
                }
            })
            return [key, pair[1]]
        })
    );

interface KeyRenameMap { [index: string]: string }
/**
 * @summary Rename object keys
 * @param {Object} keyRenameMap An enumerator used to rename keys. eg {
      source: 'alert_source',
      hostname: 'host'
    }
 * @param {Object} origObj flat object
 * @returns {Object} Clone of object with property names (keys) renamed
 * @example See tests for examples.
 */
export var renameKeys = (keyRenameMap: KeyRenameMap = {}) => (origObj: object) =>
    Object.fromEntries(
        Object.entries(origObj).map(pair => {
            let key = pair[0]
            for (let prop in keyRenameMap) {
                if (prop === pair[0]) key = keyRenameMap[prop]
            }
            return [key, pair[1]]
        })
    );

type KeyDeleteMap = string[]
/**
 * @summary Delete object keys
 * @param {Object} keyDeleteMap A list used to remove (filter out) keys. eg [
      "emailText",
      "emailHtml",
    ]
 * @param {Object} origObject flat object
 * @returns {Object} Clone of object with specified propterties (keys) filtered out
 * @example See tests for examples.
 */
export function deleteKeys(keyDeleteMap: KeyDeleteMap = []) {
    return (origObj: object) =>
        Object.fromEntries(
            Object.entries(origObj).filter(pair => !keyDeleteMap.includes(pair[0])
            )
        )
}

/**
 * @returns {Boolean}
 */
export function valueMatchesRegex(pattern: string, value: any) { return Boolean(pattern) && new RegExp(pattern, 'mi').test(value) }

const toEpochNumber = (val: number | string): number => Number(String(val.toString()).match(/^[0-9]{1,10}/)[0]);

// function that returns a 10 digit unix epoch value
export function fixTimestamp(tsValue: number | string | undefined | void): number {
    if (Boolean(tsValue) && typeof tsValue === "number") return toEpochNumber(tsValue)
    if (Boolean(tsValue) && typeof tsValue === "string") {
        // handle string of only digits
        if (/^[\d.]+$/.test(tsValue)) return toEpochNumber(tsValue)
        // assume it's a date string
        if (Number.isSafeInteger(Date.parse(tsValue))) return Math.floor(Date.parse(tsValue) / 1000);
    }
    return Math.floor(Date.now() / 1000);
}


/**
 * Sometimes a monitoring tool introduces characters that are illegal for JSON. Note that escape character must be escaped (ie \ becomes \\\\).
 * @summary Replace characters (that prevent JSON.parse) with a space character
 * @param {Array} charRemoveMap - Array of regex patterns
 * @param {String} payload 
 * @returns {Object} New event object
 * @example <caption>Example of removing "\n" from event body.</caption>
 * cleanPayload(['\\\n'])(event.body)
 * @example <caption>Example of specializing (curry) and composition.</caption>
 * const removeNewline = cleanPayload(['\\\n'])
 * const removeTab = cleanPayload(['\\\t'])
 * let cleanedPayload = pipe(removeNewline, removeTab)(payload)
 * @requires Ramda
 */
export var cleanPayload = (charRemoveMap = ['\\\n', '\\\t']) => (payload: string) => {
    let newPayload = R.clone(payload)
    let pattern = new RegExp(charRemoveMap.join('|'), 'g')
    return newPayload.replace(pattern, " ")
};

/**
 * Property name is trimmed and non-word characters are replaced with an underscore. Value is trimmed if it's a string.
 * @summary Normalize raw KV pairs 
 * @param {Array} kvPair - An array of two strings representing key and value
 * @param {string} kvPair[].key - A string to be used as the property name
 * @param {*} kvPair[].value - Any type to be used as the property value
 * @example <caption>Example usage of normalizeKV.</caption>
 * // returns ["my_key_1", "ABCdef123!"]
 * normalizeKV(["My-key/1 ", " ABCdef123! "])
 * @returns {Array} new kv pair (immutable)
 */
var normalizeKV = ([...kvPair]) => [
    kvPair[0].trim().replace(/[^0-9a-zA-Z_-]+/g, '_'),
    typeof kvPair[1] === 'string' ? kvPair[1].trim() : kvPair[1]
]

/**
 * Note: Higher order function; returns a function that parses text. Intended for specialization and composition.
 * @function getPropsFromText
 * @summary Parse text into an object using a filter and an array of possible delimiters
 * @param {String} splitCharsExp A RegEx pattern of delimiters. example: '[=:>]{1,}'
 * @param {String} matchCharsExp A RegEx pattern for filtering Key candidates. example: '[\\w \\(\\)]+'
 * @param {String} text text containing potential key/value pairs
 * @requires cleanKvPair
 * @requires Ramda
 * @returns {Object} Plain object with properties parsed from the text
 * @example <caption>Example of single use.</caption>
 * let newObjectFromText = getPropsFromText('[=:>]{1,}', '[\\w \\(\\)]+')('host: myhost \n check: mycheck')
 * // {host: "myhost", check: "mycheck"}
 * @example <caption>Example of specializing (curry).</caption>
 * const extractWordsWithParens = getPropsFromText('[=:>]{1,}', '[\\w \\(\\)]+')
 * let newObjectFromText = extractWordsWithParens('host: myhost \n check: mycheck')
 * // {host: "myhost", check: "mycheck"}
 */
export var getPropsFromText = (splitCharsExp: string, matchCharsExp: string) => {
    let splitChars = new RegExp(splitCharsExp, 'g')
    let matchChars = new RegExp('^' + matchCharsExp + splitCharsExp + '.*', 'i')
    return pipe(
        R.split('\n'),
        R.filter((line: string) => matchChars.test(line)),
        trace('K/V PAIR CANDIDATES:'),
        R.reduce((obj: { [index: string]: any }, str: string) => {
            // grab all matches of the split characters
            let split = Array.from(str.matchAll(splitChars), m => m[0])
            // split on first occurance of split char(s) to create k/v pair, then 'clean' it
            let kvPair = normalizeKV([
                str.slice(0, str.search(split[0])),
                str.slice(str.search(split[0]) + split[0].length)
            ])
            obj[kvPair[0]] = kvPair[1]
            return obj
        }, {})
    )
};

export const setKeysToLowerCase = (origObj: object) => Object.entries(origObj).reduce((acc, [key, val]) => {
    return {
        ...acc,
        [key.toLowerCase()]: val
    }
}, {})

type CharRemoveMap = string[]
/**
 * Sometimes a monitoring tool introduces characters that are illegal for JSON. Note that escape character must be escaped (ie \ becomes \\\\).
 * @summary Replace characters (that prevent JSON.parse) with a space character
 * @param {Array} charRemoveMap - Array of regex patterns
 * @param {String} payload 
 * @returns {String} New string, minus the illegal characters
 * @example <caption>Example of removing "\n" from event body (JSON).</caption>
 * cleanString(['\\\n'])(event.body)
 * @example <caption>Example of specializing (curry) and composition.</caption>
 * const removeNewline = cleanString(['\\\n'])
 * const removeTab = cleanString(['\\\t'])
 * let cleanedBody = pipe(removeNewLine, removeTab)(event.body)
 */
export var cleanString = (charRemoveMap: CharRemoveMap = []) => (payload: string) =>
    payload.replace(new RegExp(charRemoveMap.join('|'), 'g'), " ");

export function absolute(num: number) {
    if (num < 0) return num * -1;
    return num;
}
export type ConvertedCsvTable = ReadonlyArray<any[]>

export type HeadersAndRows = {
    headers: string[];
    rows: {
        _id: string;
        [key: string]: any
    }[];
};

export const csvTableToHeadersAndRows = function tableToHeadersColumns(table: ConvertedCsvTable): HeadersAndRows {
    // let cnt = 0;
    const headers = table[0]
    // .map((h) => (Boolean(h) ? h.replace(/\W/g, "_").toLowerCase() : `_${cnt++}`));
    const rows = table.slice(1).map((row) => {
        const eachObject = headers.reduce((obj, header, i) => {
            obj[header] = Boolean(row[i]) ? row[i] : null;
            return obj;
        }, { _id: uuidv4() });
        return eachObject;
    });
    return { headers, rows };
};

export const randomizeAllNumbersInString = function randomizeAllNumbersInString(str) {
    return str.replace(/[0-9]+/g, (match) => {
        return Math.floor(Math.random() * 10 ** match.length);
    });
}

export const replace = function replace(
    data: any,
    searchPattern: string,
    replacementPattern: string,
    searchGlobal: boolean,
    ignoreCase: boolean
) {
    if (!searchPattern) return data;
    let searchRegex = new RegExp(
        searchPattern,
        `${searchGlobal ? "g" : ""}${ignoreCase ? "i" : ""}`
    );
    if (typeof data === "string") {
        return data.replace(searchRegex, replacementPattern);
    }
    if (typeof data === "number") {
        return Number(data.toString().replace(searchRegex, replacementPattern));
    }
    if (typeof data === "boolean") {
        // replace value of boolean as string then marshal it to boolean
        let replacedValue = data
            .toString()
            .replace(searchRegex, replacementPattern);
        return replacedValue === "true" ? true : false;
    }
    if (Array.isArray(data)) {
        return data.map((item) =>
            replace(item, searchPattern, replacementPattern, searchGlobal, ignoreCase)
        );
    }
    if (typeof data === "object") {
        let newData = {};
        for (let key in data) {
            newData[key] = replace(
                data[key],
                searchPattern,
                replacementPattern,
                searchGlobal,
                ignoreCase
            );
        }
        return newData;
    }
    return data;
}

export const removeUndefined = function removeUndefined(obj) {
    return Object.keys(obj).filter(key => obj[key] !== undefined).reduce((acc, key) => {
        acc[key] = obj[key];
        return acc;
    }, {});
}

// function to update the original object based on values in the flat object
// Use a generic type to match the structure of the original object
export const updateObject = <T extends Record<string, any>>(original: T, valuesCache: FlatObject): T => {

    // Recursive helper function to update nested objects immutably using reduce
    const updateNestedObject = (originalObj: any, newValues: FlatObject): T => {
        return Object.keys(originalObj).reduce<Partial<T>>((acc, key) => {
            if (typeof originalObj[key] === 'object' && originalObj[key] !== null && !Array.isArray(originalObj[key])) {
                // Recursively handle nested objects
                return {
                    ...acc,
                    [key]: updateNestedObject(originalObj[key], newValues)
                };
            } else if (newValues.hasOwnProperty(key)) {
                // Coerce the value from newValues to match the original type
                const originalValue = originalObj[key];
                const newValue = newValues[key];

                let coercedValue: any;

                // Type coercion based on the original property's type
                if (typeof originalValue === 'number') {
                    coercedValue = Number(newValue);
                } else if (typeof originalValue === 'boolean') {
                    coercedValue = newValue === 'true' || newValue === true;
                } else if (typeof originalValue === 'string') {
                    coercedValue = String(newValue);
                } else {
                    // If the type isn't specifically handled, just assign the new value
                    coercedValue = newValue;
                }

                return {
                    ...acc,
                    [key]: coercedValue
                };
            } else {
                // Preserve the original value if no match in the flat object
                return {
                    ...acc,
                    [key]: originalObj[key]
                };
            }
        }, {} as Partial<T>) as T;
    };

    // Return the updated object, ensuring immutability and type consistency
    return updateNestedObject(original, valuesCache);
};

export const extractUniqueKeysFromProperty = function extractUniqueKeysFromProperty<T>(data: T[], propertyKey: keyof T): string[] {
    return data.reduce<string[]>((acc, row) => {
        const propertyValue = row[propertyKey];

        // Ensure the property value is a non-null object and not an array
        if (propertyValue !== null && typeof propertyValue === "object" && !Array.isArray(propertyValue)) {
            // Merge current keys with accumulator and remove duplicates
            return [...new Set([...acc, ...Object.keys(propertyValue as Record<string, any>)])];
        }

        return acc;
    }, []);
}

export const extractUniqueKeysFromData = function extractUniqueKeysFromData<T>(data: T[]): string[] {
    return data.reduce<string[]>((acc, row) => {
        // Ensure the row is a non-null object
        if (row !== null && typeof row === "object") {
            // Merge current keys with accumulator and remove duplicates
            return [...new Set([...acc, ...Object.keys(row)])];
        }
        return acc;
    }, []);
}

export const isInvalidApiKey = function isInvalidApiKey(value) {
    // Check if the string does not meet either of the two valid conditions
    const isValidBPUAK = value.length === 37 && value.startsWith("BPUAK");
    const isValidEndZ = value.length === 579 && value.endsWith("--z");

    // Return true if neither of the conditions is met
    return !(isValidBPUAK || isValidEndZ);
}

export function getNestedValue(obj: any, path: string) {
    return path.split('.').reduce((acc, part) => obj[part], undefined);
}

export function isValidArrayOfObjects(payload: unknown): boolean {
    // Check if the payload is an array
    if (!Array.isArray(payload)) {
        return false;
    }

    // Recursive function to validate each object
    function validateObject(obj: Record<string, unknown>): boolean {
        for (const key in obj) {
            const value = obj[key];

            if (Array.isArray(value)) {
                // Check if the array contains objects or nested arrays
                if (value.some(item => isObject(item) || Array.isArray(item))) {
                    return false;
                }
            } else if (isObject(value)) {
                // Recursively validate nested objects
                if (!validateObject(value)) {
                    return false;
                }
            }
        }
        return true;
    }

    // Validate each object in the array
    return payload.every(item => isObject(item) && validateObject(item));
}

export function deduplicateRecords<T extends { id: unknown }>(records: T[] = []): T[] {
    console.log('deduplicateRecords', records)
    return records.reduce<T[]>((acc, record) => {
        if (!acc.find(accRecord => accRecord.id === record.id)) {
            return [...acc, record];
        }
        return acc;
    }, []);
}

export function convertArrayPropertiesToString(obj) {
    const updatedObj = { ...obj }; // Create a shallow copy of the object
    for (const key in obj) {
        if (Array.isArray(obj[key])) {
            updatedObj[key] = obj[key].join('|'); // Join array elements with a colon
        }
    }
    return updatedObj;
}