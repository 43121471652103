/* eslint-disable */

export const deleteOrg = /* GraphQL */ `
  mutation DeleteOrg(
    $input: DeleteOrgInput!
    $condition: ModelOrgConditionInput
  ) {
    deleteOrg(input: $input, condition: $condition) {
      id
      name
      spoc_email
      region
      owners
    }
  }
`;
export const deleteOrgScenario = /* GraphQL */ `
  mutation DeleteOrgScenario(
    $input: DeleteOrgScenarioInput!
    $condition: ModelOrgScenarioConditionInput
  ) {
    deleteOrgScenario(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteScenario = /* GraphQL */ `
  mutation DeleteScenario(
    $input: DeleteScenarioInput!
    $condition: ModelScenarioConditionInput
  ) {
    deleteScenario(input: $input, condition: $condition) {
      id
      name
      description
      owners
    }
  }
`;

export const updateOrg = /* GraphQL */ `
  mutation UpdateOrg(
    $input: UpdateOrgInput!
    $condition: ModelOrgConditionInput
  ) {
    updateOrg(input: $input, condition: $condition) {
      id
      name
      spoc_email
      api_key
      region
      params {
        name
        values
      }
      integrations {
        integration_type
        primary_property
        secondary_property
        bp_integration {
          name
          stream_id
          type
          source_system
          transport
          parent_source_system
          dataset_options
        }
      }
      owners
      bporgname
    }
  }
`;

export const updateScenario = /* GraphQL */ `
  mutation UpdateScenario(
    $input: UpdateScenarioInput!
    $condition: ModelScenarioConditionInput
  ) {
    updateScenario(input: $input, condition: $condition) {
      id
      name
      description
      category
      events {
        event_type
        _offset
        seconds
        integration_type
        primary_property
        secondary_property
        tags
      }
      changes {
        integration_type
        _offset
        identifier
        status
        summary
        ticket_url
        tags
      }
      orgs {
        items {
          id
          orgID
          scenarioID
          owners
        }
        nextToken
      }
      groupsCanEdit
      groupsCanRead
      owners
      eventPages {
        items {
          page
          groupsCanEdit
          groupsCanRead
          owners
          id
          scenarioEventPagesId
        }
        nextToken
      }
      changePages {
        items {
          page
          groupsCanEdit
          groupsCanRead
          owners
          id
          scenarioChangePagesId
        }
        nextToken
      }
    }
  }
`;
export const createOrg = /* GraphQL */ `
  mutation CreateOrg(
    $input: CreateOrgInput!
    $condition: ModelOrgConditionInput
  ) {
    createOrg(input: $input, condition: $condition) {
      id
      name
      spoc_email
      api_key
      region
      params {
        name
        values
      }
      integrations {
        integration_type
        primary_property
        secondary_property
        bp_integration {
          _id
          name
          stream_id
          type
          source_system
          transport
          parent_source_system
          dataset_options
        }
      }
      owners
      bporgname
    }
  }
`;
export const createOrgScenario = /* GraphQL */ `
  mutation CreateOrgScenario(
    $input: CreateOrgScenarioInput!
    $condition: ModelOrgScenarioConditionInput
  ) {
    createOrgScenario(input: $input, condition: $condition) {
      id
      orgID
      scenarioID
      owners
    }
  }
`;
export const deleteEventPage = /* GraphQL */ `
  mutation DeleteEventPage(
    $input: DeleteEventPageInput!
    $condition: ModelEventPageConditionInput
  ) {
    deleteEventPage(input: $input, condition: $condition) {
      id
      page
    }
  }
`;
export const createScenario = /* GraphQL */ `
  mutation CreateScenario(
    $input: CreateScenarioInput!
    $condition: ModelScenarioConditionInput
  ) {
    createScenario(input: $input, condition: $condition) {
      id
      name
      description
      category
      events {
        event_type
        _offset
        seconds
        integration_type
        primary_property
        secondary_property
        tags
      }
      changes {
        integration_type
        _offset
        identifier
        status
        summary
        ticket_url
        tags
      }
      groupsCanEdit
      groupsCanRead
      owners
      eventPages {
        items {
          page
          groupsCanEdit
          groupsCanRead
          owners
          id
          scenarioEventPagesId
        }
        nextToken
      }
      changePages {
        items {
          page
          groupsCanEdit
          groupsCanRead
          owners
          id
          scenarioChangePagesId
        }
        nextToken
      }
    }
  }
`;
export const deleteChangePage = /* GraphQL */ `
  mutation DeleteChangePage(
    $input: DeleteChangePageInput!
    $condition: ModelChangePageConditionInput
  ) {
    deleteChangePage(input: $input, condition: $condition) {
      id
      page
    }
  }
`;

export const createEventPage = /* GraphQL */ `
  mutation CreateEventPage(
    $input: CreateEventPageInput!
    $condition: ModelEventPageConditionInput
  ) {
    createEventPage(input: $input, condition: $condition) {
      page
      events {
        event_type
        _offset
        seconds
        integration_type
        primary_property
        secondary_property
        tags
      }
      groupsCanEdit
      groupsCanRead
      owners
      id
      scenarioEventPagesId
    }
  }
`;

export const createChangePage = /* GraphQL */ `
  mutation CreateChangePage(
    $input: CreateChangePageInput!
    $condition: ModelChangePageConditionInput
  ) {
    createChangePage(input: $input, condition: $condition) {
      page
      changes {
        integration_type
        _offset
        identifier
        status
        summary
        ticket_url
        tags
      }
      groupsCanEdit
      groupsCanRead
      owners
      id
      scenarioChangePagesId
    }
  }
`;

export const postUtil = /* GraphQL */ `
  mutation PostUtil(
    $access_token: String!
    $region: String!
    $params: AWSJSON!
    $payload: AWSJSON
  ) {
    postUtil(
      access_token: $access_token
      region: $region
      params: $params
      payload: $payload
    )
  }
`;

export const postChanges = /* GraphQL */ `
  mutation PostChanges(
    $access_token: String!
    $changes: AWSJSON
    $region: String
  ) {
    postChanges(access_token: $access_token, changes: $changes, region: $region)
  }
`;

export const createResource = /* GraphQL */ `
  mutation CreateResource(
    $input: CreateResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    createResource(input: $input, condition: $condition) {
      name
      description
      type
      secret
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateResource = /* GraphQL */ `
  mutation UpdateResource(
    $input: UpdateResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    updateResource(input: $input, condition: $condition) {
      name
      description
      type
      secret
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteResource = /* GraphQL */ `
  mutation DeleteResource(
    $input: DeleteResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    deleteResource(input: $input, condition: $condition) {
      name
      description
      type
      secret
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createSchedule = /* GraphQL */ `
  mutation CreateSchedule(
    $input: CreateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    createSchedule(input: $input, condition: $condition) {
      event_id
      title
      start
      end
      resource {
        name
        description
        type
        secret
        id
        createdAt
        updatedAt
        owner
        __typename
      }
      project
      history
      owner
      createdAt
      updatedAt
      scheduleResourceId
      __typename
    }
  }
`;
export const updateSchedule = /* GraphQL */ `
  mutation UpdateSchedule(
    $input: UpdateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    updateSchedule(input: $input, condition: $condition) {
      event_id
      title
      start
      end
      resource {
        name
        description
        type
        secret
        id
        createdAt
        updatedAt
        owner
        __typename
      }
      project
      history
      owner
      createdAt
      updatedAt
      scheduleResourceId
      __typename
    }
  }
`;
export const deleteSchedule = /* GraphQL */ `
  mutation DeleteSchedule(
    $input: DeleteScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    deleteSchedule(input: $input, condition: $condition) {
      event_id
      title
      start
      end
      resource {
        name
        description
        type
        secret
        id
        createdAt
        updatedAt
        owner
        __typename
      }
      project
      history
      owner
      createdAt
      updatedAt
      scheduleResourceId
      __typename
    }
  }
`;
export const queryOpenAI = /* GraphQL */ `
  mutation QueryOpenAI($config: AWSJSON!) {
    queryOpenAI(config: $config)
  }
`;