import { Logger } from "aws-amplify";
var R = require('ramda');
const logger = new Logger("ai_funcs", "INFO");

export const getTokenCount = (encoding, data: any) => {

    if (R.is(String, data)) {
        // do nothing
    } else
        // stringify data if it's an object
        if (R.is(Object, data)) {
            data = tightStringify(data);
        } else
            // stringify date if it's an array
            if (R.is(Array, data)) {
                data = tightStringify(data);
            } else
                // stringify data if it's a date
                if (R.is(Date, data)) {
                    data = data.toString();
                } else
                    // stringify data if it's a boolean
                    if (R.is(Boolean, data)) {
                        data = data.toString();
                    } else
                        // stringify data if it's a number
                        if (R.is(Number, data)) {
                            data = data.toString();
                        } else
                            // replace undefined with empty string
                            if (R.isNil(data)) {
                                data = " ";
                            }
    const tokens = encoding.encode(data);
    return tokens.length;
};

/**
 * @description split the payload array into multiple arrays, such that each array is within the token limit
 * @param tokenLimit 
 * @param bufferCount 
 * @param model 
 * @param data 
 * @returns Array of arrays
 */
export function splitArrayByTokenCount(tokenLimit: number = 128000, bufferCount: number = 0, encoding, origDataArray: any[] = []) {
    const dataTokens = getTokenCount(encoding, origDataArray)
    console.log(`Raw data token estimate = ${dataTokens}. Buffer estimate = ${bufferCount}. Pre-split total estimate = ${dataTokens + bufferCount}`)

    if (dataTokens + bufferCount <= tokenLimit) return [origDataArray];

    let splitDataArrays = [[]];
    let currentTokenCount = 0;
    let currentArrayIndex = 0;
    let currentArray = splitDataArrays[currentArrayIndex];
    for (let i = 0; i < origDataArray.length; i++) {
        let tokenCount = encoding.encode(tightStringify(origDataArray[i])).length
        if (currentTokenCount + tokenCount > tokenLimit - bufferCount) {
            currentArrayIndex++;
            splitDataArrays[currentArrayIndex] = [];
            currentArray = splitDataArrays[currentArrayIndex];
            currentTokenCount = 0;
        }
        currentArray.push(origDataArray[i]);
        currentTokenCount += tokenCount;
    }
    // splitDataArrays.forEach((arr, idx) => console.log(`Split array #${idx} token estimate:`, getTokenCount(encoding, arr)))
    return splitDataArrays;
};

export async function queryOpenAI(config) {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 300000); // 5 minutes

    try {
        return fetch('https://postraw.bigpandademo.com/proxyai', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(config),
            signal: controller.signal
        }).then(response => {
            clearTimeout(timeoutId);
            return response.json();
        })
    } catch (error) {
        clearTimeout(timeoutId);
        logger.error(error)
        throw error;
    }
}

export function tightStringify(jsonData) {
    return JSON.stringify(
        jsonData,
        null,
        0
    )
        .replace(/\\n|\\r/g, "")
        .replace(/\\"/g, `"`)
}

export function removeFirstAndLastIfJSONBlock(rawResponse) {
    return rawResponse
        .split("\n")
        .filter((line) => !line.startsWith("```"))
        .join("\n");
};


export function formatCorrelationPatternsForAI(correlationPatterns) {
    const formattedPatterns = correlationPatterns.map((pattern) => {
        return {
            id: pattern.id,
            active: pattern.active,
            tags: pattern.tags,
            time_window: pattern.time_window,
        };
    });

    return formattedPatterns;
}

function formatTagsForAi(tags) {
    return tags.map((tag) => {
        return {
            name: tag.name.replace(/^_/g, ""),
            value: tag.value,
        };
    });
}

export function formatAlertForAI(alert) {
    return {
        start: alert.start,
        end: alert.end,
        description: alert.description,
        primary_property: alert.primary_property,
        secondary_property: alert.secondary_property,
        source_system: alert.source_system,
        status: alert.status,
        tags: formatTagsForAi(alert.tags),
    };
}

export function formatIncidentsForAI(incidents) {
    const formattedIncidents = incidents.map((incident) => {
        return {
            active: incident.active,
            alerts: incident.alerts.map((alert) => formatAlertForAI(alert)),
            correlation_matchers_log: incident.correlation_matchers_log,
            start: incident.start,
        };
    });

    return formattedIncidents;
}

// a function to create Unique list of tag names joined from all alerts.
function getUniqueTagNamesFromAlerts(alerts) {
    const tagNames = alerts.reduce((acc, alert) => {
        const tags = alert.tags.map((tag) => tag.name);
        return acc.concat(tags);
    }, []);
    return [...new Set(tagNames)];
}
// a function using getUniqueTagNames to create Unique list of tag names from all incidents.
export function getUniqueTagNamesFromIncidents(incidents) {
    const tagNames = incidents.reduce((acc, incident) => {
        const tags = getUniqueTagNamesFromAlerts(incident.alerts);
        return acc.concat(tags);
    }, []);
    return [...new Set(tagNames)];
}

// a function to create A unique list of tag names that have the same value across two or more alerts, where the tag name is identical and the tag value is also identical among those alerts, regardless of the tag matching status of other tags or values of other tags associated with those alerts.
function getCommonTagNames(alerts) {
    const tagNames = getUniqueTagNamesFromAlerts(alerts);
    // for each tag name get all the values from all the alerts. Include the tag name in commonTagNames if there are at least two occurances of the same value.
    const commonTagNames = tagNames.filter((tagName) => {
        // list the tag values and how many times the value appears in the alerts. If there are at least two occurences of the same value, include the tag name in commonTagNames
        const tagValues = alerts.reduce((acc, alert) => {
            const tag = alert.tags.find((tag) => tag.name === tagName);
            return tag ? acc.concat(tag.value) : acc;
        }, []);
        const uniqueTagValues = [...new Set(tagValues)];
        const commonTagValues = uniqueTagValues.filter((tagValue) => {
            const tagValueOccurences = tagValues.filter(
                (value) => value === tagValue
            );
            return tagValueOccurences.length > 1;
        });
        return commonTagValues.length > 0;
    });

    return [...new Set(commonTagNames)];
}
// a function to create A unique list of tag names that have the same value across two or more alerts from all incidents.
export function getCommonTagNamesFromIncidents(incidents) {
    const alerts = incidents.reduce((acc, incident) => {
        return acc.concat(incident.alerts);
    }, []);
    return getCommonTagNames(alerts);
}

// a function to create a list of Alerts that have one or more tag name/value pairs in common with at least one other alert.
function getCommonAlerts(tagNames, alerts) {
    let commonAlerts = [];
    tagNames.forEach((tagName) => {
        // list all the occurrences of the tag name and value in the alerts
        const tagValues = alerts.reduce((acc, alert) => {
            const tag = alert.tags.find((tag) => tag.name === tagName);
            return tag ? acc.concat(tag.value) : acc;
        }, []);
        // eliminate single occurence values, only include those that have at least two occurences
        const uniqueTagValues = [...new Set(tagValues)];
        const commonTagValues = uniqueTagValues.filter((tagValue) => {
            const tagValueOccurences = tagValues.filter(
                (value) => value === tagValue
            );
            return tagValueOccurences.length > 1;
        });
        // create a list of alerts that have the tag name and value in common
        commonTagValues.forEach((tagValue) => {
            const commonAlert = alerts.filter((alert) => {
                const tag = alert.tags.find(
                    (tag) => tag.name === tagName && tag.value === tagValue
                );
                return tag ? true : false;
            });
            commonAlerts = commonAlerts.concat(commonAlert);
        });
    });
    return [...new Set(commonAlerts)];
}
// create a list of Alerts that have one or more tag name/value pairs in common with at least one other alert from all incidents.
export function getCommonAlertsFromIncidents(tagNames, incidents) {
    const alerts = incidents.reduce((acc, incident) => {
        return acc.concat(incident.alerts);
    }, []);
    return getCommonAlerts(tagNames, alerts);
}

export function getCandidatesForCorrelation(incidents) {
    return getCommonAlertsFromIncidents(
        getCommonTagNamesFromIncidents(incidents),
        incidents
    );
}

// a function to return the currently matching correlation pattern from an incident. This is defined as the pattern identified in the correlation_matchers_log, where the current matcher is in the last array of matchers and the pattern with the longest time window.
// Input is the correlation_matchers_log from an incident, which contains one or more arrays of matchers. Each array has one or more matchers, and the one with the longest time window is the current matcher.
// Output is the matcher object from the last array of matchers with the longest time window.
export function getCurrentlyMatchingCorrelationPattern(correlationMatchersLog) {
    const lastMatcherArrayIndex = correlationMatchersLog.length - 1;
    const currentMatcher = correlationMatchersLog[lastMatcherArrayIndex].reduce(
        (acc, matcher) => {
            return matcher.time_window > acc.time_window ? matcher : acc;
        },
        { time_window: 0 }
    );
    return currentMatcher;
}

// Correlation Pattern Compression Rate: 1 - (total number of incidents that include the correlation pattern id in their correlation_matchers_log) / (total number of Candidate for Correlation alerts that included the pattern tags)

//Return an object ("currentlyMatchingCorrelationPatterns") reporting the correlation patterns that are applied to the currently correlated incidents (ie the pattern identified in the correlation_matchers_log, where the current matcher is in the last array of matchers and the pattern with the longest time window.). For each pattern list the correlation pattern ID, tags, time window, number of correlated incidents (ie incidents that used the pattern), number of candidate alerts (ie alerts that included the patterns tags), and the calculated Correlation Pattern Compression Rate.
export function getCurrentlyMatchingCorrelationPatterns(
    correlationPatterns,
    incidents
) {
    return correlationPatterns
        .filter((pattern) => {
            return incidents.some((incident) => {
                const currentMatcher = getCurrentlyMatchingCorrelationPattern(
                    incident.correlation_matchers_log
                );
                return currentMatcher.correlation_id === pattern.id;
            });
        })
        .map((pattern) => {
            const patternId = pattern.id;
            const tagNames = pattern.tags;
            const timeWindow = pattern.time_window;
            const correlatedIncidents = incidents.filter((incident) => {
                const currentMatcher = getCurrentlyMatchingCorrelationPattern(
                    incident.correlation_matchers_log
                );
                return currentMatcher.correlation_id === patternId;
            });
            const matchedAlerts = correlatedIncidents.flatMap((incident) => {
                return incident.alerts;
            });
            const compressionRate =
                parseFloat((1 - correlatedIncidents.length / matchedAlerts.length).toFixed(4))
            return {
                patternId,
                tags: tagNames,
                timeWindow,
                correlatedIncidents: correlatedIncidents.length,
                matchedAlerts: matchedAlerts.length,
                compressionRate,
            };
        });
}

function arraysShareCommonElement(array1, array2) {
    return array1.some((element) => array2.includes(element));
}

export function correlateAlertsByTagNames(tagNames, alerts) {
    return alerts
        .reduce((incidents, alert) => {
            let alertTags = alert.tags
                .filter((tag) => tagNames.includes(tag.name))
                .reduce((acc, tag) => {
                    acc[tag.name] = tag.value;
                    return acc;
                }, {});
            let matchedIncidentIndex = incidents.findIndex((incident) =>
                incident.every((incidentAlert) =>
                    tagNames.every((tagName) =>
                        incidentAlert.tags.some(
                            (tag) =>
                                tag.name === tagName &&
                                (tag.value === alertTags[tagName] ||
                                    (Array.isArray(tag.value) &&
                                        tag.value.includes(alertTags[tagName])) ||
                                    (Array.isArray(alertTags[tagName]) &&
                                        alertTags[tagName].includes(tag.value)) ||
                                    (Array.isArray(tag.value) &&
                                        Array.isArray(alertTags[tagName]) &&
                                        arraysShareCommonElement(tag.value, alertTags[tagName])))
                        )
                    )
                )
            );
            matchedIncidentIndex > -1
                ? incidents[matchedIncidentIndex].push(alert)
                : incidents.push([alert]);
            return incidents;
        }, [])
        .filter((incident) => incident.length > 1);
}

function getMaxAlertTimeWindow(correlatedIncidents) {
    // get the maximum range of time in seconds among all alert start times in the correlated incidents
    // alert start times are in unix epoch time, so the difference is in seconds. Translate to minutes and return the maximum time window in minutes.
    const incidentTimeWindows = correlatedIncidents.map((incident) => {
        const alertStartTimes = incident.map((alert) => alert.start);
        return (Math.max(...alertStartTimes) - Math.min(...alertStartTimes)) / 60;
    });

    return Math.round(Math.max(Math.min(Math.max(...incidentTimeWindows), 120), 5));
}

export function generateCorrelationPattern(tagNames, correlatedIncidents) {
    // only consider correlated incidents (those that have more than one alert correlated on the same tag value)
    const allCorrelatedAlerts = correlatedIncidents
        .filter((incident) => incident.length > 1)
        .flat();
    // examples of the tagName values from the correlated incident alerts
    const tagValues = allCorrelatedAlerts.reduce((acc, alert) => {
        const tags = alert.tags.filter((tag) => tagNames.includes(tag.name));
        return acc.concat(tags);
    }, []);
    // create a unique list of tag values for each tag name
    const uniqueTagValues = tagNames.reduce((acc, tagName) => {
        const values = tagValues
            .filter((tag) => tag.name === tagName)
            .map((tag) => tag.value);
        return acc.concat([...new Set(values)]);
    }, []);

    const timeWindow = getMaxAlertTimeWindow(correlatedIncidents);
    const compressionRate = parseFloat((
        1 -
        correlatedIncidents.length / allCorrelatedAlerts.length
    ).toFixed(4));
    return {
        tags: tagNames,
        timeWindow,
        compressionRate,
        incidents: correlatedIncidents.length,
        alerts: allCorrelatedAlerts.length,
        examplesValues: uniqueTagValues,
    };
}

export function analyzeIncidents(bpCorrPattns, selectedIncidents) {
    const invalidTags = [
        // "description",
        "short_description",
        // "check",
        "timestamp",
        "start",
        "end",
        "opened_at",
        "alert_updates",
        "primary_property",
        "secondary_property",
        "email",
        "username",
        "phone",
        "status",
        "severity",
        "priority",
        "impact",
        // "state",
        "assignment_group",
        "team",
        "source",
        "integration_name",
        "integration_type",
        "integration_id",
        "sys_updated_on",
        "sys_updated_by",
        "assigned_to",
    ];

    const commonTagNames = getCommonTagNamesFromIncidents(
        formatIncidentsForAI(selectedIncidents)
    ).filter((tagName: any) => !invalidTags.includes(tagName));

    const candidatesForCorrelation = getCommonAlertsFromIncidents(
        commonTagNames,
        formatIncidentsForAI(selectedIncidents)
    );

    const currentlyMatchingCorrelationPatterns =
        getCurrentlyMatchingCorrelationPatterns(
            formatCorrelationPatternsForAI(bpCorrPattns),
            formatIncidentsForAI(selectedIncidents)
        );

    const candidateTagNames = commonTagNames.filter(
        (tagName) =>
            !currentlyMatchingCorrelationPatterns.some((pattern) =>
                pattern.tags.includes(tagName)
            )
    );

    // const suggestedCorrelationPatterns = [];
    const suggestedCorrelationPatterns = candidateTagNames.map((tagName) =>
        generateCorrelationPattern(
            [tagName],
            correlateAlertsByTagNames([tagName], candidatesForCorrelation)
        )
    ).filter(pattern => pattern.compressionRate >= 0.6)        
    return {
        candidatesForCorrelation,
        currentlyMatchingCorrelationPatterns,
        commonTagNames,
        candidateTagNames,
        suggestedCorrelationPatterns,
    };
}

export function mergeCorrelationSuggestions(correlationSuggestions) {
    return correlationSuggestions.reduce((acc, current) => {
        // Sort the correlationSuggestions tags array to ensure order doesn't matter
        const sortedCurrentTags = current.tags.slice().sort().join(",");

        // Check if there is an existing object with the same sorted tags
        const existing = acc.find(
            (item) => item.tags.slice().sort().join(",") === sortedCurrentTags
        );

        if (existing) {
            // Merge the current object with the existing one
            existing.timeWindow = Math.max(
                existing.timeWindow,
                current.timeWindow
            );
            existing.compressionRate = Math.max(
                existing.compressionRate,
                current.compressionRate
            );
            existing.explanation = current.explanation;
            existing.exampleValues = current.exampleValues;
            existing.extractionRules = current.extractionRules;
        } else {
            // If no existing object is found, add the current object to the accumulator
            acc.push(current);
        }

        return acc;
    }, []);
}