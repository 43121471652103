/* eslint-disable no-template-curly-in-string */

const newScenarioTemplate = {
  name: "",
  description: "",
  category: "",
  events: [
    {
      event_type: "ALERT",
      _offset: 0,
      seconds: 0,
      integration_type: "infra-mon",
      primary_property: "host",
      secondary_property: "check",
      tags: JSON.stringify({
        host: '${rnd.aws_id.s1}.${rnd.geo_code.s1}.${rnd.urldomain.s1}',
        check: '${rnd.app_code.s1} failure',
        app: '${rnd.app_code.s1}',
        az: '${rnd.az_code.s1}',
        city: '${rnd.city_code.s1}',
        cloud: '${rnd.cloud_code.s1}',
        company: '${rnd.company.s1}',
        database: '${rnd.db_code.s1}',
        environment: '${rnd.env_code.s1}',
        geo: '${rnd.geo_code.s1}',
        tier: '${rnd.tier_code.s1}',
        ip: '10.${rnd.ip_octet}.${rnd.ip_octet}.${rnd.ip_octet}',
        mac: '${rnd.hh}:${rnd.hh}:${rnd.hh}:${rnd.hh}:${rnd.hh}:${rnd.hh}',
        status: 'critical',
      })
    }
  ], //TODO ITAG: add example ITAG for OpenAI
  changes: [
    {
      _offset: 350,
      integration_type: 'change-man',
      identifier: 'CHS${rnd.dddddddd.s1}',
      status: 'In Progress',
      summary: 'Upgrade O/S on ${rnd.aws_id.s1}.${rnd.geo_code.s1}.${rnd.urldomain.s1} to fix errors',
      ticket_url: 'https://www.servicenow.com/CHS${rnd.dddddddd.s1}',
      tags: JSON.stringify({
        Assignee: '${rnd.aaaaaadd}@${rnd.urldomain.s1}',
        AZ: '${rnd.az_code.s1}',
        Instance: '${rnd.aws_id.s1}.${rnd.geo_code.s1}.${rnd.urldomain.s1}',
        Environment: '${rnd.env_code.s1}',
        Approval: 'Approved',
        Risk: '${rnd.d}',
      })
    },
  ],
  groupsCanRead: [],
  groupsCanEdit: ["SELT"],
};

const baseScenarioTemplate = {
  name: "",
  description: "",
  category: "",
  events: [],
  changes: [],
};

export { newScenarioTemplate, baseScenarioTemplate };