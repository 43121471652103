import { atom, useAtom, } from 'jotai';

// runtime (locally mutatable) state (copied when mutating Demo Configs & scenarios)
const currentDemoConfigIdAtom = atom(undefined);
const openAiAppKeyAtom = atom(window.localStorage.getItem("aiAppKey") || "");

if (process.env.NODE_ENV !== "production") {
  currentDemoConfigIdAtom.debugLabel = "currentDemoConfigIdAtom";
}

export default function useAppState() {
  // Local state provided by jotai
  const [currentDemoConfigId, setCurrentDemoConfigId] = useAtom(
    currentDemoConfigIdAtom
  );
  const [openAiAppKey, setOpenAiAppKey] = useAtom(openAiAppKeyAtom);

  return {
    currentDemoConfigId,
    setCurrentDemoConfigId,
    openAiAppKey,
    setOpenAiAppKey,
  };
}
