import React from "react";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";

interface ThrottleRateControlProps {
  direction: "horizontal" | "vertical";
  throttle_rate: number;
  setThrottleRate: React.Dispatch<React.SetStateAction<number>>;
  defaultMinimumRate: number;
  defaultMaximumRate: number;
  height?: number;
  width?: number;
}

const ThrottleRateControl: React.FC<ThrottleRateControlProps> = ({
  direction,
  throttle_rate = 3,
  setThrottleRate,
  defaultMinimumRate = 100,
  defaultMaximumRate = 1,
  height = 200,
  width = 300,
}) => {
  function preventHorizontalKeyboardNavigation(event: React.KeyboardEvent) {
    if (
      direction === "vertical" &&
      (event.key === "ArrowLeft" || event.key === "ArrowRight")
    ) {
      event.preventDefault();
    }
  }

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setThrottleRate(newValue as number);
  };

  const increaseRate = () => {
    setThrottleRate((prevRate) => Math.min(prevRate + 1, defaultMaximumRate));
  };

  const decreaseRate = () => {
    setThrottleRate((prevRate) => Math.max(prevRate - 1, defaultMinimumRate));
  };

  return (
    <Box sx={{ width: width }}>
      <Typography variant="h6">Throttle Rate (per second)</Typography>

      <Stack
        direction={direction === "horizontal" ? "row" : "column"}
        spacing={0.5}
        alignItems="center"
      >
        <IconButton onClick={decreaseRate}>
          <ArrowCircleDownIcon />
        </IconButton>

        <Slider
          sx={{
            '& input[type="range"]': {
              WebkitAppearance:
                direction === "vertical"
                  ? "slider-vertical"
                  : "slider-horizontal",
            },
            height: direction === "vertical" ? height : undefined,
          }}
          orientation={direction}
          onKeyDown={preventHorizontalKeyboardNavigation}
          value={throttle_rate}
          onChange={handleSliderChange}
          aria-labelledby="throttle-rate-slider"
          min={defaultMinimumRate}
          max={defaultMaximumRate}
        />

        <IconButton onClick={increaseRate}>
          <ArrowCircleUpIcon />
        </IconButton>
      </Stack>

      <Typography>Current Rate: {throttle_rate}</Typography>
    </Box>
  );
};

export default ThrottleRateControl;
