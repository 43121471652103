import React, { useState, useContext, useEffect } from "react";

// MUI components
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// custom components
import RegionSelector from "components/RegionSelector/RegionSelector";
import useAppState from "store/appState";
import AuthContext from "store/AuthContext";
import useServerStateMutations, {
  useDemoConfig,
  useOrgScenarios,
} from "store/serverState";

import { Logger } from "aws-amplify";
const logger = new Logger("CloneOrgDialog", "INFO");

export default function CloneOrgDialog({ open, setOpen }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { setCurrentDemoConfigId, currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);
  const { orgScenerios } = useOrgScenarios(currentDemoConfigId);
  const { cloneDemoConfig, addOrgScenarios } = useServerStateMutations();
  const { user } = useContext(AuthContext);

  // prime with current demo config data
  const [apiKey, setApiKey] = useState(demoConfig.api_key);
  const [spocEmail, setSpocEmail] = useState(demoConfig.spoc_email);
  const [region, setRegion] = useState(demoConfig.region || "USA");
  const [name, setName] = useState("");

  useEffect(() => {
    setApiKey(demoConfig.api_key);
    setSpocEmail(demoConfig.spoc_email);
    setRegion(demoConfig.region || "USA");
  }, [demoConfig.api_key, demoConfig.spoc_email, demoConfig.region]);

  const handleCloseDialog = () => {
    setApiKey(demoConfig.api_key);
    setSpocEmail(demoConfig.spoc_email);
    setRegion(demoConfig.region || "USA");
    setName("");
    setOpen(false);
  };

  async function handleCloneDemoConfigFormDialogSubmit(event) {
    event.preventDefault();
    let scenarioIDsToAdd = orgScenerios.map(
      (orgScenario) => orgScenario.scenarioID
    );
    const newOrgRecord = {
      name,
      api_key: apiKey,
      spoc_email: spocEmail,
      region,
      integrations: demoConfig.integrations,
      params: demoConfig.params,
      owners: [user.username],
    };
    logger.info("Cloning as new DemoConfig:", newOrgRecord);
    cloneDemoConfig
      .mutateAsync(newOrgRecord)
      .then(async (newOrgData) => {
        setCurrentDemoConfigId(newOrgData.id);
        if (scenarioIDsToAdd.length > 0)
          addOrgScenarios.mutateAsync({
            orgID: newOrgData.id,
            scenarioIDs: scenarioIDsToAdd,
            owners: newOrgData.owners,
          });
      })
      .then(handleCloseDialog);
  }

  return (
    <Dialog
      id="clone-form-dialog"
      data-test="clone-form-dialog"
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="clone-form-dialog"
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
      // disableEnforceFocus
      // keepMounted
    >
      <form onSubmit={handleCloneDemoConfigFormDialogSubmit}>
        <DialogTitle>Cloning {demoConfig.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>All fields are required.</DialogContentText>
          <Stack direction="column" spacing={2}>
            <TextField
              id="input-clone-name"
              inputProps={{ "data-test": "input-clone-name" }}
              autoFocus
              required
              margin="dense"
              value={name}
              onChange={(event) => setName(event.target.value)}
              label="New Name"
              type="text"
              variant="standard"
              helperText="Name of the Demo Config"
            />
            <TextField
              id="input-clone-api_key"
              data-lpignore="true"
              inputProps={{
                "data-test": "input-clone-api_key",
                "data-lpignore": true,
              }}
              required
              margin="dense"
              value={apiKey}
              onChange={(event) => setApiKey(event.target.value)}
              label="New BigPanda Org User API Key"
              // type="password"
              variant="standard"
              helperText="BPUAKxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
              error={
                apiKey.length > 0 &&
                (!apiKey.match(/^BPUAK[0-9a-zA-Z]{32}$/) ||
                  apiKey.length !== 37)
              }
            />
            <TextField
              id="input-clone-spoc_email"
              data-lpignore="true"
              inputProps={{
                "data-test": "input-clone-spoc_email",
                "data-lpignore": true,
              }}
              required
              margin="dense"
              value={spocEmail}
              onChange={(event) => setSpocEmail(event.target.value)}
              label="New SPOC Email"
              type="email"
              variant="standard"
              helperText="Single Point of Contact Email"
            />
            <RegionSelector region={region} setRegion={setRegion} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            id="btn-clone-form-submit"
            data-test="btn-clone-form-submit"
            type={"submit"}
            disabled={name.length === 0}
          >
            Clone
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
