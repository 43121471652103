import Button from "@mui/material/Button";
import CloneCircleOutlineIcon from "@mui/icons-material/ControlPointDuplicate";
import Tooltip from "@mui/material/Tooltip";
import { type MRT_RowSelectionState } from "material-react-table";

interface MrtToolbarButtonCloneProps {
  recordDescription: string;
  dataRowsSelection: MRT_RowSelectionState;
  onClone: () => void;
}

export default function MrtToolbarButtonClone({
  recordDescription,
  dataRowsSelection,
  onClone,
}: MrtToolbarButtonCloneProps) {
  return (
    <Tooltip title={`Clone selected ${recordDescription}(s)`}>
      <span>
        <Button
          variant="contained"
          size="small"
          startIcon={<CloneCircleOutlineIcon />}
          onClick={onClone}
          disabled={Object.keys(dataRowsSelection).length === 0}
        >
          {`Clone ${recordDescription}(s)`}
        </Button>
      </span>
    </Tooltip>
  );
}
