import React from 'react'
import { Typography } from "@mui/material";
import Box from '@mui/material/Box'

export default function Analytics() {
    return (
        <>
          <Typography>
            Got an idea for a chart? Contact SWAT via email at{" "}
            {
              <a href="mailto:swat@bigpanda.io" rel="noreferrer" target="_blank">
                swat@bigpanda.io
              </a>
            }
          </Typography>
          <Box margin={2}>
            <iframe title="DemoSim Metrics" src="https://analytics.amplitude.com/share/b1a3eb9ca84547a5a77612b3b0980097/tv" width={1700} height={3500} />
          </Box>
        </>
      );
}
