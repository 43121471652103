import {
    type MRT_RowData,
    type MRT_Row,
    type MRT_TableOptions,
    type MRT_RowSelectionState,
    type MRT_Cell,
} from 'material-react-table';
import { removeUndefined, updateObject } from "../../lib/transform_funcs";
import { v4 as uuidv4 } from "uuid";
import { Logger } from "aws-amplify";
import { Theme } from "@mui/material/styles";

const logger = new Logger("MRT_Factories", "INFO");

//define re-useable default table options for all tables in your app
export const getDefaultMRTOptions = <TData extends MRT_RowData>(
    theme: Theme
): Partial<MRT_TableOptions<TData>> => ({
    enableEditing: true,
    editDisplayMode: "cell",
    enableColumnResizing: true,
    enableColumnActions: true,
    enableCellActions: true,
    enableClickToCopy: "context-menu",
    createDisplayMode: "row",
    layoutMode: "grid-no-grow",
    enableRowNumbers: true,
    enableGrouping: true,
    groupedColumnMode: "reorder",
    enablePagination: false,
    enableRowVirtualization: true,
    enableColumnVirtualization: true,
    enableBottomToolbar: false,
    enableRowSelection: true,
    enableBatchRowSelection: true,
    enableMultiRowSelection: true,
    enableSelectAll: true,
    selectAllMode: "all",
    enableStickyHeader: true,
    muiTablePaperProps: {
        elevation: 0,
        sx: {
            borderRadius: "0",
            border: `1px dashed ${theme.palette.divider}`,
            maxWidth: "100%",
            width: "100%",
            overflow: "hidden",
            boxSizing: "border-box",
        },
    },
    muiTableContainerProps: {
        sx: {
            // maxHeight: "600px",
            maxHeight: "100%",
            maxWidth: "100%",
            width: "100%",
            overflowX: "auto",
            overflowY: "auto",
            display: "block",
            "& table": {
                tableLayout: "auto",
                width: "100%",
                minWidth: "100%",
            },
        },
    },
    muiTableBodyProps: {
        sx: {
            "& tr:nth-of-type(odd) > td": {
                backgroundColor: theme.palette.mode === "dark"
                    ? theme.palette.action.hover // Use a dark-mode-friendly hover shade
                    : "#f5f5f5", // Light mode color
            },
            "& tr:nth-of-type(even) > td": {
                backgroundColor: theme.palette.background.default, // Background color for even rows
            },
        },
    },
    displayColumnDefOptions: {
        "mrt-row-numbers": {
            enableResizing: false,
            size: 60,
            grow: false,
        },
        "mrt-row-select": {
            enableResizing: false,
            size: 10,
            grow: false,
        },
    },
});

// generic function factories
export const createHandleCreatingRowChange = <TData>({
    setValidationErrors = () => { },
    validateFn = () => ({}),
}: {
    setValidationErrors?: React.Dispatch<React.SetStateAction<Record<string, any>>>,
    validateFn?: (data: TData) => Record<string, any>,
}): MRT_TableOptions<TData>["onCreatingRowChange"] => {
    return (row) => {
        logger.info("HandleCreatingRowChange updating row", row)
        //@ts-ignore
        if (Boolean(row)) {
            const updatedRow = updateObject(
                //@ts-ignore
                { ...row.original },
                //@ts-ignore
                { ...row._valuesCache },
            );
            logger.info("HandleCreatingRowChange updatedRow:", updatedRow)

            const updatedRowValidation = validateFn(updatedRow)
            logger.info(`updatedRowValidation:`, updatedRowValidation)
            setValidationErrors((prev) => {
                logger.info(`prev validation errors:`, prev)
                const newValidationErrors = {
                    ...prev,
                    ...removeUndefined(updatedRowValidation)
                };
                logger.info(`newValidationErrors:`, newValidationErrors)
                return newValidationErrors
            });
        }
    }
};

export const createHandleCreatingRowCancel = <TData extends { id: string }>({
    setValidationErrors = () => { },
}: {
    setValidationErrors?: React.Dispatch<React.SetStateAction<Record<string, any>>>,
}): MRT_TableOptions<TData>["onCreatingRowCancel"] => {
    return ({ row }) => {
        logger.info("HandleCreatingRowCancel setValidationErrors, removing row:", row.original)
        setValidationErrors((prev) => {
            const { id } = row.original;
            const { [id]: _, ...rest } = prev;
            logger.info("HandleCreatingRowCancel setValidationErrors, returning:", rest)
            return rest;
        });
    };
};

export const createHandleCreatingRowSave = <TData>({
    setIsDataChanged = () => { },
    setValidationErrors = () => { },
    validateFn = () => ({}),
    sortFn = ((a, b) => 0),
    setData,
}: {
    setIsDataChanged?: React.Dispatch<React.SetStateAction<boolean>>,
    setValidationErrors?: React.Dispatch<React.SetStateAction<Record<string, any>>>,
    validateFn?: (data: TData) => Record<string, any>,
    sortFn?: (a: TData, b: TData) => number,
    setData: React.Dispatch<React.SetStateAction<TData[]>>,
    }): MRT_TableOptions<TData>["onCreatingRowSave"] => {
    return ({ exitCreatingMode, row, values }) => {
        setIsDataChanged(true);
        const updatedRow = updateObject(
            { ...row.original },
            { ...values },
        );
        logger.info("HandleCreatingRowSave updatedRow:", updatedRow)
        setValidationErrors((prev) =>
            removeUndefined({
                ...prev,
                ...validateFn(updatedRow),
            })
        );

        setData((prev) => [...prev, updatedRow].sort(sortFn));

        exitCreatingMode();
    };
};

export const createHandleEditingRowSave = <TData extends { id: string }>({
    setIsDataChanged = () => { },
    setValidationErrors = () => { },
    validateFn = () => ({}),
    sortFn = ((a, b) => 0),
    setData,
}: {
    setIsDataChanged?: React.Dispatch<React.SetStateAction<boolean>>,
    setValidationErrors?: React.Dispatch<React.SetStateAction<Record<string, any>>>,
    validateFn?: (data: TData) => Record<string, any>,
    sortFn?: (a: TData, b: TData) => number,
    setData: React.Dispatch<React.SetStateAction<TData[]>>,
    }): MRT_TableOptions<TData>["onEditingRowSave"] => {
    return ({ exitEditingMode, row, values }) => {
        setIsDataChanged(true);
        const updatedRow = updateObject(
            { ...row.original },
            { ...values },
        );
        logger.info("HandleEditingRowSave updatedRow:", updatedRow)
        setValidationErrors((prev) =>
            removeUndefined({
                ...prev,
                ...validateFn(updatedRow),
            })
        );

        setData((prev) =>
            prev.map((item) => (item.id === updatedRow.id ? updatedRow : item)).sort(sortFn)
        );

        exitEditingMode();
    };
};

export const createHandleRowUpdate = <TData extends { id: string }>({
    setIsDataChanged = () => { },
    setValidationErrors = () => { },
    validateFn = () => ({}),
}: {
    setIsDataChanged?: React.Dispatch<React.SetStateAction<boolean>>,
    setValidationErrors?: React.Dispatch<React.SetStateAction<Record<string, any>>>,
    validateFn?: (data: TData) => Record<string, any>,
}): ((row: TData) => void) => {
    return (row) => {
        logger.info("HandleRowUpdate updatedRow:", row)
        setIsDataChanged(true);
        setValidationErrors((prev) => {
            return {
                ...prev,
                ...removeUndefined(validateFn(row))
            }
        });
    };
};

export const createHandleMuiEditTextFieldOnChange = <TData extends { id: string }>({
    setIsDataChanged = () => { },
    setValidationErrors = () => { },
    validateFn = () => ({}),
}: {
    setIsDataChanged?: React.Dispatch<React.SetStateAction<boolean>>,
    setValidationErrors?: React.Dispatch<React.SetStateAction<Record<string, any>>>,
    validateFn?: (data: TData) => Record<string, any>,
}): ((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, row: MRT_Row<TData>, cell: MRT_Cell<TData>) => void) => {
    return (event, row, cell) => {
        if (row.id !== "mrt-row-create" && cell.column.columnDef.editVariant === "select") {
            const newRow = { ...row.original };
            // Find the object level where to add the new column
            const keys = cell.column.id.split("."); // Break the path into its levels
            let targetObject = newRow;
            logger.info("targetObject keys:", keys, targetObject);

            // Traverse the object until reaching the appropriate level
            for (let i = 0; i < keys.length - 1; i++) {
                targetObject = targetObject[keys[i]];
            }
            logger.info("targetObject:", targetObject);
            // Add the new property at the same level as the selected column
            targetObject[keys[keys.length - 1]] = event.target.value;
            logger.info("Handling change for event row", newRow, event);
            setIsDataChanged(true);
            setValidationErrors((prev) => {
                return {
                    ...prev,
                    ...removeUndefined(validateFn(newRow))
                }
            });

        }
    };
};

export const createHandleMuiEditTextFieldOnBlur = <TData extends { id: string }>({
    setIsDataChanged = () => { },
    setValidationErrors = () => { },
    validateFn = () => ({}),
    setData,
}: {
    setIsDataChanged?: React.Dispatch<React.SetStateAction<boolean>>,
    setValidationErrors?: React.Dispatch<React.SetStateAction<Record<string, any>>>,
    validateFn?: (data: TData) => Record<string, any>,
    setData: React.Dispatch<React.SetStateAction<TData[]>>,
}): ((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, row: MRT_Row<TData>, cell: MRT_Cell<TData>) => void) => {
    return (event, row, cell) => {
        if (row.id !== "mrt-row-create") {
            setData((prev) => {
                const newRow = prev.find((item) => item.id === row.id);
                // Find the object level where to add the new column
                const keys = cell.column.id.split("."); // Break the path into its levels
                let targetObject = newRow;
                logger.info("targetObject keys:", keys, targetObject);

                // Traverse the object until reaching the appropriate level
                for (let i = 0; i < keys.length - 1; i++) {
                    targetObject = targetObject[keys[i]];
                }
                logger.info("targetObject:", targetObject);
                // Add the new property at the same level as the selected column
                targetObject[keys[keys.length - 1]] = event.target.value;
                logger.info("Handling change for row", newRow, event);
                setValidationErrors((prev) => {
                    return {
                        ...prev,
                        ...removeUndefined(validateFn(newRow))
                    }
                });

                return prev.map((item) => (item.id === newRow.id ? newRow : item));
            });
            setIsDataChanged(true);
        }
    };
};

export const createHandleDeleteSelected = <TData extends { id: string }>({
    setIsDataChanged = () => { },
    setValidationErrors = () => { },
    setSelection,
    setData,
    selection,
}: {
    setIsDataChanged?: React.Dispatch<React.SetStateAction<boolean>>,
    setValidationErrors?: React.Dispatch<React.SetStateAction<Record<string, any>>>,
    setSelection: React.Dispatch<React.SetStateAction<MRT_RowSelectionState>>,
    setData: React.Dispatch<React.SetStateAction<TData[]>>,
    selection: MRT_RowSelectionState,
}): (() => Promise<void>) => {
    return async () => {
        logger.info('createHandleDeleteSelected selection:', selection)
        setIsDataChanged(true);

        setValidationErrors((prev) =>
            removeUndefined(
                Object.keys(prev).reduce((acc, rowID) => {
                    return Object.keys(selection).includes(rowID)
                        ? acc
                        : { ...acc, [rowID]: prev[rowID] };
                }, {})
            )
        );

        setSelection({});
        return setData((prev) =>
            prev.filter((row) => !Object.keys(selection).includes(row.id))
        );
    };
};

export const createHandleCloneSelected = <TData extends { id: string; }>({
    setIsDataChanged = () => { },
    setValidationErrors = () => { },
    validateFn = () => ({}),
    sortFn = ((a, b) => 0),
    setSelection,
    setData,
    selection,
    data,
}: {
    setIsDataChanged?: React.Dispatch<React.SetStateAction<boolean>>,
    setValidationErrors?: React.Dispatch<React.SetStateAction<Record<string, any>>>,
    validateFn?: (data: TData) => Record<string, any>,
    sortFn?: (a: TData, b: TData) => number,
        setSelection: React.Dispatch<React.SetStateAction<MRT_RowSelectionState>>,
        setData: React.Dispatch<React.SetStateAction<TData[]>>,
    selection: MRT_RowSelectionState,
    data: TData[], // Current dataset
    }): (() => Promise<void>) => {
    return async () => {
        logger.info("createHandleCloneSelected selection:", selection)
        setIsDataChanged(true);

        // Clone the selected rows and generate new IDs
        const newClonedRows = data
            .filter((row) => Object.keys(selection).includes(row.id))
            .map((row) => ({ ...row, id: uuidv4() }));
        logger.info("createHandleCloneSelected newClonedRows:", newClonedRows)

        // Validate all rows and update validation errors
        setValidationErrors((prev) => {
            const newValidations = newClonedRows.reduce((acc, row) => ({
                ...acc,
                ...validateFn(row),
            }), {})

            return removeUndefined(
                {
                    ...prev,
                    ...newValidations
                }
            )
        }
        );

        setSelection({}); // Clear the selection
        // Update the dataset with the cloned rows
        return setData((prev) =>
            [...prev, ...newClonedRows].sort(sortFn)
        );
    };
};