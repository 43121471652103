import React from "react";
import { Snackbar, Alert } from "@mui/material";

interface ErrorAlertProps {
  errorState: boolean; // Control visibility based on this prop
  errorMessage: string; // The error message to display
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({
  errorState,
  errorMessage,
}) => {
  return (
    <Snackbar
      open={errorState}
      anchorOrigin={{ vertical: "top", horizontal: "center" }} // Keeps the alert at the top
    >
      <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};

export default ErrorAlert;
