import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import useAppState from "store/appState";
import useServerStateMutations, { useDemoConfig } from "store/serverState";

const AddIntegrationTypeDialog = () => {
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);
  const { updateDemoConfig } = useServerStateMutations();
  const [open, setOpen] = React.useState(false);
  const [integrationType, setIntegrationType] = useState("");
  const [primaryProperty, setPrimaryProperty] = useState("");
  const [secondaryProperty, setSecondaryProperty] = useState("");

  const handleAddIntegrationTypeButtonClick = () => {
    if (
      !demoConfig.integrations.find(
        (i) => i.integration_type === integrationType
      )
    ) {
      updateDemoConfig
        .mutateAsync({
          ...demoConfig,
          integrations: [
            ...demoConfig.integrations,
            {
              bp_integration: null,
              integration_type: integrationType,
              primary_property: primaryProperty,
              secondary_property: secondaryProperty,
            },
          ],
        })
        .then(() => setOpen(false));
    } else setOpen(false)
  };

  return (
    <div>
      <Button
        id="add-integration-type-button"
        data-test="add-integration-type-button"
        variant="contained"
        size="small"
        color="success"
        startIcon={<AddIcon />}
        onClick={() => setOpen(true)}
      >
        New Integration Type
      </Button>
      <Dialog
        data-test="add-integration-type-dialog"
        open={open}
        onClose={() => setOpen(false)}
        disableEnforceFocus
      >
        <DialogTitle>New Integration Type</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <DialogContentText>
              Fill in these fields to create the new integration type
            </DialogContentText>
            <Divider variant="middle" />
            <TextField
              inputProps={{ "data-test": "new-integration-type-input" }}
              required
              label="Integration Type"
              helperText="This is the integration_type that you would choose in a scenario alert"
              margin="dense"
              value={integrationType}
              onChange={(e) => {
                setIntegrationType(e.target.value);
              }}
            >
              Integration Type
            </TextField>
            <TextField
              inputProps={{
                "data-test": "new-integration-type-primary-property",
              }}
              required
              label="Primary Property"
              helperText="The required primary tag"
              margin="dense"
              value={primaryProperty}
              onChange={(e) => {
                setPrimaryProperty(e.target.value);
              }}
            >
              Primary Property
            </TextField>
            <TextField
              inputProps={{
                "data-test": "new-integration-type-secondary-property",
              }}
              required
              label="Secondary Property"
              helperText="The required secondary tag"
              margin="dense"
              value={secondaryProperty}
              onChange={(e) => {
                setSecondaryProperty(e.target.value);
              }}
            >
              Secondary Property
            </TextField>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            data-test="new-integration-type-cancel-button"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            data-test="new-integration-type-create-button"
            onClick={handleAddIntegrationTypeButtonClick}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddIntegrationTypeDialog;
