import React from "react";
import { Autocomplete, TextField, Chip } from "@mui/material";

interface FreeSoloChipsProps {
  data: string[];
  setData: (newData: string[]) => void;
  label: string;
  disabled?: boolean;
  validate?: (newValue: string, newDataset?: string[]) => boolean;
  options?: string[]; // New options prop for predefined values
}

const FreeSoloChips: React.FC<FreeSoloChipsProps> = ({
  data,
  setData,
  label,
  disabled,
  validate,
  options = [], // Default to an empty array if no options are provided
}) => {
  const handleAdd = (
    event: React.KeyboardEvent<HTMLDivElement>,
    value: string | null
  ) => {
    if (value && !data.includes(value)) {
      const isValid = validate ? validate(value, [...data, value]) : true;
      if (isValid) {
        setData([...data, value]);
      }
    }
  };

  const handleDelete = (chipToDelete: string) => {
    const proposedData = data.filter((chip) => chip !== chipToDelete);
    const isValid = validate ? validate(chipToDelete, proposedData) : true;
    if (isValid) {
      setData(proposedData);
    }
  };

  return (
    <Autocomplete
      multiple
      freeSolo={!options.length} // Disable free solo mode if options are provided
      disableClearable
      selectOnFocus
      handleHomeEndKeys
      fullWidth
      sx={{ minHeight: "max-content", pt: 1 }}
      options={options} // Use the options provided by the caller
      value={data}
      onChange={(event, newValue) => {
        setData(newValue as string[]);
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter" && !options.length) {
          // Handle adding only if free solo is enabled
          const inputElement = event.target as HTMLInputElement;
          if (inputElement.value) {
            handleAdd(event, inputElement.value);
            inputElement.value = ""; // Clear the input after adding
          }
        }
      }}
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: string, index: number) => {
          let { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              key={key}
              label={option}
              {...tagProps}
              onDelete={() => handleDelete(option)}
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          disabled={disabled}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            readOnly: options.length > 0, // Set readOnly if options are provided
          }}
        />
      )}
      disabled={disabled}
    />
  );
};

export default FreeSoloChips;
