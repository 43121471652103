import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileOpenIcon from "@mui/icons-material/FileOpen";

type Props = {
  type: string;
  text: string;
  accept: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function InputFileOpen({ type, text, accept, onChange }: Props) {
  return (
    <Button
      component="label"
      variant="contained"
      startIcon={<FileOpenIcon />}
      // set width to content of text
      sx={{ margin: 2, width: "fit-content", height: "fit-content" }}
    >
      {text}
      <VisuallyHiddenInput type={type} accept={accept} onChange={onChange} />
    </Button>
  );
}
