import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
// import { Logger } from "aws-amplify";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
// Icons
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SettingsIcon from "@mui/icons-material/Settings";
import InputIcon from "@mui/icons-material/Input";
import ListIcon from "@mui/icons-material/List";
import MovieIcon from "@mui/icons-material/Movie";
// MUI components
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
// custom components
import OrgSelector from "components/OrgSelector/OrgSelector";
import OrgMetaTab from "./OrgMetaTab";
import OrgIntegrationsTab from "./OrgIntegrationsTab";
import OrgVariablesTab from "./OrgVariablesTab";
import OrgScenariosTab from "./OrgScenariosTab";
import OrgScheduleTab from "./OrgScheduleTab";
import useAppState from "store/appState";
import { Storage } from "aws-amplify";
import { useQueryClient } from "@tanstack/react-query";
import { listDemoConfigs } from "store/graphql-functions";
import AuthContext from "store/AuthContext";

// const logger = new Logger("DemoAdmin", "INFO");

const DemoAdmin = () => {
  const { user } = useContext(AuthContext);
  const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const mobileScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const { currentDemoConfigId } = useAppState();

  // React Query Prefetch
  const queryClient = useQueryClient();

  queryClient.prefetchQuery({
    queryKey: ["democonfigs", user.username],
    queryFn: listDemoConfigs,
  });

  const [signedUrlChromeExtension, setSignedUrlChromeExtension] =
    useState(null);
  useEffect(() => {
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "staging.d1qjl26xsbp2oi.amplifyapp.com"
    ) {
      // const signedURL = await Storage.get(key);
      Storage.get("chrome_extension/demosim-cloud-extension_staging.zip").then(
        (link) => setSignedUrlChromeExtension(link)
      );
    }
  }, [setSignedUrlChromeExtension]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`org-tabpanel-${index}`}
        data-test={`org-tabpanel-${index}`}
        aria-labelledby={`org-tab-${index}`}
        sx={{ display: "flex", flexGrow: 1, width: "100%" }}
        {...other}
      >
        {value === index && children}
      </Box>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `org-tab-${index}`,
      "aria-controls": `org-tabpanel-${index}`,
    };
  }
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
      }}
    >
      <Paper
        id="Demo Admin Page"
        className="RouteContainer"
        sx={{
          flexGrow: 1, // Allows it to expand when necessary
          flexShrink: 0, // Prevents shrinking too much
          padding: 2,
          maxWidth: "100%",
          // border: "1px dashed red",
          boxSizing: "border-box",
          minHeight: "calc(100vh - 64px - 64px)", // Subtract AppBar and Footer height
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h3">Demo Admin</Typography>
        <OrgSelector />
        {currentDemoConfigId && (
          <Box
            id="TabsContainer"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "stretch",
              alignItems: "center",
              width: "100%",
              flexWrap: "nowrap",
              // border: "1px dashed green",
              pb: 2,
            }}
          >
            <Tabs
              // sx={{
              //   maxWidth: { xs: 375, sm: 2400 },
              // }}
              variant={mobileScreen ? "fullWidth" : "scrollable"}
              scrollButtons
              allowScrollButtonsMobile
              value={tabValue}
              onChange={handleTabChange}
              aria-label="org tabs"
            >
              <Tab
                label="SETTINGS"
                icon={<SettingsIcon />}
                data-test="demoadmin-tab-settings"
                {...a11yProps(0)}
              />
              <Tab
                label="INTEGRATIONS"
                icon={<InputIcon />}
                data-test="demoadmin-tab-integrations"
                {...a11yProps(1)}
              />
              <Tab
                label="VARIABLES"
                icon={<ListIcon />}
                data-test="demoadmin-tab-variables"
                {...a11yProps(2)}
              />
              <Tab
                label="SCENARIOS"
                icon={<MovieIcon />}
                data-test="demoadmin-tab-scenarios"
                {...a11yProps(3)}
              />
              <Tab
                label="SCHEDULES"
                icon={<CalendarMonthIcon />}
                data-test="demoadmin-tab-scenarios"
                {...a11yProps(4)}
              />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <OrgMetaTab />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <OrgIntegrationsTab />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <OrgVariablesTab />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <OrgScenariosTab />
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
              <OrgScheduleTab />
            </TabPanel>
          </Box>
        )}
      </Paper>

      <Paper
        id="footer"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          py: 1,
          mb: 2,
          width: "100%", // Full width
          boxSizing: "border-box",
        }}
        elevation={3}
      >
        {window.location.hostname === "www.bpsalesengineering.com" && (
          <Link
            href="https://chrome.google.com/webstore/detail/jjepeadnbaojmpmbdbbmahbckghonhkm"
            target="_blank"
            rel="noopener noreferrer"
          >
            DemoSim Chrome Extension
          </Link>
        )}
        {window.location.hostname === "localhost" && (
          <Link
            href={signedUrlChromeExtension}
            target="_blank"
            rel="noreferrer"
          >
            Download DemoSim Chrome Extension for Staging
          </Link>
        )}
        {window.location.hostname ===
          "staging.d1qjl26xsbp2oi.amplifyapp.com" && (
          <Link
            href={signedUrlChromeExtension}
            target="_blank"
            rel="noreferrer"
          >
            Download DemoSim Chrome Extension for Staging
          </Link>
        )}
        <Link
          href="https://docs.bigpanda.io/docs/demosim"
          target="_blank"
          rel="noopener noreferrer"
        >
          DemoSim and Demo Admin Documentation
        </Link>
      </Paper>
    </Box>
  );
};

export default DemoAdmin;
