import { useState, useEffect, useCallback, useMemo } from "react";
import { Logger } from "aws-amplify";
import ReactJson from "@microlink/react-json-view";
import isJSON from "is-json";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

// MUI components
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

// Custom components
import OrgSelector from "../../../components/OrgSelector/OrgSelector";
import IncidentSearch from "../../../components/IncidentSearch/IncidentSearch";
import HorizontalLinearStepper, {
  WizardStepProps,
} from "../../../components/WizardStepper/HorizontalLinearStepper";

// Custom hooks and functions
import useAppState from "../../../store/appState.js";
import { useDemoConfig, useBpCorrPattns } from "../../../store/serverState.js";
import { isValidJSON } from "../../../lib/transform_funcs";
import { getEncoding, getEncodingNameForModel } from "js-tiktoken";
import {
  queryOpenAI,
  getTokenCount,
  splitArrayByTokenCount,
  removeFirstAndLastIfJSONBlock,
  tightStringify,
  mergeCorrelationSuggestions,
} from "../../../lib/ai_funcs";
import { TextField } from "@mui/material";
import Bottleneck from "bottleneck";
import LinearWithValueLabel from "../../../components/Progress/LinearWithValueLabel";

const logger = new Logger("AiAssist/CorrPattGen", "INFO");

const model = "gpt-4o-mini";
const encoding = getEncoding(getEncodingNameForModel("gpt-4o"));

// Setup throttling handler
    const queue = new Bottleneck({
      reservoir: 5, // initial value
      reservoirRefreshAmount: 5,
      reservoirRefreshInterval: 250, // must be divisible by 250
      minTime: 100,
      maxConcurrent: 5,
    });
    queue.on("idle", function () {
      logger.info("at this point we should be done with all stage 1 posting");
      // setPosting(false);
      // setSubmitStage(2);
    });
    queue.on("failed", async (error, jobInfo: any) => {
      // if (error.code === "ECONNABORTED") {
        logger.warn(
          `Throttle error posting prompt ${jobInfo.options.id}, Retrying after 1 second.`,
          error
        );
        return 1000; // wait 1000ms before retrying
      // } else {
      //   logger.error(`Failed to post prompt ${jobInfo.options.id}`, error);
      // }
    });
    queue.on("done", function () {});

export default function CorrPattGen() {
  // Global state
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);
  const { data: bpCorrPattns } = useBpCorrPattns({
    region: demoConfig.region,
    api_key: demoConfig.api_key,
  });

  // Local state
  const [selectedIncidents, setSelectedIncidents] = useState([]);
  const [selectedIncidentAlerts, setSelectedIncidentAlerts] = useState([]);
  const [llmResponse, setLlmResponse] = useState(null);
  const [llmResponseError, setLlmResponseError] = useState([]);
  const [llmResponseLoading, setLlmResponseLoading] = useState(false);
  const [analysisCost, setAnalysisCost] = useState(0);
  const [tokenEstimate, setTokenEstimate] = useState(0);
  const [goals, setGoals] = useState("");
  const [patternResults, setPatternResults] = useState("");
  const [mergedReports, setMergedReports] = useState({
    correlationSuggestions: [],
    incidents: [],
  });
  const [submitStage, setSubmitStage] = useState(0);
  const [totalToPost, setTotalToPost] = useState(0);
  const [candidateArrayCount, setCandidateArrayCount] = useState(0);
  const [normalizedProgress, setNormalizedProgress] = useState(0);

  // reset local state
  const handleReset = () => {
    setSelectedIncidents([]);
    setSelectedIncidentAlerts([]);
    setLlmResponse(null);
    setLlmResponseError([]);
    setLlmResponseLoading(false);
    setAnalysisCost(0);
    setTokenEstimate(0);
    setGoals("");
    setPatternResults("");
    setMergedReports({ correlationSuggestions: [], incidents: [] });
    setSubmitStage(0);
    setTotalToPost(0);
    setCandidateArrayCount(0);
    setNormalizedProgress(0);
  };

  // update token and analysis cost based on selected incidents
  useEffect(() => {
    if (selectedIncidents.length === 0) {
      setTokenEstimate(0);
      setAnalysisCost(0);
      return;
    }
    // calculate the cost of the tokens where the cost is $0.150 per 1M tokens for the gpt-4o-mini model
    const incidentAlerts = selectedIncidents.reduce((acc, incident) => {
      return acc.concat(incident.alerts);
    }, []);
    let tknEstimate =
      getTokenCount(encoding, generateMessages("", incidentAlerts)) + 2000;
    setTokenEstimate(tknEstimate);
    let cost = (tknEstimate * 0.15) / 1000000;
    setAnalysisCost(Number((Math.round(cost * 100) / 100).toFixed(2)));
    setSelectedIncidentAlerts(incidentAlerts);
  }, [selectedIncidents]);

  const getLlmResponse = useCallback(() => {
    const promptTokenCount = getTokenCount(encoding, generateMessages());
    const arrayOfCandidates = splitArrayByTokenCount(
      128000,
      promptTokenCount + 2000,
      encoding,
      selectedIncidentAlerts
    );
    logger.info("arrayOfCandidates:", arrayOfCandidates);
    setTotalToPost(arrayOfCandidates.length + 1);
    setCandidateArrayCount(arrayOfCandidates.length + 1);
    setLlmResponseLoading(true);
    setLlmResponse(null);
    setLlmResponseError([]);
    setSubmitStage(1);
    setNormalizedProgress(0);

    arrayOfCandidates.forEach((candidateArray, index) => {
      queue.schedule(
        {
          id: `${index}`,
          expiration: 300000,
        },
        () => {
          return queryOpenAI({
            messages: generateMessages(goals, candidateArray),
            model: model,
            temperature: 0,
          })
            .then((response) => {
              logger.info("Post result:", response);
              let responseContent = removeFirstAndLastIfJSONBlock(
                response.choices[0]?.message?.content
              );
              if (isJSON(responseContent)) {
                const responseObject = JSON.parse(responseContent);
                setMergedReports((prev) => {
                  return {
                    incidents: prev?.incidents.concat(
                      responseObject?.incidents
                    ),
                    correlationSuggestions: mergeCorrelationSuggestions([
                      ...prev?.correlationSuggestions,
                      ...responseObject?.correlationSuggestions,
                    ]),
                  };
                });
              }
            })
            .catch((error: Error) => {
              if (error instanceof Bottleneck.BottleneckError) {
                logger.error(`Scheduling error`, error);
                // append error message to the end of the llmResponseError array
                setLlmResponseError((prev) => {
                  return [
                    `Scheduling error - ${error.name}: ${error.message}`,
                    ...prev,
                  ];
                });
              } else {
                logger.error(error);
                setLlmResponseError((prev) => {
                  return [`${error.name}: ${error.message}`, ...prev];
                });
              }
              logger.error(
                "Error in postStage1",
                JSON.stringify(error, null, 2)
              );
              throw error;
            })
            .finally(() => {
              setTotalToPost((prev) => prev - 1);
            });
        }
      );
    });
  }, [selectedIncidentAlerts, goals]);

  // increment normalizedProgress as each candidateArray is posted
  useEffect(() => {
    if (submitStage === 1) {
      setNormalizedProgress(
        ((candidateArrayCount - totalToPost) / candidateArrayCount) * 100
      );
      if (totalToPost === 1) {
        setSubmitStage(2);
      }
    }
  }, [totalToPost, candidateArrayCount, submitStage]);

  // stage 2: analyze the merged reports. totalToPost = 1
  useEffect(() => {
    if (
      submitStage < 2 ||
      mergedReports.correlationSuggestions.length === 0 ||
      mergedReports.incidents.length === 0
    )
      return;

    {
      const finalMergePrompt = getFinalMergePrompt(
        goals,
        bpCorrPattns,
        mergedReports
      );

      queryOpenAI({
        messages: [
          {
            role: "system",
            content:
              "You are an IT systems administrator with expertise in IT monitoring and observability, the creation of alert correlation patterns, and alert enrichment.",
          },
          {
            role: "user",
            content: finalMergePrompt,
          },
        ],
        model: model,
        temperature: 0,
      })
        .then((response) => response.choices[0].message.content)
        .then((finalMergeResponse) => {
          let finalReportSummary = finalMergeResponse.substring(
            0,
            finalMergeResponse.indexOf("###json")
          ); //?
          let finalCorrelationSuggestionsObject = JSON.parse(
            finalMergeResponse.substring(finalMergeResponse.indexOf("["))
          );
          // extract JSON object from finalMergedResponse to populate patternResults
          setPatternResults(
            JSON.stringify(finalCorrelationSuggestionsObject, null, 2)
          );
          setLlmResponse(finalReportSummary);
        })
        .catch((error) => {
          logger.error("Error in getLlmResponse", error);
          setLlmResponseError((prev) => [
            `${error.name}: ${error.message}`,
            ...prev,
          ]);
        })
        .finally(() => {
          setNormalizedProgress(100);
          setTotalToPost(0);
          setLlmResponseLoading(false);
          setSubmitStage(0);
        });
    }
  }, [submitStage, mergedReports, goals, bpCorrPattns]);

  const steps: WizardStepProps[] = useMemo(() => {
    return [
      {
        label: `${
          demoConfig.bporgname
            ? `Using BigPanda Org:  ${demoConfig.bporgname}`
            : "Select/Create a Demo Config"
        }`,
        optional: false,
        description: `Correlation Patterns Suggestions Generator enables you to:
      - Select a subset of incidents from your BigPanda org
      - Use AI to analyse the selected incidents and suggest correlation patterns
      - Download the suggested correlation patterns report as a PDF
      - Recall previous analysis results
      - Add selected suggestions to your BigPanda org

      To perform the following steps you need a "Demo Config" with a BigPanda Org User API Key.

      If you've already created a Demo Config, select it.
      
      If you need to create a Demo Config, type a new record name into the Demo Config Selector.
      This will open a dialog; enter your BigPanda org's User API Key and your BigPanda email address.
      
      If you've entered the correct User API key, you'll see your BigPanda Org name in the box on the right
      and the step label will display the name of your BigPanda Org.`,
        // contextComponent: (

        // ),
        component: <OrgSelector />,
        gate: Boolean(demoConfig.bporgname),
      },
      {
        label: "Query BigPanda and select incidents",
        optional: false,
        description: `Use the query parameters here to filter incidents from BigPanda.

        The query results will be displayed in the Incident List. 

        None of the incidents will be selected by default; 
        you must select the incidents you want to use in your analysis.`,
        contextComponent: (
          <Stack
            id="Analysis Cost"
            direction="column"
            spacing={2}
            justifyContent="flex-start"
            alignItems="flex-start"
            width="50%"
          >
            <Stack direction="row">
              <Typography variant="h5">Analysis Cost:</Typography>
              <Typography variant="h5">${analysisCost.toFixed(2)}</Typography>
            </Stack>
            <Stack direction="row">
              <Typography variant="h5">Token Estimate:</Typography>
              <Typography variant="h5">
                {tokenEstimate.toLocaleString()}
              </Typography>
            </Stack>
            {tokenEstimate > 128000 && (
              <Typography variant="caption" color="error">
                Selected incidents will be analyzed in subsets of 128,000 tokens
                and results merged into one final report. It's highly
                recommended to reduce the number of selected incidents to keep
                the token count under 128,000.
              </Typography>
            )}
          </Stack>
        ),
        component: (
          <IncidentSearch
            selectedIncidents={selectedIncidents}
            setSelectedIncidents={setSelectedIncidents}
            expanded={true}
          />
        ),
        gate: selectedIncidents.length > 0,
      },
      {
        label: "Goals",
        optional: true,
        description: `Add supplementary goals and/or constraints for the AI to consider when generating the correlation patterns.`,
        component: (
          <TextField
            id="goals"
            label="Goals / Constraints"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={goals}
            onChange={(e) => setGoals(e.target.value)}
            sx={{ mt: 2 }}
          />
        ),
        gate: true,
      },
      {
        label: "Analyze",
        optional: false,
        description: `Click the ANALYZE button to suggest correlation patterns.`,
        component: (
          <Stack direction="column" spacing={2} margin={2}>
            {llmResponseLoading && (
              <LinearWithValueLabel progress={normalizedProgress} />
              // <LinearProgress />
            )}
            {Boolean(llmResponseError) &&
              (Array.isArray(llmResponseError) ? (
                llmResponseError.map((error, index) => (
                  <Typography key={index} color="error">
                    Error: {error.message}
                  </Typography>
                ))
              ) : (
                <Typography color="error">
                  Error: {`${llmResponseError}`}
                </Typography>
              ))}

            <Card>
              <CardHeader title="AI Analysis" />
              <CardContent>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={getLlmResponse}
                  sx={{ width: "200px" }}
                >
                  Analyze
                </Button>
                {llmResponse && (
                  <>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {llmResponse}
                    </ReactMarkdown>
                    <Stack
                      direction="column"
                      spacing={2}
                      sx={{ borderStyle: "ridge", padding: 1 }}
                    >
                      <Typography variant="body1">
                        This analysis is based on the AI engine's attempt to
                        correlated the alerts into incidents. That is, the
                        engine was asked to analyze the alerts, independent of
                        how they were correlated by BigPanda, and attempt to
                        correlate them into incidents.
                      </Typography>

                      <Typography variant="body1">
                        The engine was instructed to only suggest the most
                        effective combination of correlation patterns to achieve
                        the correct correlation of alerts into related
                        incidents. The engine was also instructed to contrast
                        the analysis with the current correlation patterns to
                        determine which patterns should be changed and which new
                        patterns should be added to achieve optimal correlation
                        of alerts into incidents. Further inspection of the
                        incidents may reveal correlation opportunities that the
                        AI engine did not identify.
                      </Typography>
                      <Typography variant="body1">
                        If you'd like to give context and guidance on how to
                        better correlate these alerts, go back to the Goals step
                        to provide guidance and then return here to re-run the
                        analysis.
                      </Typography>
                    </Stack>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                      AI-Correlated Incidents:
                    </Typography>

                    <ReactJson
                      src={mergedReports.incidents}
                      theme="monokai"
                      collapsed={true}
                      name={"incidents"}
                    />
                    <Typography variant="h6" sx={{ mt: 2 }}>
                      Correlation Suggestions:
                    </Typography>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ marginBottom: 2 }}
                      disabled={
                        !isValidJSON(patternResults) ||
                        llmResponseLoading ||
                        llmResponse === null ||
                        llmResponse === "" ||
                        llmResponse.length === 0
                      }
                      onClick={() => {
                        const blob = new Blob([patternResults], {
                          type: "application/json",
                        });
                        const url = URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.href = url;
                        a.download = `suggested_correlation_patterns-${demoConfig.bporgname}.json`;
                        a.click();
                      }}
                    >
                      Download Correlation Suggestions
                    </Button>
                    <ReactJson
                      src={
                        isValidJSON(patternResults)
                          ? JSON.parse(patternResults)
                          : {}
                      }
                      theme="monokai"
                      collapsed={false}
                      name={"Correlation Suggestions"}
                    />
                  </>
                )}
              </CardContent>
            </Card>
          </Stack>
        ),
        gate: true,
      },
    ];
  }, [
    demoConfig.bporgname,
    selectedIncidents,
    analysisCost,
    llmResponse,
    goals,
    llmResponseError,
    llmResponseLoading,
    tokenEstimate,
    patternResults,
    mergedReports.incidents,
    normalizedProgress,
    setSelectedIncidents,
    getLlmResponse,
  ]);

  return <HorizontalLinearStepper steps={steps} resetFunction={handleReset} />;
}

const generalConstraints = `
### General Constraints:
IMPORTANT: DO NOT HALLUCINATE ANY RESULTS AT ALL. YOU MUST ONLY USE THE DATA PROVIDED TO PERFORM YOUR ANALYSIS. YOUR GENERATED RESULTS MUST BE SOLELY DERIVED FROM THE PROVIDED DATA AND NOT MADE UP IN ANY WAY.

1) Only correlate alerts using the tags you find in the provided alerts.
1a) You may suggest new tags to correlate on if you extract them from the provided tags using a regex.

2) Use the following logic to determine which tags are appropriate for correlation:
2a) Good choices for correlation are: 
    - tags that describe attributes of the subject of the alert, such as the host, service, or application that the alert is related to.
    - tags that describe the context of the alert event, such as the environment, region, or datacenter that the alert is related to.
    - combination tags: a combination of two or more tags that more accurately describe the subject or context of the alert than any one tag would.

2b) Poor choices for correlation are: 
    - The tag referred to by the 'secondary_property' metadata field; this tag is often indicative of alert category or configuration.
    - Descriptive tags: any field that describes the type or nature of the alert.
    - Time-oriented tags: any field that indicates the time of the alert.
    - Measurement-oriented tags: any fields that contain threshold or metric values.
    - Metadata tags: any fields that reference other fields in the alert or represent the alert itself.
    - Personally Identifiable Information (PII) tags: any field related to an individual human.
    - Stateful tags: any field that describes the monitoring status or severity of the alert.
    - Escalation tags: any field that would indicate the actor(s) responsible for the alert or the escalation path.
    - Source tags: any field representing the source of the alert.

3) Correlation time windows focus specificity; therefore, you should typically use larger time windows for more specific scope (e.g. correlating on 'host') and smaller time windows for broader scope (e.g. correlating on 'datacenter').
3a) Time windows should not exceed 120 minutes.

4) IMPORTANT: When providing an extraction rule for a correlation pattern, the correlation pattern "tags" array MUST ONLY contain the extraction rule's "destination" tag. Do not include any other tag in the "tags" array when providing an extraction rule!

5) Extraction Rules:
    - Important: Limit to a single RegEx capture group for each regex - do not use multiple RegEx capture groups as that is not supported. 
    - Regex should be generic so that different alerts not present in the provided list of alerts would benefit from the extraction you suggest. 
    - Include the regex, tag you extract from, and new tag you create as part of the suggested correlation pattern whenever this extraction+correlation is part of your suggestion. 
    - You should name the new tag something descriptive of what you are extracting. Do not just call it 'newTag'
    - You should explain the regex you create in the 'explanation' section of the pattern suggestion so the user understands types of extracted values it may produce. Include an example source value and extracted value in your explanation.
    
6) When calculating individual suggestion compression rate, round to two decimal places (e.g. 95.47% or 0.9547).
`;
function generateMessages(goals = "", candidatesForCorrelation = []) {
  return [
    {
      role: "system",
      content: [
        {
          type: "text",
          text: "You are an BigPanda.io administrator with expertise in IT monitoring and observability, the creation of alert correlation patterns, and alert enrichment. Your overall goal is to use your understanding of IT systems, data, and observability systems to suggest improvement to BigPanda.io alert correlation and enrichment.",
        },
      ],
    },
    {
      role: "user",
      content: [
        {
          type: "text",
          text: `
### Input Data: Attached is a JSON object named "candidatesForCorrelation", which is a set of IT alerts that have been collected from a monitoring system. Each alert has a time stamp, a status (eg critical, warning, etc), a description, some other metadata that describes the alert itself and a set of properties (listed as "tags") that represent various attributes of the originating adverse event. The tags are key-value pairs that describe the alert in more detail.

### Task: Using your knowledge of IT systems and monitoring, correlate the alerts into one or more incidents and suggest correlation patterns that would effectively group alerts into related incidents. The goal is to reduce the number of incidents by grouping alerts that are related to the same issue. The correlation patterns should be based on the tags that are common across the alerts and should be specific enough to group alerts that are related to the same issue, but not so specific that unrelated alerts are grouped together.

### Instructions: Describe each incident, explaining the alerts that occurred and why they were correlated together. For each incident, provide the tags that were used to correlate the alerts, the time window used for the correlation, and any other relevant information that would help the user understand why the alerts were correlated together. If you use any extraction rules to correlate the alerts, provide the regex used, the source tag, the destination tag, and an example of the extracted value.

Return a JSON object that contains an array of incident summaries and an array of suggested correlation patterns (and optional extraction rules).

### Response: Your response must be valid JSON in the following schema:
    {
    "incidents": [
        {
            "id": 1,
            "title": "Incident 1",
            "description": "This incident is about... These are the events that occurred... [Anything else you want to add]",
            "tagsUsedForCorrelation": ["tag1", "tag2"]
        },
        {
            "id": 2,
            "title": "Incident 2",
            "description": "This incident is about... These are the events that occurred... [Anything else you want to add]",
            "tagsUsedForCorrelation": ["tag3", "tag4"]
        },
        {
            "id": 3,
            "title": "Incident 3",
            "description": "This incident is about... These are the events that occurred... [Anything else you want to add]",
            "tagsUsedForCorrelation": ["tag5"]
        }
    ],
    "correlationSuggestions": [
        {
            "tags": ["tag1, tag2"],
            "timeWindow": 20,
            "compressionRate": 0.9697,
            "alerts": 33,
            "incidents": 1,
            "explanation": "This pattern will... It will effectively capture issues where... [Anything else you want to add]",
            "exampleValues": [ "tag1ExampleValue1, tag1ExampleValue2", "tag2ExampleValue1 | tag2ExampleValue2" ]
        },
        {
            "tags": ["tag4"],
            "timeWindow": 60,
            "compressionRate": 0.6667,
            "alerts": 9,
            "incidents": 3,
            "extractionRule": {
                "source": "tag3",
                "destination": "tag4",
                "regex": "([a-zA-Z0-9]+)-.*",
                "template": "$1"
            },
            "explanation": "This pattern will... It will effectively capture issues where... The regex extraction will... [Anything else you want to add]",
            "exampleValues": [{"sourceVal": "value1", "extractedVal": "extractedVal1"}, {"sourceVal": "value2", "extractedVal": "extractedVal2"}]
        },
        {
            "tags": ["tag5"],
            "timeWindow": 120,
            "compressionRate": 0.9286,
            "alerts": 14,
            "incidents": 1,
            "explanation": "This pattern will... It will effectively capture issues where... [Anything else you want to add]",
            "exampleValues": ["value1", "value2", "value3"]
        }
    ]
}

${generalConstraints}

### Overrides: The following overriding goals and/or constraints (if any) take final precedence over the General Constraints: ${goals}

### candidatesForCorrelation: ${tightStringify(candidatesForCorrelation)}
`,
        },
      ],
    },
  ];
}

function getFinalMergePrompt(
  goals,
  currentCorrelationPatterns,
  CorrelationAnalysis
) {
  return `
    ### Input Data: Attached is a JSON object named "CorrelationAnalysis" which contains incident summaries ("incidents" array) and suggested correlation patterns ("correlationSuggestions" array) with optional extraction rules. The incident summaries describe the alerts that occurred, why they were correlated together, and the tags and time windows that were used to correlate the alerts. The suggested correlation patterns (and optional extraction rules) are provided in order to repeat the correlation process created the provided incidents. The "currentCorrelationPatterns" object contains correlation patterns from a currently running BigPanda instance, which will be considered for modification (see Task instructions).
    
    ### Task: Using your knowledge of IT Operations, analyze the incidents and all the suggested correlation patterns to determine the most effective combination that would result in the correct correlation of alerts into related incidents. Contrast your analysis with the current correlation patterns to determine which patterns should be changed and which new patterns should be added to achieve optimal correlation of alerts into incidents. If any current correlation patterns should be changed, highlight this in your final response. Above all, recommend which newly suggested patterns should be added to the current correlation patterns to improve the overall correlation effectiveness.

    When you refer to correlation patterns in the report, refer to them by the tags they correlate on, not like "Pattern 1", "Pattern 2", etc.

    Return your conclusion as a single paragraph in Github Flavored Markdown (gfm) format, followed by a valid JSON object (delineated by three pound signs) containing your final suggested correlation patterns and any extraction rules in the following schema:
    ###json
    [
        {
            "tags": ["tag1, tag2"],
            "timeWindow": 20,
            "compressionRate": 0.9697,
            "alerts": 33,
            "incidents": 1,
            "explanation": "This pattern will... It will effectively capture issues where... [Anything else you want to add]",
            "exampleValues": [ "tag1ExampleValue1, tag1ExampleValue2", "tag2ExampleValue1 | tag2ExampleValue2" ]
        },
        {
            "tags": ["tag4"],
            "timeWindow": 60,
            "compressionRate": 0.6667,
            "alerts": 9,
            "incidents": 3,
            "extractionRule": {
                "source": "tag3",
                "destination": "tag4",
                "regex": "([a-zA-Z0-9]+)-.*",
                "template": "$1"
            },
            "explanation": "This pattern will... It will effectively capture issues where... The regex extraction will... [Anything else you want to add]",
            "exampleValues": [{"sourceVal": "value1", "extractedVal": "extractedVal1"}, {"sourceVal": "value2", "extractedVal": "extractedVal2"}]
        },
        {
            "tags": ["tag5"],
            "timeWindow": 120,
            "compressionRate": 0.9286,
            "alerts": 14,
            "incidents": 1,
            "explanation": "This pattern will... It will effectively capture issues where... [Anything else you want to add]",
            "exampleValues": ["value1", "value2", "value3"]
        }
    ]

    ${generalConstraints}

    When performing these tasks, consider the following overriding goals and/or constraints (if any): ${goals}

    ### CorrelationAnalysis: ${tightStringify(CorrelationAnalysis)}
    ### currentCorrelationPatterns: ${tightStringify(currentCorrelationPatterns)}
    `;
}
